// Generated by purs version 0.14.3
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var showWithSpaces = function (dictShow) {
    return function (xs) {
        return Data_String_Common.joinWith(" ")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(dictShow))(xs));
    };
};
var printArray = function (dictShow) {
    return function (xs) {
        return Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(dictShow))(xs));
    };
};
module.exports = {
    printArray: printArray,
    showWithSpaces: showWithSpaces
};
