// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Hydra = require("../Hydra/index.js");
var Noodle_Network = require("../Noodle.Network/index.js");
var Noodle_Patch = require("../Noodle.Patch/index.js");
var network = function (toolkit) {
    return Noodle_Network["withPatch'"]("hydra")(function (patch) {
        return Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Noodle_Patch.addNodesFrom(toolkit)([ new Data_Tuple.Tuple("num", "freq"), new Data_Tuple.Tuple("num", "sync"), new Data_Tuple.Tuple("num", "offset"), new Data_Tuple.Tuple("osc", "osc"), new Data_Tuple.Tuple("out", "out") ])(patch))(Noodle_Patch.connect(new Data_Tuple.Tuple("freq", "num"))(new Data_Tuple.Tuple("osc", "freq"))))(Noodle_Patch.connect(new Data_Tuple.Tuple("sync", "num"))(new Data_Tuple.Tuple("osc", "sync"))))(Noodle_Patch.connect(new Data_Tuple.Tuple("offset", "num"))(new Data_Tuple.Tuple("osc", "offset"))))(Noodle_Patch["send'"](new Data_Tuple.Tuple("freq", "num"))(Hydra.num(60.0))))(Noodle_Patch["send'"](new Data_Tuple.Tuple("sync", "num"))(Hydra.num(0.1))))(Noodle_Patch["send'"](new Data_Tuple.Tuple("offset", "num"))(Hydra.num(0.0)));
    })(Noodle_Network.addPatch(new Data_Tuple.Tuple("hydra", Noodle_Patch.empty))(Noodle_Network.empty));
};
module.exports = {
    network: network
};
