// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var squigglyMap = function (dictFunctor) {
    return Data_Functor.map(dictFunctor);
};
var squigglyApply = function (dictApply) {
    return Control_Apply.apply(dictApply);
};
var semigroupSignal = new Data_Semigroup.Semigroup($foreign.merge);
var monoidSignal = function (dictMonoid) {
    return new Data_Monoid.Monoid(function () {
        return semigroupSignal;
    }, $foreign.constant(Data_Monoid.mempty(dictMonoid)));
};
var mergeMany = function (dictFunctor) {
    return function (dictFoldable) {
        return function (sigs) {
            var mergeMaybe = function (v) {
                return function (v1) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return v;
                    };
                    if (v instanceof Data_Maybe.Nothing) {
                        return v1;
                    };
                    if (v instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
                        return new Data_Maybe.Just($foreign.merge(v.value0)(v1.value0));
                    };
                    throw new Error("Failed pattern match at Signal (line 53, column 9 - line 53, column 33): " + [ v.constructor.name, v1.constructor.name ]);
                };
            };
            return Data_Foldable.foldl(dictFoldable)(mergeMaybe)(Data_Maybe.Nothing.value)(Data_Functor.map(dictFunctor)(Data_Maybe.Just.create)(sigs));
        };
    };
};
var functorSignal = new Data_Functor.Functor($foreign.mapSig);
var flippedMap = function (dictFunctor) {
    return Data_Function.flip(Data_Functor.map(dictFunctor));
};
var flatten = function (dictFunctor) {
    return function (dictFoldable) {
        return function (sig) {
            return $foreign.flattenArray(flippedMap(functorSignal)(sig)((function () {
                var $14 = Data_Foldable.fold(dictFoldable)(Data_Monoid.monoidArray);
                var $15 = Data_Functor.map(dictFunctor)(function (i) {
                    return [ i ];
                });
                return function ($16) {
                    return $14($15($16));
                };
            })()));
        };
    };
};
var filterMap = function (f) {
    return function (def) {
        return function (sig) {
            return Data_Functor.map(functorSignal)(Data_Maybe.fromMaybe(def))($foreign.filter(Data_Maybe.isJust)(new Data_Maybe.Just(def))(Data_Functor.map(functorSignal)(f)(sig)));
        };
    };
};
var dropRepeats$prime = $foreign.dropRepeatsByStrictInequality;
var applySignal = new Control_Apply.Apply(function () {
    return functorSignal;
}, $foreign.applySig);
var map2 = function (f) {
    return function (a) {
        return function (b) {
            return squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b);
        };
    };
};
var map3 = function (f) {
    return function (a) {
        return function (b) {
            return function (c) {
                return squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b))(c);
            };
        };
    };
};
var map4 = function (f) {
    return function (a) {
        return function (b) {
            return function (c) {
                return function (d) {
                    return squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b))(c))(d);
                };
            };
        };
    };
};
var map5 = function (f) {
    return function (a) {
        return function (b) {
            return function (c) {
                return function (d) {
                    return function (e) {
                        return squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b))(c))(d))(e);
                    };
                };
            };
        };
    };
};
var applicativeSignal = new Control_Applicative.Applicative(function () {
    return applySignal;
}, $foreign.constant);
module.exports = {
    mergeMany: mergeMany,
    "dropRepeats'": dropRepeats$prime,
    filterMap: filterMap,
    flatten: flatten,
    squigglyMap: squigglyMap,
    squigglyApply: squigglyApply,
    flippedMap: flippedMap,
    map2: map2,
    map3: map3,
    map4: map4,
    map5: map5,
    functorSignal: functorSignal,
    applySignal: applySignal,
    applicativeSignal: applicativeSignal,
    semigroupSignal: semigroupSignal,
    monoidSignal: monoidSignal,
    constant: $foreign.constant,
    merge: $foreign.merge,
    foldp: $foreign.foldp,
    sampleOn: $foreign.sampleOn,
    dropRepeats: $foreign.dropRepeats,
    runSignal: $foreign.runSignal,
    unwrap: $foreign.unwrap,
    get: $foreign.get,
    filter: $foreign.filter,
    flattenArray: $foreign.flattenArray
};
