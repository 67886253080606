// Generated by purs version 0.14.3
"use strict";
var Halogen_Svg_Core = require("../Halogen.Svg.Core/index.js");
var element = Halogen_Svg_Core.element;
var ellipse = function (props) {
    return element("ellipse")(props)([  ]);
};
var foreignObject = element("foreignObject");
var g = element("g");
var image = function (props) {
    return element("image")(props)([  ]);
};
var line = function (props) {
    return element("line")(props)([  ]);
};
var marker = element("marker");
var mask = element("mask");
var mpath = function (props) {
    return element("mpath")(props)([  ]);
};
var path = function (props) {
    return element("path")(props)([  ]);
};
var rect = function (props) {
    return element("rect")(props)([  ]);
};
var svg = element("svg");
var text = element("text");
var title = element("title");
var defs = element("defs");
var circleNode = element("circle");
var circle = function (props) {
    return element("circle")(props)([  ]);
};
var animateMotion = element("animateMotion");
var animate = function (props) {
    return element("animate")(props)([  ]);
};
module.exports = {
    element: element,
    svg: svg,
    g: g,
    circle: circle,
    image: image,
    ellipse: ellipse,
    rect: rect,
    path: path,
    line: line,
    text: text,
    foreignObject: foreignObject,
    defs: defs,
    mask: mask,
    marker: marker,
    animate: animate,
    animateMotion: animateMotion,
    circleNode: circleNode,
    mpath: mpath,
    title: title
};
