// Generated by purs version 0.14.3
"use strict";
var CSS_Common = require("../CSS.Common/index.js");
var CSS_Property = require("../CSS.Property/index.js");
var CSS_Size = require("../CSS.Size/index.js");
var CSS_String = require("../CSS.String/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var GenericFontFamily = function (x) {
    return x;
};
var FontWeight = function (x) {
    return x;
};
var weight = function (i) {
    return FontWeight(CSS_Property.value(CSS_Property.valNumber)(i));
};
var valGenericFontFamily = new CSS_Property.Val(function (v) {
    return v;
});
var valFontWeight = new CSS_Property.Val(function (v) {
    return v;
});
var unsetFontWeight = new CSS_Common.Unset(CSS_String.fromString(CSS_Property.isStringValue)("unset"));
var sansSerif = GenericFontFamily(CSS_String.fromString(CSS_Property.isStringValue)("sans-serif"));
var normalFontWeight = new CSS_Common.Normal(CSS_String.fromString(CSS_Property.isStringValue)("normal"));
var lighter = FontWeight(CSS_String.fromString(CSS_Property.isStringValue)("lighter"));
var initialFontWeight = new CSS_Common.Initial(CSS_String.fromString(CSS_Property.isStringValue)("initial"));
var inheritFontWeight = new CSS_Common.Inherit(CSS_String.fromString(CSS_Property.isStringValue)("inherit"));
var fontWeight = CSS_Stylesheet.key(valFontWeight)(CSS_String.fromString(CSS_Property.isStringKey)("font-weight"));
var fontSize = CSS_Stylesheet.key(CSS_Size.valSize)(CSS_String.fromString(CSS_Property.isStringKey)("font-size"));
var fontFamily = function (a) {
    return function (b) {
        return CSS_Stylesheet.key(CSS_Property.valValue)(CSS_String.fromString(CSS_Property.isStringKey)("font-family"))(CSS_Property.value(CSS_Property.valList(CSS_Property.valValue))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)((function () {
            var $28 = CSS_Property.value(CSS_Property.valString);
            return function ($29) {
                return $28(CSS_Property.quote($29));
            };
        })())(a))(Data_NonEmpty.oneOf(Control_Alternative.alternativeArray)(Data_Functor.map(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(CSS_Property.value(valGenericFontFamily))(b)))));
    };
};
var eqGenericFontFamily = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordGenericFontFamily = new Data_Ord.Ord(function () {
    return eqGenericFontFamily;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var eqFontWeight = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordFontWeight = new Data_Ord.Ord(function () {
    return eqFontWeight;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var color = CSS_Stylesheet.key(CSS_Property.valColor)(CSS_String.fromString(CSS_Property.isStringKey)("color"));
var bolder = FontWeight(CSS_String.fromString(CSS_Property.isStringValue)("bolder"));
var bold = FontWeight(CSS_String.fromString(CSS_Property.isStringValue)("bold"));
module.exports = {
    color: color,
    GenericFontFamily: GenericFontFamily,
    sansSerif: sansSerif,
    fontFamily: fontFamily,
    fontSize: fontSize,
    FontWeight: FontWeight,
    bold: bold,
    bolder: bolder,
    lighter: lighter,
    weight: weight,
    fontWeight: fontWeight,
    eqGenericFontFamily: eqGenericFontFamily,
    ordGenericFontFamily: ordGenericFontFamily,
    valGenericFontFamily: valGenericFontFamily,
    eqFontWeight: eqFontWeight,
    ordFontWeight: ordFontWeight,
    valFontWeight: valFontWeight,
    normalFontWeight: normalFontWeight,
    initialFontWeight: initialFontWeight,
    inheritFontWeight: inheritFontWeight,
    unsetFontWeight: unsetFontWeight
};
