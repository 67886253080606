// Generated by purs version 0.14.3
"use strict";
var Color = require("../Color/index.js");
var nodeTab = {
    background: Color.rgb(170)(170)(170),
    stroke: Color.rgba(220)(220)(220)(0.7)
};
module.exports = {
    nodeTab: nodeTab
};
