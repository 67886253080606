// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Const = require("../Data.Const/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_App = require("../Data.Functor.App/index.js");
var Data_Functor_Compose = require("../Data.Functor.Compose/index.js");
var Data_Functor_Coproduct = require("../Data.Functor.Coproduct/index.js");
var Data_Functor_Product = require("../Data.Functor.Product/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Maybe_First = require("../Data.Maybe.First/index.js");
var Data_Maybe_Last = require("../Data.Maybe.Last/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");
var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");
var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");
var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var FunctorWithIndex = function (Functor0, mapWithIndex) {
    this.Functor0 = Functor0;
    this.mapWithIndex = mapWithIndex;
};
var mapWithIndex = function (dict) {
    return dict.mapWithIndex;
};
var mapDefault = function (dictFunctorWithIndex) {
    return function (f) {
        return mapWithIndex(dictFunctorWithIndex)(Data_Function["const"](f));
    };
};
var functorWithIndexTuple = new FunctorWithIndex(function () {
    return Data_Tuple.functorTuple;
}, function (f) {
    return Data_Functor.map(Data_Tuple.functorTuple)(f(Data_Unit.unit));
});
var functorWithIndexProduct = function (dictFunctorWithIndex) {
    return function (dictFunctorWithIndex1) {
        return new FunctorWithIndex(function () {
            return Data_Functor_Product.functorProduct(dictFunctorWithIndex.Functor0())(dictFunctorWithIndex1.Functor0());
        }, function (f) {
            return function (v) {
                return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(mapWithIndex(dictFunctorWithIndex)(function ($27) {
                    return f(Data_Either.Left.create($27));
                }))(mapWithIndex(dictFunctorWithIndex1)(function ($28) {
                    return f(Data_Either.Right.create($28));
                }))(v);
            };
        });
    };
};
var functorWithIndexMultiplicative = new FunctorWithIndex(function () {
    return Data_Monoid_Multiplicative.functorMultiplicative;
}, function (f) {
    return Data_Functor.map(Data_Monoid_Multiplicative.functorMultiplicative)(f(Data_Unit.unit));
});
var functorWithIndexMaybe = new FunctorWithIndex(function () {
    return Data_Maybe.functorMaybe;
}, function (f) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(f(Data_Unit.unit));
});
var functorWithIndexLast = new FunctorWithIndex(function () {
    return Data_Maybe_Last.functorLast;
}, function (f) {
    return Data_Functor.map(Data_Maybe_Last.functorLast)(f(Data_Unit.unit));
});
var functorWithIndexIdentity = new FunctorWithIndex(function () {
    return Data_Identity.functorIdentity;
}, function (f) {
    return function (v) {
        return f(Data_Unit.unit)(v);
    };
});
var functorWithIndexFirst = new FunctorWithIndex(function () {
    return Data_Maybe_First.functorFirst;
}, function (f) {
    return Data_Functor.map(Data_Maybe_First.functorFirst)(f(Data_Unit.unit));
});
var functorWithIndexEither = new FunctorWithIndex(function () {
    return Data_Either.functorEither;
}, function (f) {
    return Data_Functor.map(Data_Either.functorEither)(f(Data_Unit.unit));
});
var functorWithIndexDual = new FunctorWithIndex(function () {
    return Data_Monoid_Dual.functorDual;
}, function (f) {
    return Data_Functor.map(Data_Monoid_Dual.functorDual)(f(Data_Unit.unit));
});
var functorWithIndexDisj = new FunctorWithIndex(function () {
    return Data_Monoid_Disj.functorDisj;
}, function (f) {
    return Data_Functor.map(Data_Monoid_Disj.functorDisj)(f(Data_Unit.unit));
});
var functorWithIndexCoproduct = function (dictFunctorWithIndex) {
    return function (dictFunctorWithIndex1) {
        return new FunctorWithIndex(function () {
            return Data_Functor_Coproduct.functorCoproduct(dictFunctorWithIndex.Functor0())(dictFunctorWithIndex1.Functor0());
        }, function (f) {
            return function (v) {
                return Data_Bifunctor.bimap(Data_Bifunctor.bifunctorEither)(mapWithIndex(dictFunctorWithIndex)(function ($29) {
                    return f(Data_Either.Left.create($29));
                }))(mapWithIndex(dictFunctorWithIndex1)(function ($30) {
                    return f(Data_Either.Right.create($30));
                }))(v);
            };
        });
    };
};
var functorWithIndexConst = new FunctorWithIndex(function () {
    return Data_Const.functorConst;
}, function (v) {
    return function (v1) {
        return v1;
    };
});
var functorWithIndexConj = new FunctorWithIndex(function () {
    return Data_Monoid_Conj.functorConj;
}, function (f) {
    return Data_Functor.map(Data_Monoid_Conj.functorConj)(f(Data_Unit.unit));
});
var functorWithIndexCompose = function (dictFunctorWithIndex) {
    return function (dictFunctorWithIndex1) {
        return new FunctorWithIndex(function () {
            return Data_Functor_Compose.functorCompose(dictFunctorWithIndex.Functor0())(dictFunctorWithIndex1.Functor0());
        }, function (f) {
            return function (v) {
                return Data_Functor_Compose.Compose(mapWithIndex(dictFunctorWithIndex)((function () {
                    var $31 = mapWithIndex(dictFunctorWithIndex1);
                    var $32 = Data_Tuple.curry(f);
                    return function ($33) {
                        return $31($32($33));
                    };
                })())(v));
            };
        });
    };
};
var functorWithIndexArray = new FunctorWithIndex(function () {
    return Data_Functor.functorArray;
}, $foreign.mapWithIndexArray);
var functorWithIndexApp = function (dictFunctorWithIndex) {
    return new FunctorWithIndex(function () {
        return Data_Functor_App.functorApp(dictFunctorWithIndex.Functor0());
    }, function (f) {
        return function (v) {
            return Data_Functor_App.App(mapWithIndex(dictFunctorWithIndex)(f)(v));
        };
    });
};
var functorWithIndexAdditive = new FunctorWithIndex(function () {
    return Data_Monoid_Additive.functorAdditive;
}, function (f) {
    return Data_Functor.map(Data_Monoid_Additive.functorAdditive)(f(Data_Unit.unit));
});
module.exports = {
    FunctorWithIndex: FunctorWithIndex,
    mapWithIndex: mapWithIndex,
    mapDefault: mapDefault,
    functorWithIndexArray: functorWithIndexArray,
    functorWithIndexMaybe: functorWithIndexMaybe,
    functorWithIndexFirst: functorWithIndexFirst,
    functorWithIndexLast: functorWithIndexLast,
    functorWithIndexAdditive: functorWithIndexAdditive,
    functorWithIndexDual: functorWithIndexDual,
    functorWithIndexConj: functorWithIndexConj,
    functorWithIndexDisj: functorWithIndexDisj,
    functorWithIndexMultiplicative: functorWithIndexMultiplicative,
    functorWithIndexEither: functorWithIndexEither,
    functorWithIndexTuple: functorWithIndexTuple,
    functorWithIndexIdentity: functorWithIndexIdentity,
    functorWithIndexConst: functorWithIndexConst,
    functorWithIndexProduct: functorWithIndexProduct,
    functorWithIndexCoproduct: functorWithIndexCoproduct,
    functorWithIndexCompose: functorWithIndexCompose,
    functorWithIndexApp: functorWithIndexApp
};
