// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Auto = (function () {
    function Auto() {

    };
    Auto.value = new Auto();
    return Auto;
})();
var UseScript = (function () {
    function UseScript() {

    };
    UseScript.value = new UseScript();
    return UseScript;
})();
var NoChange = (function () {
    function NoChange() {

    };
    NoChange.value = new NoChange();
    return NoChange;
})();
var ResetSize = (function () {
    function ResetSize() {

    };
    ResetSize.value = new ResetSize();
    return ResetSize;
})();
var Ideographic = (function () {
    function Ideographic() {

    };
    Ideographic.value = new Ideographic();
    return Ideographic;
})();
var Alphabetic = (function () {
    function Alphabetic() {

    };
    Alphabetic.value = new Alphabetic();
    return Alphabetic;
})();
var Hanging = (function () {
    function Hanging() {

    };
    Hanging.value = new Hanging();
    return Hanging;
})();
var Mathematical = (function () {
    function Mathematical() {

    };
    Mathematical.value = new Mathematical();
    return Mathematical;
})();
var Central = (function () {
    function Central() {

    };
    Central.value = new Central();
    return Central;
})();
var BaselineMiddle = (function () {
    function BaselineMiddle() {

    };
    BaselineMiddle.value = new BaselineMiddle();
    return BaselineMiddle;
})();
var TextAfterEdge = (function () {
    function TextAfterEdge() {

    };
    TextAfterEdge.value = new TextAfterEdge();
    return TextAfterEdge;
})();
var TextBeforeEdge = (function () {
    function TextBeforeEdge() {

    };
    TextBeforeEdge.value = new TextBeforeEdge();
    return TextBeforeEdge;
})();
var showBaseline = new Data_Show.Show(function (v) {
    if (v instanceof Auto) {
        return "Auto";
    };
    if (v instanceof UseScript) {
        return "UseScript";
    };
    if (v instanceof NoChange) {
        return "NoChange";
    };
    if (v instanceof ResetSize) {
        return "ResetSize";
    };
    if (v instanceof Ideographic) {
        return "Ideographic";
    };
    if (v instanceof Alphabetic) {
        return "Alphabetic";
    };
    if (v instanceof Hanging) {
        return "Hanging";
    };
    if (v instanceof Mathematical) {
        return "Mathematical";
    };
    if (v instanceof Central) {
        return "Central";
    };
    if (v instanceof BaselineMiddle) {
        return "BaselineMiddle";
    };
    if (v instanceof TextAfterEdge) {
        return "TextAfterEdge";
    };
    if (v instanceof TextBeforeEdge) {
        return "TextBeforeEdge";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Baseline (line 25, column 10 - line 37, column 39): " + [ v.constructor.name ]);
});
var printBaseline = function (v) {
    if (v instanceof Auto) {
        return "auto";
    };
    if (v instanceof UseScript) {
        return "use-script";
    };
    if (v instanceof NoChange) {
        return "no-change";
    };
    if (v instanceof ResetSize) {
        return "reset-size";
    };
    if (v instanceof Ideographic) {
        return "ideographic";
    };
    if (v instanceof Alphabetic) {
        return "alphabetic";
    };
    if (v instanceof Hanging) {
        return "hanging";
    };
    if (v instanceof Mathematical) {
        return "mathematical";
    };
    if (v instanceof Central) {
        return "central";
    };
    if (v instanceof BaselineMiddle) {
        return "middle";
    };
    if (v instanceof TextAfterEdge) {
        return "text-after-edge";
    };
    if (v instanceof TextBeforeEdge) {
        return "text-before-edge";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Baseline (line 40, column 17 - line 52, column 39): " + [ v.constructor.name ]);
};
var eqBaseline = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Auto && y instanceof Auto) {
            return true;
        };
        if (x instanceof UseScript && y instanceof UseScript) {
            return true;
        };
        if (x instanceof NoChange && y instanceof NoChange) {
            return true;
        };
        if (x instanceof ResetSize && y instanceof ResetSize) {
            return true;
        };
        if (x instanceof Ideographic && y instanceof Ideographic) {
            return true;
        };
        if (x instanceof Alphabetic && y instanceof Alphabetic) {
            return true;
        };
        if (x instanceof Hanging && y instanceof Hanging) {
            return true;
        };
        if (x instanceof Mathematical && y instanceof Mathematical) {
            return true;
        };
        if (x instanceof Central && y instanceof Central) {
            return true;
        };
        if (x instanceof BaselineMiddle && y instanceof BaselineMiddle) {
            return true;
        };
        if (x instanceof TextAfterEdge && y instanceof TextAfterEdge) {
            return true;
        };
        if (x instanceof TextBeforeEdge && y instanceof TextBeforeEdge) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Auto: Auto,
    UseScript: UseScript,
    NoChange: NoChange,
    ResetSize: ResetSize,
    Ideographic: Ideographic,
    Alphabetic: Alphabetic,
    Hanging: Hanging,
    Mathematical: Mathematical,
    Central: Central,
    BaselineMiddle: BaselineMiddle,
    TextAfterEdge: TextAfterEdge,
    TextBeforeEdge: TextBeforeEdge,
    printBaseline: printBaseline,
    eqBaseline: eqBaseline,
    showBaseline: showBaseline
};
