// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Halogen_Svg_Attributes_Utils = require("../Halogen.Svg.Attributes.Utils/index.js");
var Safe_Coerce = require("../Safe.Coerce/index.js");
var PathCommand = function (x) {
    return x;
};
var Sweep0 = (function () {
    function Sweep0() {

    };
    Sweep0.value = new Sweep0();
    return Sweep0;
})();
var Sweep1 = (function () {
    function Sweep1() {

    };
    Sweep1.value = new Sweep1();
    return Sweep1;
})();
var Rel = (function () {
    function Rel() {

    };
    Rel.value = new Rel();
    return Rel;
})();
var Abs = (function () {
    function Abs() {

    };
    Abs.value = new Abs();
    return Abs;
})();
var Arc0 = (function () {
    function Arc0() {

    };
    Arc0.value = new Arc0();
    return Arc0;
})();
var Arc1 = (function () {
    function Arc1() {

    };
    Arc1.value = new Arc1();
    return Arc1;
})();
var z = "z";
var toArrayString = Safe_Coerce.coerce();
var showCommandSweepChoice = new Data_Show.Show(function (v1) {
    if (v1 instanceof Sweep0) {
        return "Sweep0";
    };
    if (v1 instanceof Sweep1) {
        return "Sweep1";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Path (line 60, column 10 - line 62, column 23): " + [ v1.constructor.name ]);
});
var showCommandPositionReference = new Data_Show.Show(function (v1) {
    if (v1 instanceof Abs) {
        return "Abs";
    };
    if (v1 instanceof Rel) {
        return "Rel";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Path (line 33, column 10 - line 35, column 17): " + [ v1.constructor.name ]);
});
var showCommandArcChoice = new Data_Show.Show(function (v1) {
    if (v1 instanceof Arc0) {
        return "Arc0";
    };
    if (v1 instanceof Arc1) {
        return "Arc1";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Path (line 44, column 10 - line 46, column 19): " + [ v1.constructor.name ]);
});
var renderCommand = function (cmd) {
    return function (s_) {
        if (cmd instanceof Rel) {
            return s_;
        };
        if (cmd instanceof Abs) {
            return Data_String_Common.toUpper(s_);
        };
        throw new Error("Failed pattern match at Halogen.Svg.Attributes.Path (line 71, column 24 - line 73, column 20): " + [ cmd.constructor.name ]);
    };
};
var renderCommand1Arg = function (s_) {
    return function (ref) {
        return function (a_) {
            return PathCommand(renderCommand(ref)(s_) + Data_Show.show(Data_Show.showNumber)(a_));
        };
    };
};
var v = renderCommand1Arg("v");
var renderCommand2Args = function (s_) {
    return function (ref) {
        return function (a_) {
            return function (b) {
                return PathCommand(renderCommand(ref)(s_) + (Data_Show.show(Data_Show.showNumber)(a_) + (", " + Data_Show.show(Data_Show.showNumber)(b))));
            };
        };
    };
};
var t = renderCommand2Args("t");
var renderCommand4Args = function (s_) {
    return function (ref) {
        return function (a_) {
            return function (b) {
                return function (c_) {
                    return function (d_) {
                        return PathCommand(renderCommand(ref)(s_) + (Data_Show.show(Data_Show.showNumber)(a_) + (", " + (Data_Show.show(Data_Show.showNumber)(b) + (", " + (Data_Show.show(Data_Show.showNumber)(c_) + (", " + Data_Show.show(Data_Show.showNumber)(d_))))))));
                    };
                };
            };
        };
    };
};
var s = renderCommand4Args("s");
var q = renderCommand4Args("q");
var printPathCommand = function (v1) {
    return v1;
};
var showPathCommand = new Data_Show.Show(function (val) {
    return printPathCommand(val);
});
var printCommandSweepChoice = function (v1) {
    if (v1 instanceof Sweep0) {
        return "0";
    };
    if (v1 instanceof Sweep1) {
        return "1";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Path (line 65, column 27 - line 67, column 16): " + [ v1.constructor.name ]);
};
var printCommandArcChoice = function (v1) {
    if (v1 instanceof Arc0) {
        return "0";
    };
    if (v1 instanceof Arc1) {
        return "1";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Path (line 49, column 25 - line 51, column 14): " + [ v1.constructor.name ]);
};
var m = renderCommand2Args("m");
var l = renderCommand2Args("l");
var h = renderCommand1Arg("h");
var eqPathCommand = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var eqCommandSweepChoice = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Sweep0 && y instanceof Sweep0) {
            return true;
        };
        if (x instanceof Sweep1 && y instanceof Sweep1) {
            return true;
        };
        return false;
    };
});
var eqCommandPositionReference = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Rel && y instanceof Rel) {
            return true;
        };
        if (x instanceof Abs && y instanceof Abs) {
            return true;
        };
        return false;
    };
});
var eqCommandArcChoice = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Arc0 && y instanceof Arc0) {
            return true;
        };
        if (x instanceof Arc1 && y instanceof Arc1) {
            return true;
        };
        return false;
    };
});
var c = function (ref) {
    return function (x1_) {
        return function (y1_) {
            return function (x2_) {
                return function (y2_) {
                    return function (x_) {
                        return function (y_) {
                            return PathCommand(renderCommand(ref)("c") + Halogen_Svg_Attributes_Utils.printArray(Data_Show.showNumber)([ x1_, y1_, x2_, y2_, x_, y_ ]));
                        };
                    };
                };
            };
        };
    };
};
var a = function (ref) {
    return function (rx_) {
        return function (ry_) {
            return function (rot) {
                return function (arc) {
                    return function (sweep) {
                        return function (x_) {
                            return function (y_) {
                                return PathCommand(renderCommand(ref)("a") + (Data_Show.show(Data_Show.showNumber)(rx_) + (", " + (Data_Show.show(Data_Show.showNumber)(ry_) + (", " + (Data_Show.show(Data_Show.showNumber)(rot) + (" " + (printCommandArcChoice(arc) + (" " + (printCommandSweepChoice(sweep) + (" " + (Data_Show.show(Data_Show.showNumber)(x_) + (" " + Data_Show.show(Data_Show.showNumber)(y_))))))))))))));
                            };
                        };
                    };
                };
            };
        };
    };
};
module.exports = {
    Rel: Rel,
    Abs: Abs,
    Arc0: Arc0,
    Arc1: Arc1,
    Sweep0: Sweep0,
    Sweep1: Sweep1,
    toArrayString: toArrayString,
    m: m,
    l: l,
    h: h,
    v: v,
    c: c,
    s: s,
    q: q,
    t: t,
    a: a,
    z: z,
    eqPathCommand: eqPathCommand,
    showPathCommand: showPathCommand,
    eqCommandPositionReference: eqCommandPositionReference,
    showCommandPositionReference: showCommandPositionReference,
    eqCommandArcChoice: eqCommandArcChoice,
    showCommandArcChoice: showCommandArcChoice,
    eqCommandSweepChoice: eqCommandSweepChoice,
    showCommandSweepChoice: showCommandSweepChoice
};
