// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var Color = require("../Color/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var title = {
    mode: App_Style.OutsideBody.value,
    background: Color.rgba(0)(0)(0)(0.0),
    fill: Color.rgb(255)(255)(255),
    size: 20.0,
    padding: Data_Vec2.make(3.0)(10.0)
};
module.exports = {
    title: title
};
