// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Halogen_Svg_Attributes_Utils = require("../Halogen.Svg.Attributes.Utils/index.js");
var Duration = (function () {
    function Duration(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    Duration.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new Duration(value0, value1, value2, value3);
                };
            };
        };
    };
    return Duration;
})();
var showDurationF = new Data_Show.Show(function (v) {
    return "(Duration " + (Halogen_Svg_Attributes_Utils.showWithSpaces(Data_Maybe.showMaybe(Data_Show.showNumber))([ v.value0, v.value1, v.value2, v.value3 ]) + ")");
});
var printDurationF = function (dictShow) {
    return function (v) {
        var f = function (u) {
            return Data_Maybe.maybe("")(function (val) {
                return Data_Show.show(dictShow)(val) + u;
            });
        };
        return f("h")(v.value0) + (f("m")(v.value1) + (f("s")(v.value2) + f("i")(v.value3)));
    };
};
var printDuration = printDurationF(Data_Show.showNumber);
var functorDurationF = new Data_Functor.Functor(function (f) {
    return function (m) {
        return new Duration(Data_Functor.map(Data_Maybe.functorMaybe)(f)(m.value0), Data_Functor.map(Data_Maybe.functorMaybe)(f)(m.value1), Data_Functor.map(Data_Maybe.functorMaybe)(f)(m.value2), Data_Functor.map(Data_Maybe.functorMaybe)(f)(m.value3));
    };
});
var eqDurationF = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            return Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(x.value0)(y.value0) && Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(x.value1)(y.value1) && Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(x.value2)(y.value2) && Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(x.value3)(y.value3);
        };
    });
};
module.exports = {
    printDuration: printDuration,
    Duration: Duration,
    printDurationF: printDurationF,
    eqDurationF: eqDurationF,
    functorDurationF: functorDurationF,
    showDurationF: showDurationF
};
