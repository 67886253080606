// Generated by purs version 0.14.3
"use strict";
var App_Emitters = require("../App.Emitters/index.js");
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Subscription = require("../Halogen.Subscription/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Queue = require("../Hydra.Queue/index.js");
var Hydra_Toolkit_UI_Action = require("../Hydra.Toolkit.UI.Action/index.js");
var Web_HTML_Common = require("../Web.HTML.Common/index.js");
var ToBuffer = (function () {
    function ToBuffer() {

    };
    ToBuffer.value = new ToBuffer();
    return ToBuffer;
})();
var FromBuffer = (function () {
    function FromBuffer() {

    };
    FromBuffer.value = new FromBuffer();
    return FromBuffer;
})();
var NoOp = (function () {
    function NoOp() {

    };
    NoOp.value = new NoOp();
    return NoOp;
})();
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var Select = (function () {
    function Select(value0) {
        this.value0 = value0;
    };
    Select.create = function (value0) {
        return new Select(value0);
    };
    return Select;
})();
var Store = (function () {
    function Store(value0) {
        this.value0 = value0;
    };
    Store.create = function (value0) {
        return new Store(value0);
    };
    return Store;
})();
var sourceBuffers = [ Hydra.S0.value, Hydra.S1.value, Hydra.S2.value, Hydra.S3.value ];
var outputBuffers = [ Hydra.O0.value, Hydra.O1.value, Hydra.O2.value, Hydra.O3.value ];
var initialState = function (mode) {
    return function (v) {
        return {
            mode: mode,
            buffer: Hydra.O0.value,
            queue: v.patchState,
            node: v.node
        };
    };
};
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof NoOp) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                if (v1.mode instanceof ToBuffer) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(App_Emitters.fromInlet(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(v1.node)("texture"))(function (emitter) {
                        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM.subscribe(Data_Functor.map(Halogen_Subscription.functorEmitter)((function () {
                            var $55 = Data_Maybe.maybe(NoOp.value)(Store.create);
                            return function ($56) {
                                return $55(Hydra.toTexture($56));
                            };
                        })())(emitter)))(function () {
                            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                        });
                    });
                };
                if (v1.mode instanceof FromBuffer) {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at Hydra.Component.Node.Buffer (line 144, column 9 - line 149, column 36): " + [ v1.mode.constructor.name ]);
            });
        };
        if (v instanceof Receive) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $25 = {};
                for (var $26 in v1) {
                    if ({}.hasOwnProperty.call(v1, $26)) {
                        $25[$26] = v1[$26];
                    };
                };
                $25.queue = v.value0;
                return $25;
            });
        };
        if (v instanceof Select) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $29 = {};
                for (var $30 in v1) {
                    if ({}.hasOwnProperty.call(v1, $30)) {
                        $29[$30] = v1[$30];
                    };
                };
                $29.buffer = v.value0;
                return $29;
            }))(function (v1) {
                if (v1.mode instanceof ToBuffer) {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                };
                if (v1.mode instanceof FromBuffer) {
                    var v2 = Hydra_Queue.textureAt(v.value0)(v1.queue);
                    if (v2 instanceof Data_Maybe.Just) {
                        return Halogen_Query_HalogenM.raise(App_Toolkit_UI.SendToOutlet.create("texture")(Hydra.hydraOf(v2.value0)));
                    };
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at Hydra.Component.Node.Buffer (line 157, column 17 - line 159, column 41): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at Hydra.Component.Node.Buffer (line 154, column 9 - line 159, column 41): " + [ v1.mode.constructor.name ]);
            });
        };
        if (v instanceof Store) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                if (v1.mode instanceof ToBuffer) {
                    return Halogen_Query_HalogenM.raise(App_Toolkit_UI.ToPatch.create(new Hydra_Toolkit_UI_Action.Store(v1.buffer, v.value0)));
                };
                if (v1.mode instanceof FromBuffer) {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at Hydra.Component.Node.Buffer (line 165, column 13 - line 168, column 40): " + [ v1.mode.constructor.name ]);
            });
        };
        throw new Error("Failed pattern match at Hydra.Component.Node.Buffer (line 140, column 16 - line 168, column 40): " + [ v.constructor.name ]);
    };
};
var eqMode = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof ToBuffer && y instanceof ToBuffer) {
            return true;
        };
        if (x instanceof FromBuffer && y instanceof FromBuffer) {
            return true;
        };
        return false;
    };
});
var bodyWidth = 110.0;
var bodyHeight = 80.0;
var render = function (v) {
    var isOwned = Data_Function.flip(Hydra_Queue.isOwned)(v.queue);
    var fillFor = function (otherBuffer) {
        var $47 = Data_Eq.eq(Hydra.eqBuffer)(otherBuffer)(v.buffer);
        if ($47) {
            return Color.rgba(255)(255)(255)((function () {
                var $48 = isOwned(otherBuffer);
                if ($48) {
                    return 0.5;
                };
                return 1.0;
            })());
        };
        var $49 = isOwned(otherBuffer);
        if ($49) {
            return Color.rgba(100)(100)(100)(1.0);
        };
        return Color.rgba(0)(0)(0)(0.0);
    };
    var circleDiameter = 5.0 * 2.0;
    var circleDiameterAndPadding = circleDiameter + 2.0;
    var xFor = function (idx) {
        return (bodyWidth / 2.0 - (4.0 * circleDiameter) / 2.0) + Data_Int.toNumber(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(idx)(9)) * circleDiameterAndPadding;
    };
    var yFor = function (v1) {
        return bodyHeight / 2.0 - circleDiameter * 2.0;
    };
    var bufferButton = function (y) {
        return function (color) {
            return function (idx) {
                return function (otherBuffer) {
                    return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.class_(Web_HTML_Common.ClassName((function () {
                        var $50 = isOwned(otherBuffer);
                        if ($50) {
                            return "buffer-button-owned";
                        };
                        return "buffer-button-free";
                    })())) ])([ Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.cx(xFor(idx)), Halogen_Svg_Attributes.cy(y + yFor(idx)), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(color))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(fillFor(otherBuffer)))), Halogen_Svg_Attributes.strokeWidth(1.0), Halogen_Svg_Attributes.r(5.0) ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.x(xFor(idx) - 5.0), Halogen_Svg_Attributes.y((y + yFor(idx)) - 5.0), Halogen_Svg_Attributes.width(circleDiameter), Halogen_Svg_Attributes.height(circleDiameter), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_HTML_Events.onClick(Data_Function["const"]((function () {
                        var $51 = Data_Eq.eq(eqMode)(v.mode)(ToBuffer.value) && isOwned(otherBuffer);
                        if ($51) {
                            return NoOp.value;
                        };
                        return new Select(otherBuffer);
                    })())) ]) ]);
                };
            };
        };
    };
    return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.class_("buffer-node") ])([ Halogen_Svg_Elements.g([  ])(Data_Array.mapWithIndex(bufferButton(0.0)(Color.rgb(200)(200)(200)))(sourceBuffers)), Halogen_Svg_Elements.g([  ])(Data_Array.mapWithIndex(bufferButton(18.0)(Color.rgb(127)(127)(127)))(outputBuffers)) ]);
};
var component = function (mode) {
    return function (dictMonadEffect) {
        return Halogen_Component.mkComponent({
            initialState: initialState(mode),
            render: render,
            "eval": Halogen_Component.mkEval({
                handleAction: handleAction(dictMonadEffect),
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: function ($57) {
                    return Data_Maybe.Just.create(Receive.create((function (v1) {
                        return v1.patchState;
                    })($57)));
                },
                initialize: new Data_Maybe.Just(Initialize.value),
                finalize: Halogen_Component.defaultEval.finalize
            })
        });
    };
};
module.exports = {
    ToBuffer: ToBuffer,
    FromBuffer: FromBuffer,
    NoOp: NoOp,
    Initialize: Initialize,
    Receive: Receive,
    Select: Select,
    Store: Store,
    outputBuffers: outputBuffers,
    sourceBuffers: sourceBuffers,
    bodyWidth: bodyWidth,
    bodyHeight: bodyHeight,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component,
    eqMode: eqMode
};
