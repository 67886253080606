// Generated by purs version 0.14.3
"use strict";
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Effect = require("../Effect/index.js");
var Noodle_Patch = require("../Noodle.Patch/index.js");
var Network = (function () {
    function Network(value0) {
        this.value0 = value0;
    };
    Network.create = function (value0) {
        return new Network(value0);
    };
    return Network;
})();
var withPatch = function (name) {
    return function (f) {
        return function (v) {
            return Network.create(Data_Map_Internal.update(Data_Ord.ordString)(function ($35) {
                return Data_Maybe.Just.create(f($35));
            })(name)(v.value0));
        };
    };
};
var removePatch = function (name) {
    return function (v) {
        return Network.create(Data_Map_Internal["delete"](Data_Ord.ordString)(name)(v.value0));
    };
};
var patches = function (v) {
    return Data_Map_Internal.toUnfoldableUnordered(Data_Unfoldable.unfoldableArray)(v.value0);
};
var patch = function (name) {
    return function (v) {
        return Data_Map_Internal.lookup(Data_Ord.ordString)(name)(v.value0);
    };
};
var withPatch$prime = function (name) {
    return function (f) {
        return function (v) {
            return function __do() {
                var maybeNextPatch = Data_Traversable.sequence(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(patch(name)(v))(f))();
                if (maybeNextPatch instanceof Data_Maybe.Just) {
                    return Network.create(Data_Map_Internal.update(Data_Ord.ordString)(Data_Function["const"](new Data_Maybe.Just(maybeNextPatch.value0)))(name)(v.value0));
                };
                if (maybeNextPatch instanceof Data_Maybe.Nothing) {
                    return v;
                };
                throw new Error("Failed pattern match at Noodle.Network (line 58, column 9 - line 65, column 24): " + [ maybeNextPatch.constructor.name ]);
            };
        };
    };
};
var empty = Network.create(Data_Map_Internal.empty);
var disconnect = function (patch1) {
    return function (outlet) {
        return function (inlet) {
            return withPatch$prime(patch1)(Noodle_Patch.disconnect(outlet)(inlet));
        };
    };
};
var connect = function (patch1) {
    return function (outlet) {
        return function (inlet) {
            return withPatch$prime(patch1)(Noodle_Patch.connect(outlet)(inlet));
        };
    };
};
var addPatch = function (v) {
    return function (v1) {
        return Network.create(Data_Map_Internal.insert(Data_Ord.ordString)(v.value0)(v.value1)(v1.value0));
    };
};
var addNode = function (v) {
    return function (theNode) {
        return withPatch(v.value0)(Noodle_Patch.addNode(v.value1)(theNode));
    };
};
module.exports = {
    Network: Network,
    empty: empty,
    patch: patch,
    patches: patches,
    addPatch: addPatch,
    removePatch: removePatch,
    withPatch: withPatch,
    "withPatch'": withPatch$prime,
    addNode: addNode,
    connect: connect,
    disconnect: disconnect
};
