// Generated by purs version 0.14.3
"use strict";
var App_Layout = require("../App.Layout/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Item = function (x) {
    return x;
};
var Node = (function () {
    function Node(value0) {
        this.value0 = value0;
    };
    Node.create = function (value0) {
        return new Node(value0);
    };
    return Node;
})();
var Free = (function () {
    function Free(value0) {
        this.value0 = value0;
    };
    Free.create = function (value0) {
        return new Free(value0);
    };
    return Free;
})();
var valueOf = function (v) {
    if (v instanceof Free) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Node) {
        return new Data_Maybe.Just(v.value0.i);
    };
    throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 171, column 1 - line 171, column 41): " + [ v.constructor.name ]);
};
var toList = function (dictSemiring) {
    var unpack$prime = function (v) {
        return function (v1) {
            if (v1 instanceof Free) {
                return Data_List_Types.Nil.value;
            };
            if (v1 instanceof Node) {
                return Data_Semigroup.append(Data_List_Types.semigroupList)(new Data_List_Types.Cons(new Data_Tuple.Tuple(v1.value0.i, new Data_Tuple.Tuple(v, Data_Vec2.make(v1.value0.w)(v1.value0.h))), unpack$prime(Data_Semiring.add(Data_Vec.semiringVec(dictSemiring)(Data_Typelevel_Num_Sets.natD2))(v)(Data_Vec2["w'"](dictSemiring)(v1.value0.w)))(v1.value0.r)))(unpack$prime(Data_Semiring.add(Data_Vec.semiringVec(dictSemiring)(Data_Typelevel_Num_Sets.natD2))(v)(Data_Vec2["h'"](dictSemiring)(v1.value0.h)))(v1.value0.b));
            };
            throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 130, column 9 - line 130, column 51): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
    return unpack$prime(Data_Semiring.zero(Data_Vec.semiringVec(dictSemiring)(Data_Typelevel_Num_Sets.natD2)));
};
var size = function (v) {
    if (v instanceof Free) {
        return Data_Vec2.make(v.value0.w)(v.value0.h);
    };
    if (v instanceof Node) {
        return Data_Vec2.make(v.value0.w)(v.value0.h);
    };
    throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 178, column 1 - line 178, column 38): " + [ v.constructor.name ]);
};
var showBin2 = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            if (v instanceof Node) {
                return "{ Node: " + (Data_Show.show(dictShow1)(v.value0.w) + ("x" + (Data_Show.show(dictShow1)(v.value0.h) + (" -> " + (Data_Show.show(dictShow)(v.value0.i) + (" >> " + (Data_Show.show(showBin2(dictShow)(dictShow1))(v.value0.r) + ("\x0a" + (Data_Show.show(showBin2(dictShow)(dictShow1))(v.value0.b) + " }")))))))));
            };
            if (v instanceof Free) {
                return "{ Free: " + (Data_Show.show(dictShow1)(v.value0.w) + ("x" + (Data_Show.show(dictShow1)(v.value0.h) + " }")));
            };
            throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 80, column 1 - line 84, column 73): " + [ v.constructor.name ]);
        });
    };
};
var sample = function ($copy_dictRing) {
    return function ($copy_dictOrd) {
        return function ($copy_v) {
            return function ($copy_v1) {
                var $tco_var_dictRing = $copy_dictRing;
                var $tco_var_dictOrd = $copy_dictOrd;
                var $tco_var_v = $copy_v;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(dictRing, dictOrd, v, v1) {
                    if (v instanceof Free) {
                        $tco_done = true;
                        return Data_Maybe.Nothing.value;
                    };
                    if (v instanceof Node) {
                        var v2 = new Data_Tuple.Tuple(Data_Ord.compare(dictOrd)(Data_Vec2.x(v1))(v.value0.w), Data_Ord.compare(dictOrd)(Data_Vec2.y(v1))(v.value0.h));
                        if (v2.value0 instanceof Data_Ordering.LT && v2.value1 instanceof Data_Ordering.LT) {
                            $tco_done = true;
                            return Data_Maybe.Just.create(new Data_Tuple.Tuple(v.value0.i, new Data_Tuple.Tuple(v1, Data_Vec2.make(v.value0.w)(v.value0.h))));
                        };
                        if (v2.value1 instanceof Data_Ordering.LT) {
                            $tco_var_dictRing = dictRing;
                            $tco_var_dictOrd = dictOrd;
                            $tco_var_v = v.value0.r;
                            $copy_v1 = Data_Ring.sub(Data_Vec.ringVec(dictRing)(Data_Typelevel_Num_Sets.natD2))(v1)(Data_Vec2["w'"](dictRing.Semiring0())(v.value0.w));
                            return;
                        };
                        $tco_var_dictRing = dictRing;
                        $tco_var_dictOrd = dictOrd;
                        $tco_var_v = v.value0.b;
                        $copy_v1 = Data_Ring.sub(Data_Vec.ringVec(dictRing)(Data_Typelevel_Num_Sets.natD2))(v1)(Data_Vec2["h'"](dictRing.Semiring0())(v.value0.h));
                        return;
                    };
                    throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 160, column 1 - line 160, column 94): " + [ v.constructor.name, v1.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_dictRing, $tco_var_dictOrd, $tco_var_v, $copy_v1);
                };
                return $tco_result;
            };
        };
    };
};
var sample$prime = function (dictRing) {
    return function (dictOrd) {
        return function (bin) {
            return function (pos) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.fst)(sample(dictRing)(dictOrd)(bin)(pos));
            };
        };
    };
};
var node = function (size1) {
    return function (pright) {
        return function (pbelow) {
            return function (item1) {
                return new Node({
                    w: Data_Vec2.w(size1),
                    h: Data_Vec2.h(size1),
                    r: pright,
                    b: pbelow,
                    i: item1
                });
            };
        };
    };
};
var itemOf = function (bin) {
    return Control_Bind.bind(Data_Maybe.bindMaybe)(valueOf(bin))(function (v) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new Data_Tuple.Tuple(v, size(bin)));
    });
};
var item = function (size1) {
    return function (i) {
        return Item(new Data_Tuple.Tuple(size1, i));
    };
};
var sqItem = function (l) {
    return item(Data_Vec2.make(l)(l));
};
var functorBin2 = new Data_Functor.Functor(function (v) {
    return function (v1) {
        if (v1 instanceof Node) {
            return node(Data_Vec2.make(v1.value0.w)(v1.value0.h))(Data_Functor.map(functorBin2)(v)(v1.value0.r))(Data_Functor.map(functorBin2)(v)(v1.value0.b))(v(v1.value0.i));
        };
        if (v1 instanceof Free) {
            return new Free({
                w: v1.value0.w,
                h: v1.value0.h
            });
        };
        throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 66, column 1 - line 68, column 51): " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var foldableBin2 = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Node) {
                return Data_Semigroup.append(dictMonoid.Semigroup0())(v(v1.value0.i))(Data_Semigroup.append(dictMonoid.Semigroup0())(Data_Foldable.foldMap(foldableBin2)(dictMonoid)(v)(v1.value0.r))(Data_Foldable.foldMap(foldableBin2)(dictMonoid)(v)(v1.value0.b)));
            };
            if (v1 instanceof Free) {
                return Data_Monoid.mempty(dictMonoid);
            };
            throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 70, column 1 - line 78, column 42): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
}, function (f) {
    return function (e) {
        return function (v) {
            if (v instanceof Node) {
                return Data_Foldable.foldl(foldableBin2)(f)(Data_Foldable.foldl(foldableBin2)(f)(f(e)(v.value0.i))(v.value0.r))(v.value0.b);
            };
            if (v instanceof Free) {
                return e;
            };
            throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 70, column 1 - line 78, column 42): " + [ f.constructor.name, e.constructor.name, v.constructor.name ]);
        };
    };
}, function (v) {
    return function (e) {
        return function (v1) {
            if (v1 instanceof Node) {
                return v(v1.value0.i)(Data_Foldable.foldr(foldableBin2)(v)(Data_Foldable.foldr(foldableBin2)(v)(e)(v1.value0.b))(v1.value0.r));
            };
            if (v1 instanceof Free) {
                return e;
            };
            throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 70, column 1 - line 78, column 42): " + [ v.constructor.name, e.constructor.name, v1.constructor.name ]);
        };
    };
});
var fold = function (dictSemiring) {
    return function (f) {
        var fold$prime = function (v) {
            return function (v1) {
                return function (v2) {
                    if (v2 instanceof Free) {
                        return v1;
                    };
                    if (v2 instanceof Node) {
                        return f(new Data_Tuple.Tuple(v2.value0.i, new Data_Tuple.Tuple(v, Data_Vec2.make(v2.value0.w)(v2.value0.h))))(fold$prime(Data_Semiring.add(Data_Vec.semiringVec(dictSemiring)(Data_Typelevel_Num_Sets.natD2))(v)(Data_Vec2["h'"](dictSemiring)(v2.value0.h)))(fold$prime(Data_Semiring.add(Data_Vec.semiringVec(dictSemiring)(Data_Typelevel_Num_Sets.natD2))(v)(Data_Vec2["w'"](dictSemiring)(v2.value0.w)))(v1)(v2.value0.r))(v2.value0.b));
                    };
                    throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 138, column 9 - line 138, column 47): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
        return fold$prime(Data_Semiring.zero(Data_Vec.semiringVec(dictSemiring)(Data_Typelevel_Num_Sets.natD2)));
    };
};
var find = function (dictEq) {
    return function (dictSemiring) {
        return function (needle) {
            return fold(dictSemiring)(function (v) {
                return function (prev) {
                    var $135 = Data_Maybe.isJust(prev);
                    if ($135) {
                        return prev;
                    };
                    var $136 = Data_Eq.eq(dictEq)(v.value0)(needle);
                    if ($136) {
                        return Data_Maybe.Just.create(new Data_Tuple.Tuple(v.value1.value0, v.value1.value1));
                    };
                    return Data_Maybe.Nothing.value;
                };
            })(Data_Maybe.Nothing.value);
        };
    };
};
var container = function (size1) {
    return new Free({
        w: Data_Vec2.w(size1),
        h: Data_Vec2.h(size1)
    });
};
var pack$prime = function (dictRing) {
    return function (dictOrd) {
        return function (v) {
            return function (v1) {
                if (v instanceof Free) {
                    var v2 = Data_Vec2.toTuple(v1.value0);
                    var pright = container(Data_Vec2.make(Data_Ring.sub(dictRing)(v.value0.w)(v2.value0))(v2.value1));
                    var pbelow = container(Data_Vec2.make(v.value0.w)(Data_Ring.sub(dictRing)(v.value0.h)(v2.value1)));
                    var fits = Data_Ord.lessThanOrEq(dictOrd)(v2.value0)(v.value0.w) && Data_Ord.lessThanOrEq(dictOrd)(v2.value1)(v.value0.h);
                    if (fits) {
                        return Data_Maybe.Just.create(node(v1.value0)(pright)(pbelow)(v1.value1));
                    };
                    return Data_Maybe.Nothing.value;
                };
                if (v instanceof Node) {
                    var pright = (function () {
                        var $171 = Data_Functor.map(Data_Maybe.functorMaybe)(function (r$prime) {
                            return node(Data_Vec2.make(v.value0.w)(v.value0.h))(r$prime)(v.value0.b)(v.value0.i);
                        });
                        var $172 = pack$prime(dictRing)(dictOrd)(v.value0.r);
                        return function ($173) {
                            return $171($172($173));
                        };
                    })();
                    var pbelow = (function () {
                        var $174 = Data_Functor.map(Data_Maybe.functorMaybe)(function (b$prime) {
                            return node(Data_Vec2.make(v.value0.w)(v.value0.h))(v.value0.r)(b$prime)(v.value0.i);
                        });
                        var $175 = pack$prime(dictRing)(dictOrd)(v.value0.b);
                        return function ($176) {
                            return $174($175($176));
                        };
                    })();
                    return Control_Alt.alt(Data_Maybe.altMaybe)(pright(v1))(pbelow(v1));
                };
                throw new Error("Failed pattern match at App.Layout.BinPack.R2 (line 103, column 1 - line 103, column 81): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    };
};
var pack = function (dictRing) {
    return function (dictOrd) {
        return function (c) {
            var area = function (v) {
                return Data_Vec2.area(dictRing.Semiring0())(v.value0);
            };
            var $177 = Data_Foldable.foldM(Data_List_Types.foldableList)(Data_Maybe.monadMaybe)(pack$prime(dictRing)(dictOrd))(c);
            var $178 = Data_List.sortBy(Data_Ord.comparing(dictOrd)(area));
            return function ($179) {
                return $177($178($179));
            };
        };
    };
};
var packOne = function (dictRing) {
    return function (dictOrd) {
        return function (c) {
            var $180 = pack(dictRing)(dictOrd)(c);
            return function ($181) {
                return $180(Data_List.singleton($181));
            };
        };
    };
};
var reflow = function (dictRing) {
    return function (dictOrd) {
        var $182 = fold(dictRing.Semiring0())(function (v) {
            return function (dst) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(dst)(Data_Function.flip(packOne(dictRing)(dictOrd))(item(v.value1.value1)(v.value0)));
            };
        });
        return function ($183) {
            return $182(Data_Maybe.Just.create(container($183)));
        };
    };
};
var reflow$prime = function (dictRing) {
    return function (dictOrd) {
        var $184 = fold(dictRing.Semiring0())(function (v) {
            return function (dst) {
                return Data_Maybe.fromMaybe(dst)(packOne(dictRing)(dictOrd)(dst)(item(v.value1.value1)(v.value0)));
            };
        });
        return function ($185) {
            return $184(container($185));
        };
    };
};
var sqContainer = function (x) {
    return container(Data_Vec2.make(x)(x));
};
var bin2IsLayout = new App_Layout.IsLayout(container, function (dictEq) {
    return find(dictEq)(Data_Semiring.semiringNumber);
}, fold(Data_Semiring.semiringNumber), Data_Function.flip(sample(Data_Ring.ringNumber)(Data_Ord.ordNumber)), size);
var bin2IsAutoLayout = new App_Layout.IsAutoLayout(function () {
    return bin2IsLayout;
}, function (v) {
    return function (s) {
        return Data_Function.flip(packOne(Data_Ring.ringNumber)(Data_Ord.ordNumber))(item(s)(v));
    };
});
module.exports = {
    pack: pack,
    packOne: packOne,
    toList: toList,
    find: find,
    sample: sample,
    "sample'": sample$prime,
    container: container,
    sqContainer: sqContainer,
    item: item,
    sqItem: sqItem,
    itemOf: itemOf,
    valueOf: valueOf,
    size: size,
    fold: fold,
    reflow: reflow,
    "reflow'": reflow$prime,
    bin2IsLayout: bin2IsLayout,
    bin2IsAutoLayout: bin2IsAutoLayout,
    functorBin2: functorBin2,
    foldableBin2: foldableBin2,
    showBin2: showBin2
};
