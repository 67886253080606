// Generated by purs version 0.14.3
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Typelevel_Undefined = require("../Data.Typelevel.Undefined/index.js");
var Trich = function (Nat0, Nat1) {
    this.Nat0 = Nat0;
    this.Nat1 = Nat1;
};
var SuccP = {};
var Succ = function (Nat0, Pos1) {
    this.Nat0 = Nat0;
    this.Pos1 = Pos1;
};
var Sub = {};
var Pred = function (Pos0) {
    this.Pos0 = Pos0;
};
var Mul10 = function (Nat0) {
    this.Nat0 = Nat0;
};
var Mul = function (Nat0, Nat1) {
    this.Nat0 = Nat0;
    this.Nat1 = Nat1;
};
var Mod = {};
var Min = {};
var MaxP = {};
var Max = {};
var LtEq = {};
var Lt = {};
var IsZero = {};
var IsDivBy = function (Pos0) {
    this.Pos0 = Pos0;
};
var GtEq = {};
var Gt = {};
var GCDP = function (Nat0, Nat1, Nat2) {
    this.Nat0 = Nat0;
    this.Nat1 = Nat1;
    this.Nat2 = Nat2;
};
var GCD = function (Nat0, Nat1, Nat2) {
    this.Nat0 = Nat0;
    this.Nat1 = Nat1;
    this.Nat2 = Nat2;
};
var Failure = {};
var Eq = {};
var DivModP = function (Nat0, Pos1) {
    this.Nat0 = Nat0;
    this.Pos1 = Pos1;
};
var DivMod10 = function (Nat0, Nat1) {
    this.Nat0 = Nat0;
    this.Nat1 = Nat1;
};
var DivMod = function (Nat0, Pos1) {
    this.Nat0 = Nat0;
    this.Pos1 = Pos1;
};
var Div10 = function (Nat0) {
    this.Nat0 = Nat0;
};
var Div = {};
var CS = {};
var AddP = function (Nat0) {
    this.Nat0 = Nat0;
};
var Add = function (AddP0, AddP1) {
    this.AddP0 = AddP0;
    this.AddP1 = AddP1;
};
var typelevelSucc = function (dictPos) {
    return function (dictIsZero) {
        return function (dictDivMod10) {
            return function (dictSuccP) {
                return function (dictDivMod101) {
                    return new Succ(dictDivMod10.Nat1, function () {
                        return dictPos;
                    });
                };
            };
        };
    };
};
var trichLtEq = function (dictSucc) {
    return function (dictTrich) {
        return LtEq;
    };
};
var trichLt = function (dictTrich) {
    return Lt;
};
var trichGtEq = function (dictSucc) {
    return function (dictTrich) {
        return GtEq;
    };
};
var trichGt = function (dictTrich) {
    return Gt;
};
var trichEq = function (dictTrich) {
    return Eq;
};
var trichDxxDxx = function (dictPos) {
    return function (dictPos1) {
        return function (dictTrich) {
            return function (dictTrich1) {
                return function (dictCS) {
                    return new Trich(dictPos.Nat0, dictPos1.Nat0);
                };
            };
        };
    };
};
var trichDxxD9 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD9;
    });
};
var trichDxxD8 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD8;
    });
};
var trichDxxD7 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD7;
    });
};
var trichDxxD6 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD6;
    });
};
var trichDxxD5 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD5;
    });
};
var trichDxxD4 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD4;
    });
};
var trichDxxD3 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD3;
    });
};
var trichDxxD2 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD2;
    });
};
var trichDxxD1 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD1;
    });
};
var trichDxxD0 = function (dictPos) {
    return new Trich(dictPos.Nat0, function () {
        return Data_Typelevel_Num_Sets.natD0;
    });
};
var trichD9Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD9;
    }, dictPos.Nat0);
};
var trichD9D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD9D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD9D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD9D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD9D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD9D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD9D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD9D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD9D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD9D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD9;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD8Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD8;
    }, dictPos.Nat0);
};
var trichD8D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD8D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD8D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD8D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD8D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD8D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD8D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD8D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD8D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD8D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD8;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD7Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD7;
    }, dictPos.Nat0);
};
var trichD7D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD7D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD7D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD7D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD7D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD7D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD7D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD7D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD7D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD7D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD7;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD6Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD6;
    }, dictPos.Nat0);
};
var trichD6D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD6D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD6D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD6D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD6D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD6D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD6D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD6D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD6D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD6D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD6;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD5Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD5;
    }, dictPos.Nat0);
};
var trichD5D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD5D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD5D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD5D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD5D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD5D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD5D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD5D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD5D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD5D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD5;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD4Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD4;
    }, dictPos.Nat0);
};
var trichD4D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD4D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD4D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD4D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD4D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD4D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD4D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD4D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD4D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD4D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD4;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD3Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD3;
    }, dictPos.Nat0);
};
var trichD3D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD3D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD3D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD3D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD3D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD3D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD3D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD3D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD3D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD3D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD3;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD2Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD2;
    }, dictPos.Nat0);
};
var trichD2D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD2D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD2D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD2D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD2D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD2D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD2D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD2D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD2D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD2D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD2;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD1Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD1;
    }, dictPos.Nat0);
};
var trichD1D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD1D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD1D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD1D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD1D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD1D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD1D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD1D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD1D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD1D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD1;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trichD0Dxx = function (dictPos) {
    return new Trich(function () {
        return Data_Typelevel_Num_Sets.natD0;
    }, dictPos.Nat0);
};
var trichD0D9 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var trichD0D8 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var trichD0D7 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var trichD0D6 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var trichD0D5 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var trichD0D4 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var trichD0D3 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var trichD0D2 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var trichD0D1 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var trichD0D0 = new Trich(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var trich = function (dictTrich) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var succPxiD9iyD0 = function (dictSucc) {
    return SuccP;
};
var succPxiD8xiD9 = SuccP;
var succPxiD7xiD8 = SuccP;
var succPxiD6xiD7 = SuccP;
var succPxiD5xiD6 = SuccP;
var succPxiD4xiD5 = SuccP;
var succPxiD3xiD4 = SuccP;
var succPxiD2xiD3 = SuccP;
var succPxiD1xiD2 = SuccP;
var succPxiD0xiD1 = SuccP;
var succPred = function (dictSucc) {
    return new Pred(dictSucc.Pos1);
};
var succ = function (dictSucc) {
    return function (v) {
        return Data_Typelevel_Undefined["undefined"];
    };
};
var subtractTypeLevelRelation = function (dictAdd) {
    return Sub;
};
var sub = function (dictSub) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var showLT = new Data_Show.Show(function (v) {
    return "LT";
});
var showGT = new Data_Show.Show(function (v) {
    return "GT";
});
var showEQ = new Data_Show.Show(function (v) {
    return "EQ";
});
var pred = function (dictPred) {
    return function (v) {
        return Data_Typelevel_Undefined["undefined"];
    };
};
var mulMultidigits = function (dictPos) {
    return function (dictNat) {
        return function (dictMul) {
            return function (dictMul10) {
                return function (dictMul1) {
                    return function (dictAdd) {
                        return new Mul(dictPos.Nat0, dictMul.Nat1);
                    };
                };
            };
        };
    };
};
var mulD9Nat = function (dictAdd) {
    return function (dictMul) {
        return new Mul(function () {
            return Data_Typelevel_Num_Sets.natD9;
        }, (dictAdd.AddP1()).Nat0);
    };
};
var mulD8Nat = function (dictAdd) {
    return function (dictMul) {
        return new Mul(function () {
            return Data_Typelevel_Num_Sets.natD8;
        }, (dictAdd.AddP1()).Nat0);
    };
};
var mulD7Nat = function (dictAdd) {
    return function (dictMul) {
        return new Mul(function () {
            return Data_Typelevel_Num_Sets.natD7;
        }, (dictAdd.AddP1()).Nat0);
    };
};
var mulD6Nat = function (dictAdd) {
    return function (dictMul) {
        return new Mul(function () {
            return Data_Typelevel_Num_Sets.natD6;
        }, (dictAdd.AddP1()).Nat0);
    };
};
var mulD5Nat = function (dictAdd) {
    return function (dictMul) {
        return new Mul(function () {
            return Data_Typelevel_Num_Sets.natD5;
        }, (dictAdd.AddP1()).Nat0);
    };
};
var mulD4Nat = function (dictAdd) {
    return function (dictMul) {
        return new Mul(function () {
            return Data_Typelevel_Num_Sets.natD4;
        }, (dictAdd.AddP1()).Nat0);
    };
};
var mulD3Nat = function (dictAdd) {
    return function (dictMul) {
        return new Mul(function () {
            return Data_Typelevel_Num_Sets.natD3;
        }, (dictAdd.AddP1()).Nat0);
    };
};
var mulD2Nat = function (dictAdd) {
    return new Mul(function () {
        return Data_Typelevel_Num_Sets.natD2;
    }, (dictAdd.AddP1()).Nat0);
};
var mulD1Nat = function (dictNat) {
    return new Mul(function () {
        return Data_Typelevel_Num_Sets.natD1;
    }, function () {
        return dictNat;
    });
};
var mulD0Nat = function (dictNat) {
    return new Mul(function () {
        return Data_Typelevel_Num_Sets.natD0;
    }, function () {
        return dictNat;
    });
};
var mul10Nat = function (dictDivMod10) {
    return new Mul10(dictDivMod10.Nat1);
};
var mul10 = function (dictMul10) {
    return function (v) {
        return Data_Typelevel_Undefined["undefined"];
    };
};
var mul = function (dictMul) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var modNatPos = function (dictDivMod) {
    return Mod;
};
var mod = function (dictMod) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var minRelation = function (dictMaxP) {
    return function (dictTrich) {
        return Min;
    };
};
var min = function (dictMin) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var maxRelation = function (dictMaxP) {
    return function (dictTrich) {
        return Max;
    };
};
var maxPLT = MaxP;
var maxPGT = MaxP;
var maxPEQ = MaxP;
var max = function (dictMax) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var lteq = function (dictLtEq) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var lt = function (dictLt) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var isZeroPos = function (dictPos) {
    return IsZero;
};
var isZeroD9 = IsZero;
var isZeroD8 = IsZero;
var isZeroD7 = IsZero;
var isZeroD6 = IsZero;
var isZeroD5 = IsZero;
var isZeroD4 = IsZero;
var isZeroD3 = IsZero;
var isZeroD2 = IsZero;
var isZeroD1 = IsZero;
var isZeroD0 = IsZero;
var isDivByPosNat = function (dictDivMod) {
    return new IsDivBy(dictDivMod.Pos1);
};
var isDivBy = function (dictIsDivBy) {
    return function (v) {
        return Data_Typelevel_Undefined["undefined"];
    };
};
var gteq = function (dictGtEq) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var gt = function (dictGt) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var gcdpXYLT = function (dictNat) {
    return function (dictNat1) {
        return function (dictGCD) {
            return new GCDP(dictGCD.Nat1, dictGCD.Nat0, dictGCD.Nat2);
        };
    };
};
var gcdpXYGT = function (dictNat) {
    return function (dictNat1) {
        return function (dictSub) {
            return function (dictGCD) {
                return new GCDP(function () {
                    return dictNat;
                }, dictGCD.Nat1, dictGCD.Nat2);
            };
        };
    };
};
var gcdpXX = function (dictNat) {
    return new GCDP(function () {
        return dictNat;
    }, function () {
        return dictNat;
    }, function () {
        return dictNat;
    });
};
var gcdpD0 = function (dictNat) {
    return new GCDP(function () {
        return dictNat;
    }, function () {
        return Data_Typelevel_Num_Sets.natD0;
    }, function () {
        return Data_Typelevel_Num_Sets.natD0;
    });
};
var gcdRelation = function (dictNat) {
    return function (dictNat1) {
        return function (dictTrich) {
            return function (dictIsZero) {
                return function (dictGCDP) {
                    return new GCD(dictGCDP.Nat0, dictGCDP.Nat1, dictGCDP.Nat2);
                };
            };
        };
    };
};
var gcd = function (dictGCD) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var failurePredOfZeroError = function (dictFailure) {
    return SuccP;
};
var eq = function (dictEq) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var divNatPos = function (dictDivMod) {
    return Div;
};
var divModPQR = function (dictNat) {
    return function (dictPos) {
        return function (dictSub) {
            return function (dictPred) {
                return function (dictDivMod) {
                    return new DivModP(function () {
                        return dictNat;
                    }, dictDivMod.Pos1);
                };
            };
        };
    };
};
var divModPD0Nat = function (dictNat) {
    return function (dictPos) {
        return new DivModP(function () {
            return dictNat;
        }, function () {
            return dictPos;
        });
    };
};
var divModPD0D1 = function (dictNat) {
    return function (dictPos) {
        return new DivModP(function () {
            return dictNat;
        }, function () {
            return dictPos;
        });
    };
};
var divModNatPos = function (dictNat) {
    return function (dictPos) {
        return function (dictTrich) {
            return function (dictDivModP) {
                return new DivMod(dictDivModP.Nat0, dictDivModP.Pos1);
            };
        };
    };
};
var divModIDontEvenAnymore = function (dictNat) {
    return function (dictNat1) {
        return new DivMod10(function () {
            return dictNat;
        }, function () {
            return dictNat1;
        });
    };
};
var divMod10D9x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD9;
    }, function () {
        return dictNat;
    });
};
var divMod10D9D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD9;
});
var divMod10D8x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD8;
    }, function () {
        return dictNat;
    });
};
var divMod10D8D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD8;
});
var divMod10D7x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD7;
    }, function () {
        return dictNat;
    });
};
var divMod10D7D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD7;
});
var divMod10D6x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD6;
    }, function () {
        return dictNat;
    });
};
var divMod10D6D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD6;
});
var divMod10D5x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD5;
    }, function () {
        return dictNat;
    });
};
var divMod10D5D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD5;
});
var divMod10D4x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD4;
    }, function () {
        return dictNat;
    });
};
var divMod10D4D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD4;
});
var divMod10D3x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD3;
    }, function () {
        return dictNat;
    });
};
var divMod10D3D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD3;
});
var divMod10D2x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD2;
    }, function () {
        return dictNat;
    });
};
var divMod10D2D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD2;
});
var divMod10D1x = function (dictNat) {
    return new DivMod10(function () {
        return Data_Typelevel_Num_Sets.natD1;
    }, function () {
        return dictNat;
    });
};
var divMod10D1D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD1;
});
var divMod10D0D0 = new DivMod10(function () {
    return Data_Typelevel_Num_Sets.natD0;
}, function () {
    return Data_Typelevel_Num_Sets.natD0;
});
var divMod10 = function (dictDivMod10) {
    return function (v) {
        return new Data_Tuple.Tuple(Data_Typelevel_Undefined["undefined"], Data_Typelevel_Undefined["undefined"]);
    };
};
var divMod = function (dictDivMod) {
    return function (v) {
        return function (v1) {
            return new Data_Tuple.Tuple(Data_Typelevel_Undefined["undefined"], Data_Typelevel_Undefined["undefined"]);
        };
    };
};
var div10Nat = function (dictDivMod10) {
    return new Div10(dictDivMod10.Nat1);
};
var div10 = function (dictDiv10) {
    return function (v) {
        return Data_Typelevel_Undefined["undefined"];
    };
};
var div = function (dictDiv) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
var csLTrLT = CS;
var csGTrGT = CS;
var csEQrr = CS;
var addTypeLevelRelation = function (dictAddP) {
    return function (dictAddP1) {
        return new Add(function () {
            return dictAddP;
        }, function () {
            return dictAddP1;
        });
    };
};
var addPMultiDigits = function (dictPos) {
    return function (dictNat) {
        return function (dictAddP) {
            return function (dictDivMod10) {
                return function (dictAddP1) {
                    return new AddP(dictPos.Nat0);
                };
            };
        };
    };
};
var addPD9ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD9;
        });
    };
};
var addPD8ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD8;
        });
    };
};
var addPD7ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD7;
        });
    };
};
var addPD6ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD6;
        });
    };
};
var addPD5ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD5;
        });
    };
};
var addPD4ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD4;
        });
    };
};
var addPD3ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD3;
        });
    };
};
var addPD2ToSucc = function (dictSucc) {
    return function (dictAddP) {
        return new AddP(function () {
            return Data_Typelevel_Num_Sets.natD2;
        });
    };
};
var addPD1ToSucc = function (dictSucc) {
    return new AddP(function () {
        return Data_Typelevel_Num_Sets.natD1;
    });
};
var addPD0ToNat = function (dictNat) {
    return new AddP(function () {
        return Data_Typelevel_Num_Sets.natD0;
    });
};
var add = function (dictAdd) {
    return function (v) {
        return function (v1) {
            return Data_Typelevel_Undefined["undefined"];
        };
    };
};
module.exports = {
    Succ: Succ,
    SuccP: SuccP,
    succ: succ,
    Pred: Pred,
    pred: pred,
    Failure: Failure,
    AddP: AddP,
    Add: Add,
    add: add,
    Sub: Sub,
    sub: sub,
    Mul: Mul,
    mul: mul,
    DivMod: DivMod,
    DivModP: DivModP,
    divMod: divMod,
    Div: Div,
    div: div,
    Mod: Mod,
    mod: mod,
    Mul10: Mul10,
    mul10: mul10,
    DivMod10: DivMod10,
    divMod10: divMod10,
    Div10: Div10,
    div10: div10,
    IsDivBy: IsDivBy,
    isDivBy: isDivBy,
    Trich: Trich,
    trich: trich,
    CS: CS,
    Eq: Eq,
    eq: eq,
    Gt: Gt,
    gt: gt,
    Lt: Lt,
    lt: lt,
    GtEq: GtEq,
    gteq: gteq,
    LtEq: LtEq,
    lteq: lteq,
    MaxP: MaxP,
    Max: Max,
    max: max,
    Min: Min,
    min: min,
    GCD: GCD,
    GCDP: GCDP,
    gcd: gcd,
    IsZero: IsZero,
    typelevelSucc: typelevelSucc,
    succPred: succPred,
    failurePredOfZeroError: failurePredOfZeroError,
    succPxiD0xiD1: succPxiD0xiD1,
    succPxiD1xiD2: succPxiD1xiD2,
    succPxiD2xiD3: succPxiD2xiD3,
    succPxiD3xiD4: succPxiD3xiD4,
    succPxiD4xiD5: succPxiD4xiD5,
    succPxiD5xiD6: succPxiD5xiD6,
    succPxiD6xiD7: succPxiD6xiD7,
    succPxiD7xiD8: succPxiD7xiD8,
    succPxiD8xiD9: succPxiD8xiD9,
    succPxiD9iyD0: succPxiD9iyD0,
    addPD0ToNat: addPD0ToNat,
    addPD1ToSucc: addPD1ToSucc,
    addPD2ToSucc: addPD2ToSucc,
    addPD3ToSucc: addPD3ToSucc,
    addPD4ToSucc: addPD4ToSucc,
    addPD5ToSucc: addPD5ToSucc,
    addPD6ToSucc: addPD6ToSucc,
    addPD7ToSucc: addPD7ToSucc,
    addPD8ToSucc: addPD8ToSucc,
    addPD9ToSucc: addPD9ToSucc,
    addPMultiDigits: addPMultiDigits,
    addTypeLevelRelation: addTypeLevelRelation,
    subtractTypeLevelRelation: subtractTypeLevelRelation,
    mulD0Nat: mulD0Nat,
    mulD1Nat: mulD1Nat,
    mulD2Nat: mulD2Nat,
    mulD3Nat: mulD3Nat,
    mulD4Nat: mulD4Nat,
    mulD5Nat: mulD5Nat,
    mulD6Nat: mulD6Nat,
    mulD7Nat: mulD7Nat,
    mulD8Nat: mulD8Nat,
    mulD9Nat: mulD9Nat,
    mulMultidigits: mulMultidigits,
    divModNatPos: divModNatPos,
    divModPD0Nat: divModPD0Nat,
    divModPD0D1: divModPD0D1,
    divModPQR: divModPQR,
    divNatPos: divNatPos,
    modNatPos: modNatPos,
    mul10Nat: mul10Nat,
    divMod10D0D0: divMod10D0D0,
    divMod10D1D0: divMod10D1D0,
    divMod10D2D0: divMod10D2D0,
    divMod10D3D0: divMod10D3D0,
    divMod10D4D0: divMod10D4D0,
    divMod10D5D0: divMod10D5D0,
    divMod10D6D0: divMod10D6D0,
    divMod10D7D0: divMod10D7D0,
    divMod10D8D0: divMod10D8D0,
    divMod10D9D0: divMod10D9D0,
    divMod10D1x: divMod10D1x,
    divMod10D2x: divMod10D2x,
    divMod10D3x: divMod10D3x,
    divMod10D4x: divMod10D4x,
    divMod10D5x: divMod10D5x,
    divMod10D6x: divMod10D6x,
    divMod10D7x: divMod10D7x,
    divMod10D8x: divMod10D8x,
    divMod10D9x: divMod10D9x,
    divModIDontEvenAnymore: divModIDontEvenAnymore,
    div10Nat: div10Nat,
    isDivByPosNat: isDivByPosNat,
    showLT: showLT,
    showEQ: showEQ,
    showGT: showGT,
    trichD0D0: trichD0D0,
    trichD0D1: trichD0D1,
    trichD0D2: trichD0D2,
    trichD0D3: trichD0D3,
    trichD0D4: trichD0D4,
    trichD0D5: trichD0D5,
    trichD0D6: trichD0D6,
    trichD0D7: trichD0D7,
    trichD0D8: trichD0D8,
    trichD0D9: trichD0D9,
    trichD0Dxx: trichD0Dxx,
    trichDxxD0: trichDxxD0,
    trichD1D0: trichD1D0,
    trichD1D1: trichD1D1,
    trichD1D2: trichD1D2,
    trichD1D3: trichD1D3,
    trichD1D4: trichD1D4,
    trichD1D5: trichD1D5,
    trichD1D6: trichD1D6,
    trichD1D7: trichD1D7,
    trichD1D8: trichD1D8,
    trichD1D9: trichD1D9,
    trichD1Dxx: trichD1Dxx,
    trichDxxD1: trichDxxD1,
    trichD2D0: trichD2D0,
    trichD2D1: trichD2D1,
    trichD2D2: trichD2D2,
    trichD2D3: trichD2D3,
    trichD2D4: trichD2D4,
    trichD2D5: trichD2D5,
    trichD2D6: trichD2D6,
    trichD2D7: trichD2D7,
    trichD2D8: trichD2D8,
    trichD2D9: trichD2D9,
    trichD2Dxx: trichD2Dxx,
    trichDxxD2: trichDxxD2,
    trichD3D0: trichD3D0,
    trichD3D1: trichD3D1,
    trichD3D2: trichD3D2,
    trichD3D3: trichD3D3,
    trichD3D4: trichD3D4,
    trichD3D5: trichD3D5,
    trichD3D6: trichD3D6,
    trichD3D7: trichD3D7,
    trichD3D8: trichD3D8,
    trichD3D9: trichD3D9,
    trichD3Dxx: trichD3Dxx,
    trichDxxD3: trichDxxD3,
    trichD4D0: trichD4D0,
    trichD4D1: trichD4D1,
    trichD4D2: trichD4D2,
    trichD4D3: trichD4D3,
    trichD4D4: trichD4D4,
    trichD4D5: trichD4D5,
    trichD4D6: trichD4D6,
    trichD4D7: trichD4D7,
    trichD4D8: trichD4D8,
    trichD4D9: trichD4D9,
    trichD4Dxx: trichD4Dxx,
    trichDxxD4: trichDxxD4,
    trichD5D0: trichD5D0,
    trichD5D1: trichD5D1,
    trichD5D2: trichD5D2,
    trichD5D3: trichD5D3,
    trichD5D4: trichD5D4,
    trichD5D5: trichD5D5,
    trichD5D6: trichD5D6,
    trichD5D7: trichD5D7,
    trichD5D8: trichD5D8,
    trichD5D9: trichD5D9,
    trichD5Dxx: trichD5Dxx,
    trichDxxD5: trichDxxD5,
    trichD6D0: trichD6D0,
    trichD6D1: trichD6D1,
    trichD6D2: trichD6D2,
    trichD6D3: trichD6D3,
    trichD6D4: trichD6D4,
    trichD6D5: trichD6D5,
    trichD6D6: trichD6D6,
    trichD6D7: trichD6D7,
    trichD6D8: trichD6D8,
    trichD6D9: trichD6D9,
    trichD6Dxx: trichD6Dxx,
    trichDxxD6: trichDxxD6,
    trichD7D0: trichD7D0,
    trichD7D1: trichD7D1,
    trichD7D2: trichD7D2,
    trichD7D3: trichD7D3,
    trichD7D4: trichD7D4,
    trichD7D5: trichD7D5,
    trichD7D6: trichD7D6,
    trichD7D7: trichD7D7,
    trichD7D8: trichD7D8,
    trichD7D9: trichD7D9,
    trichD7Dxx: trichD7Dxx,
    trichDxxD7: trichDxxD7,
    trichD8D0: trichD8D0,
    trichD8D1: trichD8D1,
    trichD8D2: trichD8D2,
    trichD8D3: trichD8D3,
    trichD8D4: trichD8D4,
    trichD8D5: trichD8D5,
    trichD8D6: trichD8D6,
    trichD8D7: trichD8D7,
    trichD8D8: trichD8D8,
    trichD8D9: trichD8D9,
    trichD8Dxx: trichD8Dxx,
    trichDxxD8: trichDxxD8,
    trichD9D0: trichD9D0,
    trichD9D1: trichD9D1,
    trichD9D2: trichD9D2,
    trichD9D3: trichD9D3,
    trichD9D4: trichD9D4,
    trichD9D5: trichD9D5,
    trichD9D6: trichD9D6,
    trichD9D7: trichD9D7,
    trichD9D8: trichD9D8,
    trichD9D9: trichD9D9,
    trichD9Dxx: trichD9Dxx,
    trichDxxD9: trichDxxD9,
    trichDxxDxx: trichDxxDxx,
    csEQrr: csEQrr,
    csGTrGT: csGTrGT,
    csLTrLT: csLTrLT,
    trichEq: trichEq,
    trichGt: trichGt,
    trichLt: trichLt,
    trichGtEq: trichGtEq,
    trichLtEq: trichLtEq,
    maxPLT: maxPLT,
    maxPEQ: maxPEQ,
    maxPGT: maxPGT,
    maxRelation: maxRelation,
    minRelation: minRelation,
    gcdRelation: gcdRelation,
    gcdpD0: gcdpD0,
    gcdpXYLT: gcdpXYLT,
    gcdpXX: gcdpXX,
    gcdpXYGT: gcdpXYGT,
    isZeroD0: isZeroD0,
    isZeroD1: isZeroD1,
    isZeroD2: isZeroD2,
    isZeroD3: isZeroD3,
    isZeroD4: isZeroD4,
    isZeroD5: isZeroD5,
    isZeroD6: isZeroD6,
    isZeroD7: isZeroD7,
    isZeroD8: isZeroD8,
    isZeroD9: isZeroD9,
    isZeroPos: isZeroPos
};
