// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Min = (function () {
    function Min() {

    };
    Min.value = new Min();
    return Min;
})();
var Mid = (function () {
    function Mid() {

    };
    Mid.value = new Mid();
    return Mid;
})();
var Max = (function () {
    function Max() {

    };
    Max.value = new Max();
    return Max;
})();
var showAlign = new Data_Show.Show(function (v) {
    if (v instanceof Min) {
        return "Min";
    };
    if (v instanceof Mid) {
        return "Mid";
    };
    if (v instanceof Max) {
        return "Max";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Align (line 16, column 10 - line 19, column 17): " + [ v.constructor.name ]);
});
var printAlign = function (v) {
    if (v instanceof Min) {
        return "Min";
    };
    if (v instanceof Mid) {
        return "Mid";
    };
    if (v instanceof Max) {
        return "Max";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Align (line 22, column 14 - line 25, column 15): " + [ v.constructor.name ]);
};
var eqAlign = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Min && y instanceof Min) {
            return true;
        };
        if (x instanceof Mid && y instanceof Mid) {
            return true;
        };
        if (x instanceof Max && y instanceof Max) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Min: Min,
    Mid: Mid,
    Max: Max,
    printAlign: printAlign,
    eqAlign: eqAlign,
    showAlign: showAlign
};
