// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var App_Style_Hydra_Background = require("../App.Style.Hydra.Background/index.js");
var App_Style_Hydra_Body = require("../App.Style.Hydra.Body/index.js");
var App_Style_Hydra_Link = require("../App.Style.Hydra.Link/index.js");
var App_Style_Hydra_NodeTab = require("../App.Style.Hydra.NodeTab/index.js");
var App_Style_Hydra_PatchTab = require("../App.Style.Hydra.PatchTab/index.js");
var App_Style_Hydra_Slot = require("../App.Style.Hydra.Slot/index.js");
var App_Style_Hydra_Title = require("../App.Style.Hydra.Title/index.js");
var App_Style_Order = require("../App.Style.Order/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Set = require("../Data.Set/index.js");
var style = {
    order: App_Style_Order.make(Data_Foldable.foldableArray)([ App_Style.Title.value, App_Style.Ribbon.value, new App_Style.UserBody(55.0), App_Style.OnlyInlets.value, App_Style.OnlyOutlets.value ]),
    bg: App_Style_Hydra_Background.bg,
    body: App_Style_Hydra_Body.body,
    slot: App_Style_Hydra_Slot.slot,
    link: App_Style_Hydra_Link.link,
    title: App_Style_Hydra_Title.title,
    supportedFlows: Data_Set.singleton(App_Style.Vertical.value),
    font: {
        size: 7.0,
        family: [ "Trispace", "PT Mono", "Andale Mono", "Fira mono", "Menlo" ]
    },
    patchTab: App_Style_Hydra_PatchTab.patchTab,
    nodeTab: App_Style_Hydra_NodeTab.nodeTab
};
module.exports = {
    style: style
};
