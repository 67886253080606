// Generated by purs version 0.14.3
"use strict";
var Safe_Coerce = require("../Safe.Coerce/index.js");
var Newtype = function (Coercible0) {
    this.Coercible0 = Coercible0;
};
var wrap = function (dictNewtype) {
    return Safe_Coerce.coerce();
};
var unwrap = function (dictNewtype) {
    return Safe_Coerce.coerce();
};
var underF2 = function (dictCoercible) {
    return function (dictCoercible1) {
        return function (dictNewtype) {
            return function (dictNewtype1) {
                return function (v) {
                    return Safe_Coerce.coerce();
                };
            };
        };
    };
};
var underF = function (dictCoercible) {
    return function (dictCoercible1) {
        return function (dictNewtype) {
            return function (dictNewtype1) {
                return function (v) {
                    return Safe_Coerce.coerce();
                };
            };
        };
    };
};
var under2 = function (dictNewtype) {
    return function (dictNewtype1) {
        return function (v) {
            return Safe_Coerce.coerce();
        };
    };
};
var under = function (dictNewtype) {
    return function (dictNewtype1) {
        return function (v) {
            return Safe_Coerce.coerce();
        };
    };
};
var un = function (dictNewtype) {
    return function (v) {
        return unwrap();
    };
};
var traverse = function (dictCoercible) {
    return function (dictNewtype) {
        return function (v) {
            return Safe_Coerce.coerce();
        };
    };
};
var overF2 = function (dictCoercible) {
    return function (dictCoercible1) {
        return function (dictNewtype) {
            return function (dictNewtype1) {
                return function (v) {
                    return Safe_Coerce.coerce();
                };
            };
        };
    };
};
var overF = function (dictCoercible) {
    return function (dictCoercible1) {
        return function (dictNewtype) {
            return function (dictNewtype1) {
                return function (v) {
                    return Safe_Coerce.coerce();
                };
            };
        };
    };
};
var over2 = function (dictNewtype) {
    return function (dictNewtype1) {
        return function (v) {
            return Safe_Coerce.coerce();
        };
    };
};
var over = function (dictNewtype) {
    return function (dictNewtype1) {
        return function (v) {
            return Safe_Coerce.coerce();
        };
    };
};
var newtypeMultiplicative = new Newtype(function () {
    return undefined;
});
var newtypeLast = new Newtype(function () {
    return undefined;
});
var newtypeFirst = new Newtype(function () {
    return undefined;
});
var newtypeEndo = new Newtype(function () {
    return undefined;
});
var newtypeDual = new Newtype(function () {
    return undefined;
});
var newtypeDisj = new Newtype(function () {
    return undefined;
});
var newtypeConj = new Newtype(function () {
    return undefined;
});
var newtypeAdditive = new Newtype(function () {
    return undefined;
});
var collect = function (dictCoercible) {
    return function (dictNewtype) {
        return function (v) {
            return Safe_Coerce.coerce();
        };
    };
};
var alaF = function (dictCoercible) {
    return function (dictCoercible1) {
        return function (dictNewtype) {
            return function (dictNewtype1) {
                return function (v) {
                    return Safe_Coerce.coerce();
                };
            };
        };
    };
};
var ala = function (dictCoercible) {
    return function (dictNewtype) {
        return function (dictNewtype1) {
            return function (v) {
                return function (f) {
                    return Safe_Coerce.coerce()(f(wrap()));
                };
            };
        };
    };
};
module.exports = {
    Newtype: Newtype,
    wrap: wrap,
    unwrap: unwrap,
    un: un,
    ala: ala,
    alaF: alaF,
    over: over,
    overF: overF,
    under: under,
    underF: underF,
    over2: over2,
    overF2: overF2,
    under2: under2,
    underF2: underF2,
    traverse: traverse,
    collect: collect,
    newtypeAdditive: newtypeAdditive,
    newtypeMultiplicative: newtypeMultiplicative,
    newtypeConj: newtypeConj,
    newtypeDisj: newtypeDisj,
    newtypeDual: newtypeDual,
    newtypeEndo: newtypeEndo,
    newtypeFirst: newtypeFirst,
    newtypeLast: newtypeLast
};
