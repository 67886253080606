// Generated by purs version 0.14.3
"use strict";
var App_Emitters = require("../App.Emitters/index.js");
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Subscription = require("../Halogen.Subscription/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Halogen_Svg_Elements_None = require("../Halogen.Svg.Elements.None/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Component_Input = require("../Hydra.Component.Input/index.js");
var Hydra_Extract = require("../Hydra.Extract/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var NoOp = (function () {
    function NoOp() {

    };
    NoOp.value = new NoOp();
    return NoOp;
})();
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Change = (function () {
    function Change(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Change.create = function (value0) {
        return function (value1) {
            return new Change(value0, value1);
        };
    };
    return Change;
})();
var Remove = (function () {
    function Remove(value0) {
        this.value0 = value0;
    };
    Remove.create = function (value0) {
        return new Remove(value0);
    };
    return Remove;
})();
var Update = (function () {
    function Update(value0) {
        this.value0 = value0;
    };
    Update.create = function (value0) {
        return new Update(value0);
    };
    return Update;
})();
var render = function (v) {
    var itemInput = function (v1) {
        return function (v2) {
            if (v2 instanceof Hydra.Num) {
                return Halogen_Svg_Elements.g([  ])([ Hydra_Component_Input.number(v2.value0)({
                    min: 0.0,
                    max: 255.0,
                    step: 1.0e-2
                })(NoOp.value)(Change.create(v1)), Hydra_Component_Input.button(new Remove(v1)) ]);
            };
            return Halogen_Svg_Elements_None.none;
        };
    };
    return Halogen_Svg_Elements.g([  ])([ Halogen_Svg_Elements.g([  ])(Data_Array.mapWithIndex(itemInput)(v.value0)) ]);
};
var initialState = function (v) {
    return new Data_Tuple.Tuple(Data_Maybe.fromMaybe([  ])(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Noodle_Node.defaultOfInlet("seq")(v.node))(Hydra_Extract.seq)), v.node);
};
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof NoOp) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(App_Emitters.fromOutlet(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(v1.value1)("seq"))(function (emitter) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM.subscribe(Data_Functor.map(Halogen_Subscription.functorEmitter)(Update.create)(emitter)))(function () {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    });
                });
            });
        };
        if (v instanceof Change) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return new Data_Tuple.Tuple(Data_Maybe.fromMaybe(v1.value0)(Data_Array.updateAt(v.value0)(new Hydra.Num(v.value1))(v1.value0)), v1.value1);
            }))(function () {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                    return Halogen_Query_HalogenM.raise(App_Toolkit_UI.SendToOutlet.create("seq")(Hydra.seq(v1.value0)));
                });
            });
        };
        if (v instanceof Update) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return new Data_Tuple.Tuple(Hydra_Extract.seq(v.value0), v1.value1);
            });
        };
        if (v instanceof Remove) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return new Data_Tuple.Tuple(Data_Maybe.fromMaybe(v1.value0)(Data_Array.deleteAt(v.value0)(v1.value0)), v1.value1);
            }))(function () {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                    return Halogen_Query_HalogenM.raise(App_Toolkit_UI.SendToOutlet.create("seq")(Hydra.seq(v1.value0)));
                });
            });
        };
        throw new Error("Failed pattern match at Hydra.Component.Node.Seq (line 71, column 16 - line 92, column 57): " + [ v.constructor.name ]);
    };
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    NoOp: NoOp,
    Initialize: Initialize,
    Change: Change,
    Remove: Remove,
    Update: Update,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
