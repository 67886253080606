// Generated by purs version 0.14.3
"use strict";
var mouseup = "mouseup";
var mouseover = "mouseover";
var mouseout = "mouseout";
var mousemove = "mousemove";
var mouseleave = "mouseleave";
var mouseenter = "mouseenter";
var mousedown = "mousedown";
var dblclick = "dblclick";
var click = "click";
module.exports = {
    click: click,
    dblclick: dblclick,
    mousedown: mousedown,
    mouseenter: mouseenter,
    mouseleave: mouseleave,
    mousemove: mousemove,
    mouseout: mouseout,
    mouseover: mouseover,
    mouseup: mouseup
};
