// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Effect = require("../Effect/index.js");
var Effect_Ref = require("../Effect.Ref/index.js");
var Noodle_Channel_Shape = require("../Noodle.Channel.Shape/index.js");
var Noodle_Node_Shape = require("../Noodle.Node.Shape/index.js");
var Signal = require("../Signal/index.js");
var Signal_Channel = require("../Signal.Channel/index.js");
var Signal_Channel_Extra = require("../Signal.Channel.Extra/index.js");
var Link = function (x) {
    return x;
};
var Node = (function () {
    function Node(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    Node.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new Node(value0, value1, value2, value3);
                };
            };
        };
    };
    return Node;
})();
var markFamily = function (family1) {
    return function (v) {
        return Node.create(v.value0)(v.value1)(v.value2)(new Data_Maybe.Just(family1));
    };
};
var linksAtOutlet = function (outlet1) {
    var $105 = Data_Maybe.fromMaybe(0);
    var $106 = Data_Map_Internal.lookup(Data_Ord.ordString)(outlet1);
    return function ($107) {
        return $105($106(Data_Tuple.snd($107)));
    };
};
var linksAtInlet = function (inlet1) {
    var $108 = Data_Maybe.fromMaybe(0);
    var $109 = Data_Map_Internal.lookup(Data_Ord.ordString)(inlet1);
    return function ($110) {
        return $108($109(Data_Tuple.fst($110)));
    };
};
var getShape$prime = function (v) {
    return v.value1;
};
var inlet = function (name) {
    var $111 = Noodle_Node_Shape.inlet(name);
    return function ($112) {
        return $111(getShape$prime($112));
    };
};
var inlets = function ($113) {
    return Noodle_Node_Shape.inlets(getShape$prime($113));
};
var indexOfInlet = function (inletName) {
    return function (node) {
        return Data_Array.elemIndex(Data_Eq.eqString)(inletName)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(inlets(node)));
    };
};
var inletsBy = function (pred) {
    var $114 = Noodle_Node_Shape.inletsBy(pred);
    return function ($115) {
        return $114(getShape$prime($115));
    };
};
var outlet = function (name) {
    var $116 = Noodle_Node_Shape.outlet(name);
    return function ($117) {
        return $116(getShape$prime($117));
    };
};
var outlets = function ($118) {
    return Noodle_Node_Shape.outlets(getShape$prime($118));
};
var indexOfOutlet = function (outletName) {
    return function (node) {
        return Data_Array.elemIndex(Data_Eq.eqString)(outletName)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(outlets(node)));
    };
};
var outletsBy = function (pred) {
    var $119 = Noodle_Node_Shape.outletsBy(pred);
    return function ($120) {
        return $119(getShape$prime($120));
    };
};
var getShape = (function () {
    var $121 = Data_Newtype.unwrap();
    return function ($122) {
        return $121(getShape$prime($122));
    };
})();
var getOutletsChannel = function (v) {
    return v.value2.value1;
};
var outletsSignal = function ($123) {
    return Signal_Channel.subscribe(getOutletsChannel($123));
};
var outletsSignal$prime = (function () {
    var $124 = Signal.foldp(Data_Tuple.uncurry(Data_Map_Internal.insert(Data_Ord.ordString)))(Data_Map_Internal.empty);
    return function ($125) {
        return $124(outletsSignal($125));
    };
})();
var produce = function (node) {
    return function (v) {
        return Signal_Channel.send(getOutletsChannel(node))(new Data_Tuple.Tuple(v.value0, v.value1));
    };
};
var getInletsChannel = function (v) {
    return v.value2.value0;
};
var inletsSignal = function ($126) {
    return Signal_Channel.subscribe(getInletsChannel($126));
};
var inletsSignal$prime = (function () {
    var $127 = Signal.foldp(Data_Tuple.uncurry(Data_Map_Internal.insert(Data_Ord.ordString)))(Data_Map_Internal.empty);
    return function ($128) {
        return $127(inletsSignal($128));
    };
})();
var send = function (node) {
    return function (v) {
        return Signal_Channel.send(getInletsChannel(node))(new Data_Tuple.Tuple(v.value0, v.value1));
    };
};
var family = function (v) {
    return v.value3;
};
var distribute = function (passTo) {
    return function (v) {
        return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(Signal_Channel.send(passTo))(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.toOutlets));
    };
};
var disconnect = function (v) {
    return Effect_Ref.write(false)(v);
};
var dimensionsBy = function (pred) {
    return function (v) {
        return Noodle_Node_Shape.dimensionsBy(pred)(v.value1);
    };
};
var dimensions = function (v) {
    return Noodle_Node_Shape.dimensions(v.value1);
};
var defaultOfOutlet = function (name) {
    return function (node) {
        return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(outlet(name)(node))(Noodle_Channel_Shape.getDefault);
    };
};
var defaultOfInlet = function (name) {
    return function (node) {
        return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(inlet(name)(node))(Noodle_Channel_Shape.getDefault);
    };
};
var $$default = function (v) {
    return v.value0;
};
var consumer = "consume_";
var inletSignal = function (node) {
    return function (name) {
        return Signal.flippedMap(Signal.functorSignal)(Signal.filter((function () {
            var $129 = Data_Eq.eq(Data_Eq.eqString)(name);
            return function ($130) {
                return $129(Data_Tuple.fst($130));
            };
        })())(new Data_Tuple.Tuple(consumer, $$default(node)))(Signal_Channel.subscribe(getInletsChannel(node))))(Data_Tuple.snd);
    };
};
var get = function (node) {
    return function (name) {
        return Signal.get(inletSignal(node)(name));
    };
};
var make = function (default1) {
    return function (v) {
        return function __do() {
            var inlets_chan = Signal_Channel.channel(new Data_Tuple.Tuple(consumer, default1))();
            var outlets_chan = Signal_Channel.channel(new Data_Tuple.Tuple(consumer, default1))();
            var toReceive = function (v1) {
                return {
                    last: v1.value0,
                    fromInlets: v1.value1
                };
            };
            var store = function (v1) {
                return function (v2) {
                    return new Data_Tuple.Tuple(v1.value0, Data_Map_Internal.insert(Data_Ord.ordString)(v1.value0)(v1.value1)(v2.value1));
                };
            };
            var node = new Node(default1, v.value0, new Data_Tuple.Tuple(inlets_chan, outlets_chan), Data_Maybe.Nothing.value);
            var inlets1 = Signal_Channel.subscribe(inlets_chan);
            var maps = Signal.foldp(store)(new Data_Tuple.Tuple(consumer, Data_Map_Internal.empty))(inlets1);
            var fn_signal = Signal.flippedMap(Signal.functorSignal)(Signal.flippedMap(Signal.functorSignal)(maps)(toReceive))(v.value1);
            var passFx = Data_Functor.map(Signal.functorSignal)(Control_Bind.bindFlipped(Effect.bindEffect)(distribute(outlets_chan)))(fn_signal);
            Signal.runSignal(passFx)();
            return node;
        };
    };
};
var move = function (f) {
    return function (g) {
        return function (v) {
            var nextDefault = f(v.value0);
            var movedShape = Data_Functor_Invariant.imap(Noodle_Node_Shape.invariantShape)(f)(g)(v.value1);
            return function __do() {
                var newInChannel = Signal_Channel.channel(new Data_Tuple.Tuple(consumer, nextDefault))();
                var newOutChannel = Signal_Channel.channel(new Data_Tuple.Tuple(consumer, nextDefault))();
                Signal.runSignal(Signal.flippedMap(Signal.functorSignal)(Signal.flippedMap(Signal.functorSignal)(Signal_Channel.subscribe(v.value2.value0))(Data_Functor.map(Data_Tuple.functorTuple)(f)))(Signal_Channel.send(newInChannel)))();
                Signal.runSignal(Signal.flippedMap(Signal.functorSignal)(Signal.flippedMap(Signal.functorSignal)(Signal_Channel.subscribe(v.value2.value1))(Data_Functor.map(Data_Tuple.functorTuple)(f)))(Signal_Channel.send(newOutChannel)))();
                return new Node(nextDefault, movedShape, new Data_Tuple.Tuple(newInChannel, newOutChannel), v.value3);
            };
        };
    };
};
var outletSignal = function (node) {
    return function (name) {
        return Signal.flippedMap(Signal.functorSignal)(Signal.filter((function () {
            var $131 = Data_Eq.eq(Data_Eq.eqString)(name);
            return function ($132) {
                return $131(Data_Tuple.fst($132));
            };
        })())(new Data_Tuple.Tuple(consumer, $$default(node)))(Signal_Channel.subscribe(getOutletsChannel(node))))(Data_Tuple.snd);
    };
};
var get$prime = function (node) {
    return function (name) {
        return Signal.get(outletSignal(node)(name));
    };
};
var outletSignalFlipped = Data_Function.flip(outletSignal);
var connect = function (v) {
    return function (v1) {
        var inlets_chan = getInletsChannel(v1.value0);
        return function __do() {
            var ref = Effect_Ref["new"](true)();
            Signal.runSignal(Signal.flippedMap(Signal.functorSignal)(Signal.flippedMap(Signal.functorSignal)(outletSignal(v.value0)(v.value1))(Data_Tuple.Tuple.create(v1.value1)))(Signal_Channel_Extra.sendIfRef(inlets_chan)(ref)))();
            return ref;
        };
    };
};
module.exports = {
    send: send,
    produce: produce,
    connect: connect,
    disconnect: disconnect,
    make: make,
    move: move,
    markFamily: markFamily,
    family: family,
    inlet: inlet,
    outlet: outlet,
    inlets: inlets,
    outlets: outlets,
    inletsBy: inletsBy,
    outletsBy: outletsBy,
    inletSignal: inletSignal,
    outletSignal: outletSignal,
    outletSignalFlipped: outletSignalFlipped,
    inletsSignal: inletsSignal,
    outletsSignal: outletsSignal,
    "inletsSignal'": inletsSignal$prime,
    "outletsSignal'": outletsSignal$prime,
    get: get,
    "get'": get$prime,
    consumer: consumer,
    dimensions: dimensions,
    dimensionsBy: dimensionsBy,
    indexOfInlet: indexOfInlet,
    indexOfOutlet: indexOfOutlet,
    defaultOfInlet: defaultOfInlet,
    defaultOfOutlet: defaultOfOutlet,
    linksAtInlet: linksAtInlet,
    linksAtOutlet: linksAtOutlet
};
