// Generated by purs version 0.14.3
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var MonadZeroIsDeprecated = {};
var MonadZero = function (Alternative1, Monad0, MonadZeroIsDeprecated2) {
    this.Alternative1 = Alternative1;
    this.Monad0 = Monad0;
    this.MonadZeroIsDeprecated2 = MonadZeroIsDeprecated2;
};
var monadZeroIsDeprecated = function (dictWarn) {
    return MonadZeroIsDeprecated;
};
var monadZeroArray = new MonadZero(function () {
    return Control_Alternative.alternativeArray;
}, function () {
    return Control_Monad.monadArray;
}, function () {
    return undefined;
});
module.exports = {
    MonadZeroIsDeprecated: MonadZeroIsDeprecated,
    MonadZero: MonadZero,
    monadZeroIsDeprecated: monadZeroIsDeprecated,
    monadZeroArray: monadZeroArray,
    Alt: Control_Alt.Alt,
    alt: Control_Alt.alt,
    Alternative: Control_Alternative.Alternative,
    guard: Control_Alternative.guard,
    Applicative: Control_Applicative.Applicative,
    liftA1: Control_Applicative.liftA1,
    pure: Control_Applicative.pure,
    unless: Control_Applicative.unless,
    when: Control_Applicative.when,
    Apply: Control_Apply.Apply,
    apply: Control_Apply.apply,
    Bind: Control_Bind.Bind,
    bind: Control_Bind.bind,
    ifM: Control_Bind.ifM,
    join: Control_Bind.join,
    Monad: Control_Monad.Monad,
    ap: Control_Monad.ap,
    liftM1: Control_Monad.liftM1,
    Plus: Control_Plus.Plus,
    empty: Control_Plus.empty,
    Functor: Data_Functor.Functor,
    map: Data_Functor.map,
    "void": Data_Functor["void"]
};
