// Generated by purs version 0.14.3
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Inl = (function () {
    function Inl(value0) {
        this.value0 = value0;
    };
    Inl.create = function (value0) {
        return new Inl(value0);
    };
    return Inl;
})();
var Inr = (function () {
    function Inr(value0) {
        this.value0 = value0;
    };
    Inr.create = function (value0) {
        return new Inr(value0);
    };
    return Inr;
})();
var Product = (function () {
    function Product(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Product.create = function (value0) {
        return function (value1) {
            return new Product(value0, value1);
        };
    };
    return Product;
})();
var NoArguments = (function () {
    function NoArguments() {

    };
    NoArguments.value = new NoArguments();
    return NoArguments;
})();
var Generic = function (from, to) {
    this.from = from;
    this.to = to;
};
var Constructor = function (x) {
    return x;
};
var Argument = function (x) {
    return x;
};
var to = function (dict) {
    return dict.to;
};
var showSum = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            if (v instanceof Inl) {
                return "(Inl " + (Data_Show.show(dictShow)(v.value0) + ")");
            };
            if (v instanceof Inr) {
                return "(Inr " + (Data_Show.show(dictShow1)(v.value0) + ")");
            };
            throw new Error("Failed pattern match at Data.Generic.Rep (line 31, column 1 - line 33, column 42): " + [ v.constructor.name ]);
        });
    };
};
var showProduct = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            return "(Product " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
        });
    };
};
var showNoArguments = new Data_Show.Show(function (v) {
    return "NoArguments";
});
var showConstructor = function (dictIsSymbol) {
    return function (dictShow) {
        return new Data_Show.Show(function (v) {
            return "(Constructor @" + (Data_Show.show(Data_Show.showString)(Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value)) + (" " + (Data_Show.show(dictShow)(v) + ")")));
        });
    };
};
var showArgument = function (dictShow) {
    return new Data_Show.Show(function (v) {
        return "(Argument " + (Data_Show.show(dictShow)(v) + ")");
    });
};
var repOf = function (dictGeneric) {
    return function (v) {
        return Type_Proxy["Proxy"].value;
    };
};
var from = function (dict) {
    return dict.from;
};
module.exports = {
    Generic: Generic,
    to: to,
    from: from,
    repOf: repOf,
    NoArguments: NoArguments,
    Inl: Inl,
    Inr: Inr,
    Product: Product,
    Constructor: Constructor,
    Argument: Argument,
    showNoArguments: showNoArguments,
    showSum: showSum,
    showProduct: showProduct,
    showConstructor: showConstructor,
    showArgument: showArgument
};
