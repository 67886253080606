// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Query_HalogenQ = require("../Halogen.Query.HalogenQ/index.js");
var Halogen_Query_Input = require("../Halogen.Query.Input/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var tellAll = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictOrd) {
            return function (label) {
                return function (req) {
                    return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.queryAll()(dictIsSymbol)(dictOrd)(label)(req(Data_Unit.unit)));
                };
            };
        };
    };
};
var tell = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictOrd) {
            return function (slot) {
                return function (label) {
                    return function (req) {
                        return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.query()(dictIsSymbol)(dictOrd)(slot)(label)(req(Data_Unit.unit)));
                    };
                };
            };
        };
    };
};
var requestAll = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictOrd) {
            return function (label) {
                return function (req) {
                    return Halogen_Query_HalogenM.queryAll()(dictIsSymbol)(dictOrd)(label)(req(Control_Category.identity(Control_Category.categoryFn)));
                };
            };
        };
    };
};
var request = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictOrd) {
            return function (slot) {
                return function (label) {
                    return function (req) {
                        return Halogen_Query_HalogenM.query()(dictIsSymbol)(dictOrd)(slot)(label)(req(Control_Category.identity(Control_Category.categoryFn)));
                    };
                };
            };
        };
    };
};
var mkTell = function (act) {
    return act(Data_Unit.unit);
};
var mkRequest = function (req) {
    return req(Control_Category.identity(Control_Category.categoryFn));
};
var getHTMLElementRef = (function () {
    var $13 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(function (v) {
        return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(v);
    });
    return function ($14) {
        return $13(Halogen_Query_HalogenM.getRef($14));
    };
})();
module.exports = {
    mkTell: mkTell,
    tell: tell,
    tellAll: tellAll,
    mkRequest: mkRequest,
    request: request,
    requestAll: requestAll,
    getHTMLElementRef: getHTMLElementRef,
    get: Control_Monad_State_Class.get,
    gets: Control_Monad_State_Class.gets,
    modify: Control_Monad_State_Class.modify,
    modify_: Control_Monad_State_Class.modify_,
    put: Control_Monad_State_Class.put,
    lift: Control_Monad_Trans_Class.lift,
    liftAff: Effect_Aff_Class.liftAff,
    liftEffect: Effect_Class.liftEffect,
    ChildQuery: Halogen_Query_HalogenM.ChildQuery,
    Fork: Halogen_Query_HalogenM.Fork,
    GetRef: Halogen_Query_HalogenM.GetRef,
    Kill: Halogen_Query_HalogenM.Kill,
    Lift: Halogen_Query_HalogenM.Lift,
    Par: Halogen_Query_HalogenM.Par,
    Raise: Halogen_Query_HalogenM.Raise,
    State: Halogen_Query_HalogenM.State,
    Subscribe: Halogen_Query_HalogenM.Subscribe,
    Unsubscribe: Halogen_Query_HalogenM.Unsubscribe,
    HalogenM: Halogen_Query_HalogenM.HalogenM,
    fork: Halogen_Query_HalogenM.fork,
    getRef: Halogen_Query_HalogenM.getRef,
    kill: Halogen_Query_HalogenM.kill,
    query: Halogen_Query_HalogenM.query,
    queryAll: Halogen_Query_HalogenM.queryAll,
    raise: Halogen_Query_HalogenM.raise,
    subscribe: Halogen_Query_HalogenM.subscribe,
    "subscribe'": Halogen_Query_HalogenM["subscribe'"],
    unsubscribe: Halogen_Query_HalogenM.unsubscribe,
    Action: Halogen_Query_HalogenQ.Action,
    Finalize: Halogen_Query_HalogenQ.Finalize,
    Initialize: Halogen_Query_HalogenQ.Initialize,
    Query: Halogen_Query_HalogenQ.Query,
    Receive: Halogen_Query_HalogenQ.Receive,
    RefLabel: Halogen_Query_Input.RefLabel
};
