// Generated by purs version 0.14.3
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_Partial = require("../Data.Array.Partial/index.js");
var Data_CommutativeRing = require("../Data.CommutativeRing/index.js");
var Data_Distributive = require("../Data.Distributive/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Ops = require("../Data.Typelevel.Num.Ops/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Typelevel_Undefined = require("../Data.Typelevel.Undefined/index.js");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary/index.js");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Vec = function (x) {
    return x;
};
var zipWithE = function (f) {
    return function (v) {
        return function (v1) {
            return Data_Array.zipWith(f)(v)(v1);
        };
    };
};
var zipWith = function (dictMin) {
    return function (f) {
        return function (v) {
            return function (v1) {
                return Data_Array.zipWith(f)(v)(v1);
            };
        };
    };
};
var zip = function (dictMin) {
    return function (v) {
        return function (v1) {
            return Data_Array.zip(v)(v1);
        };
    };
};
var updateAt = function (dictNat) {
    return function (dictLt) {
        return function (i) {
            return function (v) {
                return function (v1) {
                    return Data_Maybe.fromJust()(Data_Array.updateAt(Data_Typelevel_Num_Sets.toInt(dictNat)(i))(v)(v1));
                };
            };
        };
    };
};
var unzip = function (v) {
    var v2 = Data_Array.unzip(v);
    return new Data_Tuple.Tuple(v2.value0, v2.value1);
};
var uncons = function (dictPred) {
    return function (v) {
        return {
            head: Data_Array_Partial.head()(v),
            tail: Data_Array_Partial.tail()(v)
        };
    };
};
var traversableWithIndexVec = Data_TraversableWithIndex.traversableWithIndexArray;
var traversableVec = Data_Traversable.traversableArray;
var toUnfoldable = function (dictUnfoldable) {
    return function (dictNat) {
        return function (v) {
            return Data_Array.toUnfoldable(dictUnfoldable)(v);
        };
    };
};
var toArray = function (v) {
    return v;
};
var take$prime = function (dictNat) {
    return function (dictLtEq) {
        return function (v) {
            return Data_Array.take(Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value))(v);
        };
    };
};
var take = function (dictNat) {
    return function (dictLtEq) {
        return Data_Function["const"](take$prime(dictNat)());
    };
};
var tail = function (dictPred) {
    return function (v) {
        return Data_Array_Partial.tail()(v);
    };
};
var sortBy = function (f) {
    return function (v) {
        return Data_Array.sortBy(f)(v);
    };
};
var sort = function (dictOrd) {
    return function (v) {
        return Data_Array.sort(dictOrd)(v);
    };
};
var snoc = function (dictSucc) {
    return function (x) {
        return function (v) {
            return Data_Array.snoc(v)(x);
        };
    };
};
var slice$prime = function (dictNat) {
    return function (dictNat1) {
        return function (dictLtEq) {
            return function (dictLtEq1) {
                return function (dictLtEq2) {
                    return function (dictSub) {
                        return function (i1) {
                            return function (v) {
                                return Data_Array.slice(Data_Typelevel_Num_Sets.toInt(dictNat)(i1))(Data_Typelevel_Num_Sets["toInt'"](dictNat1)(Type_Proxy["Proxy"].value))(v);
                            };
                        };
                    };
                };
            };
        };
    };
};
var slice = function (dictNat) {
    return function (dictNat1) {
        return function (dictLtEq) {
            return function (dictLtEq1) {
                return function (dictLtEq2) {
                    return function (dictSub) {
                        return function (i1) {
                            return function (i2) {
                                return function (v) {
                                    return Data_Array.slice(Data_Typelevel_Num_Sets.toInt(dictNat)(i1))(Data_Typelevel_Num_Sets.toInt(dictNat1)(i2))(v);
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var reverse = function (v) {
    return Data_Array.reverse(v);
};
var replicate$prime = function (dictNat) {
    return function (a) {
        return Data_Array.replicate(Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value))(a);
    };
};
var replicate = function (dictNat) {
    return Data_Function["const"](replicate$prime(dictNat));
};
var range = function (dictNat) {
    return function (dictNat1) {
        return function (dictMax) {
            return function (dictMin) {
                return function (dictSub) {
                    return function (dictSucc) {
                        return function (a) {
                            return function (b) {
                                return Data_Array.range(Data_Typelevel_Num_Sets.toInt(dictNat)(a))(Data_Typelevel_Num_Sets.toInt(dictNat1)(b));
                            };
                        };
                    };
                };
            };
        };
    };
};
var ordVec = function (dictOrd) {
    return Data_Ord.ordArray(dictOrd);
};
var modifyAt = function (dictNat) {
    return function (dictLt) {
        return function (i) {
            return function (f) {
                return function (v) {
                    return Data_Maybe.fromJust()(Data_Array.modifyAt(Data_Typelevel_Num_Sets.toInt(dictNat)(i))(f)(v));
                };
            };
        };
    };
};
var lengthT = function (dictNat) {
    return function (v) {
        return Data_Typelevel_Undefined["undefined"];
    };
};
var length = function (dictNat) {
    return function (v) {
        return Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value);
    };
};
var last = function (dictPos) {
    return function (v) {
        return Data_Array_Partial.last()(v);
    };
};
var insertBy = function (dictSucc) {
    return function (f) {
        return function (a) {
            return function (v) {
                return Data_Array.insertBy(f)(a)(v);
            };
        };
    };
};
var insertAt = function (dictNat) {
    return function (dictLt) {
        return function (dictSucc) {
            return function (i) {
                return function (a) {
                    return function (v) {
                        return Data_Maybe.fromJust()(Data_Array.insertAt(Data_Typelevel_Num_Sets.toInt(dictNat)(i))(a)(v));
                    };
                };
            };
        };
    };
};
var insert = function (dictSucc) {
    return function (dictOrd) {
        return function (a) {
            return function (v) {
                return Data_Array.insert(dictOrd)(a)(v);
            };
        };
    };
};
var init = function (dictPred) {
    return function (v) {
        return Data_Array_Partial.init()(v);
    };
};
var index$prime = function (v) {
    return Data_Array.index(v);
};
var index = function (dictNat) {
    return function (dictLt) {
        return function (v) {
            return function (i) {
                return v[Data_Typelevel_Num_Sets.toInt(dictNat)(i)];
            };
        };
    };
};
var head = function (dictPos) {
    return function (v) {
        return Data_Array_Partial.head()(v);
    };
};
var genericVec = function (dictGeneric) {
    return new Data_Generic_Rep.Generic(function (x) {
        return x;
    }, function (x) {
        return x;
    });
};
var functorWithIndexVec = Data_FunctorWithIndex.functorWithIndexArray;
var functorVec = Data_Functor.functorArray;
var fromArray = function (dictNat) {
    return function (xs) {
        var $190 = Data_Array.length(xs) === Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value);
        if ($190) {
            return new Data_Maybe.Just(xs);
        };
        return Data_Maybe.Nothing.value;
    };
};
var foldableWithIndexVec = Data_FoldableWithIndex.foldableWithIndexArray;
var foldableVec = Data_Foldable.foldableArray;
var showVec = function (dictNat) {
    return function (dictShow) {
        return new Data_Show.Show(function (v) {
            return "(" + (Data_Foldable.foldMap(foldableVec)(Data_Monoid.monoidString)(function (e) {
                return Data_Show.show(dictShow)(e) + " +> ";
            })(v) + "empty)");
        });
    };
};
var fill = function (dictNat) {
    return function (f) {
        var s = Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value);
        var range_ = (function () {
            if (s === 0) {
                return [  ];
            };
            return Data_Array.range(0)(s - 1 | 0);
        })();
        return Data_Functor.map(Data_Functor.functorArray)(f)(range_);
    };
};
var eqVec = function (dictEq) {
    return Data_Eq.eqArray(dictEq);
};
var encodeJsonVec = function (dictEncodeJson) {
    return Data_Argonaut_Encode_Class.encodeJsonArray(dictEncodeJson);
};
var empty = [  ];
var drop$prime = function (dictNat) {
    return function (dictLtEq) {
        return function (dictSub) {
            return function (v) {
                return Data_Array.drop(Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value))(v);
            };
        };
    };
};
var drop = function (dictNat) {
    return function (dictLtEq) {
        return function (dictSub) {
            return function (c) {
                return function (v) {
                    return Data_Array.drop(Data_Typelevel_Num_Sets.toInt(dictNat)(c))(v);
                };
            };
        };
    };
};
var dotProduct = function (dictSemiring) {
    return function (a) {
        return function (b) {
            return Data_Foldable.sum(foldableVec)(dictSemiring)(zipWithE(Data_Semiring.mul(dictSemiring))(a)(b));
        };
    };
};
var distributiveVec = function (dictNat) {
    return new Data_Distributive.Distributive(function () {
        return functorVec;
    }, function (dictFunctor) {
        return Data_Distributive.collectDefault(distributiveVec(dictNat))(dictFunctor);
    }, function (dictFunctor) {
        return function (vs) {
            var len = Data_Typelevel_Num_Sets["toInt'"](dictNat)(Type_Proxy["Proxy"].value);
            var indexes = (function () {
                var $195 = len === 0;
                if ($195) {
                    return [  ];
                };
                return Data_Array.range(0)(len - 1 | 0);
            })();
            var as = Data_Functor.map(dictFunctor)(toArray)(vs);
            return Data_Functor.map(Data_Functor.functorArray)(Data_Functor.flap(dictFunctor)(Data_Functor.map(dictFunctor)(Data_Array.unsafeIndex())(as)))(indexes);
        };
    });
};
var deleteAt = function (dictNat) {
    return function (dictLt) {
        return function (dictPred) {
            return function (i) {
                return function (v) {
                    return Data_Maybe.fromJust()(Data_Array.deleteAt(Data_Typelevel_Num_Sets.toInt(dictNat)(i))(v));
                };
            };
        };
    };
};
var decodeJsonVec = function (dictDecodeJson) {
    return Data_Argonaut_Decode_Class.decodeArray(dictDecodeJson);
};
var cons = function (dictSucc) {
    return function (x) {
        return function (v) {
            return Data_Array.cons(x)(v);
        };
    };
};
var singleton = function (x) {
    return cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(x)(empty);
};
var vec2 = function (x) {
    return function (y) {
        return cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(x)(cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(y)(empty));
    };
};
var vec3 = function (x) {
    return function (y) {
        return function (z) {
            return cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(x)(cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(y)(cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(z)(empty)));
        };
    };
};
var concat = function (dictAdd) {
    return function (v) {
        return function (v1) {
            return Data_Array.concat([ v, v1 ]);
        };
    };
};
var coarbitraryVec = function (dictCoarbitrary) {
    return function (dictNat) {
        return new Test_QuickCheck_Arbitrary.Coarbitrary(Data_Foldable.foldl(foldableVec)(function (f) {
            return function (x) {
                var $202 = Test_QuickCheck_Arbitrary.coarbitrary(dictCoarbitrary)(x);
                return function ($203) {
                    return f($202($203));
                };
            };
        })(Control_Category.identity(Control_Category.categoryFn)));
    };
};
var arbitraryVec = function (dictArbitrary) {
    return function (dictNat) {
        return new Test_QuickCheck_Arbitrary.Arbitrary((function () {
            var s = Data_Typelevel_Num_Sets.toInt(dictNat)(Data_Typelevel_Undefined["undefined"]);
            return Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Vec)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Test_QuickCheck_Gen.applicativeGen)(Data_Array.replicate(s)(Test_QuickCheck_Arbitrary.arbitrary(dictArbitrary))));
        })());
    };
};
var applyVec = new Control_Apply.Apply(function () {
    return functorVec;
}, function (a) {
    return function (b) {
        return zipWithE(Data_Function.apply)(a)(b);
    };
});
var bindVec = function (dictNat) {
    return new Control_Bind.Bind(function () {
        return applyVec;
    }, function (vec) {
        return function (f) {
            return Control_Apply.apply(applyVec)(Data_Distributive.distribute(distributiveVec(dictNat))(Data_Functor.functorFn)(f))(vec);
        };
    });
};
var semigroupVec = function (dictSemigroup) {
    return function (dictNat) {
        return new Data_Semigroup.Semigroup(Control_Apply.lift2(applyVec)(Data_Semigroup.append(dictSemigroup)));
    };
};
var applicativeVec = function (dictNat) {
    return new Control_Applicative.Applicative(function () {
        return applyVec;
    }, function (a) {
        return replicate$prime(dictNat)(a);
    });
};
var monadVec = function (dictNat) {
    return new Control_Monad.Monad(function () {
        return applicativeVec(dictNat);
    }, function () {
        return bindVec(dictNat);
    });
};
var monoidVec = function (dictMonoid) {
    return function (dictNat) {
        return new Data_Monoid.Monoid(function () {
            return semigroupVec(dictMonoid.Semigroup0())(dictNat);
        }, Control_Applicative.pure(applicativeVec(dictNat))(Data_Monoid.mempty(dictMonoid)));
    };
};
var semiringVec = function (dictSemiring) {
    return function (dictNat) {
        return new Data_Semiring.Semiring(Control_Apply.lift2(applyVec)(Data_Semiring.add(dictSemiring)), Control_Apply.lift2(applyVec)(Data_Semiring.mul(dictSemiring)), Control_Applicative.pure(applicativeVec(dictNat))(Data_Semiring.one(dictSemiring)), Control_Applicative.pure(applicativeVec(dictNat))(Data_Semiring.zero(dictSemiring)));
    };
};
var ringVec = function (dictRing) {
    return function (dictNat) {
        return new Data_Ring.Ring(function () {
            return semiringVec(dictRing.Semiring0())(dictNat);
        }, Control_Apply.lift2(applyVec)(Data_Ring.sub(dictRing)));
    };
};
var commutativeRingVec = function (dictCommutativeRing) {
    return function (dictNat) {
        return new Data_CommutativeRing.CommutativeRing(function () {
            return ringVec(dictCommutativeRing.Ring0())(dictNat);
        });
    };
};
module.exports = {
    empty: empty,
    cons: cons,
    snoc: snoc,
    uncons: uncons,
    singleton: singleton,
    vec2: vec2,
    vec3: vec3,
    fill: fill,
    range: range,
    replicate: replicate,
    "replicate'": replicate$prime,
    fromArray: fromArray,
    length: length,
    lengthT: lengthT,
    toArray: toArray,
    toUnfoldable: toUnfoldable,
    index: index,
    "index'": index$prime,
    concat: concat,
    updateAt: updateAt,
    modifyAt: modifyAt,
    insertAt: insertAt,
    deleteAt: deleteAt,
    head: head,
    last: last,
    tail: tail,
    init: init,
    insert: insert,
    insertBy: insertBy,
    slice: slice,
    "slice'": slice$prime,
    take: take,
    "take'": take$prime,
    drop: drop,
    "drop'": drop$prime,
    zip: zip,
    zipWith: zipWith,
    zipWithE: zipWithE,
    unzip: unzip,
    sort: sort,
    sortBy: sortBy,
    reverse: reverse,
    dotProduct: dotProduct,
    genericVec: genericVec,
    eqVec: eqVec,
    ordVec: ordVec,
    functorVec: functorVec,
    foldableVec: foldableVec,
    functorWithIndexVec: functorWithIndexVec,
    foldableWithIndexVec: foldableWithIndexVec,
    traversableWithIndexVec: traversableWithIndexVec,
    traversableVec: traversableVec,
    encodeJsonVec: encodeJsonVec,
    decodeJsonVec: decodeJsonVec,
    arbitraryVec: arbitraryVec,
    coarbitraryVec: coarbitraryVec,
    applyVec: applyVec,
    applicativeVec: applicativeVec,
    bindVec: bindVec,
    monadVec: monadVec,
    distributiveVec: distributiveVec,
    showVec: showVec,
    semiringVec: semiringVec,
    ringVec: ringVec,
    commutativeRingVec: commutativeRingVec,
    semigroupVec: semigroupVec,
    monoidVec: monoidVec
};
