// Generated by purs version 0.14.3
"use strict";
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Attributes_Baseline = require("../Halogen.Svg.Attributes.Baseline/index.js");
var Halogen_Svg_Attributes_CSSLength = require("../Halogen.Svg.Attributes.CSSLength/index.js");
var Halogen_Svg_Attributes_FontSize = require("../Halogen.Svg.Attributes.FontSize/index.js");
var Halogen_Svg_Attributes_TextAnchor = require("../Halogen.Svg.Attributes.TextAnchor/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra = require("../Hydra/index.js");
var Send = (function () {
    function Send() {

    };
    Send.value = new Send();
    return Send;
})();
var handleAction = function (v) {
    return Halogen_Query_HalogenM.raise(App_Toolkit_UI.SendToOutlet.create("pi")(Hydra.pi));
};
var bodyWidth = 110.0;
var bodyHeight = 80.0;
var render = function (v) {
    return Halogen_Svg_Elements.g([  ])([ Halogen_Svg_Elements.text([ Halogen_HTML_Events.onClick(Data_Function["const"](Send.value)), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(255)(255)(255)(0.7)))), Halogen_Svg_Attributes.font_size(Halogen_Svg_Attributes_FontSize.FontSizeLength.create(new Halogen_Svg_Attributes_CSSLength.Px(60.0))), Halogen_Svg_Attributes.x(bodyWidth / 2.0), Halogen_Svg_Attributes.y(bodyHeight / 2.0), Halogen_Svg_Attributes.text_anchor(Halogen_Svg_Attributes_TextAnchor.AnchorMiddle.value), Halogen_Svg_Attributes.dominant_baseline(Halogen_Svg_Attributes_Baseline.BaselineMiddle.value) ])([ Halogen_HTML_Core.text("\u03c0") ]) ]);
};
var component = Halogen_Component.mkComponent({
    initialState: Data_Function["const"](Data_Unit.unit),
    render: render,
    "eval": Halogen_Component.mkEval({
        handleAction: handleAction,
        handleQuery: Halogen_Component.defaultEval.handleQuery,
        receive: Halogen_Component.defaultEval.receive,
        initialize: new Data_Maybe.Just(Send.value),
        finalize: Halogen_Component.defaultEval.finalize
    })
});
module.exports = {
    Send: Send,
    bodyWidth: bodyWidth,
    bodyHeight: bodyHeight,
    render: render,
    handleAction: handleAction,
    component: component
};
