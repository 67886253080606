// Generated by purs version 0.14.3
"use strict";
var Control_Category = require("../Control.Category/index.js");
var IsString = function (fromString) {
    this.fromString = fromString;
};
var isStringString = new IsString(Control_Category.identity(Control_Category.categoryFn));
var fromString = function (dict) {
    return dict.fromString;
};
module.exports = {
    fromString: fromString,
    IsString: IsString,
    isStringString: isStringString
};
