// Generated by purs version 0.14.3
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map = require("../Data.Map/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var Noodle_Toolkit = require("../Noodle.Toolkit/index.js");
var Patch = (function () {
    function Patch(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Patch.create = function (value0) {
        return function (value1) {
            return new Patch(value0, value1);
        };
    };
    return Patch;
})();
var registerLink = function (outletPath) {
    return function (inletPath) {
        return function (link) {
            return function (v) {
                return Patch.create(v.value0)(Data_Map_Internal.insert(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString))(Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString)))(new Data_Tuple.Tuple(outletPath, inletPath))(link)(v.value1));
            };
        };
    };
};
var nodesCount = function (v) {
    return Data_Map_Internal.size(v.value0);
};
var nodes = function (v) {
    return Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.value0);
};
var linksToNode = function (nodeId) {
    return function (v) {
        return Data_Array.mapMaybe(function (v1) {
            var $46 = v1.value0.value1.value0 === nodeId;
            if ($46) {
                return Data_Maybe.Just.create(new Data_Tuple.Tuple(v1.value0, v1.value1));
            };
            return Data_Maybe.Nothing.value;
        })(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.value1));
    };
};
var linksStartingFrom = function (outletPath) {
    return function (v) {
        return Data_Array.mapMaybe(function (v1) {
            var $58 = Data_Eq.eq(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString))(v1.value0.value0)(outletPath);
            if ($58) {
                return Data_Maybe.Just.create(new Data_Tuple.Tuple(v1.value0.value1, v1.value1));
            };
            return Data_Maybe.Nothing.value;
        })(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.value1));
    };
};
var linksLeadingTo = function (inletPath) {
    return function (v) {
        return Data_Array.mapMaybe(function (v1) {
            var $68 = Data_Eq.eq(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString))(v1.value0.value1)(inletPath);
            if ($68) {
                return Data_Maybe.Just.create(new Data_Tuple.Tuple(v1.value0.value0, v1.value1));
            };
            return Data_Maybe.Nothing.value;
        })(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.value1));
    };
};
var linksFromNode = function (nodeId) {
    return function (v) {
        return Data_Array.mapMaybe(function (v1) {
            var $78 = v1.value0.value0.value0 === nodeId;
            if ($78) {
                return Data_Maybe.Just.create(new Data_Tuple.Tuple(v1.value0, v1.value1));
            };
            return Data_Maybe.Nothing.value;
        })(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.value1));
    };
};
var linksToFromNode = function (node) {
    return function (patch) {
        var samePath = function (v) {
            return function (v1) {
                return Data_Eq.eq(Data_Tuple.eqTuple(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString))(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString)))(v.value0)(v1.value0);
            };
        };
        return Data_Array.nubByEq(samePath)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(linksToNode(node)(patch))(linksFromNode(node)(patch)));
    };
};
var linksCountAtNode = function (node) {
    return function (patch) {
        var linksData = Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(linksToFromNode(node)(patch));
        var addOne = function (v) {
            if (v instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(v.value0 + 1 | 0);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new Data_Maybe.Just(1);
            };
            throw new Error("Failed pattern match at Noodle.Patch (line 205, column 9 - line 205, column 39): " + [ v.constructor.name ]);
        };
        var addOutletInfo = function (v) {
            return function (map) {
                var $98 = node === v.value0.value0;
                if ($98) {
                    return Data_Map_Internal.alter(Data_Ord.ordString)(addOne)(v.value0.value1)(map);
                };
                return map;
            };
        };
        var addInletInfo = function (v) {
            return function (map) {
                var $106 = node === v.value1.value0;
                if ($106) {
                    return Data_Map_Internal.alter(Data_Ord.ordString)(addOne)(v.value1.value1)(map);
                };
                return map;
            };
        };
        return new Data_Tuple.Tuple(Data_Foldable.foldr(Data_Foldable.foldableArray)(addInletInfo)(Data_Map_Internal.empty)(linksData), Data_Foldable.foldr(Data_Foldable.foldableArray)(addOutletInfo)(Data_Map_Internal.empty)(linksData));
    };
};
var linksCount = function (v) {
    return Data_Map_Internal.size(v.value1);
};
var links = function (v) {
    return Data_Map.keys(v.value1);
};
var forgetNode = function (nodeId) {
    return function (v) {
        return new Patch(Data_Map_Internal["delete"](Data_Ord.ordString)(nodeId)(v.value0), v.value1);
    };
};
var forgetLink = function (outletPath) {
    return function (inletPath) {
        return function (v) {
            return Patch.create(v.value0)(Data_Map_Internal["delete"](Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString))(Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString)))(new Data_Tuple.Tuple(outletPath, inletPath))(v.value1));
        };
    };
};
var findNode = function (name) {
    return function (v) {
        return Data_Map_Internal.lookup(Data_Ord.ordString)(name)(v.value0);
    };
};
var produce = function (v) {
    return function (v1) {
        return function (patch) {
            return Data_Maybe.fromMaybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(findNode(v.value0)(patch))(Data_Function.flip(Noodle_Node.produce)(new Data_Tuple.Tuple(v.value1, v1))));
        };
    };
};
var produce$prime = function (path) {
    return function (v) {
        return function (patch) {
            return Control_Apply.applySecond(Effect.applyEffect)(produce(path)(v)(patch))(Control_Applicative.pure(Effect.applicativeEffect)(patch));
        };
    };
};
var send = function (v) {
    return function (v1) {
        return function (patch) {
            return Data_Maybe.fromMaybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(findNode(v.value0)(patch))(Data_Function.flip(Noodle_Node.send)(new Data_Tuple.Tuple(v.value1, v1))));
        };
    };
};
var send$prime = function (path) {
    return function (v) {
        return function (patch) {
            return Control_Apply.applySecond(Effect.applyEffect)(send(path)(v)(patch))(Control_Applicative.pure(Effect.applicativeEffect)(patch));
        };
    };
};
var empty = new Patch(Data_Map_Internal.empty, Data_Map_Internal.empty);
var disconnect = function (outletPath) {
    return function (inletPath) {
        return function (v) {
            var v1 = Data_Map_Internal.lookup(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString))(Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString)))(new Data_Tuple.Tuple(outletPath, inletPath))(v.value1);
            if (v1 instanceof Data_Maybe.Just) {
                return function __do() {
                    Noodle_Node.disconnect(v1.value0)();
                    return forgetLink(outletPath)(inletPath)(v);
                };
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(v);
            };
            throw new Error("Failed pattern match at Noodle.Patch (line 131, column 5 - line 136, column 23): " + [ v1.constructor.name ]);
        };
    };
};
var removeNode = function (nodeId) {
    return function (v) {
        var linksWithNode = linksToFromNode(nodeId)(v);
        return function __do() {
            var noMoreLinksPatch = Data_Foldable.foldM(Data_Foldable.foldableArray)(Effect.monadEffect)(Data_Function.flip(Data_Tuple.uncurry(disconnect)))(v)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(linksWithNode))();
            return forgetNode(nodeId)(noMoreLinksPatch);
        };
    };
};
var connect = function (v) {
    return function (v1) {
        return function (patch) {
            var v2 = Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create)(findNode(v.value0)(patch)))(findNode(v1.value0)(patch));
            if (v2 instanceof Data_Maybe.Just) {
                return Data_Functor.mapFlipped(Effect.functorEffect)(Noodle_Node.connect(new Data_Tuple.Tuple(v2.value0.value0, v.value1))(new Data_Tuple.Tuple(v2.value0.value1, v1.value1)))(function (link) {
                    return registerLink(new Data_Tuple.Tuple(v.value0, v.value1))(new Data_Tuple.Tuple(v1.value0, v1.value1))(link)(patch);
                });
            };
            if (v2 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(patch);
            };
            throw new Error("Failed pattern match at Noodle.Patch (line 121, column 5 - line 126, column 30): " + [ v2.constructor.name ]);
        };
    };
};
var addUniqueNodeId = function (patch) {
    return function (nodeFamily) {
        return nodeFamily + ("-" + Data_Show.show(Data_Show.showInt)(nodesCount(patch) + 1 | 0));
    };
};
var addNode = function (name) {
    return function (node) {
        return function (v) {
            return new Patch(Data_Map_Internal.insert(Data_Ord.ordString)(name)(node)(v.value0), v.value1);
        };
    };
};
var addNodeFrom = function (toolkit) {
    return function (v) {
        return function (patch) {
            return Data_Functor.mapFlipped(Effect.functorEffect)(Noodle_Toolkit.spawn(v.value0)(toolkit))(Data_Maybe.maybe(patch)(function (node) {
                return addNode(v.value1)(Noodle_Node.markFamily(v.value0)(node))(patch);
            }));
        };
    };
};
var addNodeFrom$prime = function (toolkit) {
    return function (nodeFamily) {
        return function (patch) {
            var nextNodeId = addUniqueNodeId(patch)(nodeFamily);
            return Data_Functor.mapFlipped(Effect.functorEffect)(addNodeFrom(toolkit)(new Data_Tuple.Tuple(nodeFamily, nextNodeId))(patch))(Data_Tuple.Tuple.create(nextNodeId));
        };
    };
};
var addNodesFrom = function (toolkit) {
    return function (pairs) {
        return function (patch) {
            return Data_Foldable.foldr(Data_Foldable.foldableArray)(function (pair) {
                return function (patchEff) {
                    return Control_Bind.bind(Effect.bindEffect)(patchEff)(addNodeFrom(toolkit)(pair));
                };
            })(Control_Applicative.pure(Effect.applicativeEffect)(patch))(pairs);
        };
    };
};
module.exports = {
    Patch: Patch,
    empty: empty,
    addNode: addNode,
    addNodeFrom: addNodeFrom,
    "addNodeFrom'": addNodeFrom$prime,
    addNodesFrom: addNodesFrom,
    nodes: nodes,
    links: links,
    findNode: findNode,
    nodesCount: nodesCount,
    linksCount: linksCount,
    registerLink: registerLink,
    forgetLink: forgetLink,
    forgetNode: forgetNode,
    connect: connect,
    disconnect: disconnect,
    removeNode: removeNode,
    linksStartingFrom: linksStartingFrom,
    linksLeadingTo: linksLeadingTo,
    linksToNode: linksToNode,
    linksFromNode: linksFromNode,
    linksToFromNode: linksToFromNode,
    linksCountAtNode: linksCountAtNode,
    send: send,
    "send'": send$prime,
    produce: produce,
    "produce'": produce$prime,
    addUniqueNodeId: addUniqueNodeId
};
