// Generated by purs version 0.14.3
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Noodle_Node_Shape = require("../Noodle.Node.Shape/index.js");
var Receive = function (x) {
    return x;
};
var Pass = function (x) {
    return x;
};
var Def = (function () {
    function Def(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Def.create = function (value0) {
        return function (value1) {
            return new Def(value0, value1);
        };
    };
    return Def;
})();
var reshape = function (inlets) {
    return function (outlets) {
        return function (v) {
            return new Def(Noodle_Node_Shape.make(inlets)(outlets), v.value1);
        };
    };
};
var receive = function (label) {
    return function (v) {
        return Data_Map_Internal.lookup(Data_Ord.ordString)(label)(v.fromInlets);
    };
};
var processEffectfulWith = function (fn) {
    return function (v) {
        return new Def(v.value0, fn);
    };
};
var processWith = function (fn) {
    return processEffectfulWith((function () {
        var $58 = Control_Applicative.pure(Effect.applicativeEffect);
        return function ($59) {
            return $58(fn($59));
        };
    })());
};
var passThrough$prime = function (inlets) {
    return function (v) {
        return {
            toOutlets: Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(function (inlet) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create(inlet))(Data_Map_Internal.lookup(Data_Ord.ordString)(inlet)(v.fromInlets));
            })(inlets)))
        };
    };
};
var passThrough = function (v) {
    return {
        toOutlets: v.fromInlets
    };
};
var passNothing = {
    toOutlets: Data_Map_Internal.empty
};
var pass$prime = function (values) {
    return {
        toOutlets: Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)(Data_Array.mapMaybe(Data_Traversable.sequence(Data_Traversable.traversableTuple)(Data_Maybe.applicativeMaybe))(values))
    };
};
var passTo$prime = function ($60) {
    return pass$prime(Data_Array.singleton($60));
};
var pass = function (values) {
    return {
        toOutlets: Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)(values)
    };
};
var passTo = function ($61) {
    return pass(Data_Array.singleton($61));
};
var outletShape = function (outlet) {
    return function (v) {
        return Noodle_Node_Shape.outlet(outlet)(v.value0);
    };
};
var lastUpdateAt = function (v) {
    return v.last;
};
var inletShape = function (inlet) {
    return function (v) {
        return Noodle_Node_Shape.inlet(inlet)(v.value0);
    };
};
var getShape = function (v) {
    return Data_Newtype.unwrap()(v.value0);
};
var functorReceive = new Data_Functor.Functor(function (f) {
    return function (v) {
        return {
            last: v.last,
            fromInlets: Data_Functor.map(Data_Map_Internal.functorMap)(f)(v.fromInlets)
        };
    };
});
var functorPass = new Data_Functor.Functor(function (f) {
    return function (v) {
        return {
            toOutlets: Data_Functor.map(Data_Map_Internal.functorMap)(f)(v.toOutlets)
        };
    };
});
var invariantDef = new Data_Functor_Invariant.Invariant(function (aToB) {
    return function (bToA) {
        return function (v) {
            return Def.create(Data_Functor_Invariant.imap(Noodle_Node_Shape.invariantShape)(aToB)(bToA)(v.value0))(function (receive1) {
                return Data_Functor.map(Effect.functorEffect)(Data_Functor.map(functorPass)(aToB))(v.value1(Data_Functor.map(functorReceive)(bToA)(receive1)));
            });
        };
    };
});
var doNothing = Data_Function["const"](passNothing);
var dimensions = function (v) {
    return Noodle_Node_Shape.dimensions(v.value0);
};
var defineEffectful = function (i) {
    return function (o) {
        return Def.create(Noodle_Node_Shape.make(i)(o));
    };
};
var fromFnEffectful = defineEffectful(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.noOutlets);
var define = function (inlets) {
    return function (outlets) {
        return function (fn) {
            return defineEffectful(inlets)(outlets)((function () {
                var $62 = Control_Applicative.pure(Effect.applicativeEffect);
                return function ($63) {
                    return $62(fn($63));
                };
            })());
        };
    };
};
var empty = define(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.noOutlets)(Data_Function["const"](pass([  ])));
var fromFn = define(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.noOutlets);
var always = function ($64) {
    return Data_Function["const"](pass($64));
};
var alwaysOne = function ($65) {
    return always(Data_Array.singleton($65));
};
module.exports = {
    Def: Def,
    Receive: Receive,
    Pass: Pass,
    empty: empty,
    define: define,
    defineEffectful: defineEffectful,
    fromFn: fromFn,
    fromFnEffectful: fromFnEffectful,
    receive: receive,
    pass: pass,
    "pass'": pass$prime,
    passTo: passTo,
    "passTo'": passTo$prime,
    passNothing: passNothing,
    passThrough: passThrough,
    "passThrough'": passThrough$prime,
    doNothing: doNothing,
    always: always,
    alwaysOne: alwaysOne,
    lastUpdateAt: lastUpdateAt,
    dimensions: dimensions,
    processWith: processWith,
    processEffectfulWith: processEffectfulWith,
    functorReceive: functorReceive,
    functorPass: functorPass,
    invariantDef: invariantDef
};
