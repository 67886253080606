// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var App_Style_Order = require("../App.Style.Order/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Noodle_Channel_Shape = require("../Noodle.Channel.Shape/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var titleTextPos = function (v) {
    return function (s) {
        return function (v1) {
            return function (v2) {
                return s.title.padding;
            };
        };
    };
};
var titleSize = function (v) {
    return function (s) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof App_Style.Vertical) {
                    return Data_Vec2.make(s.body.size)(s.title.size);
                };
                if (v1 instanceof App_Style.Horizontal) {
                    return Data_Vec2.make(s.title.size)(s.body.size);
                };
                throw new Error("Failed pattern match at App.Style.Calculate (line 227, column 1 - line 227, column 33): " + [ v.constructor.name, s.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    };
};
var titlePos = function (v) {
    return function (s) {
        return function (v1) {
            return function (v2) {
                return s.body.margin;
            };
        };
    };
};
var slotPadding = Data_Vec2.make(2.0)(7.0);
var ribbonSize = function (v) {
    return function (s) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof App_Style.Vertical) {
                    return Data_Vec2.make(s.body.size)(6.0);
                };
                if (v1 instanceof App_Style.Horizontal) {
                    return Data_Vec2.make(6.0)(s.body.size);
                };
                throw new Error("Failed pattern match at App.Style.Calculate (line 240, column 1 - line 240, column 34): " + [ v.constructor.name, s.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    };
};
var removeButtonRadius = 7.0;
var removeButtonPos = function (f) {
    return function (s) {
        return function (w) {
            return function (n) {
                return Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(s.body.margin)(titleSize(f)(s)(w)(n)))(Data_Vec2.vv(removeButtonRadius)))(Data_Vec2["y'"](Data_Semiring.semiringNumber)(3.0));
            };
        };
    };
};
var orderBy = function (sizeF) {
    return function (f) {
        return function (s) {
            return function (flow) {
                return function (node) {
                    if (flow instanceof App_Style.Vertical) {
                        var bodyHeight = App_Style_Order.sizeBy(sizeF(f)(s)(flow)(node))(s.order);
                        return Data_Vec2.make(s.body.size)(bodyHeight);
                    };
                    if (flow instanceof App_Style.Horizontal) {
                        var bodyWidth = App_Style_Order.sizeBy(sizeF(f)(s)(flow)(node))(s.order);
                        return Data_Vec2.make(bodyWidth)(s.body.size);
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 325, column 5 - line 335, column 41): " + [ flow.constructor.name ]);
                };
            };
        };
    };
};
var hasOutlets = function (v) {
    if (v instanceof App_Style.OnlyOutlets) {
        return true;
    };
    if (v instanceof App_Style.InletsAndOutlets) {
        return true;
    };
    if (v instanceof App_Style.UserBodyBetweenSlots) {
        return true;
    };
    if (v instanceof App_Style.UserBodyBetweenSlotsMin) {
        return true;
    };
    return false;
};
var hasInlets = function (v) {
    if (v instanceof App_Style.OnlyInlets) {
        return true;
    };
    if (v instanceof App_Style.InletsAndOutlets) {
        return true;
    };
    if (v instanceof App_Style.UserBodyBetweenSlots) {
        return true;
    };
    if (v instanceof App_Style.UserBodyBetweenSlotsMin) {
        return true;
    };
    return false;
};
var connectorSize = function (v) {
    if (v instanceof App_Style.Square) {
        return Data_Vec2.make(v.value0)(v.value0);
    };
    if (v instanceof App_Style.Rect) {
        return v.value0;
    };
    if (v instanceof App_Style.Circle) {
        return Data_Vec2.make(v.value0 * 2.0)(v.value0 * 2.0);
    };
    if (v instanceof App_Style.DoubleCircle) {
        return Data_Vec2.make(v.value1 * 2.0)(v.value1 * 2.0);
    };
    throw new Error("Failed pattern match at App.Style.Calculate (line 55, column 1 - line 55, column 35): " + [ v.constructor.name ]);
};
var slotArea = function (v) {
    return function (s) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof App_Style.Vertical) {
                    return Data_Vec2.make(Data_Vec2.w(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(connectorSize(s.slot.connector))(s.slot.offset)) + Data_Vec2.w(slotPadding) * 2.0 + s.slot.label.maxWidth)(Data_Vec2.h(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(connectorSize(s.slot.connector))(s.slot.offset)) + Data_Vec2.h(slotPadding) * 2.0);
                };
                if (v1 instanceof App_Style.Horizontal) {
                    return Data_Vec2.make(Data_Vec2.w(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(connectorSize(s.slot.connector))(s.slot.offset)) + Data_Vec2.w(slotPadding) * 2.0)(Data_Vec2.h(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(connectorSize(s.slot.connector))(s.slot.offset)) + Data_Vec2.w(slotPadding) * 2.0 + s.slot.label.maxWidth);
                };
                throw new Error("Failed pattern match at App.Style.Calculate (line 62, column 1 - line 62, column 32): " + [ v.constructor.name, s.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    };
};
var nodeAreaF = function (f) {
    return function (s) {
        return function (flow) {
            return function (node) {
                var v = Noodle_Node.dimensionsBy(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Noodle_Channel_Shape.isHidden))(node);
                var sizeOf = function (v1) {
                    if (v1 instanceof App_Style.Title) {
                        if (f.hasTitle) {
                            if (s.title.mode instanceof App_Style.InsideBody) {
                                return Data_Vec2.h(titleSize(f)(s)(flow)(node));
                            };
                            if (s.title.mode instanceof App_Style.OutsideBody) {
                                return Data_Vec2.h(titleSize(f)(s)(flow)(node));
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 301, column 17 - line 303, column 66): " + [ s.title.mode.constructor.name ]);
                        };
                        return 0.0;
                    };
                    if (v1 instanceof App_Style.Ribbon) {
                        if (f.hasRibbon) {
                            return Data_Vec2.h(ribbonSize(f)(s)(flow)(node));
                        };
                        return 0.0;
                    };
                    if (v1 instanceof App_Style.UserBody) {
                        if (f.controlArea) {
                            return v1.value0;
                        };
                        return 0.0;
                    };
                    if (v1 instanceof App_Style.OnlyInlets) {
                        return Data_Int.toNumber(v.value0) * Data_Vec2.h(slotArea(f)(s)(flow)(node));
                    };
                    if (v1 instanceof App_Style.OnlyOutlets) {
                        return Data_Int.toNumber(v.value1) * Data_Vec2.h(slotArea(f)(s)(flow)(node));
                    };
                    if (v1 instanceof App_Style.InletsAndOutlets) {
                        return Data_Int.toNumber(Data_Ord.max(Data_Ord.ordInt)(v.value0)(v.value1)) * Data_Vec2.h(slotArea(f)(s)(flow)(node));
                    };
                    if (v1 instanceof App_Style.UserBodyBetweenSlots) {
                        return sizeOf(App_Style.InletsAndOutlets.value);
                    };
                    if (v1 instanceof App_Style.UserBodyBetweenSlotsMin) {
                        if (f.controlArea) {
                            var $69 = sizeOf(App_Style.InletsAndOutlets.value) > v1.value0;
                            if ($69) {
                                return sizeOf(App_Style.InletsAndOutlets.value);
                            };
                            return v1.value0;
                        };
                        return 0.0;
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 299, column 9 - line 304, column 21): " + [ v1.constructor.name ]);
                };
                return sizeOf;
            };
        };
    };
};
var inletConnectorPos = function (f) {
    return function (s) {
        return function (v) {
            return function (node) {
                return function (idx) {
                    if (v instanceof App_Style.Vertical) {
                        var sizeF_ = nodeAreaF(f)(s)(App_Style.Vertical.value)(node);
                        var outerHeight = Data_Vec2.h(slotArea(f)(s)(App_Style.Vertical.value)(node));
                        var offsetY = Data_Maybe.fromMaybe(0.0)(App_Style_Order.sizeBefore(sizeF_)(hasInlets)(s.order));
                        var connectorOffsetX = function (v1) {
                            if (v1 instanceof App_Style.Inside) {
                                return 0.0;
                            };
                            if (v1 instanceof App_Style.Between) {
                                return 0.0;
                            };
                            if (v1 instanceof App_Style.Outside) {
                                return 0.0;
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 89, column 9 - line 89, column 38): " + [ v1.constructor.name ]);
                        };
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(s.body.margin)(s.slot.offset))(Data_Vec2.make(connectorOffsetX(s.slot.direction))(offsetY + outerHeight * Data_Int.toNumber(idx) + outerHeight / 2.0));
                    };
                    if (v instanceof App_Style.Horizontal) {
                        var titleWidth = (function () {
                            if (f.hasTitle) {
                                return Data_Vec2.w(titleSize(f)(s)(App_Style.Horizontal.value)(node));
                            };
                            return 0;
                        })();
                        var outerWidth = Data_Vec2.w(slotArea(f)(s)(App_Style.Horizontal.value)(node));
                        var connectorOffsetY = function (v1) {
                            if (v1 instanceof App_Style.Inside) {
                                return Data_Vec2.h(slotArea(f)(s)(App_Style.Horizontal.value)(node));
                            };
                            if (v1 instanceof App_Style.Between) {
                                return Data_Vec2.h(Data_Vec2.div(Data_EuclideanRing.euclideanRingNumber)(slotArea(f)(s)(App_Style.Horizontal.value)(node))(Data_Vec2.vv(2.0))) - Data_Vec2.w(connectorSize(s.slot.connector));
                            };
                            if (v1 instanceof App_Style.Outside) {
                                return 0.0;
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 105, column 9 - line 105, column 70): " + [ v1.constructor.name ]);
                        };
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(s.body.margin)(s.slot.offset))(Data_Vec2.make(titleWidth + outerWidth * Data_Int.toNumber(idx) + outerWidth / 2.0)(connectorOffsetY(s.slot.direction)));
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 73, column 1 - line 73, column 45): " + [ f.constructor.name, s.constructor.name, v.constructor.name, node.constructor.name, idx.constructor.name ]);
                };
            };
        };
    };
};
var inletTextPos = function (f) {
    return function (s) {
        return function (v) {
            return function (idx) {
                return function (node) {
                    if (v instanceof App_Style.Vertical) {
                        var offsetX = function (v1) {
                            if (v1 instanceof App_Style.Inside) {
                                return Data_Vec2["x'"](Data_Semiring.semiringNumber)(Data_Vec2.w(connectorSize(s.slot.connector)) + Data_Vec2.w(slotPadding));
                            };
                            if (v1 instanceof App_Style.Outside) {
                                return Data_Ring.negate(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Vec2["x'"](Data_Semiring.semiringNumber)(Data_Vec2.w(connectorSize(s.slot.connector)) + Data_Vec2.w(slotPadding)));
                            };
                            if (v1 instanceof App_Style.Between) {
                                return Data_Vec2["x'"](Data_Semiring.semiringNumber)(Data_Vec2.w(connectorSize(s.slot.connector)) + Data_Vec2.w(slotPadding));
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 150, column 9 - line 150, column 90): " + [ v1.constructor.name ]);
                        };
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(inletConnectorPos(f)(s)(App_Style.Vertical.value)(idx)(node))(offsetX(s.slot.direction));
                    };
                    if (v instanceof App_Style.Horizontal) {
                        return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 146, column 1 - line 146, column 40): " + [ f.constructor.name, s.constructor.name, v.constructor.name, idx.constructor.name, node.constructor.name ]);
                };
            };
        };
    };
};
var inletRectPos = function (f) {
    return function (s) {
        return function (v) {
            return function (node) {
                return function (idx) {
                    if (v instanceof App_Style.Vertical) {
                        var sizeF_ = nodeAreaF(f)(s)(App_Style.Vertical.value)(node);
                        var outerHeight = Data_Vec2.h(slotArea(f)(s)(App_Style.Vertical.value)(node));
                        var offsetY = Data_Maybe.fromMaybe(0.0)(App_Style_Order.sizeBefore(sizeF_)(hasInlets)(s.order));
                        var offsetX = function (v1) {
                            if (v1 instanceof App_Style.Inside) {
                                return Data_Vec2.w(s.body.margin) - Data_Vec2.w(connectorSize(s.slot.connector));
                            };
                            if (v1 instanceof App_Style.Between) {
                                return Data_Vec2.w(slotArea(f)(s)(App_Style.Vertical.value)(node)) / 2.0;
                            };
                            if (v1 instanceof App_Style.Outside) {
                                return 0.0;
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 129, column 9 - line 129, column 84): " + [ v1.constructor.name ]);
                        };
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(s.slot.offset)(Data_Vec2.make(offsetX(s.slot.direction))((offsetY + outerHeight * Data_Int.toNumber(idx) + outerHeight / 2.0) - Data_Vec2.h(connectorSize(s.slot.connector)) / 2.0));
                    };
                    if (v instanceof App_Style.Horizontal) {
                        var sizeF_ = nodeAreaF(f)(s)(App_Style.Vertical.value)(node);
                        var outerWidth = Data_Vec2.w(slotArea(f)(s)(App_Style.Horizontal.value)(node));
                        var offsetX = Data_Maybe.fromMaybe(0.0)(App_Style_Order.sizeBefore(sizeF_)(hasInlets)(s.order));
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(s.body.margin)(s.slot.offset))(Data_Vec2.make(offsetX + outerWidth * Data_Int.toNumber(idx))(0));
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 113, column 1 - line 113, column 40): " + [ f.constructor.name, s.constructor.name, v.constructor.name, node.constructor.name, idx.constructor.name ]);
                };
            };
        };
    };
};
var nodeArea = function (f) {
    return function (s) {
        return function (flow) {
            return function (node) {
                var v = Data_Vec2.toTuple(orderBy(nodeAreaF)(f)(s)(flow)(node));
                return Data_Vec2.make(Data_Vec2.w(s.body.margin) * 2.0 + v.value0)(Data_Vec2.h(s.body.margin) * 2.0 + v.value1);
            };
        };
    };
};
var outletConnectorPos = function (f) {
    return function (s) {
        return function (v) {
            return function (node) {
                return function (idx) {
                    if (v instanceof App_Style.Vertical) {
                        var sizeF_ = nodeAreaF(f)(s)(App_Style.Vertical.value)(node);
                        var outerHeight = Data_Vec2.h(slotArea(f)(s)(App_Style.Vertical.value)(node));
                        var offsetY = Data_Maybe.fromMaybe(0.0)(App_Style_Order.sizeBefore(sizeF_)(hasOutlets)(s.order));
                        var connectorOffsetX = function (v1) {
                            if (v1 instanceof App_Style.Inside) {
                                return 0.0;
                            };
                            if (v1 instanceof App_Style.Between) {
                                return 0.0;
                            };
                            if (v1 instanceof App_Style.Outside) {
                                return 0.0;
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 168, column 9 - line 168, column 38): " + [ v1.constructor.name ]);
                        };
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(s.body.margin)(s.slot.offset))(Data_Vec2.make(connectorOffsetX(s.slot.direction) + s.body.size)(offsetY + outerHeight / 2.0 + outerHeight * Data_Int.toNumber(idx)));
                    };
                    if (v instanceof App_Style.Horizontal) {
                        return Data_Vec2.make(0)(Data_Int.toNumber(idx));
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 156, column 1 - line 156, column 46): " + [ f.constructor.name, s.constructor.name, v.constructor.name, node.constructor.name, idx.constructor.name ]);
                };
            };
        };
    };
};
var outletTextPos = function (f) {
    return function (s) {
        return function (v) {
            return function (node) {
                return function (idx) {
                    if (v instanceof App_Style.Vertical) {
                        var offsetX = function (v1) {
                            if (v1 instanceof App_Style.Inside) {
                                return Data_Ring.negate(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Vec2["x'"](Data_Semiring.semiringNumber)(Data_Vec2.w(connectorSize(s.slot.connector)) + Data_Vec2.w(slotPadding)));
                            };
                            if (v1 instanceof App_Style.Outside) {
                                return Data_Vec2["x'"](Data_Semiring.semiringNumber)(Data_Vec2.w(connectorSize(s.slot.connector)) + Data_Vec2.w(slotPadding));
                            };
                            if (v1 instanceof App_Style.Between) {
                                return Data_Ring.negate(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Vec2["x'"](Data_Semiring.semiringNumber)(Data_Vec2.w(connectorSize(s.slot.connector)) * 2.0 + Data_Vec2.w(slotPadding) * 2.0));
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 181, column 9 - line 181, column 92): " + [ v1.constructor.name ]);
                        };
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(outletConnectorPos(f)(s)(App_Style.Vertical.value)(node)(idx))(offsetX(s.slot.direction));
                    };
                    if (v instanceof App_Style.Horizontal) {
                        return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 177, column 1 - line 177, column 41): " + [ f.constructor.name, s.constructor.name, v.constructor.name, node.constructor.name, idx.constructor.name ]);
                };
            };
        };
    };
};
var outletRectPos = function (f) {
    return function (s) {
        return function (v) {
            return function (node) {
                return function (idx) {
                    if (v instanceof App_Style.Vertical) {
                        var sizeF_ = nodeAreaF(f)(s)(App_Style.Vertical.value)(node);
                        var outerHeight = Data_Vec2.h(slotArea(f)(s)(App_Style.Vertical.value)(node));
                        var offsetY = Data_Maybe.fromMaybe(0.0)(App_Style_Order.sizeBefore(sizeF_)(hasOutlets)(s.order));
                        var offsetX = function (v1) {
                            if (v1 instanceof App_Style.Inside) {
                                return Data_Ring.negate(Data_Ring.ringFn(Data_Ring.ringNumber))(Data_Vec2.w)(slotArea(f)(s)(App_Style.Vertical.value)(node));
                            };
                            if (v1 instanceof App_Style.Between) {
                                return 0.0;
                            };
                            if (v1 instanceof App_Style.Outside) {
                                return -Data_Vec2.w(connectorSize(s.slot.connector)) - Data_Vec2.w(slotPadding);
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 202, column 9 - line 202, column 60): " + [ v1.constructor.name ]);
                        };
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(s.body.margin)(s.slot.offset))(Data_Vec2.make(s.body.size + offsetX(s.slot.direction) + Data_Vec2.w(connectorSize(s.slot.connector)))(offsetY + outerHeight * Data_Int.toNumber(idx)));
                    };
                    if (v instanceof App_Style.Horizontal) {
                        return Data_Vec2.make(0)(Data_Int.toNumber(idx));
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 187, column 1 - line 187, column 41): " + [ f.constructor.name, s.constructor.name, v.constructor.name, node.constructor.name, idx.constructor.name ]);
                };
            };
        };
    };
};
var bodySizeF = function (f) {
    return function (s) {
        return function (flow) {
            return function (node) {
                var v = Noodle_Node.dimensionsBy(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Noodle_Channel_Shape.isHidden))(node);
                var sizeOf = function (v1) {
                    if (v1 instanceof App_Style.Title) {
                        if (f.hasTitle) {
                            if (s.title.mode instanceof App_Style.InsideBody) {
                                return Data_Vec2.h(titleSize(f)(s)(flow)(node));
                            };
                            if (s.title.mode instanceof App_Style.OutsideBody) {
                                return 0.0;
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 272, column 17 - line 274, column 39): " + [ s.title.mode.constructor.name ]);
                        };
                        return 0.0;
                    };
                    if (v1 instanceof App_Style.Ribbon) {
                        if (f.hasRibbon) {
                            return Data_Vec2.h(ribbonSize(f)(s)(flow)(node));
                        };
                        return 0.0;
                    };
                    if (v1 instanceof App_Style.UserBody) {
                        if (f.controlArea) {
                            return v1.value0;
                        };
                        return 0.0;
                    };
                    if (v1 instanceof App_Style.OnlyInlets) {
                        return Data_Int.toNumber(v.value0) * Data_Vec2.h(slotArea(f)(s)(flow)(node));
                    };
                    if (v1 instanceof App_Style.OnlyOutlets) {
                        return Data_Int.toNumber(v.value1) * Data_Vec2.h(slotArea(f)(s)(flow)(node));
                    };
                    if (v1 instanceof App_Style.InletsAndOutlets) {
                        return Data_Int.toNumber(Data_Ord.max(Data_Ord.ordInt)(v.value0)(v.value1)) * Data_Vec2.h(slotArea(f)(s)(flow)(node));
                    };
                    if (v1 instanceof App_Style.UserBodyBetweenSlots) {
                        return sizeOf(App_Style.InletsAndOutlets.value);
                    };
                    if (v1 instanceof App_Style.UserBodyBetweenSlotsMin) {
                        if (f.controlArea) {
                            var $122 = sizeOf(App_Style.InletsAndOutlets.value) > v1.value0;
                            if ($122) {
                                return sizeOf(App_Style.InletsAndOutlets.value);
                            };
                            return v1.value0;
                        };
                        return 0.0;
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 270, column 9 - line 275, column 21): " + [ v1.constructor.name ]);
                };
                return sizeOf;
            };
        };
    };
};
var bodySize = orderBy(bodySizeF);
var bodyPos = function (f) {
    return function (s) {
        return function (flow) {
            return function (v) {
                if (f.hasTitle) {
                    if (s.title.mode instanceof App_Style.InsideBody) {
                        return s.body.margin;
                    };
                    if (s.title.mode instanceof App_Style.OutsideBody) {
                        return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(s.body.margin)((function () {
                            if (flow instanceof App_Style.Vertical) {
                                return Data_Vec2["h'"](Data_Semiring.semiringNumber)(s.title.size);
                            };
                            if (flow instanceof App_Style.Horizontal) {
                                return Data_Vec2["w'"](Data_Semiring.semiringNumber)(s.title.size);
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 259, column 19 - line 261, column 53): " + [ flow.constructor.name ]);
                        })());
                    };
                    throw new Error("Failed pattern match at App.Style.Calculate (line 256, column 9 - line 261, column 53): " + [ s.title.mode.constructor.name ]);
                };
                return Data_Vec2.make(0.0)(0.0);
            };
        };
    };
};
var shadowPos = function (f) {
    return function (s) {
        return function (flow) {
            return function (node) {
                return bodyPos(f)(s)(flow)(node);
            };
        };
    };
};
var bodyInnerOffset = function (f) {
    return function (s) {
        return function (v) {
            return function (node) {
                if (v instanceof App_Style.Vertical) {
                    return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))((function () {
                        if (f.hasTitle) {
                            if (s.title.mode instanceof App_Style.InsideBody) {
                                return Data_Vec2.zh(Data_Semiring.semiringNumber)(titleSize(f)(s)(App_Style.Vertical.value)(node));
                            };
                            if (s.title.mode instanceof App_Style.OutsideBody) {
                                return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 363, column 9 - line 365, column 32): " + [ s.title.mode.constructor.name ]);
                        };
                        return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                    })())((function () {
                        if (f.hasRibbon) {
                            return Data_Vec2.zh(Data_Semiring.semiringNumber)(ribbonSize(f)(s)(App_Style.Vertical.value)(node));
                        };
                        return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                    })());
                };
                if (v instanceof App_Style.Horizontal) {
                    return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))((function () {
                        if (f.hasTitle) {
                            if (s.title.mode instanceof App_Style.InsideBody) {
                                return Data_Vec2.wz(Data_Semiring.semiringNumber)(titleSize(f)(s)(App_Style.Horizontal.value)(node));
                            };
                            if (s.title.mode instanceof App_Style.OutsideBody) {
                                return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                            };
                            throw new Error("Failed pattern match at App.Style.Calculate (line 373, column 9 - line 375, column 32): " + [ s.title.mode.constructor.name ]);
                        };
                        return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                    })())((function () {
                        if (f.hasRibbon) {
                            return Data_Vec2.wz(Data_Semiring.semiringNumber)(ribbonSize(f)(s)(App_Style.Horizontal.value)(node));
                        };
                        return Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2));
                    })());
                };
                throw new Error("Failed pattern match at App.Style.Calculate (line 360, column 1 - line 360, column 38): " + [ f.constructor.name, s.constructor.name, v.constructor.name, node.constructor.name ]);
            };
        };
    };
};
module.exports = {
    slotPadding: slotPadding,
    removeButtonRadius: removeButtonRadius,
    hasInlets: hasInlets,
    hasOutlets: hasOutlets,
    connectorSize: connectorSize,
    slotArea: slotArea,
    inletConnectorPos: inletConnectorPos,
    inletRectPos: inletRectPos,
    inletTextPos: inletTextPos,
    outletConnectorPos: outletConnectorPos,
    outletTextPos: outletTextPos,
    outletRectPos: outletRectPos,
    removeButtonPos: removeButtonPos,
    titlePos: titlePos,
    titleTextPos: titleTextPos,
    titleSize: titleSize,
    ribbonSize: ribbonSize,
    bodyPos: bodyPos,
    bodySizeF: bodySizeF,
    nodeAreaF: nodeAreaF,
    orderBy: orderBy,
    nodeArea: nodeArea,
    bodySize: bodySize,
    shadowPos: shadowPos,
    bodyInnerOffset: bodyInnerOffset
};
