// Generated by purs version 0.14.3
"use strict";
var App_Emitters = require("../App.Emitters/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Subscription = require("../Halogen.Subscription/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Compile = require("../Hydra.Compile/index.js");
var Hydra_Engine = require("../Hydra.Engine/index.js");
var Hydra_Queue = require("../Hydra.Queue/index.js");
var NoOp = (function () {
    function NoOp() {

    };
    NoOp.value = new NoOp();
    return NoOp;
})();
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Render = (function () {
    function Render(value0) {
        this.value0 = value0;
    };
    Render.create = function (value0) {
        return new Render(value0);
    };
    return Render;
})();
var render = Data_Function["const"](Halogen_Svg_Elements.g([  ])([  ]));
var initialState = function (v) {
    return {
        node: v.node
    };
};
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof NoOp) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(App_Emitters.fromInlet(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(v1.node)("texture"))(function (emitter) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM.subscribe(Data_Functor.map(Halogen_Subscription.functorEmitter)((function () {
                        var $13 = Data_Maybe.maybe(NoOp.value)(Render.create);
                        return function ($14) {
                            return $13(Hydra.toTexture($14));
                        };
                    })())(emitter)))(function () {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    });
                });
            });
        };
        if (v instanceof Render) {
            var queue = Hydra_Queue.just(v.value0);
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(function __do() {
                Effect_Console.log(Hydra_Compile.compile(Hydra_Compile.friendly)(queue))();
                return Hydra_Engine.evaluate(Hydra_Compile.compile(Hydra_Compile.compact)(queue))();
            }))(function () {
                return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
            });
        };
        throw new Error("Failed pattern match at Hydra.Component.Node.Out (line 84, column 16 - line 97, column 18): " + [ v.constructor.name ]);
    };
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
var bodyWidth = 110.0;
module.exports = {
    NoOp: NoOp,
    Initialize: Initialize,
    Render: Render,
    bodyWidth: bodyWidth,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
