// Generated by purs version 0.14.3
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set_Ordered = require("../Data.Set.Ordered/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Order = function (x) {
    return x;
};
var toUnfoldable = function (dictUnfoldable) {
    return function (v) {
        return Data_Set_Ordered.toUnfoldable(dictUnfoldable)(v);
    };
};
var toArray = function (v) {
    return Data_Array.fromFoldable(Data_Set_Ordered.foldableOSet)(v);
};
var toArray$prime = function (sizeF) {
    var $34 = Data_Functor.map(Data_Functor.functorArray)(function (i) {
        return new Data_Tuple.Tuple(i, sizeF(i));
    });
    return function ($35) {
        return $34(toArray($35));
    };
};
var toSizes = function (sizeF) {
    var $36 = Data_Functor.map(Data_Functor.functorArray)(sizeF);
    return function ($37) {
        return $36(toArray($37));
    };
};
var sizeBy = function (sizeF) {
    var $38 = Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_Semiring.add(Data_Semiring.semiringNumber))(0.0);
    var $39 = toSizes(sizeF);
    return function ($40) {
        return $38($39($40));
    };
};
var sizeBefore = function (sizeF) {
    return function (cmp) {
        var foldF = function (v) {
            return function (v1) {
                if (v1 instanceof Data_Maybe.Nothing && cmp(v.value0)) {
                    return new Data_Maybe.Just(0.0);
                };
                if (v1 instanceof Data_Maybe.Nothing && Data_Boolean.otherwise) {
                    return Data_Maybe.Nothing.value;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return Data_Maybe.Just.create(v1.value0 + v.value1);
                };
                throw new Error("Failed pattern match at App.Style.Order (line 64, column 9 - line 64, column 61): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
        var $41 = Data_Foldable.foldr(Data_Foldable.foldableArray)(foldF)(Data_Maybe.Nothing.value);
        var $42 = toArray$prime(sizeF);
        return function ($43) {
            return $41($42($43));
        };
    };
};
var sizeAt = function (sizeF) {
    return function (cmp) {
        var foldF = function (v) {
            return function (v1) {
                if (v1 instanceof Data_Maybe.Nothing && cmp(v.value0)) {
                    return new Data_Maybe.Just(v.value1);
                };
                if (v1 instanceof Data_Maybe.Nothing && Data_Boolean.otherwise) {
                    return Data_Maybe.Nothing.value;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return Data_Maybe.Just.create(v1.value0 + v.value1);
                };
                throw new Error("Failed pattern match at App.Style.Order (line 84, column 9 - line 84, column 62): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
        var $44 = Data_Foldable.foldr(Data_Foldable.foldableArray)(foldF)(Data_Maybe.Nothing.value);
        var $45 = toArray$prime(sizeF);
        return function ($46) {
            return $44($45($46));
        };
    };
};
var sizeAfter = function (sizeF) {
    return function (cmp) {
        var foldF = function (v) {
            return function (v1) {
                if (v instanceof Data_Maybe.Nothing && cmp(v1.value0)) {
                    return new Data_Maybe.Just(0.0);
                };
                if (v instanceof Data_Maybe.Nothing && Data_Boolean.otherwise) {
                    return Data_Maybe.Nothing.value;
                };
                if (v instanceof Data_Maybe.Just) {
                    return Data_Maybe.Just.create(v.value0 + v1.value1);
                };
                throw new Error("Failed pattern match at App.Style.Order (line 74, column 9 - line 74, column 62): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
        var $47 = Data_Foldable.foldl(Data_Foldable.foldableArray)(foldF)(Data_Maybe.Nothing.value);
        var $48 = toArray$prime(sizeF);
        return function ($49) {
            return $47($48($49));
        };
    };
};
var fromFoldable = function (dictFoldable) {
    var $50 = Data_Set_Ordered.fromFoldable(dictFoldable);
    return function ($51) {
        return Order($50($51));
    };
};
var make = function (dictFoldable) {
    return fromFoldable(dictFoldable);
};
module.exports = {
    Order: Order,
    make: make,
    fromFoldable: fromFoldable,
    toUnfoldable: toUnfoldable,
    toArray: toArray,
    "toArray'": toArray$prime,
    toSizes: toSizes,
    sizeBy: sizeBy,
    sizeBefore: sizeBefore,
    sizeAfter: sizeAfter,
    sizeAt: sizeAt
};
