// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var App_Style_Calculate = require("../App.Style.Calculate/index.js");
var App_Style_ClassNames = require("../App.Style.ClassNames/index.js");
var App_Svg_Extra = require("../App.Svg.Extra/index.js");
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Halogen_Svg_Elements_None = require("../Halogen.Svg.Elements.None/index.js");
var Noodle_Channel_Shape = require("../Noodle.Channel.Shape/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Header = (function () {
    function Header() {

    };
    Header.value = new Header();
    return Header;
})();
var Inlet = (function () {
    function Inlet(value0) {
        this.value0 = value0;
    };
    Inlet.create = function (value0) {
        return new Inlet(value0);
    };
    return Inlet;
})();
var Outlet = (function () {
    function Outlet(value0) {
        this.value0 = value0;
    };
    Outlet.create = function (value0) {
        return new Outlet(value0);
    };
    return Outlet;
})();
var Remove = (function () {
    function Remove() {

    };
    Remove.value = new Remove();
    return Remove;
})();
var Replace = (function () {
    function Replace(value0) {
        this.value0 = value0;
    };
    Replace.create = function (value0) {
        return new Replace(value0);
    };
    return Replace;
})();
var ToPatch = (function () {
    function ToPatch(value0) {
        this.value0 = value0;
    };
    ToPatch.create = function (value0) {
        return new ToPatch(value0);
    };
    return ToPatch;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var RequestRemove = (function () {
    function RequestRemove() {

    };
    RequestRemove.value = new RequestRemove();
    return RequestRemove;
})();
var FromNode = (function () {
    function FromNode(value0) {
        this.value0 = value0;
    };
    FromNode.create = function (value0) {
        return new FromNode(value0);
    };
    return FromNode;
})();
var NoOp = (function () {
    function NoOp() {

    };
    NoOp.value = new NoOp();
    return NoOp;
})();
var whereInside = function (getFlags) {
    return function (style) {
        return function (flow) {
            return function (node) {
                return function (pos) {
                    var f = App_Toolkit_UI.flagsFor(getFlags)(node);
                    var $20 = Data_Vec2["inside'"](Data_Semiring.semiringNumber)(Data_Ord.ordNumber)(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(pos)(App_Style_Calculate.titlePos(f)(style)(flow)(node)))(App_Style_Calculate.titleSize(f)(style)(flow)(node));
                    if ($20) {
                        return new Data_Maybe.Just(Header.value);
                    };
                    var outlets = Data_Array.mapWithIndex(Data_Tuple.Tuple.create)(Data_Functor.mapFlipped(Data_Functor.functorArray)(Noodle_Node.outletsBy(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Noodle_Channel_Shape.isHidden))(node))(Data_Tuple.fst));
                    var isInSlot = function (sl) {
                        return function (fn) {
                            return function (v) {
                                var $24 = Data_Vec2.inside(Data_Semiring.semiringNumber)(Data_Ord.ordNumber)(pos)(new Data_Tuple.Tuple(fn(v.value0), App_Style_Calculate.slotArea(f)(style)(flow)(node)));
                                if ($24) {
                                    return Data_Maybe.Just.create(sl(v.value1));
                                };
                                return Data_Maybe.Nothing.value;
                            };
                        };
                    };
                    var testOutlets = Data_Array.findMap(isInSlot(Outlet.create)(App_Style_Calculate.outletRectPos(f)(style)(flow)(node)))(outlets);
                    var inlets = Data_Array.mapWithIndex(Data_Tuple.Tuple.create)(Data_Functor.mapFlipped(Data_Functor.functorArray)(Noodle_Node.inletsBy(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Noodle_Channel_Shape.isHidden))(node))(Data_Tuple.fst));
                    var testInlets = Data_Array.findMap(isInSlot(Inlet.create)(App_Style_Calculate.inletRectPos(f)(style)(flow)(node)))(inlets);
                    return Control_Alt.alt(Data_Maybe.altMaybe)(testOutlets)(testInlets);
                };
            };
        };
    };
};
var outletConnectorPos = function (getFlags) {
    return function (style) {
        return function (flow) {
            return function (outletId) {
                return function (node) {
                    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Noodle_Node.indexOfOutlet(outletId)(node))(App_Style_Calculate.outletConnectorPos(App_Toolkit_UI.flagsFor(getFlags)(node))(style)(flow)(node));
                };
            };
        };
    };
};
var inletConnectorPos = function (getFlags) {
    return function (style) {
        return function (flow) {
            return function (inletId) {
                return function (node) {
                    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Noodle_Node.indexOfInlet(inletId)(node))(App_Style_Calculate.inletConnectorPos(App_Toolkit_UI.flagsFor(getFlags)(node))(style)(flow)(node));
                };
            };
        };
    };
};
var initialState = Control_Category.identity(Control_Category.categoryFn);
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof Receive) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                var $28 = {};
                for (var $29 in state) {
                    if ({}.hasOwnProperty.call(state, $29)) {
                        $28[$29] = state[$29];
                    };
                };
                $28.node = v.value0.node;
                $28.linksCount = v.value0.linksCount;
                $28.patchState = v.value0.patchState;
                return $28;
            });
        };
        if (v instanceof RequestRemove) {
            return Halogen_Query_HalogenM.raise(Remove.value);
        };
        if (v instanceof FromNode && v.value0 instanceof App_Toolkit_UI.SendToOutlet) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Node.produce(state.node)(new Data_Tuple.Tuple(v.value0.value0, v.value0.value1)));
            });
        };
        if (v instanceof FromNode && v.value0 instanceof App_Toolkit_UI.SendToInlet) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Node.send(state.node)(new Data_Tuple.Tuple(v.value0.value0, v.value0.value1)));
            });
        };
        if (v instanceof FromNode && v.value0 instanceof App_Toolkit_UI.Replace) {
            return Halogen_Query_HalogenM.raise(new Replace(v.value0.value0));
        };
        if (v instanceof FromNode && v.value0 instanceof App_Toolkit_UI.ToPatch) {
            return Halogen_Query_HalogenM.raise(new ToPatch(v.value0.value0));
        };
        if (v instanceof NoOp) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        throw new Error("Failed pattern match at App.Component.Node (line 390, column 16 - line 419, column 18): " + [ v.constructor.name ]);
    };
};
var extract = function (v) {
    return {
        node: v.node,
        linksCount: v.linksCount,
        patchState: v.patchState
    };
};
var debug = false;
var areaOf = function (getFlags) {
    return function (style) {
        return function (flow) {
            return function (node) {
                return App_Style_Calculate.nodeArea(App_Toolkit_UI.flagsFor(getFlags)(node))(style)(flow)(node);
            };
        };
    };
};
var _body = Type_Proxy["Proxy"].value;
var render = function (dictMonadEffect) {
    return function (v) {
        var flagsFor = App_Toolkit_UI.flagsFor(v.getFlags);
        var f = flagsFor(v.node);
        var v1 = Data_Vec2.toTuple(App_Style_Calculate.slotArea(f)(v.style)(v.flow)(v.node));
        var v2 = Data_Vec2.toTuple(App_Style_Calculate.titleSize(f)(v.style)(v.flow)(v.node));
        var v3 = Data_Vec2.toTuple(App_Style_Calculate.ribbonSize(f)(v.style)(v.flow)(v.node));
        var v4 = Data_Vec2.toTuple(App_Style_Calculate.nodeArea(f)(v.style)(v.flow)(v.node));
        var v5 = Data_Vec2.toTuple(App_Style_Calculate.bodySize(f)(v.style)(v.flow)(v.node));
        var titleColor = new Data_Maybe.Just(v.style.title.fill);
        var shadow = Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](App_Style_Calculate.shadowPos(f)(v.style)(v.flow)(v.node)) ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.black))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.black))), Halogen_Svg_Attributes.strokeWidth(v.style.body.strokeWidth), Halogen_Svg_Attributes.rx(v.style.body.cornerRadius), Halogen_Svg_Attributes.ry(v.style.body.cornerRadius), Halogen_Svg_Attributes.width(v5.value0), Halogen_Svg_Attributes.height(v5.value1) ]) ]);
        var removeButton$prime = (function () {
            if (f.hasRemoveButton) {
                return Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](App_Style_Calculate.removeButtonPos(f)(v.style)(v.flow)(v.node)), Halogen_Svg_Attributes.classes(App_Style_ClassNames.removeButton) ])([ Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.r(App_Style_Calculate.removeButtonRadius), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(48)(48)(48)(0.0)))) ]), Halogen_Svg_Elements.line([ Halogen_Svg_Attributes.x1(-2.5), Halogen_Svg_Attributes.y1(-2.5), Halogen_Svg_Attributes.x2(2.5), Halogen_Svg_Attributes.y2(2.5), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.white))), Halogen_Svg_Attributes.strokeWidth(1.0) ]), Halogen_Svg_Elements.line([ Halogen_Svg_Attributes.x1(-2.5), Halogen_Svg_Attributes.y1(2.5), Halogen_Svg_Attributes.x2(2.5), Halogen_Svg_Attributes.y2(-2.5), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.white))), Halogen_Svg_Attributes.strokeWidth(1.0) ]), Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.r(App_Style_Calculate.removeButtonRadius), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color_Extra.transparent))), Halogen_HTML_Events.onClick(function (v6) {
                    return RequestRemove.value;
                }) ]) ]);
            };
            return Halogen_Svg_Elements_None.none;
        })();
        var outlets = Noodle_Node.outletsBy(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Noodle_Channel_Shape.isHidden))(v.node);
        var name$prime = (function () {
            if (f.hasTitle) {
                return Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](App_Style_Calculate.titlePos(f)(v.style)(v.flow)(v.node)), Halogen_Svg_Attributes.classes(App_Style_ClassNames.nodeTitle) ])([ (function () {
                    if (v.style.title.mode instanceof App_Style.InsideBody) {
                        return Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.title.background))), Halogen_Svg_Attributes.width(v2.value0), Halogen_Svg_Attributes.height(v2.value1) ]);
                    };
                    if (v.style.title.mode instanceof App_Style.OutsideBody) {
                        return Halogen_Svg_Elements_None.none;
                    };
                    throw new Error("Failed pattern match at App.Component.Node (line 145, column 23 - line 153, column 36): " + [ v.style.title.mode.constructor.name ]);
                })(), Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](App_Style_Calculate.titleTextPos(f)(v.style)(v.flow)(v.node)) ])([ Halogen_Svg_Elements.text([ Halogen_Svg_Attributes.fill(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(titleColor)) ])([ Halogen_HTML_Core.text(v.name) ]) ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.classes((function () {
                    var $55 = !debug;
                    if ($55) {
                        return App_Style_ClassNames.nodeTitleFocus;
                    };
                    return App_Style_ClassNames.nodeTitleFocusDebug;
                })()), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color_Extra.transparent))), Halogen_Svg_Attributes.width(v2.value0), Halogen_Svg_Attributes.height(v2.value1) ]) ]);
            };
            return Halogen_Svg_Elements_None.none;
        })();
        var inlets = Noodle_Node.inletsBy(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Noodle_Channel_Shape.isHidden))(v.node);
        var familyColor = Control_Alt.alt(Data_Maybe.altMaybe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(v.markings.node)(Noodle_Node.family(v.node)))(new Data_Maybe.Just(v.style.title.fill));
        var dimColors = function (linksCount1) {
            return v.style.slot.dimWhenNoLinks && linksCount1 === 0;
        };
        var slotFill = function (lC) {
            return function (shape) {
                var $56 = !dimColors(lC);
                if ($56) {
                    return Control_Alt.alt(Data_Maybe.altMaybe)(v.markings.channel(Noodle_Channel_Shape.id(shape)))(new Data_Maybe.Just(v.style.slot.fill));
                };
                return Control_Alt.alt(Data_Maybe.altMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.dim(0.3))(v.markings.channel(Noodle_Channel_Shape.id(shape))))(new Data_Maybe.Just(v.style.slot.fill));
            };
        };
        var connector = function (v6) {
            return function (lC) {
                return function (shape) {
                    if (v6 instanceof App_Style.Circle) {
                        return Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.fill(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(slotFill(lC)(shape))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.slot.stroke))), Halogen_Svg_Attributes.strokeWidth(v.style.slot.strokeWidth), Halogen_Svg_Attributes.r(v6.value0) ]);
                    };
                    if (v6 instanceof App_Style.DoubleCircle) {
                        return Halogen_Svg_Elements.g([  ])([ (function () {
                            var $61 = lC > 0;
                            if ($61) {
                                return Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.fill(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(slotFill(lC)(shape))), Halogen_Svg_Attributes.strokeWidth(0.0), Halogen_Svg_Attributes.r(v6.value0) ]);
                            };
                            return Halogen_Svg_Elements_None.none;
                        })(), Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.fill(Data_Maybe.Nothing.value), Halogen_Svg_Attributes.stroke(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(slotFill(lC)(shape))), Halogen_Svg_Attributes.strokeWidth(v.style.slot.strokeWidth), Halogen_Svg_Attributes.r(v6.value1) ]) ]);
                    };
                    if (v6 instanceof App_Style.Rect) {
                        return Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.fill(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(slotFill(lC)(shape))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.slot.stroke))), Halogen_Svg_Attributes.strokeWidth(v.style.slot.strokeWidth), Halogen_Svg_Attributes.width(Data_Vec2.w(v6.value0)), Halogen_Svg_Attributes.height(Data_Vec2.h(v6.value0)) ]);
                    };
                    if (v6 instanceof App_Style.Square) {
                        return Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.fill(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(slotFill(lC)(shape))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.slot.stroke))), Halogen_Svg_Attributes.strokeWidth(v.style.slot.strokeWidth), Halogen_Svg_Attributes.width(v6.value0), Halogen_Svg_Attributes.height(v6.value0) ]);
                    };
                    throw new Error("Failed pattern match at App.Component.Node (line 208, column 9 - line 214, column 18): " + [ v6.constructor.name, lC.constructor.name, shape.constructor.name ]);
                };
            };
        };
        var slot = function (classes) {
            return function (rectPos) {
                return function (pos) {
                    return function (textPos) {
                        return function (linksCount1) {
                            return function (v6) {
                                return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(classes) ])([ Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](pos) ])([ connector(v.style.slot.connector)(linksCount1)(v6.value1) ]), Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](textPos), Halogen_Svg_Attributes.classes(App_Style_ClassNames.slotIdLabel) ])([ Halogen_Svg_Elements.text([ Halogen_Svg_Attributes.fill(Data_Maybe.Just.create((function () {
                                    var $72 = !dimColors(linksCount1);
                                    if ($72) {
                                        return Color_Extra.toSvg(v.style.slot.label.color);
                                    };
                                    return Color_Extra.toSvg(Color_Extra.dim(0.3)(v.style.slot.label.color));
                                })())) ])([ Halogen_HTML_Core.text(v6.value0) ]) ]), Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](rectPos), Halogen_Svg_Attributes.classes((function () {
                                    var $73 = !debug;
                                    if ($73) {
                                        return App_Style_ClassNames.slotFocusArea;
                                    };
                                    return App_Style_ClassNames.slotFocusAreaDebug;
                                })()) ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color_Extra.transparent))), Halogen_Svg_Attributes.width(v1.value0), Halogen_Svg_Attributes.height(v1.value1) ]) ]) ]);
                            };
                        };
                    };
                };
            };
        };
        var inlet$prime = function (idx) {
            return function (v6) {
                return slot(App_Style_ClassNames.inlet(v6.value0))(App_Style_Calculate.inletRectPos(f)(v.style)(v.flow)(v.node)(idx))(App_Style_Calculate.inletConnectorPos(f)(v.style)(v.flow)(v.node)(idx))(App_Style_Calculate.inletTextPos(f)(v.style)(v.flow)(v.node)(idx))(Noodle_Node.linksAtInlet(v6.value0)(v.linksCount))(new Data_Tuple.Tuple(v6.value0, v6.value1));
            };
        };
        var inlets$prime = Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.nodeInlets(v.style.slot.direction)) ])(Data_Array.mapWithIndex(inlet$prime)(inlets));
        var outlet$prime = function (idx) {
            return function (v6) {
                return slot(App_Style_ClassNames.outlet(v6.value0))(App_Style_Calculate.outletRectPos(f)(v.style)(v.flow)(v.node)(idx))(App_Style_Calculate.outletConnectorPos(f)(v.style)(v.flow)(v.node)(idx))(App_Style_Calculate.outletTextPos(f)(v.style)(v.flow)(v.node)(idx))(Noodle_Node.linksAtOutlet(v6.value0)(v.linksCount))(new Data_Tuple.Tuple(v6.value0, v6.value1));
            };
        };
        var outlets$prime = Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.nodeInlets(v.style.slot.direction)) ])(Data_Array.mapWithIndex(outlet$prime)(outlets));
        var body = Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](App_Style_Calculate.bodyPos(f)(v.style)(v.flow)(v.node)) ])([ (function () {
            var $84 = f.hasRibbon || Data_Maybe.isJust(Control_Bind.bind(Data_Maybe.bindMaybe)(Noodle_Node.family(v.node))(v.controlArea)) && f.controlArea;
            if ($84) {
                return Halogen_Svg_Elements.mask([ Halogen_Svg_Attributes.id(v.name + "-body-mask") ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.black))), Halogen_Svg_Attributes.width(v5.value0), Halogen_Svg_Attributes.height(v5.value1) ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.white))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.black))), Halogen_Svg_Attributes.strokeWidth(v.style.body.strokeWidth), Halogen_Svg_Attributes.rx(v.style.body.cornerRadius), Halogen_Svg_Attributes.ry(v.style.body.cornerRadius), Halogen_Svg_Attributes.width(v5.value0), Halogen_Svg_Attributes.height(v5.value1) ]) ]);
            };
            return Halogen_Svg_Elements_None.none;
        })(), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.id(v.name + "-body-bg"), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.body.fill))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.body.stroke))), Halogen_Svg_Attributes.strokeWidth(v.style.body.strokeWidth), Halogen_Svg_Attributes.rx(v.style.body.cornerRadius), Halogen_Svg_Attributes.ry(v.style.body.cornerRadius), Halogen_Svg_Attributes.width(v5.value0), Halogen_Svg_Attributes.height(v5.value1) ]), (function () {
            if (f.hasRibbon) {
                return Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.class_("ribbon"), Halogen_Svg_Attributes.fill(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(familyColor)), Halogen_Svg_Attributes.stroke(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(familyColor)), Halogen_Svg_Attributes.strokeWidth(0.0), Halogen_Svg_Attributes.x(0.0), Halogen_Svg_Attributes.y(0.0), Halogen_Svg_Attributes.width(v3.value0), Halogen_Svg_Attributes.height(v3.value1), Halogen_Svg_Attributes.mask("url(#" + (v.name + "-body-mask)")) ]);
            };
            return Halogen_Svg_Elements_None.none;
        })(), (function () {
            var v6 = Control_Bind.bind(Data_Maybe.bindMaybe)(Noodle_Node.family(v.node))(v.controlArea);
            if (v6 instanceof Data_Maybe.Just) {
                return Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](App_Style_Calculate.bodyInnerOffset(f)(v.style)(v.flow)(v.node)), Halogen_Svg_Attributes.mask("url(#" + (v.name + "-body-mask)")) ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                    return "body";
                }))(Data_Ord.ordString)(_body)(v.name)(v6.value0)({
                    node: v.node,
                    patchState: v.patchState
                })(FromNode.create) ]);
            };
            if (v6 instanceof Data_Maybe.Nothing) {
                return Halogen_Svg_Elements_None.none;
            };
            throw new Error("Failed pattern match at App.Component.Node (line 356, column 19 - line 364, column 32): " + [ v6.constructor.name ]);
        })() ]);
        return Halogen_Svg_Elements.g([  ])([ shadow, body, name$prime, removeButton$prime, inlets$prime, outlets$prime ]);
    };
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render(dictMonadEffect),
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($103) {
                return Data_Maybe.Just.create(Receive.create(extract($103)));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    debug: debug,
    Remove: Remove,
    Replace: Replace,
    ToPatch: ToPatch,
    "_body": _body,
    Receive: Receive,
    RequestRemove: RequestRemove,
    FromNode: FromNode,
    NoOp: NoOp,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    extract: extract,
    component: component,
    Header: Header,
    Inlet: Inlet,
    Outlet: Outlet,
    whereInside: whereInside,
    areaOf: areaOf,
    inletConnectorPos: inletConnectorPos,
    outletConnectorPos: outletConnectorPos
};
