// Generated by purs version 0.14.3
"use strict";
var App = require("../App/index.js");
var App_Style = require("../App.Style/index.js");
var App_Style_Hydra = require("../App.Style.Hydra/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Hydra_Network = require("../Hydra.Network/index.js");
var Hydra_Toolkit = require("../Hydra.Toolkit/index.js");
var Hydra_Toolkit_UI = require("../Hydra.Toolkit.UI/index.js");
var Hydra_Toolkit_UI_State = require("../Hydra.Toolkit.UI.State/index.js");
var style = {
    slot: App_Style_Hydra.style.slot,
    bg: {
        fill: Color_Extra.transparent
    },
    body: App_Style_Hydra.style.body,
    title: App_Style_Hydra.style.title,
    link: App_Style_Hydra.style.link,
    patchTab: App_Style_Hydra.style.patchTab,
    nodeTab: App_Style_Hydra.style.nodeTab,
    order: App_Style_Hydra.style.order,
    supportedFlows: App_Style_Hydra.style.supportedFlows,
    font: App_Style_Hydra.style.font
};
var flow = App_Style.Vertical.value;
var app = function (nw) {
    return {
        style: style,
        flow: flow,
        toolkit: Hydra_Toolkit.toolkit,
        components: Hydra_Toolkit_UI.components(Effect_Aff.monadEffectAff),
        markings: Hydra_Toolkit_UI.markings,
        getFlags: Hydra_Toolkit_UI.getFlags,
        currentPatch: new Data_Maybe.Just("hydra"),
        network: nw,
        patchState: Hydra_Toolkit_UI_State.init
    };
};
var main = Control_Bind.bind(Effect.bindEffect)(Data_Functor.mapFlipped(Effect.functorEffect)(Hydra_Network.network(Hydra_Toolkit.toolkit))(app))(App.run);
module.exports = {
    style: style,
    flow: flow,
    app: app,
    main: main
};
