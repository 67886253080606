// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Freeze = (function () {
    function Freeze() {

    };
    Freeze.value = new Freeze();
    return Freeze;
})();
var Remove = (function () {
    function Remove() {

    };
    Remove.value = new Remove();
    return Remove;
})();
var showFillState = new Data_Show.Show(function (v) {
    if (v instanceof Freeze) {
        return "Freeze";
    };
    if (v instanceof Remove) {
        return "Remove";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.FillState (line 15, column 10 - line 17, column 23): " + [ v.constructor.name ]);
});
var printFillState = function (v) {
    if (v instanceof Freeze) {
        return "freeze";
    };
    if (v instanceof Remove) {
        return "remove";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.FillState (line 20, column 18 - line 22, column 21): " + [ v.constructor.name ]);
};
var eqFillState = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Freeze && y instanceof Freeze) {
            return true;
        };
        if (x instanceof Remove && y instanceof Remove) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Freeze: Freeze,
    Remove: Remove,
    printFillState: printFillState,
    eqFillState: eqFillState,
    showFillState: showFillState
};
