// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Halogen_Svg_Attributes_Utils = require("../Halogen.Svg.Attributes.Utils/index.js");
var Matrix = (function () {
    function Matrix(value0, value1, value2, value3, value4, value5) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
    };
    Matrix.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return new Matrix(value0, value1, value2, value3, value4, value5);
                        };
                    };
                };
            };
        };
    };
    return Matrix;
})();
var Translate = (function () {
    function Translate(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Translate.create = function (value0) {
        return function (value1) {
            return new Translate(value0, value1);
        };
    };
    return Translate;
})();
var Scale = (function () {
    function Scale(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Scale.create = function (value0) {
        return function (value1) {
            return new Scale(value0, value1);
        };
    };
    return Scale;
})();
var Rotate = (function () {
    function Rotate(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Rotate.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Rotate(value0, value1, value2);
            };
        };
    };
    return Rotate;
})();
var SkewX = (function () {
    function SkewX(value0) {
        this.value0 = value0;
    };
    SkewX.create = function (value0) {
        return new SkewX(value0);
    };
    return SkewX;
})();
var SkewY = (function () {
    function SkewY(value0) {
        this.value0 = value0;
    };
    SkewY.create = function (value0) {
        return new SkewY(value0);
    };
    return SkewY;
})();
var showTransform = new Data_Show.Show(function (v) {
    if (v instanceof Matrix) {
        return "(Matrix " + (Halogen_Svg_Attributes_Utils.showWithSpaces(Data_Show.showNumber)([ v.value0, v.value1, v.value2, v.value3, v.value4, v.value5 ]) + ")");
    };
    if (v instanceof Translate) {
        return "(Translate " + (Halogen_Svg_Attributes_Utils.showWithSpaces(Data_Show.showNumber)([ v.value0, v.value1 ]) + ")");
    };
    if (v instanceof Scale) {
        return "(Scale " + (Halogen_Svg_Attributes_Utils.showWithSpaces(Data_Show.showNumber)([ v.value0, v.value1 ]) + ")");
    };
    if (v instanceof Rotate) {
        return "(Rotate " + (Halogen_Svg_Attributes_Utils.showWithSpaces(Data_Show.showNumber)([ v.value0, v.value1, v.value2 ]) + ")");
    };
    if (v instanceof SkewX) {
        return "(SkewX " + (Data_Show.show(Data_Show.showNumber)(v.value0) + ")");
    };
    if (v instanceof SkewY) {
        return "(SkewY " + (Data_Show.show(Data_Show.showNumber)(v.value0) + ")");
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Transform (line 20, column 10 - line 26, column 53): " + [ v.constructor.name ]);
});
var printTransform = function (v) {
    if (v instanceof Matrix) {
        return "matrix(" + (Halogen_Svg_Attributes_Utils.printArray(Data_Show.showNumber)([ v.value0, v.value1, v.value2, v.value3, v.value4, v.value5 ]) + ")");
    };
    if (v instanceof Translate) {
        return "translate(" + (Halogen_Svg_Attributes_Utils.printArray(Data_Show.showNumber)([ v.value0, v.value1 ]) + ")");
    };
    if (v instanceof Scale) {
        return "scale(" + (Halogen_Svg_Attributes_Utils.printArray(Data_Show.showNumber)([ v.value0, v.value1 ]) + ")");
    };
    if (v instanceof Rotate) {
        return "rotate(" + (Halogen_Svg_Attributes_Utils.printArray(Data_Show.showNumber)([ v.value0, v.value1, v.value2 ]) + ")");
    };
    if (v instanceof SkewX) {
        return "skewX(" + (Data_Show.show(Data_Show.showNumber)(v.value0) + ")");
    };
    if (v instanceof SkewY) {
        return "skewY(" + (Data_Show.show(Data_Show.showNumber)(v.value0) + ")");
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Transform (line 29, column 18 - line 35, column 77): " + [ v.constructor.name ]);
};
var eqTransform = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Matrix && y instanceof Matrix) {
            return x.value0 === y.value0 && x.value1 === y.value1 && x.value2 === y.value2 && x.value3 === y.value3 && x.value4 === y.value4 && x.value5 === y.value5;
        };
        if (x instanceof Translate && y instanceof Translate) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof Scale && y instanceof Scale) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof Rotate && y instanceof Rotate) {
            return x.value0 === y.value0 && x.value1 === y.value1 && x.value2 === y.value2;
        };
        if (x instanceof SkewX && y instanceof SkewX) {
            return x.value0 === y.value0;
        };
        if (x instanceof SkewY && y instanceof SkewY) {
            return x.value0 === y.value0;
        };
        return false;
    };
});
module.exports = {
    Matrix: Matrix,
    Translate: Translate,
    Scale: Scale,
    Rotate: Rotate,
    SkewX: SkewX,
    SkewY: SkewY,
    printTransform: printTransform,
    eqTransform: eqTransform,
    showTransform: showTransform
};
