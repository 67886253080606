// Generated by purs version 0.14.3
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Queue = require("../Hydra.Queue/index.js");
var windowWidth = Hydra.WindowWidth.value;
var windowHeight = Hydra.WindowHeight.value;
var voronoi = function (scale5) {
    return function (speed) {
        return function (blending) {
            return Hydra.textureOf(new Hydra.Voronoi({
                scale: scale5,
                speed: speed,
                blending: blending
            }));
        };
    };
};
var time = Hydra.Time.value;
var src = function ($0) {
    return Hydra.textureOf(Hydra.Source.create($0));
};
var solid = function (r) {
    return function (g) {
        return function (b) {
            return function (a) {
                return Hydra.textureOf(new Hydra.Solid({
                    r: r,
                    g: g,
                    b: b,
                    a: a
                }));
            };
        };
    };
};
var shape = function (sides) {
    return function (radius) {
        return function (smoothing) {
            return Hydra.textureOf(new Hydra.Shape({
                sides: sides,
                radius: radius,
                smoothing: smoothing
            }));
        };
    };
};
var seq = Hydra.Seq.create;
var s3 = Hydra.S3.value;
var s2 = Hydra.S2.value;
var s1 = Hydra.S1.value;
var s0 = Hydra.S0.value;
var queue = Hydra_Queue.fromFoldable(Data_Foldable.foldableArray);
var pi = Hydra.Pi.value;
var out$prime$prime = Data_Tuple.Tuple.create;
var out$prime = function (buf) {
    return function (tex) {
        return queue(Data_Array.singleton(out$prime$prime(buf)(tex)));
    };
};
var out = Hydra_Queue.just;
var osc = function (freq) {
    return function (sync) {
        return function (offset) {
            return Hydra.textureOf(new Hydra.Osc({
                freq: freq,
                sync: sync,
                offset: offset
            }));
        };
    };
};
var ofTime = Hydra.OfTime.create;
var o3 = Hydra.O3.value;
var o2 = Hydra.O2.value;
var o1 = Hydra.O1.value;
var o0 = Hydra.O0.value;
var noise = function (scale5) {
    return function (offset) {
        return Hydra.textureOf(new Hydra.Noise({
            scale: scale5,
            offset: offset
        }));
    };
};
var n = Hydra.Num.create;
var noise1 = function (scale5) {
    return noise(scale5)(n(0.1));
};
var noise0 = noise1(n(10.0));
var osc2 = function (freq) {
    return function (sync) {
        return osc(freq)(sync)(n(0.0));
    };
};
var osc1 = function (freq) {
    return osc2(freq)(n(0.1));
};
var osc0 = osc1(n(60.0));
var shape2 = function (sides) {
    return function (radius) {
        return shape(sides)(radius)(n(1.0e-2));
    };
};
var shape1 = function (sides) {
    return shape2(sides)(n(0.3));
};
var shape0 = shape1(n(3.0));
var solid3 = function (r) {
    return function (g) {
        return function (b) {
            return solid(r)(g)(b)(n(1.0));
        };
    };
};
var solid2 = function (r) {
    return function (g) {
        return solid3(r)(g)(n(0.0));
    };
};
var solid1 = function (r) {
    return solid2(r)(n(0.0));
};
var solid0 = solid1(n(0.0));
var voronoi2 = function (scale5) {
    return function (speed) {
        return voronoi(scale5)(speed)(n(1.0e-2));
    };
};
var voronoi1 = function (scale5) {
    return voronoi2(scale5)(n(0.3));
};
var voronoi0 = voronoi1(n(3.0));
var mouseY = Hydra.MouseY.value;
var mouseX = Hydra.MouseX.value;
var modify = Data_Function.flip(Hydra.addModifier);
var modulate = function (what) {
    return function (amount) {
        return modify(Hydra.modulate(new Hydra.Modulate({
            what: what,
            amount: amount
        })));
    };
};
var modulate0 = function (what) {
    return modulate(what)(n(0.1));
};
var modulateHue = function (what) {
    return function (amount) {
        return modify(Hydra.modulate(new Hydra.ModulateHue({
            what: what,
            amount: amount
        })));
    };
};
var modulateHue0 = function (what) {
    return modulateHue(what)(n(1.0));
};
var modulateKaleid = function (what) {
    return function (nSides) {
        return modify(Hydra.modulate(new Hydra.ModulateKaleid({
            what: what,
            nSides: nSides
        })));
    };
};
var modulateKaleid0 = function (what) {
    return modulateKaleid(what)(n(4.0));
};
var modulatePixelate = function (what) {
    return function (multiple) {
        return function (offset) {
            return modify(Hydra.modulate(new Hydra.ModulatePixelate({
                what: what,
                multiple: multiple,
                offset: offset
            })));
        };
    };
};
var modulatePixelate1 = function (what) {
    return function (multiple) {
        return modulatePixelate(what)(multiple)(n(3.0));
    };
};
var modulatePixelate0 = function (what) {
    return modulatePixelate1(what)(n(10.0));
};
var modulateRepeat = function (what) {
    return function (repeatX2) {
        return function (repeatY2) {
            return function (offsetX) {
                return function (offsetY) {
                    return modify(Hydra.modulate(new Hydra.ModulateRepeat({
                        what: what,
                        repeatX: repeatX2,
                        repeatY: repeatY2,
                        offsetX: offsetX,
                        offsetY: offsetY
                    })));
                };
            };
        };
    };
};
var modulateRepeat3 = function (what) {
    return function (repeatX2) {
        return function (repeatY2) {
            return function (offsetX) {
                return modulateRepeat(what)(repeatX2)(repeatY2)(offsetX)(n(0.5));
            };
        };
    };
};
var modulateRepeat2 = function (what) {
    return function (repeatX2) {
        return function (repeatY2) {
            return modulateRepeat3(what)(repeatX2)(repeatY2)(n(0.5));
        };
    };
};
var modulateRepeat1 = function (what) {
    return function (repeatX2) {
        return modulateRepeat2(what)(repeatX2)(n(3.0));
    };
};
var modulateRepeat0 = function (what) {
    return modulateRepeat1(what)(n(3.0));
};
var modulateRepeatX = function (what) {
    return function (reps) {
        return function (offset) {
            return modify(Hydra.modulate(new Hydra.ModulateRepeatX({
                what: what,
                reps: reps,
                offset: offset
            })));
        };
    };
};
var modulateRepeatX1 = function (what) {
    return function (reps) {
        return modulateRepeatX(what)(reps)(n(0.5));
    };
};
var modulateRepeatX0 = function (what) {
    return modulateRepeatX1(what)(n(3.0));
};
var modulateRepeatY = function (what) {
    return function (reps) {
        return function (offset) {
            return modify(Hydra.modulate(new Hydra.ModulateRepeatY({
                what: what,
                reps: reps,
                offset: offset
            })));
        };
    };
};
var modulateRepeatY1 = function (what) {
    return function (reps) {
        return modulateRepeatY(what)(reps)(n(0.5));
    };
};
var modulateRepeatY0 = function (what) {
    return modulateRepeatY1(what)(n(3.0));
};
var modulateRotate = function (what) {
    return function (multiple) {
        return function (offset) {
            return modify(Hydra.modulate(new Hydra.ModulateRotate({
                what: what,
                multiple: multiple,
                offset: offset
            })));
        };
    };
};
var modulateRotate1 = function (what) {
    return function (multiple) {
        return modulateRotate(what)(multiple)(n(0.0));
    };
};
var modulateRotate0 = function (what) {
    return modulateRotate1(what)(n(1.0));
};
var modulateScale = function (what) {
    return function (multiple) {
        return function (offset) {
            return modify(Hydra.modulate(new Hydra.ModulateScale({
                what: what,
                multiple: multiple,
                offset: offset
            })));
        };
    };
};
var modulateScale1 = function (what) {
    return function (multiple) {
        return modulateScale(what)(multiple)(n(1.0));
    };
};
var modulateScale0 = function (what) {
    return modulateScale1(what)(n(1.0));
};
var modulateScrollX = function (what) {
    return function (scrollX2) {
        return function (speed) {
            return modify(Hydra.modulate(new Hydra.ModulateScrollX({
                what: what,
                scrollX: scrollX2,
                speed: speed
            })));
        };
    };
};
var modulateScrollX1 = function (what) {
    return function (scrollX2) {
        return modulateScrollX(what)(scrollX2)(n(0.0));
    };
};
var modulateScrollX0 = function (what) {
    return modulateScrollX1(what)(n(0.5));
};
var modulateScrollY = function (what) {
    return function (scrollY2) {
        return function (speed) {
            return modify(Hydra.modulate(new Hydra.ModulateScrollY({
                what: what,
                scrollY: scrollY2,
                speed: speed
            })));
        };
    };
};
var modulateScrollY1 = function (what) {
    return function (scrollY2) {
        return modulateScrollY(what)(scrollY2)(n(0.0));
    };
};
var modulateScrollY0 = function (what) {
    return modulateScrollY1(what)(n(0.5));
};
var mult = function (what) {
    return function (amount) {
        return modify(Hydra.blend(new Hydra.Mult({
            what: what,
            amount: amount
        })));
    };
};
var mult0 = function (what) {
    return mult(what)(n(0.5));
};
var pixelate = function (pixelX) {
    return function (pixelY) {
        return modify(Hydra.geometry(new Hydra.Pixelate({
            pixelX: pixelX,
            pixelY: pixelY
        })));
    };
};
var pixelate1 = function (pixelX) {
    return pixelate(pixelX)(n(20.0));
};
var pixelate0 = pixelate1(n(20.0));
var posterize = function (bins) {
    return function (gamma) {
        return modify(Hydra.color(new Hydra.Posterize({
            bins: bins,
            gamma: gamma
        })));
    };
};
var posterize1 = function (bins) {
    return posterize(bins)(n(0.6));
};
var posterize0 = posterize1(n(3.0));
var repeat = function (repeatX2) {
    return function (repeatY2) {
        return function (offsetX) {
            return function (offsetY) {
                return modify(Hydra.geometry(new Hydra.Repeat({
                    repeatX: repeatX2,
                    repeatY: repeatY2,
                    offsetX: offsetX,
                    offsetY: offsetY
                })));
            };
        };
    };
};
var repeat3 = function (repeatX2) {
    return function (repeatY2) {
        return function (offsetX) {
            return repeat(repeatX2)(repeatY2)(offsetX)(n(0.0));
        };
    };
};
var repeat2 = function (repeatX2) {
    return function (repeatY2) {
        return repeat3(repeatX2)(repeatY2)(n(0.0));
    };
};
var repeat1 = function (repeatX2) {
    return repeat2(repeatX2)(n(3.0));
};
var repeat0 = repeat1(n(3.0));
var repeatX = function (reps) {
    return function (offset) {
        return modify(Hydra.geometry(new Hydra.RepeatX({
            reps: reps,
            offset: offset
        })));
    };
};
var repeatX1 = function (reps) {
    return repeatX(reps)(n(0.0));
};
var repeatX0 = repeatX1(n(3.0));
var repeatY = function (reps) {
    return function (offset) {
        return modify(Hydra.geometry(new Hydra.RepeatY({
            reps: reps,
            offset: offset
        })));
    };
};
var repeatY1 = function (reps) {
    return repeatY(reps)(n(0.0));
};
var repeatY0 = repeatY1(n(3.0));
var rotate = function (angle) {
    return function (speed) {
        return modify(Hydra.geometry(new Hydra.Rotate({
            angle: angle,
            speed: speed
        })));
    };
};
var rotate1 = function (angle) {
    return rotate(angle)(n(0.0));
};
var rotate0 = rotate1(n(10.0));
var saturate = function (amount) {
    return modify(Hydra.color(new Hydra.Saturate({
        amount: amount
    })));
};
var saturate0 = saturate(n(2.0));
var scale = function (amount) {
    return function (xMult) {
        return function (yMult) {
            return function (offsetX) {
                return function (offsetY) {
                    return modify(Hydra.geometry(new Hydra.Scale({
                        amount: amount,
                        xMult: xMult,
                        yMult: yMult,
                        offsetX: offsetX,
                        offsetY: offsetY
                    })));
                };
            };
        };
    };
};
var scale4 = function (amount) {
    return function (xMult) {
        return function (yMult) {
            return function (offsetX) {
                return scale(amount)(xMult)(yMult)(offsetX)(n(0.0));
            };
        };
    };
};
var scale3 = function (amount) {
    return function (xMult) {
        return function (yMult) {
            return scale4(amount)(xMult)(yMult)(n(0.0));
        };
    };
};
var scale2 = function (amount) {
    return function (xMult) {
        return scale3(amount)(xMult)(n(1.0));
    };
};
var scale1 = function (amount) {
    return scale2(amount)(n(1.0));
};
var scale0 = scale1(n(3.0));
var scrollX = function (amount) {
    return function (speed) {
        return modify(Hydra.geometry(new Hydra.ScrollX({
            scrollX: amount,
            speed: speed
        })));
    };
};
var scrollX1 = function (amount) {
    return scrollX(amount)(n(0.0));
};
var scrollX0 = scrollX1(n(0.5));
var scrollY = function (amount) {
    return function (speed) {
        return modify(Hydra.geometry(new Hydra.ScrollY({
            scrollY: amount,
            speed: speed
        })));
    };
};
var scrollY1 = function (amount) {
    return scrollY(amount)(n(0.0));
};
var scrollY0 = scrollY1(n(0.5));
var shift = function (r) {
    return function (g) {
        return function (b) {
            return function (a) {
                return modify(Hydra.color(new Hydra.Shift({
                    r: r,
                    g: g,
                    b: b,
                    a: a
                })));
            };
        };
    };
};
var shift3 = function (r) {
    return function (g) {
        return function (b) {
            return shift(r)(g)(b)(n(0.5));
        };
    };
};
var shift2 = function (r) {
    return function (g) {
        return shift3(r)(g)(n(0.5));
    };
};
var shift1 = function (r) {
    return shift2(r)(n(0.5));
};
var shift0 = shift1(n(0.5));
var thresh = function (threshold) {
    return function (tolerance) {
        return modify(Hydra.color(new Hydra.Tresh({
            threshold: threshold,
            tolerance: tolerance
        })));
    };
};
var thresh1 = function (threshold) {
    return thresh(threshold)(n(4.0e-2));
};
var thresh0 = thresh1(n(0.5));
var mask = function (what) {
    return function (reps) {
        return function (offset) {
            return modify(Hydra.blend(new Hydra.Mask({
                what: what,
                reps: reps,
                offset: offset
            })));
        };
    };
};
var mask1 = function (what) {
    return function (reps) {
        return mask(what)(reps)(n(0.5));
    };
};
var mask0 = function (what) {
    return mask1(what)(n(3.0));
};
var luma = function (threshold) {
    return function (tolerance) {
        return modify(Hydra.color(new Hydra.Luma({
            threshold: threshold,
            tolerance: tolerance
        })));
    };
};
var luma1 = function (threshold) {
    return luma(threshold)(n(0.1));
};
var luma0 = luma1(n(0.5));
var layer = function (what) {
    return modify(Hydra.blend(new Hydra.Layer({
        what: what
    })));
};
var kaleid = function (nSides) {
    return modify(Hydra.geometry(new Hydra.Kaleid({
        nSides: nSides
    })));
};
var kaleid0 = kaleid(n(4.0));
var invert = function (amount) {
    return modify(Hydra.color(new Hydra.Invert({
        amount: amount
    })));
};
var invert0 = invert(n(1.0));
var gradient = function (speed) {
    return Hydra.textureOf(new Hydra.Gradient({
        speed: speed
    }));
};
var fft = Hydra.Harmonic.create;
var expr = function (op) {
    return function (v1) {
        return function (v2) {
            return new Hydra.Expr(v1, op, v2);
        };
    };
};
var multiply = expr(Hydra.Multiplication.value);
var subtract = expr(Hydra.Subtraction.value);
var dyn = Hydra.Dynamic.create;
var divide = expr(Hydra.Division.value);
var diff = function (what) {
    return modify(Hydra.blend(new Hydra.Diff({
        what: what
    })));
};
var $$default = Hydra.Default.value;
var contrast = function (amount) {
    return modify(Hydra.color(new Hydra.Contrast({
        amount: amount
    })));
};
var contrast0 = contrast(n(1.6));
var colorama = function (amount) {
    return modify(Hydra.color(new Hydra.Colorama({
        amount: amount
    })));
};
var colorama0 = colorama(n(5.0e-3));
var color = function (r) {
    return function (g) {
        return function (b) {
            return function (a) {
                return modify(Hydra.color(new Hydra.Color({
                    r: r,
                    g: g,
                    b: b,
                    a: a
                })));
            };
        };
    };
};
var color3 = function (r) {
    return function (g) {
        return function (b) {
            return color(r)(g)(b)(n(1.0));
        };
    };
};
var color2 = function (r) {
    return function (g) {
        return color3(r)(g)(n(0.0));
    };
};
var color1 = function (r) {
    return color2(r)(n(0.0));
};
var color0 = color1(n(0.0));
var canvasWidth = Hydra.CanvasWidth.value;
var gradient0 = gradient(canvasWidth);
var canvasHeight = Hydra.CanvasHeight.value;
var brightness = function (amount) {
    return modify(Hydra.color(new Hydra.Brightness({
        amount: amount
    })));
};
var brightness0 = brightness(n(0.4));
var blend = function (what) {
    return function (amount) {
        return modify(Hydra.blend(new Hydra.Blend({
            what: what,
            amount: amount
        })));
    };
};
var blend0 = function (what) {
    return blend(what)(n(0.5));
};
var add$prime = expr(Hydra.Addition.value);
var add = function (what) {
    return function (amount) {
        return modify(Hydra.blend(new Hydra.Add({
            what: what,
            amount: amount
        })));
    };
};
var add0 = function (what) {
    return add(what)(n(0.5));
};
module.exports = {
    out: out,
    "out'": out$prime,
    "out''": out$prime$prime,
    queue: queue,
    n: n,
    mouseX: mouseX,
    mouseY: mouseY,
    time: time,
    seq: seq,
    canvasWidth: canvasWidth,
    canvasHeight: canvasHeight,
    windowWidth: windowWidth,
    windowHeight: windowHeight,
    pi: pi,
    dyn: dyn,
    ofTime: ofTime,
    fft: fft,
    expr: expr,
    "add'": add$prime,
    subtract: subtract,
    multiply: multiply,
    divide: divide,
    "default": $$default,
    o0: o0,
    o1: o1,
    o2: o2,
    o3: o3,
    s0: s0,
    s1: s1,
    s2: s2,
    s3: s3,
    gradient: gradient,
    gradient0: gradient0,
    noise: noise,
    noise1: noise1,
    noise0: noise0,
    osc: osc,
    osc2: osc2,
    osc1: osc1,
    osc0: osc0,
    src: src,
    shape: shape,
    shape2: shape2,
    shape1: shape1,
    shape0: shape0,
    solid: solid,
    solid3: solid3,
    solid2: solid2,
    solid1: solid1,
    solid0: solid0,
    voronoi: voronoi,
    voronoi2: voronoi2,
    voronoi1: voronoi1,
    voronoi0: voronoi0,
    modify: modify,
    brightness: brightness,
    brightness0: brightness0,
    contrast: contrast,
    contrast0: contrast0,
    color: color,
    color3: color3,
    color2: color2,
    color1: color1,
    color0: color0,
    colorama: colorama,
    colorama0: colorama0,
    invert: invert,
    invert0: invert0,
    luma: luma,
    luma1: luma1,
    luma0: luma0,
    posterize: posterize,
    posterize1: posterize1,
    posterize0: posterize0,
    saturate: saturate,
    saturate0: saturate0,
    shift: shift,
    shift3: shift3,
    shift2: shift2,
    shift1: shift1,
    shift0: shift0,
    thresh: thresh,
    thresh1: thresh1,
    thresh0: thresh0,
    kaleid: kaleid,
    kaleid0: kaleid0,
    pixelate: pixelate,
    pixelate1: pixelate1,
    pixelate0: pixelate0,
    repeat: repeat,
    repeat3: repeat3,
    repeat2: repeat2,
    repeat1: repeat1,
    repeat0: repeat0,
    repeatX: repeatX,
    repeatX1: repeatX1,
    repeatX0: repeatX0,
    repeatY: repeatY,
    repeatY1: repeatY1,
    repeatY0: repeatY0,
    rotate: rotate,
    rotate1: rotate1,
    rotate0: rotate0,
    scale: scale,
    scale4: scale4,
    scale3: scale3,
    scale2: scale2,
    scale1: scale1,
    scale0: scale0,
    scrollX: scrollX,
    scrollX1: scrollX1,
    scrollX0: scrollX0,
    scrollY: scrollY,
    scrollY1: scrollY1,
    scrollY0: scrollY0,
    add: add,
    add0: add0,
    blend: blend,
    blend0: blend0,
    diff: diff,
    layer: layer,
    mask: mask,
    mask1: mask1,
    mask0: mask0,
    mult: mult,
    mult0: mult0,
    modulate: modulate,
    modulate0: modulate0,
    modulateHue: modulateHue,
    modulateHue0: modulateHue0,
    modulateKaleid: modulateKaleid,
    modulateKaleid0: modulateKaleid0,
    modulatePixelate: modulatePixelate,
    modulatePixelate1: modulatePixelate1,
    modulatePixelate0: modulatePixelate0,
    modulateRepeat: modulateRepeat,
    modulateRepeat3: modulateRepeat3,
    modulateRepeat2: modulateRepeat2,
    modulateRepeat1: modulateRepeat1,
    modulateRepeat0: modulateRepeat0,
    modulateRepeatX: modulateRepeatX,
    modulateRepeatX1: modulateRepeatX1,
    modulateRepeatX0: modulateRepeatX0,
    modulateRepeatY: modulateRepeatY,
    modulateRepeatY1: modulateRepeatY1,
    modulateRepeatY0: modulateRepeatY0,
    modulateRotate: modulateRotate,
    modulateRotate1: modulateRotate1,
    modulateRotate0: modulateRotate0,
    modulateScale: modulateScale,
    modulateScale1: modulateScale1,
    modulateScale0: modulateScale0,
    modulateScrollX: modulateScrollX,
    modulateScrollX1: modulateScrollX1,
    modulateScrollX0: modulateScrollX0,
    modulateScrollY: modulateScrollY,
    modulateScrollY1: modulateScrollY1,
    modulateScrollY0: modulateScrollY0
};
