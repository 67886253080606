// Generated by purs version 0.14.3
"use strict";
var Color = require("../Color/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Hydra_Component_Node_Buffer = require("../Hydra.Component.Node.Buffer/index.js");
var Hydra_Component_Node_Color = require("../Hydra.Component.Node.Color/index.js");
var Hydra_Component_Node_Fast = require("../Hydra.Component.Node.Fast/index.js");
var Hydra_Component_Node_Math = require("../Hydra.Component.Node.Math/index.js");
var Hydra_Component_Node_Num = require("../Hydra.Component.Node.Num/index.js");
var Hydra_Component_Node_Osc = require("../Hydra.Component.Node.Osc/index.js");
var Hydra_Component_Node_Out = require("../Hydra.Component.Node.Out/index.js");
var Hydra_Component_Node_Palette = require("../Hydra.Component.Node.Palette/index.js");
var Hydra_Component_Node_Pi = require("../Hydra.Component.Node.Pi/index.js");
var Hydra_Component_Node_Render = require("../Hydra.Component.Node.Render/index.js");
var Hydra_Component_Node_Seq = require("../Hydra.Component.Node.Seq/index.js");
var Hydra_Component_Patch = require("../Hydra.Component.Patch/index.js");
var Hydra_Toolkit_Generate = require("../Hydra.Toolkit.Generate/index.js");
var valueColor = Color.rgb(255)(255)(255);
var textureColor = Color.rgb(98)(190)(193);
var sourceColor = Color.rgb(255)(153)(102);
var patch = function (dictMonadEffect) {
    return new Data_Maybe.Just(Hydra_Component_Patch.component(dictMonadEffect));
};
var node = function (dictMonadEffect) {
    return function (v) {
        if (v === "num") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Num.component(dictMonadEffect));
        };
        if (v === "pi") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Pi.component);
        };
        if (v === "osc") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Osc.component(dictMonadEffect));
        };
        if (v === "color") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Color.component(dictMonadEffect));
        };
        if (v === "seq") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Seq.component(dictMonadEffect));
        };
        if (v === "palette") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Palette.component(Hydra_Component_Node_Palette.Modifier.value)(dictMonadEffect));
        };
        if (v === "solid-pal") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Palette.component(Hydra_Component_Node_Palette.Solid.value)(dictMonadEffect));
        };
        if (v === "to-buffer") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Buffer.component(Hydra_Component_Node_Buffer.ToBuffer.value)(dictMonadEffect));
        };
        if (v === "from-buffer") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Buffer.component(Hydra_Component_Node_Buffer.FromBuffer.value)(dictMonadEffect));
        };
        if (v === "out") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Out.component(dictMonadEffect));
        };
        if (v === "render") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Render.component(dictMonadEffect));
        };
        if (v === "math") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Math.component);
        };
        if (v === "fast") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Fast.component(dictMonadEffect));
        };
        if (v === "pi") {
            return Data_Maybe.Just.create(Hydra_Component_Node_Pi.component);
        };
        return Data_Maybe.Nothing.value;
    };
};
var modColor = Color.rgb(153)(102)(255);
var isValueNode = function (v) {
    if (v === "num") {
        return true;
    };
    if (v === "time") {
        return true;
    };
    if (v === "mouse") {
        return true;
    };
    if (v === "seq") {
        return true;
    };
    return false;
};
var hasTitle = function (v) {
    return true;
};
var hasRibbon = function (v) {
    return true;
};
var hasControlArea = function (v) {
    if (v === "num") {
        return true;
    };
    if (v === "osc") {
        return true;
    };
    if (v === "color") {
        return true;
    };
    if (v === "seq") {
        return true;
    };
    if (v === "palette") {
        return true;
    };
    if (v === "solid-pal") {
        return true;
    };
    if (v === "to-buffer") {
        return true;
    };
    if (v === "from-buffer") {
        return true;
    };
    if (v === "render") {
        return true;
    };
    if (v === "math") {
        return true;
    };
    if (v === "pi") {
        return true;
    };
    if (v === "fast") {
        return true;
    };
    return false;
};
var getFlags = function (family) {
    return {
        controlArea: hasControlArea(family),
        hasTitle: hasTitle(family),
        hasRibbon: hasRibbon(family),
        hasRemoveButton: true
    };
};
var geomColor = Color.rgb(204)(255)(102);
var components = function (dictMonadEffect) {
    return {
        patch: patch(dictMonadEffect),
        node: node(dictMonadEffect)
    };
};
var colorColor = Color.rgb(102)(255)(153);
var bufferColor = Color.rgb(221)(227)(146);
var markChannel = function (v) {
    if (v === "value") {
        return new Data_Maybe.Just(valueColor);
    };
    if (v === "texture") {
        return new Data_Maybe.Just(textureColor);
    };
    if (v === "buffer") {
        return new Data_Maybe.Just(bufferColor);
    };
    return Data_Maybe.Nothing.value;
};
var blendColor = Color.rgb(102)(204)(255);
var markNode = function (v) {
    if (isValueNode(v)) {
        return new Data_Maybe.Just(valueColor);
    };
    if (v === "solid-pal") {
        return new Data_Maybe.Just(sourceColor);
    };
    if (v === "palette") {
        return new Data_Maybe.Just(colorColor);
    };
    if (Data_Boolean.otherwise) {
        var markByKind = function (v1) {
            if (v1 instanceof Hydra_Toolkit_Generate.Source) {
                return sourceColor;
            };
            if (v1 instanceof Hydra_Toolkit_Generate.Geom) {
                return geomColor;
            };
            if (v1 instanceof Hydra_Toolkit_Generate.Color) {
                return colorColor;
            };
            if (v1 instanceof Hydra_Toolkit_Generate.Blend) {
                return blendColor;
            };
            if (v1 instanceof Hydra_Toolkit_Generate.Mod) {
                return modColor;
            };
            throw new Error("Failed pattern match at Hydra.Toolkit.UI (line 148, column 9 - line 148, column 40): " + [ v1.constructor.name ]);
        };
        return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Hydra_Toolkit_Generate.ofKind(v))(markByKind);
    };
    throw new Error("Failed pattern match at Hydra.Toolkit.UI (line 142, column 1 - line 142, column 39): " + [ v.constructor.name ]);
};
var markings = {
    node: markNode,
    channel: markChannel
};
module.exports = {
    components: components,
    markings: markings,
    getFlags: getFlags,
    hasTitle: hasTitle,
    hasRibbon: hasRibbon,
    hasControlArea: hasControlArea,
    patch: patch,
    node: node,
    valueColor: valueColor,
    textureColor: textureColor,
    bufferColor: bufferColor,
    sourceColor: sourceColor,
    geomColor: geomColor,
    colorColor: colorColor,
    blendColor: blendColor,
    modColor: modColor,
    isValueNode: isValueNode,
    markNode: markNode,
    markChannel: markChannel
};
