// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Query_Event = require("../Halogen.Query.Event/index.js");
var Halogen_Subscription = require("../Halogen.Subscription/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var Signal = require("../Signal/index.js");
var Signal_DOM = require("../Signal.DOM/index.js");
var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");
var Web_UIEvent_MouseEvent = require("../Web.UIEvent.MouseEvent/index.js");
var Web_UIEvent_MouseEvent_EventTypes = require("../Web.UIEvent.MouseEvent.EventTypes/index.js");
var mouse = function (evtType) {
    return function (document) {
        return function (handler) {
            return Halogen_Query_Event.eventListener(evtType)(Web_HTML_HTMLDocument.toEventTarget(document))((function () {
                var $14 = Data_Functor.map(Data_Maybe.functorMaybe)(handler);
                return function ($15) {
                    return $14(Web_UIEvent_MouseEvent.fromEvent($15));
                };
            })());
        };
    };
};
var mouseDown = mouse(Web_UIEvent_MouseEvent_EventTypes.mousedown);
var mouseMove = mouse(Web_UIEvent_MouseEvent_EventTypes.mousemove);
var mouseUp = mouse(Web_UIEvent_MouseEvent_EventTypes.mouseup);
var fromSignal = function (dictMonadEffect) {
    return function (signal) {
        return Effect_Class.liftEffect(dictMonadEffect)(function __do() {
            var v = Halogen_Subscription.create();
            Signal.runSignal(Signal.flippedMap(Signal.functorSignal)(signal)(Halogen_Subscription.notify(v.listener)))();
            return v.emitter;
        });
    };
};
var fromSignal$prime = function (dictMonadEffect) {
    return function (signalEff) {
        return Effect_Class.liftEffect(dictMonadEffect)(Control_Bind.bind(Effect.bindEffect)(signalEff)(fromSignal(Effect_Class.monadEffectEffect)));
    };
};
var windowDimensions = function (dictMonadEffect) {
    return fromSignal$prime(dictMonadEffect)(Signal_DOM.windowDimensions);
};
var fromOutlet = function (dictMonadEffect) {
    return function (node) {
        var $16 = fromSignal(dictMonadEffect);
        var $17 = Noodle_Node.outletSignal(node);
        return function ($18) {
            return $16($17($18));
        };
    };
};
var fromInlet = function (dictMonadEffect) {
    return function (node) {
        var $19 = fromSignal(dictMonadEffect);
        var $20 = Noodle_Node.inletSignal(node);
        return function ($21) {
            return $19($20($21));
        };
    };
};
var fromAllOutlets$prime = function (dictMonadEffect) {
    var $22 = fromSignal(dictMonadEffect);
    return function ($23) {
        return $22(Noodle_Node["outletsSignal'"]($23));
    };
};
var fromAllOutlets = function (dictMonadEffect) {
    var $24 = fromSignal(dictMonadEffect);
    return function ($25) {
        return $24(Noodle_Node.outletsSignal($25));
    };
};
var fromAllInlets$prime = function (dictMonadEffect) {
    var $26 = fromSignal(dictMonadEffect);
    return function ($27) {
        return $26(Noodle_Node["inletsSignal'"]($27));
    };
};
var fromAllInlets = function (dictMonadEffect) {
    var $28 = fromSignal(dictMonadEffect);
    return function ($29) {
        return $28(Noodle_Node.inletsSignal($29));
    };
};
var animationFrame = function (dictMonadEffect) {
    return fromSignal$prime(dictMonadEffect)(Signal_DOM.animationFrame);
};
module.exports = {
    mouse: mouse,
    mouseDown: mouseDown,
    mouseUp: mouseUp,
    mouseMove: mouseMove,
    "fromSignal'": fromSignal$prime,
    fromSignal: fromSignal,
    fromInlet: fromInlet,
    fromOutlet: fromOutlet,
    fromAllInlets: fromAllInlets,
    fromAllOutlets: fromAllOutlets,
    "fromAllInlets'": fromAllInlets$prime,
    "fromAllOutlets'": fromAllOutlets$prime,
    windowDimensions: windowDimensions,
    animationFrame: animationFrame
};
