// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Halogen_Svg_Attributes_CSSLength = require("../Halogen.Svg.Attributes.CSSLength/index.js");
var XXSmall = (function () {
    function XXSmall() {

    };
    XXSmall.value = new XXSmall();
    return XXSmall;
})();
var XSmall = (function () {
    function XSmall() {

    };
    XSmall.value = new XSmall();
    return XSmall;
})();
var Small = (function () {
    function Small() {

    };
    Small.value = new Small();
    return Small;
})();
var Medium = (function () {
    function Medium() {

    };
    Medium.value = new Medium();
    return Medium;
})();
var Large = (function () {
    function Large() {

    };
    Large.value = new Large();
    return Large;
})();
var XLarge = (function () {
    function XLarge() {

    };
    XLarge.value = new XLarge();
    return XLarge;
})();
var XXLarge = (function () {
    function XXLarge() {

    };
    XXLarge.value = new XXLarge();
    return XXLarge;
})();
var Smaller = (function () {
    function Smaller() {

    };
    Smaller.value = new Smaller();
    return Smaller;
})();
var Larger = (function () {
    function Larger() {

    };
    Larger.value = new Larger();
    return Larger;
})();
var FontSizeLength = (function () {
    function FontSizeLength(value0) {
        this.value0 = value0;
    };
    FontSizeLength.create = function (value0) {
        return new FontSizeLength(value0);
    };
    return FontSizeLength;
})();
var showFontSize = new Data_Show.Show(function (v) {
    if (v instanceof XXSmall) {
        return "xx-small";
    };
    if (v instanceof XSmall) {
        return "x-small";
    };
    if (v instanceof Small) {
        return "small";
    };
    if (v instanceof Medium) {
        return "medium";
    };
    if (v instanceof Large) {
        return "large";
    };
    if (v instanceof XLarge) {
        return "x-large";
    };
    if (v instanceof XXLarge) {
        return "xx-large";
    };
    if (v instanceof Smaller) {
        return "smaller";
    };
    if (v instanceof Larger) {
        return "larger";
    };
    if (v instanceof FontSizeLength) {
        return Halogen_Svg_Attributes_CSSLength.printCSSLength(v.value0);
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.FontSize (line 24, column 10 - line 34, column 41): " + [ v.constructor.name ]);
});
var printFontSize = function (v) {
    if (v instanceof XXSmall) {
        return "xx-small";
    };
    if (v instanceof XSmall) {
        return "x-small";
    };
    if (v instanceof Small) {
        return "small";
    };
    if (v instanceof Medium) {
        return "medium";
    };
    if (v instanceof Large) {
        return "large";
    };
    if (v instanceof XLarge) {
        return "x-large";
    };
    if (v instanceof XXLarge) {
        return "xx-large";
    };
    if (v instanceof Smaller) {
        return "smaller";
    };
    if (v instanceof Larger) {
        return "larger";
    };
    if (v instanceof FontSizeLength) {
        return Halogen_Svg_Attributes_CSSLength.printCSSLength(v.value0);
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.FontSize (line 39, column 17 - line 49, column 39): " + [ v.constructor.name ]);
};
var eqFontSize = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof XXSmall && y instanceof XXSmall) {
            return true;
        };
        if (x instanceof XSmall && y instanceof XSmall) {
            return true;
        };
        if (x instanceof Small && y instanceof Small) {
            return true;
        };
        if (x instanceof Medium && y instanceof Medium) {
            return true;
        };
        if (x instanceof Large && y instanceof Large) {
            return true;
        };
        if (x instanceof XLarge && y instanceof XLarge) {
            return true;
        };
        if (x instanceof XXLarge && y instanceof XXLarge) {
            return true;
        };
        if (x instanceof Smaller && y instanceof Smaller) {
            return true;
        };
        if (x instanceof Larger && y instanceof Larger) {
            return true;
        };
        if (x instanceof FontSizeLength && y instanceof FontSizeLength) {
            return Data_Eq.eq(Halogen_Svg_Attributes_CSSLength.eqCSSLength)(x.value0)(y.value0);
        };
        return false;
    };
});
module.exports = {
    XXSmall: XXSmall,
    XSmall: XSmall,
    Small: Small,
    Medium: Medium,
    Large: Large,
    XLarge: XLarge,
    XXLarge: XXLarge,
    Smaller: Smaller,
    Larger: Larger,
    FontSizeLength: FontSizeLength,
    printFontSize: printFontSize,
    eqFontSize: eqFontSize,
    showFontSize: showFontSize
};
