// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Web_UIEvent_MouseEvent = require("../Web.UIEvent.MouseEvent/index.js");
var Move = (function () {
    function Move(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Move.create = function (value0) {
        return function (value1) {
            return new Move(value0, value1);
        };
    };
    return Move;
})();
var Click = (function () {
    function Click(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Click.create = function (value0) {
        return function (value1) {
            return new Click(value0, value1);
        };
    };
    return Click;
})();
var StartDrag = (function () {
    function StartDrag(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    StartDrag.create = function (value0) {
        return function (value1) {
            return new StartDrag(value0, value1);
        };
    };
    return StartDrag;
})();
var Dragging = (function () {
    function Dragging(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Dragging.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Dragging(value0, value1, value2);
            };
        };
    };
    return Dragging;
})();
var DropAt = (function () {
    function DropAt(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DropAt.create = function (value0) {
        return function (value1) {
            return new DropAt(value0, value1);
        };
    };
    return DropAt;
})();
var updatePos = function (f) {
    return function (v) {
        if (v instanceof Move) {
            return new Move(f(v.value0), v.value1);
        };
        if (v instanceof Click) {
            return new Click(f(v.value0), v.value1);
        };
        if (v instanceof StartDrag) {
            return new StartDrag(f(v.value0), v.value1);
        };
        if (v instanceof Dragging) {
            return new Dragging(v.value0, f(v.value1), v.value2);
        };
        if (v instanceof DropAt) {
            return new DropAt(f(v.value0), v.value1);
        };
        throw new Error("Failed pattern match at App.Mouse (line 28, column 1 - line 28, column 72): " + [ f.constructor.name, v.constructor.name ]);
    };
};
var showMouse = function (dictShow) {
    return function (dictShow1) {
        return function (dictShow2) {
            return new Data_Show.Show(function (v) {
                if (v instanceof Move) {
                    return Data_Show.show(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(v.value0) + (" : " + Data_Show.show(Data_Maybe.showMaybe(dictShow))(v.value1));
                };
                if (v instanceof Click) {
                    return "click " + (Data_Show.show(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(v.value0) + (" : " + Data_Show.show(dictShow1)(v.value1)));
                };
                if (v instanceof StartDrag) {
                    return "start drag " + (Data_Show.show(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(v.value0) + (" : " + Data_Show.show(dictShow2)(v.value1)));
                };
                if (v instanceof Dragging) {
                    return "drag from " + (Data_Show.show(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(v.value0) + (" to " + (Data_Show.show(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(v.value1) + (" : " + Data_Show.show(dictShow2)(v.value2)))));
                };
                if (v instanceof DropAt) {
                    return "drop at " + (Data_Show.show(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(v.value0) + (" : " + Data_Show.show(dictShow2)(v.value1)));
                };
                throw new Error("Failed pattern match at App.Mouse (line 87, column 1 - line 98, column 56): " + [ v.constructor.name ]);
            });
        };
    };
};
var init = new Move(Data_Vec2.make(0.0)(0.0), Data_Maybe.Nothing.value);
var apply$prime = function (pToF) {
    return function (fToC) {
        return function (cToD) {
            return function (cToF) {
                return function (dToF) {
                    return function (event) {
                        return function (curState) {
                            var nextPos = Data_Vec2.make(Data_Int.toNumber(Web_UIEvent_MouseEvent.clientX(event)))(Data_Int.toNumber(Web_UIEvent_MouseEvent.clientY(event)));
                            var buttonDown = Web_UIEvent_MouseEvent.buttons(event) === 1;
                            var analyze = function (v) {
                                if (v instanceof Move) {
                                    var v1 = new Data_Tuple.Tuple(buttonDown, Control_Bind.bind(Data_Maybe.bindMaybe)(pToF(nextPos))(fToC(nextPos)));
                                    if (v1.value0 && v1.value1 instanceof Data_Maybe.Just) {
                                        return new Click(nextPos, v1.value1.value0);
                                    };
                                    return Move.create(nextPos)(pToF(nextPos));
                                };
                                if (v instanceof Click) {
                                    var v1 = new Data_Tuple.Tuple(buttonDown, cToD(nextPos)(v.value1));
                                    if (v1.value0 && v1.value1 instanceof Data_Maybe.Just) {
                                        return new StartDrag(v.value0, v1.value1.value0);
                                    };
                                    return Move.create(nextPos)(cToF(nextPos)(v.value1));
                                };
                                if (v instanceof StartDrag) {
                                    if (buttonDown) {
                                        return new Dragging(v.value0, nextPos, v.value1);
                                    };
                                    return Move.create(nextPos)(dToF(nextPos)(v.value1));
                                };
                                if (v instanceof Dragging) {
                                    if (buttonDown) {
                                        return new Dragging(v.value0, nextPos, v.value2);
                                    };
                                    return new DropAt(nextPos, v.value2);
                                };
                                if (v instanceof DropAt) {
                                    return Move.create(nextPos)(dToF(nextPos)(v.value1));
                                };
                                throw new Error("Failed pattern match at App.Mouse (line 61, column 9 - line 65, column 54): " + [ v.constructor.name ]);
                            };
                            return analyze(curState);
                        };
                    };
                };
            };
        };
    };
};
var apply = function (f) {
    return apply$prime(f)(Data_Function["const"](Data_Maybe.Just.create))(Data_Function["const"](Data_Maybe.Just.create))(Data_Function["const"](Data_Maybe.Just.create))(Data_Function["const"](Data_Maybe.Just.create));
};
module.exports = {
    Move: Move,
    Click: Click,
    StartDrag: StartDrag,
    Dragging: Dragging,
    DropAt: DropAt,
    init: init,
    updatePos: updatePos,
    apply: apply,
    "apply'": apply$prime,
    showMouse: showMouse
};
