// Generated by purs version 0.14.3
"use strict";
var App_Layout_Strip = require("../App.Layout.Strip/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var buttonSize = Data_Vec2.make(60.0)(20.0);
var buttonPadding = Data_Vec2.make(5.0)(15.0);
var make = App_Layout_Strip.make(new Data_Tuple.Tuple(buttonSize, buttonPadding));
var reflow = function (dictOrd) {
    return App_Layout_Strip.reflow(dictOrd)(new Data_Tuple.Tuple(buttonSize, buttonPadding));
};
module.exports = {
    buttonSize: buttonSize,
    buttonPadding: buttonPadding,
    make: make,
    reflow: reflow
};
