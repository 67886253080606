// Generated by purs version 0.14.3
"use strict";
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Loading = (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var Loaded = (function () {
    function Loaded() {

    };
    Loaded.value = new Loaded();
    return Loaded;
})();
var $$Error = (function () {
    function $$Error() {

    };
    $$Error.value = new $$Error();
    return $$Error;
})();
var toEnumReadyState = function (v) {
    if (v === 0) {
        return new Data_Maybe.Just(None.value);
    };
    if (v === 1) {
        return new Data_Maybe.Just(Loading.value);
    };
    if (v === 2) {
        return new Data_Maybe.Just(Loaded.value);
    };
    if (v === 3) {
        return new Data_Maybe.Just($$Error.value);
    };
    return Data_Maybe.Nothing.value;
};
var showReadyState = new Data_Show.Show(function (v) {
    if (v instanceof None) {
        return "None";
    };
    if (v instanceof Loading) {
        return "Loading";
    };
    if (v instanceof Loaded) {
        return "Loaded";
    };
    if (v instanceof $$Error) {
        return "Error";
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLTrackElement.ReadyState (line 29, column 1 - line 33, column 23): " + [ v.constructor.name ]);
});
var fromEnumReadyState = function (v) {
    if (v instanceof None) {
        return 0;
    };
    if (v instanceof Loading) {
        return 1;
    };
    if (v instanceof Loaded) {
        return 2;
    };
    if (v instanceof $$Error) {
        return 3;
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLTrackElement.ReadyState (line 46, column 3 - line 50, column 15): " + [ v.constructor.name ]);
};
var eqReadyState = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof None && y instanceof None) {
            return true;
        };
        if (x instanceof Loading && y instanceof Loading) {
            return true;
        };
        if (x instanceof Loaded && y instanceof Loaded) {
            return true;
        };
        if (x instanceof $$Error && y instanceof $$Error) {
            return true;
        };
        return false;
    };
});
var ordReadyState = new Data_Ord.Ord(function () {
    return eqReadyState;
}, function (x) {
    return function (y) {
        if (x instanceof None && y instanceof None) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof None) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof None) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Loading && y instanceof Loading) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Loading) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Loading) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Loaded && y instanceof Loaded) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Loaded) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Loaded) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof $$Error && y instanceof $$Error) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Web.HTML.HTMLTrackElement.ReadyState (line 14, column 1 - line 14, column 48): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var enumReadyState = new Data_Enum.Enum(function () {
    return ordReadyState;
}, Data_Enum.defaultPred(toEnumReadyState)(fromEnumReadyState), Data_Enum.defaultSucc(toEnumReadyState)(fromEnumReadyState));
var boundedReadyState = new Data_Bounded.Bounded(function () {
    return ordReadyState;
}, None.value, $$Error.value);
var boundedEnumReadyState = new Data_Enum.BoundedEnum(function () {
    return boundedReadyState;
}, function () {
    return enumReadyState;
}, 4, fromEnumReadyState, toEnumReadyState);
module.exports = {
    None: None,
    Loading: Loading,
    Loaded: Loaded,
    "Error": $$Error,
    toEnumReadyState: toEnumReadyState,
    fromEnumReadyState: fromEnumReadyState,
    eqReadyState: eqReadyState,
    ordReadyState: ordReadyState,
    boundedReadyState: boundedReadyState,
    enumReadyState: enumReadyState,
    boundedEnumReadyState: boundedEnumReadyState,
    showReadyState: showReadyState
};
