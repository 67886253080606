// Generated by purs version 0.14.3
"use strict";
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra_Compile = require("../Hydra.Compile/index.js");
var Hydra_Engine = require("../Hydra.Engine/index.js");
var Hydra_Queue = require("../Hydra.Queue/index.js");
var NoOp = (function () {
    function NoOp() {

    };
    NoOp.value = new NoOp();
    return NoOp;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var Render = (function () {
    function Render() {

    };
    Render.value = new Render();
    return Render;
})();
var initialState = function (v) {
    return {
        queue: v.patchState,
        node: v.node
    };
};
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof NoOp) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        if (v instanceof Receive) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $13 = {};
                for (var $14 in v1) {
                    if ({}.hasOwnProperty.call(v1, $14)) {
                        $13[$14] = v1[$14];
                    };
                };
                $13.queue = v.value0;
                return $13;
            });
        };
        if (v instanceof Render) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(function __do() {
                    Effect_Console.log(Hydra_Compile.compileWithRender(Hydra_Compile.friendly)(v1.queue))();
                    return Hydra_Engine.evaluate(Hydra_Compile.compileWithRender(Hydra_Compile.compact)(v1.queue))();
                }))(function () {
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                });
            });
        };
        throw new Error("Failed pattern match at Hydra.Component.Node.Render (line 107, column 16 - line 116, column 18): " + [ v.constructor.name ]);
    };
};
var bodyWidth = 110.0;
var bodyHeight = 50.0;
var render = function (v) {
    return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.class_("render-button") ])([ Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.cx(bodyWidth / 2.0), Halogen_Svg_Attributes.cy(bodyHeight / 2.0), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg((function () {
        var $20 = !Hydra_Queue.isEmpty(v.queue);
        if ($20) {
            return Color.rgba(0)(255)(0)(1.0);
        };
        return Color.rgba(100)(100)(100)(1.0);
    })()))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg((function () {
        var $21 = !Hydra_Queue.isEmpty(v.queue);
        if ($21) {
            return Color.rgba(0)(100)(0)(1.0);
        };
        return Color.rgba(50)(50)(50)(1.0);
    })()))), Halogen_Svg_Attributes.strokeWidth(2.0), Halogen_Svg_Attributes.r(10.0) ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.x(bodyWidth / 2.0 - 5.0), Halogen_Svg_Attributes.y(bodyHeight / 2.0 - 5.0), Halogen_Svg_Attributes.width(20.0), Halogen_Svg_Attributes.height(20.0), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_HTML_Events.onClick(Data_Function["const"](Render.value)) ]) ]);
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($23) {
                return Data_Maybe.Just.create(Receive.create((function (v1) {
                    return v1.patchState;
                })($23)));
            },
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    NoOp: NoOp,
    Receive: Receive,
    Render: Render,
    bodyWidth: bodyWidth,
    bodyHeight: bodyHeight,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
