// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Halogen_Svg_Attributes_Utils = require("../Halogen.Svg.Attributes.Utils/index.js");
var RGB = (function () {
    function RGB(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    RGB.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new RGB(value0, value1, value2);
            };
        };
    };
    return RGB;
})();
var RGBA = (function () {
    function RGBA(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    RGBA.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new RGBA(value0, value1, value2, value3);
                };
            };
        };
    };
    return RGBA;
})();
var Named = (function () {
    function Named(value0) {
        this.value0 = value0;
    };
    Named.create = function (value0) {
        return new Named(value0);
    };
    return Named;
})();
var showColor = new Data_Show.Show(function (v) {
    if (v instanceof RGB) {
        return "(RGB " + (Halogen_Svg_Attributes_Utils.showWithSpaces(Data_Show.showInt)([ v.value0, v.value1, v.value2 ]) + ")");
    };
    if (v instanceof RGBA) {
        return "(RGBA " + (Halogen_Svg_Attributes_Utils.showWithSpaces(Data_Show.showInt)([ v.value0, v.value1, v.value2 ]) + (" " + (Data_Show.show(Data_Show.showNumber)(v.value3) + ")")));
    };
    if (v instanceof Named) {
        return "(Named " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Color (line 17, column 10 - line 20, column 47): " + [ v.constructor.name ]);
});
var printColor = function (v) {
    if (v instanceof Data_Maybe.Just && v.value0 instanceof RGB) {
        return "rgb(" + (Halogen_Svg_Attributes_Utils.printArray(Data_Show.showInt)([ v.value0.value0, v.value0.value1, v.value0.value2 ]) + ")");
    };
    if (v instanceof Data_Maybe.Just && v.value0 instanceof RGBA) {
        return "rgba(" + (Halogen_Svg_Attributes_Utils.printArray(Data_Show.showInt)([ v.value0.value0, v.value0.value1, v.value0.value2 ]) + ("," + (Data_Show.show(Data_Show.showNumber)(v.value0.value3) + ")")));
    };
    if (v instanceof Data_Maybe.Just && v.value0 instanceof Named) {
        return v.value0.value0;
    };
    if (v instanceof Data_Maybe.Nothing) {
        return "None";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Color (line 23, column 14 - line 27, column 32): " + [ v.constructor.name ]);
};
var eqColor = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof RGB && y instanceof RGB) {
            return x.value0 === y.value0 && x.value1 === y.value1 && x.value2 === y.value2;
        };
        if (x instanceof RGBA && y instanceof RGBA) {
            return x.value0 === y.value0 && x.value1 === y.value1 && x.value2 === y.value2 && x.value3 === y.value3;
        };
        if (x instanceof Named && y instanceof Named) {
            return x.value0 === y.value0;
        };
        return false;
    };
});
module.exports = {
    RGB: RGB,
    RGBA: RGBA,
    Named: Named,
    printColor: printColor,
    eqColor: eqColor,
    showColor: showColor
};
