// Generated by purs version 0.14.3
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Comonad = require("../Control.Comonad/index.js");
var Control_Extend = require("../Control.Extend/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Distributive = require("../Data.Distributive/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Contravariant = require("../Data.Functor.Contravariant/index.js");
var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Profunctor_Closed = require("../Data.Profunctor.Closed/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Costar = function (x) {
    return x;
};
var semigroupoidCostar = function (dictExtend) {
    return new Control_Semigroupoid.Semigroupoid(function (v) {
        return function (v1) {
            return Control_Extend.composeCoKleisliFlipped(dictExtend)(v)(v1);
        };
    });
};
var profunctorCostar = function (dictFunctor) {
    return new Data_Profunctor.Profunctor(function (f) {
        return function (g) {
            return function (v) {
                var $46 = Data_Functor.map(dictFunctor)(f);
                return function ($47) {
                    return g(v($46($47)));
                };
            };
        };
    });
};
var strongCostar = function (dictComonad) {
    return new Data_Profunctor_Strong.Strong(function () {
        return profunctorCostar((dictComonad.Extend0()).Functor0());
    }, function (v) {
        return function (x) {
            return new Data_Tuple.Tuple(v(Data_Functor.map((dictComonad.Extend0()).Functor0())(Data_Tuple.fst)(x)), Data_Tuple.snd(Control_Comonad.extract(dictComonad)(x)));
        };
    }, function (v) {
        return function (x) {
            return new Data_Tuple.Tuple(Data_Tuple.fst(Control_Comonad.extract(dictComonad)(x)), v(Data_Functor.map((dictComonad.Extend0()).Functor0())(Data_Tuple.snd)(x)));
        };
    });
};
var newtypeCostar = new Data_Newtype.Newtype(function () {
    return undefined;
});
var hoistCostar = function (f) {
    return function (v) {
        return Data_Profunctor.lcmap(Data_Profunctor.profunctorFn)(f)(v);
    };
};
var functorCostar = new Data_Functor.Functor(function (f) {
    return function (v) {
        return function ($48) {
            return f(v($48));
        };
    };
});
var invariantCostar = new Data_Functor_Invariant.Invariant(Data_Functor_Invariant.imapF(functorCostar));
var distributiveCostar = new Data_Distributive.Distributive(function () {
    return functorCostar;
}, function (dictFunctor) {
    return function (f) {
        var $49 = Data_Distributive.distribute(distributiveCostar)(dictFunctor);
        var $50 = Data_Functor.map(dictFunctor)(f);
        return function ($51) {
            return $49($50($51));
        };
    };
}, function (dictFunctor) {
    return function (f) {
        return function (a) {
            return Data_Functor.map(dictFunctor)(function (v) {
                return v(a);
            })(f);
        };
    };
});
var closedCostar = function (dictFunctor) {
    return new Data_Profunctor_Closed.Closed(function () {
        return profunctorCostar(dictFunctor);
    }, function (v) {
        return function (g) {
            return function (x) {
                return v(Data_Functor.map(dictFunctor)(function (v1) {
                    return v1(x);
                })(g));
            };
        };
    });
};
var categoryCostar = function (dictComonad) {
    return new Control_Category.Category(function () {
        return semigroupoidCostar(dictComonad.Extend0());
    }, Control_Comonad.extract(dictComonad));
};
var bifunctorCostar = function (dictContravariant) {
    return new Data_Bifunctor.Bifunctor(function (f) {
        return function (g) {
            return function (v) {
                var $52 = Data_Functor_Contravariant.cmap(dictContravariant)(f);
                return function ($53) {
                    return g(v($52($53)));
                };
            };
        };
    });
};
var applyCostar = new Control_Apply.Apply(function () {
    return functorCostar;
}, function (v) {
    return function (v1) {
        return function (a) {
            return v(a)(v1(a));
        };
    };
});
var bindCostar = new Control_Bind.Bind(function () {
    return applyCostar;
}, function (v) {
    return function (f) {
        return function (x) {
            var v1 = f(v(x));
            return v1(x);
        };
    };
});
var applicativeCostar = new Control_Applicative.Applicative(function () {
    return applyCostar;
}, function (a) {
    return function (v) {
        return a;
    };
});
var monadCostar = new Control_Monad.Monad(function () {
    return applicativeCostar;
}, function () {
    return bindCostar;
});
module.exports = {
    Costar: Costar,
    hoistCostar: hoistCostar,
    newtypeCostar: newtypeCostar,
    semigroupoidCostar: semigroupoidCostar,
    categoryCostar: categoryCostar,
    functorCostar: functorCostar,
    invariantCostar: invariantCostar,
    applyCostar: applyCostar,
    applicativeCostar: applicativeCostar,
    bindCostar: bindCostar,
    monadCostar: monadCostar,
    distributiveCostar: distributiveCostar,
    bifunctorCostar: bifunctorCostar,
    profunctorCostar: profunctorCostar,
    strongCostar: strongCostar,
    closedCostar: closedCostar
};
