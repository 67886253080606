// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Foreign_Object_ST = require("../Foreign.Object.ST/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var unsafeLookup = $foreign.unsafeGetAny;
var unsafeFreeze = Unsafe_Coerce.unsafeCoerce;
var pokeMutMap = $foreign.unsafeSetAny;
var newMutMap = Foreign_Object_ST["new"];
var deleteMutMap = $foreign.unsafeDeleteAny;
module.exports = {
    newMutMap: newMutMap,
    pokeMutMap: pokeMutMap,
    deleteMutMap: deleteMutMap,
    unsafeFreeze: unsafeFreeze,
    unsafeLookup: unsafeLookup,
    unsafeGetAny: $foreign.unsafeGetAny,
    unsafeHasAny: $foreign.unsafeHasAny,
    unsafeSetAny: $foreign.unsafeSetAny,
    unsafeDeleteAny: $foreign.unsafeDeleteAny,
    forE: $foreign.forE,
    forEachE: $foreign.forEachE,
    forInE: $foreign.forInE,
    replicateE: $foreign.replicateE,
    diffWithIxE: $foreign.diffWithIxE,
    diffWithKeyAndIxE: $foreign.diffWithKeyAndIxE,
    strMapWithIxE: $foreign.strMapWithIxE,
    refEq: $foreign.refEq,
    createTextNode: $foreign.createTextNode,
    setTextContent: $foreign.setTextContent,
    createElement: $foreign.createElement,
    insertChildIx: $foreign.insertChildIx,
    removeChild: $foreign.removeChild,
    parentNode: $foreign.parentNode,
    setAttribute: $foreign.setAttribute,
    removeAttribute: $foreign.removeAttribute,
    hasAttribute: $foreign.hasAttribute,
    addEventListener: $foreign.addEventListener,
    removeEventListener: $foreign.removeEventListener,
    jsUndefined: $foreign.jsUndefined
};
