// Generated by purs version 0.14.3
"use strict";
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Hydra = require("../Hydra/index.js");
var Queue = function (x) {
    return x;
};
var showQueue = new Data_Show.Show(function (v) {
    var showBuf = function (v1) {
        return Data_Show.show(Hydra.showBuffer)(v1.value0) + (" :: " + Data_Show.show(Hydra.showTexture)(v1.value1));
    };
    var $11 = Data_Map_Internal.isEmpty(v);
    if ($11) {
        return "<<empty>>";
    };
    return Data_String_Common.joinWith("\x0a")(Data_Functor.map(Data_Functor.functorArray)(showBuf)(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v)));
});
var newtypeQueue_ = new Data_Newtype.Newtype(function () {
    return undefined;
});
var toUnfoldable = function (dictUnfoldable) {
    var $15 = Data_Map_Internal.toUnfoldable(dictUnfoldable);
    var $16 = Data_Newtype.unwrap();
    return function ($17) {
        return $15($16($17));
    };
};
var toBuffer = function (buf) {
    return function (tex) {
        return function (v) {
            return Queue(Data_Map_Internal.insert(Hydra.ordBuffer)(buf)(tex)(v));
        };
    };
};
var toDefault = toBuffer(Hydra.Default.value);
var textureAt = function (buf) {
    var $18 = Data_Map_Internal.lookup(Hydra.ordBuffer)(buf);
    var $19 = Data_Newtype.unwrap();
    return function ($20) {
        return $18($19($20));
    };
};
var isOwned = function (buf) {
    var $21 = Data_Map_Internal.member(Hydra.ordBuffer)(buf);
    var $22 = Data_Newtype.unwrap();
    return function ($23) {
        return $21($22($23));
    };
};
var isEmpty = (function () {
    var $24 = Data_Newtype.unwrap();
    return function ($25) {
        return Data_Map_Internal.isEmpty($24($25));
    };
})();
var fromFoldable = function (dictFoldable) {
    var $26 = Data_Map_Internal.fromFoldable(Hydra.ordBuffer)(dictFoldable);
    return function ($27) {
        return Queue($26($27));
    };
};
var empty = Data_Map_Internal.empty;
var just = Data_Function.flip(toDefault)(empty);
var atBuffer = function (buf) {
    return function (tex) {
        return toBuffer(buf)(tex)(empty);
    };
};
module.exports = {
    Queue: Queue,
    empty: empty,
    isEmpty: isEmpty,
    isOwned: isOwned,
    fromFoldable: fromFoldable,
    toUnfoldable: toUnfoldable,
    textureAt: textureAt,
    just: just,
    toDefault: toDefault,
    toBuffer: toBuffer,
    atBuffer: atBuffer,
    newtypeQueue_: newtypeQueue_,
    showQueue: showQueue
};
