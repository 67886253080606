// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Fn = require("../Hydra.Fn/index.js");
var tryV5M = function (v) {
    return function (fn) {
        return Hydra_Fn.tryFn5(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Hydra.toValue))(v)(function (v1) {
            return function (v2) {
                return function (v3) {
                    return function (v4) {
                        return function (v5) {
                            return Data_Maybe.Just.create(fn(v1)(v2)(v3)(v4)(v5));
                        };
                    };
                };
            };
        });
    };
};
var tryV5 = Hydra_Fn.tryFn5(Hydra.toValue);
var tryV4M = function (v) {
    return function (fn) {
        return Hydra_Fn.tryFn4(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Hydra.toValue))(v)(function (v1) {
            return function (v2) {
                return function (v3) {
                    return function (v4) {
                        return Data_Maybe.Just.create(fn(v1)(v2)(v3)(v4));
                    };
                };
            };
        });
    };
};
var tryV4 = Hydra_Fn.tryFn4(Hydra.toValue);
var tryV3M = function (v) {
    return function (fn) {
        return Hydra_Fn.tryFn3(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Hydra.toValue))(v)(function (v1) {
            return function (v2) {
                return function (v3) {
                    return Data_Maybe.Just.create(fn(v1)(v2)(v3));
                };
            };
        });
    };
};
var voronoi = tryV3M(new Data_Tuple.Tuple(new Hydra.Num(5.0), new Data_Tuple.Tuple(new Hydra.Num(0.3), new Hydra.Num(0.3))))(function (scale1) {
    return function (speed) {
        return function (blending) {
            return Hydra.Tex.create(Hydra.textureOf(new Hydra.Voronoi({
                scale: scale1,
                speed: speed,
                blending: blending
            })));
        };
    };
});
var tryV3 = Hydra_Fn.tryFn3(Hydra.toValue);
var tryV2M = function (v) {
    return function (fn) {
        return Hydra_Fn.tryFn2(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Hydra.toValue))(v)(function (v1) {
            return function (v2) {
                return Data_Maybe.Just.create(fn(v1)(v2));
            };
        });
    };
};
var tryV2 = Hydra_Fn.tryFn2(Hydra.toValue);
var tryV1M = function (v) {
    return function (fn) {
        return Hydra_Fn.tryFn1(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Hydra.toValue))(v)(function ($568) {
            return Data_Maybe.Just.create(fn($568));
        });
    };
};
var tryV1 = Hydra_Fn.tryFn1(Hydra.toValue);
var tryTV5M = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                return tryV5M(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1)))))(f(v1.value0.value0));
            };
            return tryV5M(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1)))))(f(v.value0));
        };
    };
};
var tryTV5 = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Hydra.Tex) {
                return tryV5(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1)))))(f(v1.value0));
            };
            return tryV5(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1)))))(f(v.value0));
        };
    };
};
var tryTV4M = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                return tryV4M(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1))))(f(v1.value0.value0));
            };
            return tryV4M(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1))))(f(v.value0));
        };
    };
};
var tryTV4 = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Hydra.Tex) {
                return tryV4(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1))))(f(v1.value0));
            };
            return tryV4(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1))))(f(v.value0));
        };
    };
};
var tryTV3M = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                return tryV3M(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1)))(f(v1.value0.value0));
            };
            return tryV3M(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1)))(f(v.value0));
        };
    };
};
var tryTV3 = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Hydra.Tex) {
                return tryV3(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1)))(f(v1.value0));
            };
            return tryV3(new Data_Tuple.Tuple(v.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1)))(f(v.value0));
        };
    };
};
var tryTV2M = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                return tryV2M(new Data_Tuple.Tuple(v.value1.value0, v.value1.value1))(f(v1.value0.value0));
            };
            return tryV2M(new Data_Tuple.Tuple(v.value1.value0, v.value1.value1))(f(v.value0));
        };
    };
};
var tryTV2 = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Hydra.Tex) {
                return tryV2(new Data_Tuple.Tuple(v.value1.value0, v.value1.value1))(f(v1.value0));
            };
            return tryV2(new Data_Tuple.Tuple(v.value1.value0, v.value1.value1))(f(v.value0));
        };
    };
};
var tryTV1M = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                return tryV1M(v.value1)(f(v1.value0.value0));
            };
            return tryV1M(v.value1)(f(v.value0));
        };
    };
};
var tryTV1 = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Hydra.Tex) {
                return tryV1(v.value1)(f(v1.value0));
            };
            return tryV1(v.value1)(f(v.value0));
        };
    };
};
var tryTTV4M = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Maybe.Just && (v1.value0 instanceof Hydra.Tex && (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex))) {
                    return tryV4M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v1.value0.value0)(v2.value0.value0));
                };
                if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                    return tryV4M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v1.value0.value0)(v.value1.value0));
                };
                if (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex) {
                    return tryV4M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v.value0)(v2.value0.value0));
                };
                return tryV4M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTV4 = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Hydra.Tex && v2 instanceof Hydra.Tex) {
                    return tryV4(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v1.value0)(v2.value0));
                };
                if (v1 instanceof Hydra.Tex) {
                    return tryV4(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v1.value0)(v.value1.value0));
                };
                if (v2 instanceof Hydra.Tex) {
                    return tryV4(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v.value0)(v2.value0));
                };
                return tryV4(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value1.value0, v.value1.value1.value1.value1.value1))))(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTV3M = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Maybe.Just && (v1.value0 instanceof Hydra.Tex && (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex))) {
                    return tryV3M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v1.value0.value0)(v2.value0.value0));
                };
                if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                    return tryV3M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v1.value0.value0)(v.value1.value0));
                };
                if (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex) {
                    return tryV3M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v.value0)(v2.value0.value0));
                };
                return tryV3M(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTV3 = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Hydra.Tex && v2 instanceof Hydra.Tex) {
                    return tryV3(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v1.value0)(v2.value0));
                };
                if (v1 instanceof Hydra.Tex) {
                    return tryV3(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v1.value0)(v.value1.value0));
                };
                if (v2 instanceof Hydra.Tex) {
                    return tryV3(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v.value0)(v2.value0));
                };
                return tryV3(new Data_Tuple.Tuple(v.value1.value1.value0, new Data_Tuple.Tuple(v.value1.value1.value1.value0, v.value1.value1.value1.value1)))(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTV2M = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Maybe.Just && (v1.value0 instanceof Hydra.Tex && (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex))) {
                    return tryV2M(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v1.value0.value0)(v2.value0.value0));
                };
                if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                    return tryV2M(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v1.value0.value0)(v.value1.value0));
                };
                if (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex) {
                    return tryV2M(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v.value0)(v2.value0.value0));
                };
                return tryV2M(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTV2 = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Hydra.Tex && v2 instanceof Hydra.Tex) {
                    return tryV2(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v1.value0)(v2.value0));
                };
                if (v1 instanceof Hydra.Tex) {
                    return tryV2(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v1.value0)(v.value1.value0));
                };
                if (v2 instanceof Hydra.Tex) {
                    return tryV2(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v.value0)(v2.value0));
                };
                return tryV2(new Data_Tuple.Tuple(v.value1.value1.value0, v.value1.value1.value1))(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTV1M = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Maybe.Just && (v1.value0 instanceof Hydra.Tex && (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex))) {
                    return tryV1M(v.value1.value1)(f(v1.value0.value0)(v2.value0.value0));
                };
                if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                    return tryV1M(v.value1.value1)(f(v1.value0.value0)(v.value1.value0));
                };
                if (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex) {
                    return tryV1M(v.value1.value1)(f(v.value0)(v2.value0.value0));
                };
                return tryV1M(v.value1.value1)(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTV1 = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Hydra.Tex && v2 instanceof Hydra.Tex) {
                    return tryV1(v.value1.value1)(f(v1.value0)(v2.value0));
                };
                if (v1 instanceof Hydra.Tex) {
                    return tryV1(v.value1.value1)(f(v1.value0)(v.value1.value0));
                };
                if (v2 instanceof Hydra.Tex) {
                    return tryV1(v.value1.value1)(f(v.value0)(v2.value0));
                };
                return tryV1(v.value1.value1)(f(v.value0)(v.value1.value0));
            };
        };
    };
};
var tryTTM = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Maybe.Just && (v1.value0 instanceof Hydra.Tex && (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex))) {
                    return Data_Maybe.Just.create(f(v1.value0.value0)(v2.value0.value0));
                };
                if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                    return Data_Maybe.Just.create(f(v1.value0.value0)(v.value1));
                };
                if (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Tex) {
                    return Data_Maybe.Just.create(f(v.value0)(v2.value0.value0));
                };
                return Data_Maybe.Just.create(f(v.value0)(v.value1));
            };
        };
    };
};
var tryTT = function (v) {
    return function (f) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Hydra.Tex && v2 instanceof Hydra.Tex) {
                    return f(v1.value0)(v2.value0);
                };
                if (v1 instanceof Hydra.Tex) {
                    return f(v1.value0)(v.value1);
                };
                if (v2 instanceof Hydra.Tex) {
                    return f(v.value0)(v2.value0);
                };
                return f(v.value0)(v.value1);
            };
        };
    };
};
var tryTM = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Tex) {
                return Data_Maybe.Just.create(f(v1.value0.value0));
            };
            return Data_Maybe.Just.create(f(v));
        };
    };
};
var tryT = function (v) {
    return function (f) {
        return function (v1) {
            if (v1 instanceof Hydra.Tex) {
                return f(v1.value0);
            };
            return f(v);
        };
    };
};
var thresh = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(4.0e-2))))(function (what) {
    return function (threshold) {
        return function (tolerance) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Tresh({
                threshold: threshold,
                tolerance: tolerance
            }))));
        };
    };
});
var solid = tryV4M(new Data_Tuple.Tuple(new Hydra.Num(0.0), new Data_Tuple.Tuple(new Hydra.Num(0.0), new Data_Tuple.Tuple(new Hydra.Num(0.0), new Hydra.Num(1.0)))))(function (r) {
    return function (g) {
        return function (b) {
            return function (a) {
                return Hydra.Tex.create(Hydra.textureOf(new Hydra.Solid({
                    r: r,
                    g: g,
                    b: b,
                    a: a
                })));
            };
        };
    };
});
var shift = tryTV4M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.5), new Data_Tuple.Tuple(new Hydra.Num(0.5), new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.5))))))(function (what) {
    return function (r) {
        return function (g) {
            return function (b) {
                return function (a) {
                    return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Shift({
                        r: r,
                        g: g,
                        b: b,
                        a: a
                    }))));
                };
            };
        };
    };
});
var shape = tryV3M(new Data_Tuple.Tuple(new Hydra.Num(3.0), new Data_Tuple.Tuple(new Hydra.Num(0.3), new Hydra.Num(1.0e-2))))(function (sides) {
    return function (radius) {
        return function (smoothing) {
            return Hydra.Tex.create(Hydra.textureOf(new Hydra.Shape({
                sides: sides,
                radius: radius,
                smoothing: smoothing
            })));
        };
    };
});
var scrollY = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.0))))(function (what) {
    return function (scrollY1) {
        return function (speed) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.ScrollY({
                scrollY: scrollY1,
                speed: speed
            }))));
        };
    };
});
var scrollX = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.0))))(function (what) {
    return function (scrollX1) {
        return function (speed) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.ScrollX({
                scrollX: scrollX1,
                speed: speed
            }))));
        };
    };
});
var scale = tryTV5M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(1.5), new Data_Tuple.Tuple(new Hydra.Num(1.0), new Data_Tuple.Tuple(new Hydra.Num(1.0), new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.5)))))))(function (what) {
    return function (amount) {
        return function (xMult) {
            return function (yMult) {
                return function (offsetX) {
                    return function (offsetY) {
                        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.Scale({
                            amount: amount,
                            xMult: xMult,
                            yMult: yMult,
                            offsetX: offsetX,
                            offsetY: offsetY
                        }))));
                    };
                };
            };
        };
    };
});
var saturate = tryTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(1.0)))(function (what) {
    return function (amount) {
        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Saturate({
            amount: amount
        }))));
    };
});
var rotate = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(10.0), new Hydra.Num(0.0))))(function (what) {
    return function (angle) {
        return function (speed) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.Rotate({
                angle: angle,
                speed: speed
            }))));
        };
    };
});
var repeatY = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Hydra.Num(0.0))))(function (what) {
    return function (reps) {
        return function (offset) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.RepeatY({
                reps: reps,
                offset: offset
            }))));
        };
    };
});
var repeatX = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Hydra.Num(0.0))))(function (what) {
    return function (reps) {
        return function (offset) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.RepeatX({
                reps: reps,
                offset: offset
            }))));
        };
    };
});
var repeat = tryTV4M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Data_Tuple.Tuple(new Hydra.Num(3.0), new Data_Tuple.Tuple(new Hydra.Num(0.0), new Hydra.Num(0.0))))))(function (what) {
    return function (repeatX1) {
        return function (repeatY1) {
            return function (offsetX) {
                return function (offsetY) {
                    return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.Repeat({
                        repeatX: repeatX1,
                        repeatY: repeatY1,
                        offsetX: offsetX,
                        offsetY: offsetY
                    }))));
                };
            };
        };
    };
});
var posterize = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Hydra.Num(0.6))))(function (what) {
    return function (bins) {
        return function (gamma) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Posterize({
                bins: bins,
                gamma: gamma
            }))));
        };
    };
});
var pixelate = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(20.0), new Hydra.Num(20.0))))(function (what) {
    return function (pixelX) {
        return function (pixelY) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.Pixelate({
                pixelX: pixelX,
                pixelY: pixelY
            }))));
        };
    };
});
var osc = tryV3M(new Data_Tuple.Tuple(new Hydra.Num(60.0), new Data_Tuple.Tuple(new Hydra.Num(0.1), new Hydra.Num(0.0))))(function (freq) {
    return function (sync) {
        return function (offset) {
            return Hydra.Tex.create(Hydra.textureOf(new Hydra.Osc({
                freq: freq,
                sync: sync,
                offset: offset
            })));
        };
    };
});
var noise = tryV2M(new Data_Tuple.Tuple(new Hydra.Num(10.0), new Hydra.Num(0.1)))(function (scale1) {
    return function (offset) {
        return Hydra.Tex.create(Hydra.textureOf(new Hydra.Noise({
            scale: scale1,
            offset: offset
        })));
    };
});
var mult = tryTTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(0.5))))(function (trg) {
    return function (src) {
        return function (amount) {
            return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.blend(new Hydra.Mult({
                what: src,
                amount: amount
            }))));
        };
    };
});
var modulate = tryTTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(0.1))))(function (trg) {
    return function (src) {
        return function (amount) {
            return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.Modulate({
                what: src,
                amount: amount
            }))));
        };
    };
});
var modScrollY = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.0)))))(function (trg) {
    return function (src) {
        return function (scrollY1) {
            return function (speed) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateScrollY({
                    what: src,
                    scrollY: scrollY1,
                    speed: speed
                }))));
            };
        };
    };
});
var modScrollX = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.0)))))(function (trg) {
    return function (src) {
        return function (scrollX1) {
            return function (speed) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateScrollX({
                    what: src,
                    scrollX: scrollX1,
                    speed: speed
                }))));
            };
        };
    };
});
var modScale = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(1.0), new Hydra.Num(1.0)))))(function (trg) {
    return function (src) {
        return function (multiple) {
            return function (offset) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateScale({
                    what: src,
                    multiple: multiple,
                    offset: offset
                }))));
            };
        };
    };
});
var modRotate = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(1.0), new Hydra.Num(0.0)))))(function (trg) {
    return function (src) {
        return function (multiple) {
            return function (offset) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateRotate({
                    what: src,
                    multiple: multiple,
                    offset: offset
                }))));
            };
        };
    };
});
var modRepeatY = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Hydra.Num(0.5)))))(function (trg) {
    return function (src) {
        return function (reps) {
            return function (offset) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateRepeatY({
                    what: src,
                    reps: reps,
                    offset: offset
                }))));
            };
        };
    };
});
var modRepeatX = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Hydra.Num(0.5)))))(function (trg) {
    return function (src) {
        return function (reps) {
            return function (offset) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateRepeatX({
                    what: src,
                    reps: reps,
                    offset: offset
                }))));
            };
        };
    };
});
var modRepeat = tryTTV4M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Data_Tuple.Tuple(new Hydra.Num(3.0), new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.5)))))))(function (trg) {
    return function (src) {
        return function (repeatX1) {
            return function (repeatY1) {
                return function (offsetX) {
                    return function (offsetY) {
                        return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateRepeat({
                            what: src,
                            repeatX: repeatX1,
                            repeatY: repeatY1,
                            offsetX: offsetX,
                            offsetY: offsetY
                        }))));
                    };
                };
            };
        };
    };
});
var modPixelate = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(10.0), new Hydra.Num(3.0)))))(function (trg) {
    return function (src) {
        return function (multiple) {
            return function (offset) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulatePixelate({
                    what: src,
                    multiple: multiple,
                    offset: offset
                }))));
            };
        };
    };
});
var modKaleid = tryTTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(4.0))))(function (trg) {
    return function (src) {
        return function (nSides) {
            return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateKaleid({
                what: src,
                nSides: nSides
            }))));
        };
    };
});
var modHue = tryTTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(1.0))))(function (trg) {
    return function (src) {
        return function (amount) {
            return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.modulate(new Hydra.ModulateHue({
                what: src,
                amount: amount
            }))));
        };
    };
});
var mask = tryTTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(3.0), new Hydra.Num(0.5)))))(function (trg) {
    return function (src) {
        return function (reps) {
            return function (offset) {
                return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.blend(new Hydra.Mask({
                    what: src,
                    reps: reps,
                    offset: offset
                }))));
            };
        };
    };
});
var luma = tryTV2M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.5), new Hydra.Num(0.1))))(function (what) {
    return function (threshold) {
        return function (tolerance) {
            return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Luma({
                threshold: threshold,
                tolerance: tolerance
            }))));
        };
    };
});
var layer = tryTTM(new Data_Tuple.Tuple(Hydra.defaultTexture, Hydra.defaultTexture))(function (trg) {
    return function (src) {
        return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.blend(new Hydra.Layer({
            what: src
        }))));
    };
});
var kaleid = tryTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(4.0)))(function (what) {
    return function (nSides) {
        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.geometry(new Hydra.Kaleid({
            nSides: nSides
        }))));
    };
});
var invert = tryTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(1.0)))(function (what) {
    return function (amount) {
        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Invert({
            amount: amount
        }))));
    };
});
var hue = tryTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(1.0)))(function (what) {
    return function (amount) {
        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Hue({
            amount: amount
        }))));
    };
});
var gradient = tryV1M(Hydra.CanvasWidth.value)(function (speed) {
    return Hydra.Tex.create(Hydra.textureOf(new Hydra.Gradient({
        speed: speed
    })));
});
var diff = tryTTM(new Data_Tuple.Tuple(Hydra.defaultTexture, Hydra.defaultTexture))(function (trg) {
    return function (src) {
        return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.blend(new Hydra.Diff({
            what: src
        }))));
    };
});
var contrast = tryTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(1.0)))(function (what) {
    return function (amount) {
        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Contrast({
            amount: amount
        }))));
    };
});
var colorama = tryTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(1.0)))(function (what) {
    return function (amount) {
        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Colorama({
            amount: amount
        }))));
    };
});
var color = tryTV4M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(new Hydra.Num(0.0), new Data_Tuple.Tuple(new Hydra.Num(0.0), new Data_Tuple.Tuple(new Hydra.Num(0.0), new Hydra.Num(1.0))))))(function (what) {
    return function (r) {
        return function (g) {
            return function (b) {
                return function (a) {
                    return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Color({
                        r: r,
                        g: g,
                        b: b,
                        a: a
                    }))));
                };
            };
        };
    };
});
var brightness = tryTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(1.0)))(function (what) {
    return function (amount) {
        return Hydra.Tex.create(Hydra.addModifier(what)(Hydra.color(new Hydra.Brightness({
            amount: amount
        }))));
    };
});
var blend = tryTTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(0.5))))(function (trg) {
    return function (src) {
        return function (amount) {
            return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.blend(new Hydra.Blend({
                what: src,
                amount: amount
            }))));
        };
    };
});
var add = tryTTV1M(new Data_Tuple.Tuple(Hydra.defaultTexture, new Data_Tuple.Tuple(Hydra.defaultTexture, new Hydra.Num(0.5))))(function (trg) {
    return function (src) {
        return function (amount) {
            return Hydra.Tex.create(Hydra.addModifier(trg)(Hydra.blend(new Hydra.Add({
                what: src,
                amount: amount
            }))));
        };
    };
});
module.exports = {
    noise: noise,
    voronoi: voronoi,
    osc: osc,
    shape: shape,
    gradient: gradient,
    solid: solid,
    rotate: rotate,
    scale: scale,
    pixelate: pixelate,
    repeat: repeat,
    repeatX: repeatX,
    repeatY: repeatY,
    kaleid: kaleid,
    scrollX: scrollX,
    scrollY: scrollY,
    posterize: posterize,
    shift: shift,
    invert: invert,
    contrast: contrast,
    brightness: brightness,
    luma: luma,
    thresh: thresh,
    color: color,
    saturate: saturate,
    hue: hue,
    colorama: colorama,
    add: add,
    layer: layer,
    blend: blend,
    mult: mult,
    diff: diff,
    mask: mask,
    modRepeat: modRepeat,
    modRepeatX: modRepeatX,
    modRepeatY: modRepeatY,
    modKaleid: modKaleid,
    modScrollX: modScrollX,
    modScrollY: modScrollY,
    modulate: modulate,
    modScale: modScale,
    modPixelate: modPixelate,
    modRotate: modRotate,
    modHue: modHue,
    tryV1: tryV1,
    tryV1M: tryV1M,
    tryV2: tryV2,
    tryV2M: tryV2M,
    tryV3: tryV3,
    tryV3M: tryV3M,
    tryV4: tryV4,
    tryV4M: tryV4M,
    tryV5: tryV5,
    tryV5M: tryV5M,
    tryT: tryT,
    tryTV1: tryTV1,
    tryTV2: tryTV2,
    tryTV3: tryTV3,
    tryTV4: tryTV4,
    tryTV5: tryTV5,
    tryTM: tryTM,
    tryTV1M: tryTV1M,
    tryTV2M: tryTV2M,
    tryTV3M: tryTV3M,
    tryTV4M: tryTV4M,
    tryTV5M: tryTV5M,
    tryTT: tryTT,
    tryTTV1: tryTTV1,
    tryTTV2: tryTTV2,
    tryTTV3: tryTTV3,
    tryTTV4: tryTTV4,
    tryTTM: tryTTM,
    tryTTV1M: tryTTV1M,
    tryTTV2M: tryTTV2M,
    tryTTV3M: tryTTV3M,
    tryTTV4M: tryTTV4M
};
