// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Signal = require("../Signal/index.js");
var send = $foreign.sendP;
var channel = $foreign.channelP(Signal.constant);
module.exports = {
    channel: channel,
    send: send,
    subscribe: $foreign.subscribe
};
