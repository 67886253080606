// Generated by purs version 0.14.3
"use strict";
var Store = (function () {
    function Store(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Store.create = function (value0) {
        return function (value1) {
            return new Store(value0, value1);
        };
    };
    return Store;
})();
module.exports = {
    Store: Store
};
