// Generated by purs version 0.14.3
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Ops = require("../Data.Typelevel.Num.Ops/index.js");
var Data_Typelevel_Num_Reps = require("../Data.Typelevel.Num.Reps/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Arg0 = (function () {
    function Arg0(value0) {
        this.value0 = value0;
    };
    Arg0.create = function (value0) {
        return new Arg0(value0);
    };
    return Arg0;
})();
var Arg1 = (function () {
    function Arg1(value0) {
        this.value0 = value0;
    };
    Arg1.create = function (value0) {
        return new Arg1(value0);
    };
    return Arg1;
})();
var Arg2 = (function () {
    function Arg2(value0) {
        this.value0 = value0;
    };
    Arg2.create = function (value0) {
        return new Arg2(value0);
    };
    return Arg2;
})();
var Arg3 = (function () {
    function Arg3(value0) {
        this.value0 = value0;
    };
    Arg3.create = function (value0) {
        return new Arg3(value0);
    };
    return Arg3;
})();
var Arg4 = (function () {
    function Arg4(value0) {
        this.value0 = value0;
    };
    Arg4.create = function (value0) {
        return new Arg4(value0);
    };
    return Arg4;
})();
var Arg5 = (function () {
    function Arg5(value0) {
        this.value0 = value0;
    };
    Arg5.create = function (value0) {
        return new Arg5(value0);
    };
    return Arg5;
})();
var Arg6 = (function () {
    function Arg6(value0) {
        this.value0 = value0;
    };
    Arg6.create = function (value0) {
        return new Arg6(value0);
    };
    return Arg6;
})();
var Unknown = (function () {
    function Unknown() {

    };
    Unknown.value = new Unknown();
    return Unknown;
})();
var Fn = function (x) {
    return x;
};
var ToFn = function (toFn) {
    this.toFn = toFn;
};
var tryFn6$prime = function (toX) {
    return function (defaults) {
        return function (fnToZ) {
            return function (p1) {
                return function (p2) {
                    return function (p3) {
                        return function (p4) {
                            return function (p5) {
                                return function (p6) {
                                    return fnToZ(Data_Vec.zipWithE(Data_Function.flip(Data_Maybe.fromMaybe))(Data_Functor.map(Data_Vec.functorVec)(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD6)()(Data_Typelevel_Num_Ops.divMod10D5D0)()(Data_Typelevel_Num_Ops.divMod10D6D0))(p1)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD5)()(Data_Typelevel_Num_Ops.divMod10D4D0)()(Data_Typelevel_Num_Ops.divMod10D5D0))(p2)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD4)()(Data_Typelevel_Num_Ops.divMod10D3D0)()(Data_Typelevel_Num_Ops.divMod10D4D0))(p3)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(p4)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(p5)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(p6)(Data_Vec.empty))))))))(defaults));
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var tryFn5$prime = function (toX) {
    return function (defaults) {
        return function (fnToZ) {
            return function (p1) {
                return function (p2) {
                    return function (p3) {
                        return function (p4) {
                            return function (p5) {
                                return fnToZ(Data_Vec.zipWithE(Data_Function.flip(Data_Maybe.fromMaybe))(Data_Functor.map(Data_Vec.functorVec)(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD5)()(Data_Typelevel_Num_Ops.divMod10D4D0)()(Data_Typelevel_Num_Ops.divMod10D5D0))(p1)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD4)()(Data_Typelevel_Num_Ops.divMod10D3D0)()(Data_Typelevel_Num_Ops.divMod10D4D0))(p2)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(p3)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(p4)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(p5)(Data_Vec.empty)))))))(defaults));
                            };
                        };
                    };
                };
            };
        };
    };
};
var tryFn4$prime = function (toX) {
    return function (defaults) {
        return function (fnToZ) {
            return function (p1) {
                return function (p2) {
                    return function (p3) {
                        return function (p4) {
                            return fnToZ(Data_Vec.zipWithE(Data_Function.flip(Data_Maybe.fromMaybe))(Data_Functor.map(Data_Vec.functorVec)(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD4)()(Data_Typelevel_Num_Ops.divMod10D3D0)()(Data_Typelevel_Num_Ops.divMod10D4D0))(p1)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(p2)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(p3)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(p4)(Data_Vec.empty))))))(defaults));
                        };
                    };
                };
            };
        };
    };
};
var tryFn3$prime = function (toX) {
    return function (defaults) {
        return function (fnToZ) {
            return function (p1) {
                return function (p2) {
                    return function (p3) {
                        return fnToZ(Data_Vec.zipWithE(Data_Function.flip(Data_Maybe.fromMaybe))(Data_Functor.map(Data_Vec.functorVec)(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(p1)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(p2)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(p3)(Data_Vec.empty)))))(defaults));
                    };
                };
            };
        };
    };
};
var tryFn2$prime = function (toX) {
    return function (defaults) {
        return function (fnToZ) {
            return function (p1) {
                return function (p2) {
                    return fnToZ(Data_Vec.zipWithE(Data_Function.flip(Data_Maybe.fromMaybe))(Data_Functor.map(Data_Vec.functorVec)(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(p1)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(p2)(Data_Vec.empty))))(defaults));
                };
            };
        };
    };
};
var tryFn1$prime = function (toX) {
    return function (defaults) {
        return function (fnToZ) {
            return function (p1) {
                return fnToZ(Data_Vec.zipWithE(Data_Function.flip(Data_Maybe.fromMaybe))(Data_Functor.map(Data_Vec.functorVec)(toX)(Data_Vec.singleton(p1)))(defaults));
            };
        };
    };
};
var toFn = function (dict) {
    return dict.toFn;
};
var getName = function (v) {
    return v.name;
};
var functorArgs = new Data_Functor.Functor(function (v) {
    return function (v1) {
        if (v1 instanceof Arg0) {
            return Arg0.create(Data_Functor.map(Data_Vec.functorVec)(v)(v1.value0));
        };
        if (v1 instanceof Arg1) {
            return Arg1.create(Data_Functor.map(Data_Vec.functorVec)(v)(v1.value0));
        };
        if (v1 instanceof Arg2) {
            return Arg2.create(Data_Functor.map(Data_Vec.functorVec)(v)(v1.value0));
        };
        if (v1 instanceof Arg3) {
            return Arg3.create(Data_Functor.map(Data_Vec.functorVec)(v)(v1.value0));
        };
        if (v1 instanceof Arg4) {
            return Arg4.create(Data_Functor.map(Data_Vec.functorVec)(v)(v1.value0));
        };
        if (v1 instanceof Arg5) {
            return Arg5.create(Data_Functor.map(Data_Vec.functorVec)(v)(v1.value0));
        };
        if (v1 instanceof Arg6) {
            return Arg6.create(Data_Functor.map(Data_Vec.functorVec)(v)(v1.value0));
        };
        if (v1 instanceof Unknown) {
            return Unknown.value;
        };
        throw new Error("Failed pattern match at Hydra.Fn (line 34, column 1 - line 42, column 28): " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var fnFunctor = new Data_Functor.Functor(function (f) {
    return function (v) {
        return {
            name: v.name,
            args: Data_Functor.map(functorArgs)(Data_Functor.map(Data_Tuple.functorTuple)(f))(v.args)
        };
    };
});
var arrayToArgs = function (arr) {
    var helper = function (v) {
        if (v === 0) {
            return Data_Maybe.fromMaybe(Unknown.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Arg0.create)(Data_Vec.fromArray(Data_Typelevel_Num_Sets.natD0)(arr)));
        };
        if (v === 1) {
            return Data_Maybe.fromMaybe(Unknown.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Arg1.create)(Data_Vec.fromArray(Data_Typelevel_Num_Sets.natD1)(arr)));
        };
        if (v === 2) {
            return Data_Maybe.fromMaybe(Unknown.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Arg2.create)(Data_Vec.fromArray(Data_Typelevel_Num_Sets.natD2)(arr)));
        };
        if (v === 3) {
            return Data_Maybe.fromMaybe(Unknown.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Arg3.create)(Data_Vec.fromArray(Data_Typelevel_Num_Sets.natD3)(arr)));
        };
        if (v === 4) {
            return Data_Maybe.fromMaybe(Unknown.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Arg4.create)(Data_Vec.fromArray(Data_Typelevel_Num_Sets.natD4)(arr)));
        };
        if (v === 5) {
            return Data_Maybe.fromMaybe(Unknown.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Arg5.create)(Data_Vec.fromArray(Data_Typelevel_Num_Sets.natD5)(arr)));
        };
        if (v === 6) {
            return Data_Maybe.fromMaybe(Unknown.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Arg6.create)(Data_Vec.fromArray(Data_Typelevel_Num_Sets.natD6)(arr)));
        };
        return Unknown.value;
    };
    return helper(Data_Array.length(arr));
};
var fn = function (name) {
    return function (args) {
        return {
            name: name,
            args: arrayToArgs(args)
        };
    };
};
var argsToArray = function (v) {
    if (v instanceof Arg0) {
        return Data_Vec.toArray(v.value0);
    };
    if (v instanceof Arg1) {
        return Data_Vec.toArray(v.value0);
    };
    if (v instanceof Arg2) {
        return Data_Vec.toArray(v.value0);
    };
    if (v instanceof Arg3) {
        return Data_Vec.toArray(v.value0);
    };
    if (v instanceof Arg4) {
        return Data_Vec.toArray(v.value0);
    };
    if (v instanceof Arg5) {
        return Data_Vec.toArray(v.value0);
    };
    if (v instanceof Arg6) {
        return Data_Vec.toArray(v.value0);
    };
    if (v instanceof Unknown) {
        return [  ];
    };
    throw new Error("Failed pattern match at Hydra.Fn (line 99, column 1 - line 99, column 43): " + [ v.constructor.name ]);
};
var getArgs = function (v) {
    return argsToArray(v.args);
};
var argsShowTpl = function (dictShow) {
    return new Data_Show.Show(function (args) {
        var showArg = function (v) {
            return "(" + (v.value0 + (" " + (Data_Show.show(dictShow)(v.value1) + ")")));
        };
        return Data_String_Common.joinWith(" ")(Data_Functor.map(Data_Functor.functorArray)(showArg)(argsToArray(args)));
    });
};
var fnShow = function (dictShow) {
    return new Data_Show.Show(function (v) {
        return v.name + (" " + Data_Show.show(argsShowTpl(dictShow))(v.args));
    });
};
var showToFn = function (dictShow) {
    return function (dictToFn) {
        return function (a) {
            return Data_Show.show(fnShow(dictShow))(toFn(dictToFn)(a));
        };
    };
};
var applyVec6 = function (f) {
    return function (v) {
        return f(Data_Vec.index(Data_Typelevel_Num_Sets.natD0)()(v)(Data_Typelevel_Num_Reps.d0))(Data_Vec.index(Data_Typelevel_Num_Sets.natD1)()(v)(Data_Typelevel_Num_Reps.d1))(Data_Vec.index(Data_Typelevel_Num_Sets.natD2)()(v)(Data_Typelevel_Num_Reps.d2))(Data_Vec.index(Data_Typelevel_Num_Sets.natD3)()(v)(Data_Typelevel_Num_Reps.d3))(Data_Vec.index(Data_Typelevel_Num_Sets.natD4)()(v)(Data_Typelevel_Num_Reps.d4))(Data_Vec.index(Data_Typelevel_Num_Sets.natD5)()(v)(Data_Typelevel_Num_Reps.d5));
    };
};
var tryFn6 = function (toX) {
    return function (v) {
        return function (fnToZ) {
            return tryFn6$prime(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD6)()(Data_Typelevel_Num_Ops.divMod10D5D0)()(Data_Typelevel_Num_Ops.divMod10D6D0))(v.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD5)()(Data_Typelevel_Num_Ops.divMod10D4D0)()(Data_Typelevel_Num_Ops.divMod10D5D0))(v.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD4)()(Data_Typelevel_Num_Ops.divMod10D3D0)()(Data_Typelevel_Num_Ops.divMod10D4D0))(v.value1.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(v.value1.value1.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(v.value1.value1.value1.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(v.value1.value1.value1.value1.value1)(Data_Vec.empty)))))))(applyVec6(fnToZ));
        };
    };
};
var applyVec5 = function (f) {
    return function (v) {
        return f(Data_Vec.index(Data_Typelevel_Num_Sets.natD0)()(v)(Data_Typelevel_Num_Reps.d0))(Data_Vec.index(Data_Typelevel_Num_Sets.natD1)()(v)(Data_Typelevel_Num_Reps.d1))(Data_Vec.index(Data_Typelevel_Num_Sets.natD2)()(v)(Data_Typelevel_Num_Reps.d2))(Data_Vec.index(Data_Typelevel_Num_Sets.natD3)()(v)(Data_Typelevel_Num_Reps.d3))(Data_Vec.index(Data_Typelevel_Num_Sets.natD4)()(v)(Data_Typelevel_Num_Reps.d4));
    };
};
var tryFn5 = function (toX) {
    return function (v) {
        return function (fnToZ) {
            return tryFn5$prime(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD5)()(Data_Typelevel_Num_Ops.divMod10D4D0)()(Data_Typelevel_Num_Ops.divMod10D5D0))(v.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD4)()(Data_Typelevel_Num_Ops.divMod10D3D0)()(Data_Typelevel_Num_Ops.divMod10D4D0))(v.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(v.value1.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(v.value1.value1.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(v.value1.value1.value1.value1)(Data_Vec.empty))))))(applyVec5(fnToZ));
        };
    };
};
var applyVec4 = function (f) {
    return function (v) {
        return f(Data_Vec.index(Data_Typelevel_Num_Sets.natD0)()(v)(Data_Typelevel_Num_Reps.d0))(Data_Vec.index(Data_Typelevel_Num_Sets.natD1)()(v)(Data_Typelevel_Num_Reps.d1))(Data_Vec.index(Data_Typelevel_Num_Sets.natD2)()(v)(Data_Typelevel_Num_Reps.d2))(Data_Vec.index(Data_Typelevel_Num_Sets.natD3)()(v)(Data_Typelevel_Num_Reps.d3));
    };
};
var tryFn4 = function (toX) {
    return function (v) {
        return function (fnToZ) {
            return tryFn4$prime(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD4)()(Data_Typelevel_Num_Ops.divMod10D3D0)()(Data_Typelevel_Num_Ops.divMod10D4D0))(v.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(v.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(v.value1.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(v.value1.value1.value1)(Data_Vec.empty)))))(applyVec4(fnToZ));
        };
    };
};
var applyVec3 = function (f) {
    return function (v) {
        return f(Data_Vec.index(Data_Typelevel_Num_Sets.natD0)()(v)(Data_Typelevel_Num_Reps.d0))(Data_Vec.index(Data_Typelevel_Num_Sets.natD1)()(v)(Data_Typelevel_Num_Reps.d1))(Data_Vec.index(Data_Typelevel_Num_Sets.natD2)()(v)(Data_Typelevel_Num_Reps.d2));
    };
};
var tryFn3 = function (toX) {
    return function (v) {
        return function (fnToZ) {
            return tryFn3$prime(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD3)()(Data_Typelevel_Num_Ops.divMod10D2D0)()(Data_Typelevel_Num_Ops.divMod10D3D0))(v.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(v.value1.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(v.value1.value1)(Data_Vec.empty))))(applyVec3(fnToZ));
        };
    };
};
var applyVec2 = function (f) {
    return function (v) {
        return f(Data_Vec.index(Data_Typelevel_Num_Sets.natD0)()(v)(Data_Typelevel_Num_Reps.d0))(Data_Vec.index(Data_Typelevel_Num_Sets.natD1)()(v)(Data_Typelevel_Num_Reps.d1));
    };
};
var tryFn2 = function (toX) {
    return function (v) {
        return function (fnToZ) {
            return tryFn2$prime(toX)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(v.value0)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(v.value1)(Data_Vec.empty)))(applyVec2(fnToZ));
        };
    };
};
var applyVec1 = function (f) {
    return function (v) {
        return f(Data_Vec.index(Data_Typelevel_Num_Sets.natD0)()(v)(Data_Typelevel_Num_Reps.d0));
    };
};
var tryFn1 = function (toX) {
    return function (v1) {
        return function (fnToZ) {
            return tryFn1$prime(toX)(Data_Vec.singleton(v1))(applyVec1(fnToZ));
        };
    };
};
var applyVec0 = function (z) {
    return function (v) {
        return z;
    };
};
var applyArgs6 = function (v) {
    return function (v1) {
        if (v1 instanceof Arg6) {
            return Data_Maybe.Just.create(applyVec6(v)(v1.value0));
        };
        return Data_Maybe.Nothing.value;
    };
};
var applyFn6 = function (f) {
    return function (v) {
        return applyArgs6(f)(Data_Functor.map(functorArgs)(Data_Tuple.snd)(v.args));
    };
};
var applyArgs5 = function (v) {
    return function (v1) {
        if (v1 instanceof Arg5) {
            return Data_Maybe.Just.create(applyVec5(v)(v1.value0));
        };
        return Data_Maybe.Nothing.value;
    };
};
var applyFn5 = function (f) {
    return function (v) {
        return applyArgs5(f)(Data_Functor.map(functorArgs)(Data_Tuple.snd)(v.args));
    };
};
var applyArgs4 = function (v) {
    return function (v1) {
        if (v1 instanceof Arg4) {
            return Data_Maybe.Just.create(applyVec4(v)(v1.value0));
        };
        return Data_Maybe.Nothing.value;
    };
};
var applyFn4 = function (f) {
    return function (v) {
        return applyArgs4(f)(Data_Functor.map(functorArgs)(Data_Tuple.snd)(v.args));
    };
};
var applyArgs3 = function (v) {
    return function (v1) {
        if (v1 instanceof Arg3) {
            return Data_Maybe.Just.create(applyVec3(v)(v1.value0));
        };
        return Data_Maybe.Nothing.value;
    };
};
var applyFn3 = function (f) {
    return function (v) {
        return applyArgs3(f)(Data_Functor.map(functorArgs)(Data_Tuple.snd)(v.args));
    };
};
var applyArgs2 = function (v) {
    return function (v1) {
        if (v1 instanceof Arg2) {
            return Data_Maybe.Just.create(applyVec2(v)(v1.value0));
        };
        return Data_Maybe.Nothing.value;
    };
};
var applyFn2 = function (f) {
    return function (v) {
        return applyArgs2(f)(Data_Functor.map(functorArgs)(Data_Tuple.snd)(v.args));
    };
};
var applyArgs1 = function (v) {
    return function (v1) {
        if (v1 instanceof Arg1) {
            return Data_Maybe.Just.create(applyVec1(v)(v1.value0));
        };
        return Data_Maybe.Nothing.value;
    };
};
var applyFn1 = function (f) {
    return function (v) {
        return applyArgs1(f)(Data_Functor.map(functorArgs)(Data_Tuple.snd)(v.args));
    };
};
var applyArgs0 = function (v) {
    return function (v1) {
        if (v1 instanceof Arg0) {
            return Data_Maybe.Just.create(applyVec0(v)(v1.value0));
        };
        return Data_Maybe.Nothing.value;
    };
};
var applyFn0 = function (v) {
    return function (v1) {
        return applyArgs0(v)(Data_Functor.map(functorArgs)(Data_Tuple.snd)(v1.args));
    };
};
var addNames = function (v) {
    var h1 = function (v1) {
        return new Data_Tuple.Tuple(v1.value0, new Data_Tuple.Tuple(v1.value0, v1.value1));
    };
    return {
        name: v.name,
        args: arrayToArgs(Data_Functor.map(Data_Functor.functorArray)(h1)(argsToArray(v.args)))
    };
};
module.exports = {
    toFn: toFn,
    Arg0: Arg0,
    Arg1: Arg1,
    Arg2: Arg2,
    Arg3: Arg3,
    Arg4: Arg4,
    Arg5: Arg5,
    Arg6: Arg6,
    Unknown: Unknown,
    Fn: Fn,
    ToFn: ToFn,
    showToFn: showToFn,
    fn: fn,
    addNames: addNames,
    getName: getName,
    getArgs: getArgs,
    argsToArray: argsToArray,
    arrayToArgs: arrayToArgs,
    applyVec0: applyVec0,
    applyVec1: applyVec1,
    applyVec2: applyVec2,
    applyVec3: applyVec3,
    applyVec4: applyVec4,
    applyVec5: applyVec5,
    applyVec6: applyVec6,
    applyArgs0: applyArgs0,
    applyArgs1: applyArgs1,
    applyArgs2: applyArgs2,
    applyArgs3: applyArgs3,
    applyArgs4: applyArgs4,
    applyArgs5: applyArgs5,
    applyArgs6: applyArgs6,
    applyFn0: applyFn0,
    applyFn1: applyFn1,
    applyFn2: applyFn2,
    applyFn3: applyFn3,
    applyFn4: applyFn4,
    applyFn5: applyFn5,
    applyFn6: applyFn6,
    tryFn1: tryFn1,
    tryFn2: tryFn2,
    tryFn3: tryFn3,
    tryFn4: tryFn4,
    tryFn5: tryFn5,
    tryFn6: tryFn6,
    "tryFn1'": tryFn1$prime,
    "tryFn2'": tryFn2$prime,
    "tryFn3'": tryFn3$prime,
    "tryFn4'": tryFn4$prime,
    "tryFn5'": tryFn5$prime,
    "tryFn6'": tryFn6$prime,
    functorArgs: functorArgs,
    argsShowTpl: argsShowTpl,
    fnShow: fnShow,
    fnFunctor: fnFunctor
};
