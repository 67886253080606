// Generated by purs version 0.14.3
"use strict";
var App_Emitters = require("../App.Emitters/index.js");
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Subscription = require("../Halogen.Subscription/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra_Extract = require("../Hydra.Extract/index.js");
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Update = (function () {
    function Update(value0) {
        this.value0 = value0;
    };
    Update.create = function (value0) {
        return new Update(value0);
    };
    return Update;
})();
var initialState = function (v) {
    return new Data_Tuple.Tuple([ Color.rgb(255)(255)(255), Color.rgb(255)(0)(0), Color.rgb(0)(255)(0), Color.rgb(0)(0)(255) ], v.node);
};
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(App_Emitters.fromOutlet(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(v1.value1)("color"))(function (emitter) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM.subscribe(Data_Functor.map(Halogen_Subscription.functorEmitter)(Update.create)(emitter)))(function () {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    });
                });
            });
        };
        if (v instanceof Update) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return new Data_Tuple.Tuple(Hydra_Extract.colorMod(v.value0), v1.value1);
            });
        };
        throw new Error("Failed pattern match at Hydra.Component.Node.Color (line 80, column 16 - line 87, column 66): " + [ v.constructor.name ]);
    };
};
var bodyWidth = 110.0;
var render = function (v) {
    var colorsCount = Data_Array.length(v.value0);
    var colorRectWidth = bodyWidth / Data_Int.toNumber(colorsCount);
    var colorRect = function (i) {
        return function (color) {
            return Halogen_Svg_Elements.g([  ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.x(colorRectWidth * Data_Int.toNumber(i)), Halogen_Svg_Attributes.y(0.0), Halogen_Svg_Attributes.width(colorRectWidth), Halogen_Svg_Attributes.height(55.0), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(color))) ]) ]);
        };
    };
    return Halogen_Svg_Elements.g([  ])([ Halogen_Svg_Elements.g([  ])(Data_Array.mapWithIndex(colorRect)(v.value0)) ]);
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    Initialize: Initialize,
    Update: Update,
    bodyWidth: bodyWidth,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
