// Generated by purs version 0.14.3
"use strict";
var App_Component_App = require("../App.Component.App/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Halogen_Aff_Util = require("../Halogen.Aff.Util/index.js");
var Halogen_VDom_Driver = require("../Halogen.VDom.Driver/index.js");
var run = function (app) {
    return Halogen_Aff_Util.runHalogenAff(Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitBody)(function (body) {
        return Halogen_VDom_Driver.runUI(App_Component_App.component(Effect_Aff_Class.monadAffAff)(Effect_Aff.monadEffectAff))(app)(body);
    }));
};
module.exports = {
    run: run
};
