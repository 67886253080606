// Generated by purs version 0.14.3
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Svg_Attributes_Align = require("../Halogen.Svg.Attributes.Align/index.js");
var Halogen_Svg_Attributes_Baseline = require("../Halogen.Svg.Attributes.Baseline/index.js");
var Halogen_Svg_Attributes_CSSLength = require("../Halogen.Svg.Attributes.CSSLength/index.js");
var Halogen_Svg_Attributes_Color = require("../Halogen.Svg.Attributes.Color/index.js");
var Halogen_Svg_Attributes_Duration = require("../Halogen.Svg.Attributes.Duration/index.js");
var Halogen_Svg_Attributes_FillState = require("../Halogen.Svg.Attributes.FillState/index.js");
var Halogen_Svg_Attributes_FontSize = require("../Halogen.Svg.Attributes.FontSize/index.js");
var Halogen_Svg_Attributes_MarkerUnit = require("../Halogen.Svg.Attributes.MarkerUnit/index.js");
var Halogen_Svg_Attributes_MaskUnit = require("../Halogen.Svg.Attributes.MaskUnit/index.js");
var Halogen_Svg_Attributes_MeetOrSlice = require("../Halogen.Svg.Attributes.MeetOrSlice/index.js");
var Halogen_Svg_Attributes_Orient = require("../Halogen.Svg.Attributes.Orient/index.js");
var Halogen_Svg_Attributes_Path = require("../Halogen.Svg.Attributes.Path/index.js");
var Halogen_Svg_Attributes_TextAnchor = require("../Halogen.Svg.Attributes.TextAnchor/index.js");
var Halogen_Svg_Attributes_Transform = require("../Halogen.Svg.Attributes.Transform/index.js");
var Halogen_Svg_Core = require("../Halogen.Svg.Core/index.js");
var Safe_Coerce = require("../Safe.Coerce/index.js");
var Web_HTML_Common = require("../Web.HTML.Common/index.js");
var xlinkHref = Halogen_HTML_Properties.attrNS("xlink")("xlink:href");
var seconds = function (s_) {
    return new Halogen_Svg_Attributes_Duration.Duration(Data_Maybe.Nothing.value, Data_Maybe.Nothing.value, new Data_Maybe.Just(s_), Data_Maybe.Nothing.value);
};
var attr = Halogen_Svg_Core.attr;
var attributeName = attr("attributeName");
var begin = attr("begin");
var class_ = (function () {
    var $4 = attr("class");
    var $5 = Data_Newtype.un()(Web_HTML_Common.ClassName);
    return function ($6) {
        return $4($5($6));
    };
})();
var classes = (function () {
    var $7 = attr("class");
    var $8 = Data_String_Common.joinWith(" ");
    var $9 = Safe_Coerce.coerce();
    return function ($10) {
        return $7($8($9($10)));
    };
})();
var cx = (function () {
    var $11 = attr("cx");
    var $12 = Data_Show.show(Data_Show.showNumber);
    return function ($13) {
        return $11($12($13));
    };
})();
var cy = (function () {
    var $14 = attr("cy");
    var $15 = Data_Show.show(Data_Show.showNumber);
    return function ($16) {
        return $14($15($16));
    };
})();
var d = (function () {
    var $17 = attr("d");
    var $18 = Data_String_Common.joinWith(" ");
    return function ($19) {
        return $17($18(Halogen_Svg_Attributes_Path.toArrayString($19)));
    };
})();
var dominant_baseline = (function () {
    var $20 = attr("dominant-baseline");
    return function ($21) {
        return $20(Halogen_Svg_Attributes_Baseline.printBaseline($21));
    };
})();
var dur = (function () {
    var $22 = attr("dur");
    return function ($23) {
        return $22(Halogen_Svg_Attributes_Duration.printDuration($23));
    };
})();
var fill = (function () {
    var $24 = attr("fill");
    return function ($25) {
        return $24(Halogen_Svg_Attributes_Color.printColor($25));
    };
})();
var fillAnim = (function () {
    var $26 = attr("fill");
    return function ($27) {
        return $26(Halogen_Svg_Attributes_FillState.printFillState($27));
    };
})();
var fillOpacity = (function () {
    var $28 = attr("fill-opacity");
    var $29 = Data_Show.show(Data_Show.showNumber);
    return function ($30) {
        return $28($29($30));
    };
})();
var font_family = attr("font-family");
var font_size = (function () {
    var $31 = attr("font-size");
    return function ($32) {
        return $31(Halogen_Svg_Attributes_FontSize.printFontSize($32));
    };
})();
var font_size_adjust = (function () {
    var $33 = attr("font-size-adjust");
    var $34 = Data_Show.show(Data_Show.showNumber);
    return function ($35) {
        return $33($34($35));
    };
})();
var font_stretch = attr("font-stretch");
var font_style = attr("font-style");
var font_variant = attr("font-variant");
var font_weight = attr("font-weight");
var from = attr("from");
var height = (function () {
    var $36 = attr("height");
    var $37 = Data_Show.show(Data_Show.showNumber);
    return function ($38) {
        return $36($37($38));
    };
})();
var href = attr("href");
var id = attr("id");
var markerEnd = attr("marker-end");
var markerHeight = (function () {
    var $39 = attr("markerHeight");
    var $40 = Data_Show.show(Data_Show.showNumber);
    return function ($41) {
        return $39($40($41));
    };
})();
var markerMid = attr("marker-mid");
var markerStart = attr("marker-start");
var markerUnits = (function () {
    var $42 = attr("markerUnits");
    return function ($43) {
        return $42(Halogen_Svg_Attributes_MarkerUnit.printMarkerUnit($43));
    };
})();
var markerWidth = (function () {
    var $44 = attr("markerWidth");
    var $45 = Data_Show.show(Data_Show.showNumber);
    return function ($46) {
        return $44($45($46));
    };
})();
var mask = attr("mask");
var maskContentUnits = (function () {
    var $47 = attr("maskContentUnits");
    return function ($48) {
        return $47(Halogen_Svg_Attributes_MaskUnit.printMaskUnit($48));
    };
})();
var maskUnits = (function () {
    var $49 = attr("maskUnits");
    return function ($50) {
        return $49(Halogen_Svg_Attributes_MaskUnit.printMaskUnit($50));
    };
})();
var orient = (function () {
    var $51 = attr("orient");
    return function ($52) {
        return $51(Halogen_Svg_Attributes_Orient.printOrient($52));
    };
})();
var path = (function () {
    var $53 = attr("path");
    var $54 = Data_String_Common.joinWith(" ");
    return function ($55) {
        return $53($54(Halogen_Svg_Attributes_Path.toArrayString($55)));
    };
})();
var preserveAspectRatio = function (align) {
    return function (slice) {
        var align_str = (function () {
            if (align instanceof Data_Maybe.Nothing) {
                return "none";
            };
            if (align instanceof Data_Maybe.Just) {
                return Data_String_Common.joinWith("")([ "x", Halogen_Svg_Attributes_Align.printAlign(align.value0.x_), "Y", Halogen_Svg_Attributes_Align.printAlign(align.value0.y_) ]);
            };
            throw new Error("Failed pattern match at Halogen.Svg.Attributes (line 226, column 17 - line 228, column 78): " + [ align.constructor.name ]);
        })();
        return attr("preserveAspectRatio")(Data_String_Common.joinWith(" ")([ align_str, Halogen_Svg_Attributes_MeetOrSlice.printMeetOrSlice(slice) ]));
    };
};
var r = (function () {
    var $56 = attr("r");
    var $57 = Data_Show.show(Data_Show.showNumber);
    return function ($58) {
        return $56($57($58));
    };
})();
var refX = (function () {
    var $59 = attr("refX");
    var $60 = Data_Show.show(Data_Show.showNumber);
    return function ($61) {
        return $59($60($61));
    };
})();
var refY = (function () {
    var $62 = attr("refY");
    var $63 = Data_Show.show(Data_Show.showNumber);
    return function ($64) {
        return $62($63($64));
    };
})();
var repeatCount = (function () {
    var $65 = attr("repeatCount");
    var $66 = Data_Show.show(Data_Show.showInt);
    return function ($67) {
        return $65($66($67));
    };
})();
var rx = (function () {
    var $68 = attr("rx");
    var $69 = Data_Show.show(Data_Show.showNumber);
    return function ($70) {
        return $68($69($70));
    };
})();
var ry = (function () {
    var $71 = attr("ry");
    var $72 = Data_Show.show(Data_Show.showNumber);
    return function ($73) {
        return $71($72($73));
    };
})();
var stroke = (function () {
    var $74 = attr("stroke");
    return function ($75) {
        return $74(Halogen_Svg_Attributes_Color.printColor($75));
    };
})();
var strokeWidth = (function () {
    var $76 = attr("stroke-width");
    var $77 = Data_Show.show(Data_Show.showNumber);
    return function ($78) {
        return $76($77($78));
    };
})();
var stroke_dasharray = attr("stroke-dasharray");
var stroke_dashoffset = (function () {
    var $79 = attr("stroke-dashoffset");
    var $80 = Data_Show.show(Data_Show.showNumber);
    return function ($81) {
        return $79($80($81));
    };
})();
var stroke_linecap = attr("stroke-linecap");
var stroke_linejoin = attr("stroke-linejoin");
var stroke_miterlimit = attr("stroke-miterlimit");
var stroke_opacity = (function () {
    var $82 = attr("stroke-opacity");
    var $83 = Data_Show.show(Data_Show.showNumber);
    return function ($84) {
        return $82($83($84));
    };
})();
var text_anchor = (function () {
    var $85 = attr("text-anchor");
    return function ($86) {
        return $85(Halogen_Svg_Attributes_TextAnchor.printTextAnchor($86));
    };
})();
var to = attr("to");
var transform = (function () {
    var $87 = attr("transform");
    var $88 = Data_String_Common.joinWith(" ");
    var $89 = Data_Functor.map(Data_Functor.functorArray)(Halogen_Svg_Attributes_Transform.printTransform);
    return function ($90) {
        return $87($88($89($90)));
    };
})();
var viewBox = function (x_) {
    return function (y_) {
        return function (w) {
            return function (h_) {
                return attr("viewBox")(Data_String_Common.joinWith(" ")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Data_Show.showNumber))([ x_, y_, w, h_ ])));
            };
        };
    };
};
var width = (function () {
    var $91 = attr("width");
    var $92 = Data_Show.show(Data_Show.showNumber);
    return function ($93) {
        return $91($92($93));
    };
})();
var x = (function () {
    var $94 = attr("x");
    var $95 = Data_Show.show(Data_Show.showNumber);
    return function ($96) {
        return $94($95($96));
    };
})();
var x1 = (function () {
    var $97 = attr("x1");
    var $98 = Data_Show.show(Data_Show.showNumber);
    return function ($99) {
        return $97($98($99));
    };
})();
var x2 = (function () {
    var $100 = attr("x2");
    var $101 = Data_Show.show(Data_Show.showNumber);
    return function ($102) {
        return $100($101($102));
    };
})();
var y = (function () {
    var $103 = attr("y");
    var $104 = Data_Show.show(Data_Show.showNumber);
    return function ($105) {
        return $103($104($105));
    };
})();
var y1 = (function () {
    var $106 = attr("y1");
    var $107 = Data_Show.show(Data_Show.showNumber);
    return function ($108) {
        return $106($107($108));
    };
})();
var y2 = (function () {
    var $109 = attr("y2");
    var $110 = Data_Show.show(Data_Show.showNumber);
    return function ($111) {
        return $109($110($111));
    };
})();
module.exports = {
    attr: attr,
    attributeName: attributeName,
    begin: begin,
    class_: class_,
    classes: classes,
    cx: cx,
    cy: cy,
    d: d,
    dominant_baseline: dominant_baseline,
    dur: dur,
    fill: fill,
    fillAnim: fillAnim,
    fillOpacity: fillOpacity,
    font_family: font_family,
    font_size: font_size,
    font_size_adjust: font_size_adjust,
    font_stretch: font_stretch,
    font_style: font_style,
    font_variant: font_variant,
    font_weight: font_weight,
    from: from,
    to: to,
    href: href,
    id: id,
    markerStart: markerStart,
    markerMid: markerMid,
    markerEnd: markerEnd,
    markerUnits: markerUnits,
    markerWidth: markerWidth,
    markerHeight: markerHeight,
    mask: mask,
    maskUnits: maskUnits,
    maskContentUnits: maskContentUnits,
    orient: orient,
    path: path,
    preserveAspectRatio: preserveAspectRatio,
    r: r,
    refX: refX,
    refY: refY,
    repeatCount: repeatCount,
    rx: rx,
    ry: ry,
    seconds: seconds,
    stroke: stroke,
    stroke_dasharray: stroke_dasharray,
    stroke_dashoffset: stroke_dashoffset,
    stroke_linecap: stroke_linecap,
    stroke_linejoin: stroke_linejoin,
    stroke_miterlimit: stroke_miterlimit,
    stroke_opacity: stroke_opacity,
    strokeWidth: strokeWidth,
    text_anchor: text_anchor,
    transform: transform,
    viewBox: viewBox,
    width: width,
    height: height,
    x: x,
    y: y,
    x1: x1,
    y1: y1,
    x2: x2,
    y2: y2,
    xlinkHref: xlinkHref,
    Max: Halogen_Svg_Attributes_Align.Max,
    Mid: Halogen_Svg_Attributes_Align.Mid,
    Min: Halogen_Svg_Attributes_Align.Min,
    printAlign: Halogen_Svg_Attributes_Align.printAlign,
    Alphabetic: Halogen_Svg_Attributes_Baseline.Alphabetic,
    Auto: Halogen_Svg_Attributes_Baseline.Auto,
    BaselineMiddle: Halogen_Svg_Attributes_Baseline.BaselineMiddle,
    Central: Halogen_Svg_Attributes_Baseline.Central,
    Hanging: Halogen_Svg_Attributes_Baseline.Hanging,
    Ideographic: Halogen_Svg_Attributes_Baseline.Ideographic,
    Mathematical: Halogen_Svg_Attributes_Baseline.Mathematical,
    NoChange: Halogen_Svg_Attributes_Baseline.NoChange,
    ResetSize: Halogen_Svg_Attributes_Baseline.ResetSize,
    TextAfterEdge: Halogen_Svg_Attributes_Baseline.TextAfterEdge,
    TextBeforeEdge: Halogen_Svg_Attributes_Baseline.TextBeforeEdge,
    UseScript: Halogen_Svg_Attributes_Baseline.UseScript,
    printBaseline: Halogen_Svg_Attributes_Baseline.printBaseline,
    Cm: Halogen_Svg_Attributes_CSSLength.Cm,
    Em: Halogen_Svg_Attributes_CSSLength.Em,
    Ex: Halogen_Svg_Attributes_CSSLength.Ex,
    Inches: Halogen_Svg_Attributes_CSSLength.Inches,
    Mm: Halogen_Svg_Attributes_CSSLength.Mm,
    Nil: Halogen_Svg_Attributes_CSSLength.Nil,
    Pc: Halogen_Svg_Attributes_CSSLength.Pc,
    Pct: Halogen_Svg_Attributes_CSSLength.Pct,
    Pt: Halogen_Svg_Attributes_CSSLength.Pt,
    Px: Halogen_Svg_Attributes_CSSLength.Px,
    Rem: Halogen_Svg_Attributes_CSSLength.Rem,
    Vh: Halogen_Svg_Attributes_CSSLength.Vh,
    Vmax: Halogen_Svg_Attributes_CSSLength.Vmax,
    Vmin: Halogen_Svg_Attributes_CSSLength.Vmin,
    Vw: Halogen_Svg_Attributes_CSSLength.Vw,
    printCSSLength: Halogen_Svg_Attributes_CSSLength.printCSSLength,
    Named: Halogen_Svg_Attributes_Color.Named,
    RGB: Halogen_Svg_Attributes_Color.RGB,
    RGBA: Halogen_Svg_Attributes_Color.RGBA,
    printColor: Halogen_Svg_Attributes_Color.printColor,
    Duration: Halogen_Svg_Attributes_Duration.Duration,
    printDuration: Halogen_Svg_Attributes_Duration.printDuration,
    printDurationF: Halogen_Svg_Attributes_Duration.printDurationF,
    Freeze: Halogen_Svg_Attributes_FillState.Freeze,
    Remove: Halogen_Svg_Attributes_FillState.Remove,
    printFillState: Halogen_Svg_Attributes_FillState.printFillState,
    FontSizeLength: Halogen_Svg_Attributes_FontSize.FontSizeLength,
    Large: Halogen_Svg_Attributes_FontSize.Large,
    Larger: Halogen_Svg_Attributes_FontSize.Larger,
    Medium: Halogen_Svg_Attributes_FontSize.Medium,
    Small: Halogen_Svg_Attributes_FontSize.Small,
    Smaller: Halogen_Svg_Attributes_FontSize.Smaller,
    XLarge: Halogen_Svg_Attributes_FontSize.XLarge,
    XSmall: Halogen_Svg_Attributes_FontSize.XSmall,
    XXLarge: Halogen_Svg_Attributes_FontSize.XXLarge,
    XXSmall: Halogen_Svg_Attributes_FontSize.XXSmall,
    printFontSize: Halogen_Svg_Attributes_FontSize.printFontSize,
    StrokeWidth: Halogen_Svg_Attributes_MarkerUnit.StrokeWidth,
    UserSpaceOnUse: Halogen_Svg_Attributes_MarkerUnit.UserSpaceOnUse,
    printMarkerUnit: Halogen_Svg_Attributes_MarkerUnit.printMarkerUnit,
    ObjectBoundingBox: Halogen_Svg_Attributes_MaskUnit.ObjectBoundingBox,
    UserSpaceOnUse_: Halogen_Svg_Attributes_MaskUnit.UserSpaceOnUse_,
    printMaskUnit: Halogen_Svg_Attributes_MaskUnit.printMaskUnit,
    Meet: Halogen_Svg_Attributes_MeetOrSlice.Meet,
    Slice: Halogen_Svg_Attributes_MeetOrSlice.Slice,
    printMeetOrSlice: Halogen_Svg_Attributes_MeetOrSlice.printMeetOrSlice,
    AutoOrient: Halogen_Svg_Attributes_Orient.AutoOrient,
    AutoStartReverse: Halogen_Svg_Attributes_Orient.AutoStartReverse,
    printOrient: Halogen_Svg_Attributes_Orient.printOrient,
    Arc0: Halogen_Svg_Attributes_Path.Arc0,
    Arc1: Halogen_Svg_Attributes_Path.Arc1,
    Abs: Halogen_Svg_Attributes_Path.Abs,
    Rel: Halogen_Svg_Attributes_Path.Rel,
    Sweep0: Halogen_Svg_Attributes_Path.Sweep0,
    Sweep1: Halogen_Svg_Attributes_Path.Sweep1,
    a: Halogen_Svg_Attributes_Path.a,
    c: Halogen_Svg_Attributes_Path.c,
    h: Halogen_Svg_Attributes_Path.h,
    l: Halogen_Svg_Attributes_Path.l,
    m: Halogen_Svg_Attributes_Path.m,
    q: Halogen_Svg_Attributes_Path.q,
    s: Halogen_Svg_Attributes_Path.s,
    t: Halogen_Svg_Attributes_Path.t,
    toArrayString: Halogen_Svg_Attributes_Path.toArrayString,
    v: Halogen_Svg_Attributes_Path.v,
    z: Halogen_Svg_Attributes_Path.z,
    AnchorMiddle: Halogen_Svg_Attributes_TextAnchor.AnchorMiddle,
    End: Halogen_Svg_Attributes_TextAnchor.End,
    Start: Halogen_Svg_Attributes_TextAnchor.Start,
    printTextAnchor: Halogen_Svg_Attributes_TextAnchor.printTextAnchor,
    Matrix: Halogen_Svg_Attributes_Transform.Matrix,
    Rotate: Halogen_Svg_Attributes_Transform.Rotate,
    Scale: Halogen_Svg_Attributes_Transform.Scale,
    SkewX: Halogen_Svg_Attributes_Transform.SkewX,
    SkewY: Halogen_Svg_Attributes_Transform.SkewY,
    Translate: Halogen_Svg_Attributes_Transform.Translate,
    printTransform: Halogen_Svg_Attributes_Transform.printTransform
};
