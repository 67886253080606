// Generated by purs version 0.14.3
"use strict";
var App_Layout = require("../App.Layout/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var packOrDrop = function (dictIsAutoLayout) {
    var $53 = App_Layout.packOrDrop(dictIsAutoLayout);
    return function ($54) {
        return $53(Data_Maybe.Just.create($54));
    };
};
var pack = function (dictIsAutoLayout) {
    var $55 = App_Layout.pack(dictIsAutoLayout);
    return function ($56) {
        return $55(Data_Maybe.Just.create($56));
    };
};
var fold = function (dictIsLayout) {
    return function (f) {
        var skipNothings = function (v) {
            return function (k) {
                if (v.value0 instanceof Data_Maybe.Nothing) {
                    return k;
                };
                if (v.value0 instanceof Data_Maybe.Just) {
                    return f(new Data_Tuple.Tuple(v.value0.value0, new Data_Tuple.Tuple(v.value1.value0, v.value1.value1)))(k);
                };
                throw new Error("Failed pattern match at App.Layout.Optional (line 33, column 9 - line 33, column 47): " + [ v.constructor.name, k.constructor.name ]);
            };
        };
        return App_Layout.fold(dictIsLayout)(skipNothings);
    };
};
var find = function (dictIsLayout) {
    return function (dictEq) {
        return function (a) {
            return App_Layout.find(dictIsLayout)(Data_Maybe.eqMaybe(dictEq))(new Data_Maybe.Just(a));
        };
    };
};
var extractMaybe = function (v) {
    if (v.value0 instanceof Data_Maybe.Nothing) {
        return Data_Maybe.Nothing.value;
    };
    if (v.value0 instanceof Data_Maybe.Just) {
        return Data_Maybe.Just.create(new Data_Tuple.Tuple(v.value0.value0, new Data_Tuple.Tuple(v.value1.value0, v.value1.value1)));
    };
    throw new Error("Failed pattern match at App.Layout.Optional (line 16, column 1 - line 16, column 74): " + [ v.constructor.name ]);
};
var sample = function (dictIsLayout) {
    return function (pos) {
        var $57 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(extractMaybe);
        var $58 = App_Layout.sample(dictIsLayout)(pos);
        return function ($59) {
            return $57($58($59));
        };
    };
};
var toArray = function (dictIsLayout) {
    var $60 = Data_Functor.map(Data_Functor.functorArray)(extractMaybe);
    var $61 = App_Layout.toArray(dictIsLayout);
    return function ($62) {
        return Data_Array.catMaybes($60($61($62)));
    };
};
var toList = function (dictIsLayout) {
    var $63 = Data_Functor.map(Data_List_Types.functorList)(extractMaybe);
    var $64 = App_Layout.toList(dictIsLayout);
    return function ($65) {
        return Data_List.catMaybes($63($64($65)));
    };
};
var count = function (dictIsLayout) {
    var $66 = toArray(dictIsLayout);
    return function ($67) {
        return Data_Array.length($66($67));
    };
};
var atPos$prime$prime = function (dictIsLayout) {
    return function (pos) {
        return function (l) {
            var v = App_Layout["atPos''"](dictIsLayout)(pos)(l);
            if (v instanceof Data_Maybe.Just && v.value0.value0 instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0.value0, v.value0.value1));
            };
            return Data_Maybe.Nothing.value;
        };
    };
};
var atPos$prime = function (dictIsLayout) {
    return function (pos) {
        return function (l) {
            var v = App_Layout["atPos'"](dictIsLayout)(pos)(l);
            if (v instanceof Data_Maybe.Just && v.value0.value0 instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0.value0, v.value0.value1));
            };
            return Data_Maybe.Nothing.value;
        };
    };
};
var atPos = function (dictIsLayout) {
    return function (pos) {
        var $68 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Control_Category.identity(Control_Category.categoryFn));
        var $69 = App_Layout.atPos(dictIsLayout)(pos);
        return function ($70) {
            return $68($69($70));
        };
    };
};
var abandon = function (dictFunctor) {
    return function (dictEq) {
        return function (needle) {
            var freeIfEq = function (v) {
                if (v instanceof Data_Maybe.Just && Data_Eq.eq(dictEq)(v.value0)(needle)) {
                    return Data_Maybe.Nothing.value;
                };
                if (v instanceof Data_Maybe.Just && Data_Boolean.otherwise) {
                    return new Data_Maybe.Just(v.value0);
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at App.Layout.Optional (line 24, column 9 - line 24, column 50): " + [ v.constructor.name ]);
            };
            return Data_Functor.map(dictFunctor)(freeIfEq);
        };
    };
};
module.exports = {
    extractMaybe: extractMaybe,
    abandon: abandon,
    fold: fold,
    find: find,
    sample: sample,
    atPos: atPos,
    "atPos'": atPos$prime,
    "atPos''": atPos$prime$prime,
    pack: pack,
    packOrDrop: packOrDrop,
    toArray: toArray,
    toList: toList,
    count: count
};
