// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Start = (function () {
    function Start() {

    };
    Start.value = new Start();
    return Start;
})();
var AnchorMiddle = (function () {
    function AnchorMiddle() {

    };
    AnchorMiddle.value = new AnchorMiddle();
    return AnchorMiddle;
})();
var End = (function () {
    function End() {

    };
    End.value = new End();
    return End;
})();
var showTextAnchor = new Data_Show.Show(function (v) {
    if (v instanceof Start) {
        return "Start";
    };
    if (v instanceof AnchorMiddle) {
        return "AnchorMiddle";
    };
    if (v instanceof End) {
        return "End";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.TextAnchor (line 16, column 10 - line 19, column 26): " + [ v.constructor.name ]);
});
var printTextAnchor = function (v) {
    if (v instanceof Start) {
        return "start";
    };
    if (v instanceof AnchorMiddle) {
        return "middle";
    };
    if (v instanceof End) {
        return "end";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.TextAnchor (line 22, column 19 - line 25, column 24): " + [ v.constructor.name ]);
};
var eqTextAnchor = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Start && y instanceof Start) {
            return true;
        };
        if (x instanceof AnchorMiddle && y instanceof AnchorMiddle) {
            return true;
        };
        if (x instanceof End && y instanceof End) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Start: Start,
    AnchorMiddle: AnchorMiddle,
    End: End,
    printTextAnchor: printTextAnchor,
    eqTextAnchor: eqTextAnchor,
    showTextAnchor: showTextAnchor
};
