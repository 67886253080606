// Generated by purs version 0.14.3
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Extract = require("../Hydra.Extract/index.js");
var Hydra_Toolkit_Shape = require("../Hydra.Toolkit.Shape/index.js");
var Hydra_Try = require("../Hydra.Try/index.js");
var Noodle_Channel_Shape = require("../Noodle.Channel.Shape/index.js");
var Noodle_Node_Define = require("../Noodle.Node.Define/index.js");
var Noodle_Node_Shape = require("../Noodle.Node.Shape/index.js");
var toBuffer = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("texture", Hydra_Toolkit_Shape.texture)))(Noodle_Node_Shape.noOutlets)(Noodle_Node_Define.doNothing);
var time = Noodle_Node_Define.define(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("time", Hydra_Toolkit_Shape.value)))(Noodle_Node_Define.alwaysOne(new Data_Tuple.Tuple("time", Hydra.time)));
var sqrt = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("value", Hydra_Toolkit_Shape.value)))(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("value", Hydra_Toolkit_Shape.value)))(function (inlets) {
    return Noodle_Node_Define["passTo'"](new Data_Tuple.Tuple("value", Hydra_Try.tryV1M(new Hydra.Num(0.0))(Hydra.sqrt)(Noodle_Node_Define.receive("value")(inlets))));
});
var solidPalette = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("palette", Noodle_Channel_Shape.hidden(Hydra_Toolkit_Shape.texture))))(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("palette", Hydra_Toolkit_Shape.texture)))(Noodle_Node_Define.passThrough);
var seq = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("1", Hydra_Toolkit_Shape.value)))(new Data_Tuple.Tuple("2", Hydra_Toolkit_Shape.value)))(new Data_Tuple.Tuple("3", Hydra_Toolkit_Shape.value)))(new Data_Tuple.Tuple("4", Hydra_Toolkit_Shape.value)))(new Data_Tuple.Tuple("5", Hydra_Toolkit_Shape.value)))(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("seq", Hydra_Toolkit_Shape.value)))(function (inlets) {
    return Noodle_Node_Define.pass([ new Data_Tuple.Tuple("seq", Hydra_Extract.buildSeq5(Noodle_Node_Define.receive("1")(inlets))(Noodle_Node_Define.receive("2")(inlets))(Noodle_Node_Define.receive("3")(inlets))(Noodle_Node_Define.receive("4")(inlets))(Noodle_Node_Define.receive("5")(inlets))) ]);
});
var render = Noodle_Node_Define.define(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.noOutlets)(Noodle_Node_Define.doNothing);
var pi = Noodle_Node_Define.define(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("pi", Hydra_Toolkit_Shape.value)))(Noodle_Node_Define.doNothing);
var palette = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("texture", Hydra_Toolkit_Shape.texture)))(new Data_Tuple.Tuple("palette", Noodle_Channel_Shape.hidden(Hydra_Toolkit_Shape.modifier))))(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("palette", Hydra_Toolkit_Shape.texture)))(function (inlets) {
    return Noodle_Node_Define["pass'"]([ new Data_Tuple.Tuple("palette", Data_Functor.map(Data_Maybe.functorMaybe)(Hydra.Tex.create)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Hydra.addModifier)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Hydra_Extract.entity)(Noodle_Node_Define.receive("texture")(inlets))))(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Hydra_Extract.modifier)(Noodle_Node_Define.receive("palette")(inlets))))) ]);
});
var out = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("texture", Hydra_Toolkit_Shape.texture)))(Noodle_Node_Shape.noOutlets)(Noodle_Node_Define.doNothing);
var number = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("num", Noodle_Channel_Shape.hidden(Hydra_Toolkit_Shape.value))))(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("num", Hydra_Toolkit_Shape.value)))(Noodle_Node_Define.passThrough);
var mouse = Noodle_Node_Define.define(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("x", Hydra_Toolkit_Shape.value)))(new Data_Tuple.Tuple("y", Hydra_Toolkit_Shape.value)))(Noodle_Node_Define.always([ new Data_Tuple.Tuple("x", Hydra.mouseX), new Data_Tuple.Tuple("y", Hydra.mouseY) ]));
var math = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("a", Hydra_Toolkit_Shape.value)))(new Data_Tuple.Tuple("b", Hydra_Toolkit_Shape.value)))(new Data_Tuple.Tuple("op", Noodle_Channel_Shape.hidden(Hydra_Toolkit_Shape.operation))))(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("=", Hydra_Toolkit_Shape.value)))(function (inlets) {
    return Noodle_Node_Define["passTo'"](new Data_Tuple.Tuple("=", Hydra_Extract.performMaybe(Noodle_Node_Define.receive("a")(inlets))(Noodle_Node_Define.receive("op")(inlets))(Noodle_Node_Define.receive("b")(inlets))));
});
var fromBuffer = Noodle_Node_Define.define(Noodle_Node_Shape.noInlets)(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("texture", Hydra_Toolkit_Shape.texture)))(Noodle_Node_Define.doNothing);
var fast = Noodle_Node_Define.define(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.andInlet(Noodle_Node_Shape.withInlets)(new Data_Tuple.Tuple("speed", Noodle_Channel_Shape.hidden(Hydra_Toolkit_Shape.value))))(new Data_Tuple.Tuple("value", Hydra_Toolkit_Shape.value)))(Noodle_Node_Shape.andOutlet(Noodle_Node_Shape.withOutlets)(new Data_Tuple.Tuple("value", Hydra_Toolkit_Shape.value)))(function (inlets) {
    return Noodle_Node_Define["passTo'"](new Data_Tuple.Tuple("value", Hydra_Extract.fastVal(Noodle_Node_Define.receive("speed")(inlets))(Noodle_Node_Define.receive("value")(inlets))));
});
module.exports = {
    number: number,
    time: time,
    mouse: mouse,
    seq: seq,
    palette: palette,
    solidPalette: solidPalette,
    out: out,
    toBuffer: toBuffer,
    fromBuffer: fromBuffer,
    render: render,
    pi: pi,
    math: math,
    fast: fast,
    sqrt: sqrt
};
