// Generated by purs version 0.14.3
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var DOM_HTML_Indexed = require("../DOM.HTML.Indexed/index.js");
var DOM_HTML_Indexed_ButtonType = require("../DOM.HTML.Indexed.ButtonType/index.js");
var DOM_HTML_Indexed_FormMethod = require("../DOM.HTML.Indexed.FormMethod/index.js");
var DOM_HTML_Indexed_InputAcceptType = require("../DOM.HTML.Indexed.InputAcceptType/index.js");
var DOM_HTML_Indexed_InputType = require("../DOM.HTML.Indexed.InputType/index.js");
var DOM_HTML_Indexed_MenuType = require("../DOM.HTML.Indexed.MenuType/index.js");
var DOM_HTML_Indexed_MenuitemType = require("../DOM.HTML.Indexed.MenuitemType/index.js");
var DOM_HTML_Indexed_OnOff = require("../DOM.HTML.Indexed.OnOff/index.js");
var DOM_HTML_Indexed_OrderedListType = require("../DOM.HTML.Indexed.OrderedListType/index.js");
var DOM_HTML_Indexed_PreloadValue = require("../DOM.HTML.Indexed.PreloadValue/index.js");
var DOM_HTML_Indexed_ScopeValue = require("../DOM.HTML.Indexed.ScopeValue/index.js");
var DOM_HTML_Indexed_StepValue = require("../DOM.HTML.Indexed.StepValue/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_Query_Input = require("../Halogen.Query.Input/index.js");
var Halogen_VDom_DOM_Prop = require("../Halogen.VDom.DOM.Prop/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var IProp = function (x) {
    return x;
};
var ref = (function () {
    var go = function (p) {
        return function (mel) {
            return new Data_Maybe.Just(new Halogen_Query_Input.RefUpdate(p, mel));
        };
    };
    return function ($9) {
        return Halogen_HTML_Core.ref(go($9));
    };
})();
var prop = function (dictIsProp) {
    return Halogen_HTML_Core.prop(dictIsProp);
};
var readOnly = prop(Halogen_HTML_Core.isPropBoolean)("readOnly");
var rel = prop(Halogen_HTML_Core.isPropString)("rel");
var required = prop(Halogen_HTML_Core.isPropBoolean)("required");
var rowSpan = prop(Halogen_HTML_Core.isPropInt)("rowSpan");
var rows = prop(Halogen_HTML_Core.isPropInt)("rows");
var scope = prop(Halogen_HTML_Core.isPropScopeValue)("scope");
var selected = prop(Halogen_HTML_Core.isPropBoolean)("selected");
var selectedIndex = prop(Halogen_HTML_Core.isPropInt)("selectedIndex");
var spellcheck = prop(Halogen_HTML_Core.isPropBoolean)("spellcheck");
var src = prop(Halogen_HTML_Core.isPropString)("src");
var step = prop(Halogen_HTML_Core.isPropStepValue)("step");
var tabIndex = prop(Halogen_HTML_Core.isPropInt)("tabIndex");
var target = prop(Halogen_HTML_Core.isPropString)("target");
var title = prop(Halogen_HTML_Core.isPropString)("title");
var type_ = function (dictIsProp) {
    return prop(dictIsProp)("type");
};
var value = prop(Halogen_HTML_Core.isPropString)("value");
var width = prop(Halogen_HTML_Core.isPropInt)("width");
var preload = prop(Halogen_HTML_Core.isPropPreloadValue)("preload");
var poster = prop(Halogen_HTML_Core.isPropString)("poster");
var placeholder = prop(Halogen_HTML_Core.isPropString)("placeholder");
var pattern = prop(Halogen_HTML_Core.isPropString)("pattern");
var noValidate = prop(Halogen_HTML_Core.isPropBoolean)("noValidate");
var newtypeIProp = new Data_Newtype.Newtype(function () {
    return undefined;
});
var name = prop(Halogen_HTML_Core.isPropString)("name");
var muted = prop(Halogen_HTML_Core.isPropBoolean)("muted");
var multiple = prop(Halogen_HTML_Core.isPropBoolean)("multiple");
var min = prop(Halogen_HTML_Core.isPropNumber)("min");
var method = prop(Halogen_HTML_Core.isPropFormMethod)("method");
var max = prop(Halogen_HTML_Core.isPropNumber)("max");
var loop = prop(Halogen_HTML_Core.isPropBoolean)("loop");
var id = prop(Halogen_HTML_Core.isPropString)("id");
var id_ = function (dictWarn) {
    return id;
};
var href = prop(Halogen_HTML_Core.isPropString)("href");
var height = prop(Halogen_HTML_Core.isPropInt)("height");
var functorIProp = new Data_Functor.Functor(function (f) {
    return function (m) {
        return Data_Functor.map(Halogen_VDom_DOM_Prop.functorProp)(Data_Functor.map(Halogen_Query_Input.functorInput)(f))(m);
    };
});
var $$for = prop(Halogen_HTML_Core.isPropString)("htmlFor");
var expand = function (dictUnion) {
    return Unsafe_Coerce.unsafeCoerce;
};
var enctype = prop(Halogen_HTML_Core.isPropMediaType)("enctype");
var draggable = prop(Halogen_HTML_Core.isPropBoolean)("draggable");
var download = prop(Halogen_HTML_Core.isPropString)("download");
var disabled = prop(Halogen_HTML_Core.isPropBoolean)("disabled");
var enabled = (function () {
    var $10 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
    return function ($11) {
        return disabled($10($11));
    };
})();
var controls = prop(Halogen_HTML_Core.isPropBoolean)("controls");
var cols = prop(Halogen_HTML_Core.isPropInt)("cols");
var colSpan = prop(Halogen_HTML_Core.isPropInt)("colSpan");
var classes = (function () {
    var $12 = prop(Halogen_HTML_Core.isPropString)("className");
    var $13 = Data_String_Common.joinWith(" ");
    var $14 = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap());
    return function ($15) {
        return $12($13($14($15)));
    };
})();
var class_ = (function () {
    var $16 = prop(Halogen_HTML_Core.isPropString)("className");
    var $17 = Data_Newtype.unwrap();
    return function ($18) {
        return $16($17($18));
    };
})();
var checked = prop(Halogen_HTML_Core.isPropBoolean)("checked");
var charset = prop(Halogen_HTML_Core.isPropString)("charset");
var autoplay = prop(Halogen_HTML_Core.isPropBoolean)("autoplay");
var autofocus = prop(Halogen_HTML_Core.isPropBoolean)("autofocus");
var autocomplete = (function () {
    var $19 = prop(Halogen_HTML_Core.isPropOnOff)("autocomplete");
    return function ($20) {
        return $19((function (b) {
            if (b) {
                return DOM_HTML_Indexed_OnOff.On.value;
            };
            return DOM_HTML_Indexed_OnOff.Off.value;
        })($20));
    };
})();
var attrNS = (function () {
    var $21 = Control_Applicative.pure(Data_Maybe.applicativeMaybe);
    return function ($22) {
        return Halogen_HTML_Core.attr($21($22));
    };
})();
var attr = Halogen_HTML_Core.attr(Data_Maybe.Nothing.value);
var list = attr("list");
var style = attr("style");
var alt = prop(Halogen_HTML_Core.isPropString)("alt");
var action = prop(Halogen_HTML_Core.isPropString)("action");
var accept = prop(Halogen_HTML_Core.isPropInputAcceptType)("accept");
module.exports = {
    IProp: IProp,
    prop: prop,
    attr: attr,
    attrNS: attrNS,
    ref: ref,
    expand: expand,
    alt: alt,
    charset: charset,
    class_: class_,
    classes: classes,
    cols: cols,
    rows: rows,
    colSpan: colSpan,
    rowSpan: rowSpan,
    "for": $$for,
    height: height,
    width: width,
    href: href,
    id: id,
    id_: id_,
    name: name,
    rel: rel,
    src: src,
    style: style,
    scope: scope,
    target: target,
    title: title,
    download: download,
    method: method,
    action: action,
    enctype: enctype,
    noValidate: noValidate,
    type_: type_,
    value: value,
    min: min,
    max: max,
    step: step,
    disabled: disabled,
    enabled: enabled,
    required: required,
    readOnly: readOnly,
    spellcheck: spellcheck,
    checked: checked,
    selected: selected,
    selectedIndex: selectedIndex,
    placeholder: placeholder,
    autocomplete: autocomplete,
    list: list,
    autofocus: autofocus,
    multiple: multiple,
    pattern: pattern,
    accept: accept,
    autoplay: autoplay,
    controls: controls,
    loop: loop,
    muted: muted,
    poster: poster,
    preload: preload,
    draggable: draggable,
    tabIndex: tabIndex,
    newtypeIProp: newtypeIProp,
    functorIProp: functorIProp,
    ButtonButton: DOM_HTML_Indexed_ButtonType.ButtonButton,
    ButtonReset: DOM_HTML_Indexed_ButtonType.ButtonReset,
    ButtonSubmit: DOM_HTML_Indexed_ButtonType.ButtonSubmit,
    GET: DOM_HTML_Indexed_FormMethod.GET,
    POST: DOM_HTML_Indexed_FormMethod.POST,
    InputAcceptType: DOM_HTML_Indexed_InputAcceptType.InputAcceptType,
    InputButton: DOM_HTML_Indexed_InputType.InputButton,
    InputCheckbox: DOM_HTML_Indexed_InputType.InputCheckbox,
    InputColor: DOM_HTML_Indexed_InputType.InputColor,
    InputDate: DOM_HTML_Indexed_InputType.InputDate,
    InputDatetimeLocal: DOM_HTML_Indexed_InputType.InputDatetimeLocal,
    InputEmail: DOM_HTML_Indexed_InputType.InputEmail,
    InputFile: DOM_HTML_Indexed_InputType.InputFile,
    InputHidden: DOM_HTML_Indexed_InputType.InputHidden,
    InputImage: DOM_HTML_Indexed_InputType.InputImage,
    InputMonth: DOM_HTML_Indexed_InputType.InputMonth,
    InputNumber: DOM_HTML_Indexed_InputType.InputNumber,
    InputPassword: DOM_HTML_Indexed_InputType.InputPassword,
    InputRadio: DOM_HTML_Indexed_InputType.InputRadio,
    InputRange: DOM_HTML_Indexed_InputType.InputRange,
    InputReset: DOM_HTML_Indexed_InputType.InputReset,
    InputSearch: DOM_HTML_Indexed_InputType.InputSearch,
    InputSubmit: DOM_HTML_Indexed_InputType.InputSubmit,
    InputTel: DOM_HTML_Indexed_InputType.InputTel,
    InputText: DOM_HTML_Indexed_InputType.InputText,
    InputTime: DOM_HTML_Indexed_InputType.InputTime,
    InputUrl: DOM_HTML_Indexed_InputType.InputUrl,
    InputWeek: DOM_HTML_Indexed_InputType.InputWeek,
    MenuContext: DOM_HTML_Indexed_MenuType.MenuContext,
    MenuList: DOM_HTML_Indexed_MenuType.MenuList,
    MenuToolbar: DOM_HTML_Indexed_MenuType.MenuToolbar,
    MenuitemCheckbox: DOM_HTML_Indexed_MenuitemType.MenuitemCheckbox,
    MenuitemCommand: DOM_HTML_Indexed_MenuitemType.MenuitemCommand,
    MenuitemRadio: DOM_HTML_Indexed_MenuitemType.MenuitemRadio,
    Off: DOM_HTML_Indexed_OnOff.Off,
    On: DOM_HTML_Indexed_OnOff.On,
    OrderedListAlphabetic: DOM_HTML_Indexed_OrderedListType.OrderedListAlphabetic,
    OrderedListNumeric: DOM_HTML_Indexed_OrderedListType.OrderedListNumeric,
    PreloadAuto: DOM_HTML_Indexed_PreloadValue.PreloadAuto,
    PreloadMetadata: DOM_HTML_Indexed_PreloadValue.PreloadMetadata,
    PreloadNone: DOM_HTML_Indexed_PreloadValue.PreloadNone,
    ScopeAuto: DOM_HTML_Indexed_ScopeValue.ScopeAuto,
    ScopeCol: DOM_HTML_Indexed_ScopeValue.ScopeCol,
    ScopeColGroup: DOM_HTML_Indexed_ScopeValue.ScopeColGroup,
    ScopeRow: DOM_HTML_Indexed_ScopeValue.ScopeRow,
    ScopeRowGroup: DOM_HTML_Indexed_ScopeValue.ScopeRowGroup,
    Any: DOM_HTML_Indexed_StepValue.Any,
    Step: DOM_HTML_Indexed_StepValue.Step
};
