// Generated by purs version 0.14.3
"use strict";
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra = require("../Hydra/index.js");
var JetBrains_Palettes = require("../JetBrains.Palettes/index.js");
var $$Math = require("../Math/index.js");
var Modifier = (function () {
    function Modifier() {

    };
    Modifier.value = new Modifier();
    return Modifier;
})();
var Solid = (function () {
    function Solid() {

    };
    Solid.value = new Solid();
    return Solid;
})();
var Change = (function () {
    function Change(value0) {
        this.value0 = value0;
    };
    Change.create = function (value0) {
        return new Change(value0);
    };
    return Change;
})();
var initialState = function (mode) {
    return function (v) {
        return new Data_Tuple.Tuple(mode, "JetBrains");
    };
};
var handleAction = function (dictMonadEffect) {
    return function (v) {
        return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
            return new Data_Tuple.Tuple(v1.value0, v.value0);
        }))(function (v1) {
            var palette = Data_Maybe.fromMaybe(JetBrains_Palettes["default"])(Data_Map_Internal.lookup(Data_Ord.ordString)(v.value0)(JetBrains_Palettes.palettes));
            return Halogen_Query_HalogenM.raise(App_Toolkit_UI.SendToInlet.create("palette")((function () {
                if (v1.value0 instanceof Modifier) {
                    return Hydra.justModifier(Hydra.color(JetBrains_Palettes.toColorMod(palette)));
                };
                if (v1.value0 instanceof Solid) {
                    return Hydra.hydraOf(Hydra.textureOf(JetBrains_Palettes.toSolidSource(palette)));
                };
                throw new Error("Failed pattern match at Hydra.Component.Node.Palette (line 122, column 46 - line 124, column 79): " + [ v1.value0.constructor.name ]);
            })()));
        });
    };
};
var bodyWidth = 110.0;
var render = function (v) {
    var paletteButton = function (idx) {
        return function (v1) {
            return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.class_("palette-button") ])([ Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.cx(7.0 + Data_Int.toNumber(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(idx)(9)) * 12.0), Halogen_Svg_Attributes.cy(7.0 + $$Math.floor(Data_Int.toNumber(idx) / 9.0) * 12.0), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.5)))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_Svg_Attributes.strokeWidth(1.0), Halogen_Svg_Attributes.r(5.0) ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.x(2.0 + Data_Int.toNumber(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(idx)(9)) * 12.0), Halogen_Svg_Attributes.y(2.0 + $$Math.floor(Data_Int.toNumber(idx) / 9.0) * 12.0), Halogen_Svg_Attributes.width(10.0), Halogen_Svg_Attributes.height(10.0), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_HTML_Events.onClick(Data_Function["const"](new Change(v1.value0))) ]) ]);
        };
    };
    var palette = Data_Maybe.fromMaybe(JetBrains_Palettes["default"])(Data_Map_Internal.lookup(Data_Ord.ordString)(v.value1)(JetBrains_Palettes.palettes));
    var colors = JetBrains_Palettes.toArray(palette);
    var colorsCount = Data_Array.length(colors);
    var colorRectWidth = bodyWidth / Data_Int.toNumber(colorsCount);
    var colorRect = function (i) {
        return function (color) {
            return Halogen_Svg_Elements.g([  ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.x(colorRectWidth * Data_Int.toNumber(i)), Halogen_Svg_Attributes.y(0.0), Halogen_Svg_Attributes.width(colorRectWidth), Halogen_Svg_Attributes.height(55.0), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(color))) ]) ]);
        };
    };
    return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.class_("palette-node") ])([ Halogen_Svg_Elements.g([  ])(Data_Array.mapWithIndex(colorRect)(colors)), Halogen_Svg_Elements.g([  ])(Data_Array.mapWithIndex(paletteButton)(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(JetBrains_Palettes.palettes))) ]);
};
var component = function (mode) {
    return function (dictMonadEffect) {
        return Halogen_Component.mkComponent({
            initialState: initialState(mode),
            render: render,
            "eval": Halogen_Component.mkEval({
                handleAction: handleAction(dictMonadEffect),
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                initialize: Halogen_Component.defaultEval.initialize,
                finalize: Halogen_Component.defaultEval.finalize
            })
        });
    };
};
module.exports = {
    Modifier: Modifier,
    Solid: Solid,
    Change: Change,
    bodyWidth: bodyWidth,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
