// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Cm = (function () {
    function Cm(value0) {
        this.value0 = value0;
    };
    Cm.create = function (value0) {
        return new Cm(value0);
    };
    return Cm;
})();
var Mm = (function () {
    function Mm(value0) {
        this.value0 = value0;
    };
    Mm.create = function (value0) {
        return new Mm(value0);
    };
    return Mm;
})();
var Inches = (function () {
    function Inches(value0) {
        this.value0 = value0;
    };
    Inches.create = function (value0) {
        return new Inches(value0);
    };
    return Inches;
})();
var Px = (function () {
    function Px(value0) {
        this.value0 = value0;
    };
    Px.create = function (value0) {
        return new Px(value0);
    };
    return Px;
})();
var Pt = (function () {
    function Pt(value0) {
        this.value0 = value0;
    };
    Pt.create = function (value0) {
        return new Pt(value0);
    };
    return Pt;
})();
var Pc = (function () {
    function Pc(value0) {
        this.value0 = value0;
    };
    Pc.create = function (value0) {
        return new Pc(value0);
    };
    return Pc;
})();
var Em = (function () {
    function Em(value0) {
        this.value0 = value0;
    };
    Em.create = function (value0) {
        return new Em(value0);
    };
    return Em;
})();
var Ex = (function () {
    function Ex(value0) {
        this.value0 = value0;
    };
    Ex.create = function (value0) {
        return new Ex(value0);
    };
    return Ex;
})();
var Rem = (function () {
    function Rem(value0) {
        this.value0 = value0;
    };
    Rem.create = function (value0) {
        return new Rem(value0);
    };
    return Rem;
})();
var Vw = (function () {
    function Vw(value0) {
        this.value0 = value0;
    };
    Vw.create = function (value0) {
        return new Vw(value0);
    };
    return Vw;
})();
var Vh = (function () {
    function Vh(value0) {
        this.value0 = value0;
    };
    Vh.create = function (value0) {
        return new Vh(value0);
    };
    return Vh;
})();
var Vmin = (function () {
    function Vmin(value0) {
        this.value0 = value0;
    };
    Vmin.create = function (value0) {
        return new Vmin(value0);
    };
    return Vmin;
})();
var Vmax = (function () {
    function Vmax(value0) {
        this.value0 = value0;
    };
    Vmax.create = function (value0) {
        return new Vmax(value0);
    };
    return Vmax;
})();
var Pct = (function () {
    function Pct(value0) {
        this.value0 = value0;
    };
    Pct.create = function (value0) {
        return new Pct(value0);
    };
    return Pct;
})();
var Nil = (function () {
    function Nil() {

    };
    Nil.value = new Nil();
    return Nil;
})();
var showCSSLength = new Data_Show.Show(function (v) {
    if (v instanceof Cm) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "cm";
    };
    if (v instanceof Mm) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "mm";
    };
    if (v instanceof Inches) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "in";
    };
    if (v instanceof Px) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "px";
    };
    if (v instanceof Pt) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "pt";
    };
    if (v instanceof Pc) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "pc";
    };
    if (v instanceof Em) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "em";
    };
    if (v instanceof Ex) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "ex";
    };
    if (v instanceof Rem) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "rem";
    };
    if (v instanceof Vw) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vw";
    };
    if (v instanceof Vh) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vh";
    };
    if (v instanceof Vmin) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vmin";
    };
    if (v instanceof Vmax) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vmax";
    };
    if (v instanceof Pct) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "%";
    };
    if (v instanceof Nil) {
        return "0";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.CSSLength (line 28, column 10 - line 43, column 20): " + [ v.constructor.name ]);
});
var printCSSLength = function (v) {
    if (v instanceof Cm) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "cm";
    };
    if (v instanceof Mm) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "mm";
    };
    if (v instanceof Inches) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "in";
    };
    if (v instanceof Px) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "px";
    };
    if (v instanceof Pt) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "pt";
    };
    if (v instanceof Pc) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "pc";
    };
    if (v instanceof Em) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "em";
    };
    if (v instanceof Ex) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "ex";
    };
    if (v instanceof Rem) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "rem";
    };
    if (v instanceof Vw) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vw";
    };
    if (v instanceof Vh) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vh";
    };
    if (v instanceof Vmin) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vmin";
    };
    if (v instanceof Vmax) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "vmax";
    };
    if (v instanceof Pct) {
        return Data_Show.show(Data_Show.showNumber)(v.value0) + "%";
    };
    if (v instanceof Nil) {
        return "0";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.CSSLength (line 48, column 18 - line 63, column 18): " + [ v.constructor.name ]);
};
var eqCSSLength = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Cm && y instanceof Cm) {
            return x.value0 === y.value0;
        };
        if (x instanceof Mm && y instanceof Mm) {
            return x.value0 === y.value0;
        };
        if (x instanceof Inches && y instanceof Inches) {
            return x.value0 === y.value0;
        };
        if (x instanceof Px && y instanceof Px) {
            return x.value0 === y.value0;
        };
        if (x instanceof Pt && y instanceof Pt) {
            return x.value0 === y.value0;
        };
        if (x instanceof Pc && y instanceof Pc) {
            return x.value0 === y.value0;
        };
        if (x instanceof Em && y instanceof Em) {
            return x.value0 === y.value0;
        };
        if (x instanceof Ex && y instanceof Ex) {
            return x.value0 === y.value0;
        };
        if (x instanceof Rem && y instanceof Rem) {
            return x.value0 === y.value0;
        };
        if (x instanceof Vw && y instanceof Vw) {
            return x.value0 === y.value0;
        };
        if (x instanceof Vh && y instanceof Vh) {
            return x.value0 === y.value0;
        };
        if (x instanceof Vmin && y instanceof Vmin) {
            return x.value0 === y.value0;
        };
        if (x instanceof Vmax && y instanceof Vmax) {
            return x.value0 === y.value0;
        };
        if (x instanceof Pct && y instanceof Pct) {
            return x.value0 === y.value0;
        };
        if (x instanceof Nil && y instanceof Nil) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Cm: Cm,
    Mm: Mm,
    Inches: Inches,
    Px: Px,
    Pt: Pt,
    Pc: Pc,
    Em: Em,
    Ex: Ex,
    Rem: Rem,
    Vw: Vw,
    Vh: Vh,
    Vmin: Vmin,
    Vmax: Vmax,
    Pct: Pct,
    Nil: Nil,
    printCSSLength: printCSSLength,
    eqCSSLength: eqCSSLength,
    showCSSLength: showCSSLength
};
