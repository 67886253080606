// Generated by purs version 0.14.3
"use strict";
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Ref = require("../Effect.Ref/index.js");
var Signal_Channel = require("../Signal.Channel/index.js");
var sendIfRef = function (channel) {
    return function (ref) {
        return function (v) {
            return function __do() {
                var flag = Effect_Ref.read(ref)();
                if (flag) {
                    return Signal_Channel.send(channel)(v)();
                };
                return Data_Unit.unit;
            };
        };
    };
};
module.exports = {
    sendIfRef: sendIfRef
};
