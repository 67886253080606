// Generated by purs version 0.14.3
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes_Path = require("../Halogen.Svg.Attributes.Path/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Disconnect = (function () {
    function Disconnect() {

    };
    Disconnect.value = new Disconnect();
    return Disconnect;
})();
var Drag = (function () {
    function Drag(value0) {
        this.value0 = value0;
    };
    Drag.create = function (value0) {
        return new Drag(value0);
    };
    return Drag;
})();
var render = function (v) {
    return Halogen_Svg_Elements.svg([  ])([  ]);
};
var pipeByH = function (v) {
    var mx = (v.x1 - v.x0) / 2.0;
    return [ Halogen_Svg_Attributes_Path.m(Halogen_Svg_Attributes_Path.Abs.value)(v.x0)(v.y0), Halogen_Svg_Attributes_Path.l(Halogen_Svg_Attributes_Path.Abs.value)((v.x0 + mx) - mx / 2.0)(v.y0), Halogen_Svg_Attributes_Path.l(Halogen_Svg_Attributes_Path.Abs.value)(v.x0 + mx + mx / 2.0)(v.y1), Halogen_Svg_Attributes_Path.l(Halogen_Svg_Attributes_Path.Abs.value)(v.x1)(v.y1) ];
};
var initialState = Control_Category.identity(Control_Category.categoryFn);
var handleAction = function (v) {
    if (v instanceof Disconnect) {
        return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
            return state;
        });
    };
    if (v instanceof Drag) {
        return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
            return state;
        });
    };
    throw new Error("Failed pattern match at App.Component.Link (line 61, column 16 - line 65, column 30): " + [ v.constructor.name ]);
};
var component = Halogen_Component.mkComponent({
    initialState: initialState,
    render: render,
    "eval": Halogen_Component.mkEval({
        handleAction: handleAction,
        handleQuery: Halogen_Component.defaultEval.handleQuery,
        receive: Halogen_Component.defaultEval.receive,
        initialize: Halogen_Component.defaultEval.initialize,
        finalize: Halogen_Component.defaultEval.finalize
    })
});
var bezierByV = function (v) {
    var my = v.y0 + (v.y1 - v.y0) / 2.0;
    return [ Halogen_Svg_Attributes_Path.m(Halogen_Svg_Attributes_Path.Abs.value)(v.x0)(v.y0), Halogen_Svg_Attributes_Path.c(Halogen_Svg_Attributes_Path.Abs.value)(v.x0)(my)(v.x1)(my)(v.x1)(v.y1) ];
};
var bezierByH = function (v) {
    var mx = v.x0 + (v.x1 - v.x0) / 2.0;
    return [ Halogen_Svg_Attributes_Path.m(Halogen_Svg_Attributes_Path.Abs.value)(v.x0)(v.y0), Halogen_Svg_Attributes_Path.c(Halogen_Svg_Attributes_Path.Abs.value)(mx)(v.y0)(mx)(v.y1)(v.x1)(v.y1) ];
};
module.exports = {
    Disconnect: Disconnect,
    Drag: Drag,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component,
    bezierByH: bezierByH,
    bezierByV: bezierByV,
    pipeByH: pipeByH
};
