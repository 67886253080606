// Generated by purs version 0.14.3
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Toolkit_Generate = require("../Hydra.Toolkit.Generate/index.js");
var Hydra_Toolkit_Node = require("../Hydra.Toolkit.Node/index.js");
var Noodle_Toolkit = require("../Noodle.Toolkit/index.js");
var toolkit = Noodle_Toolkit.make(Hydra["default"])(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Data_Tuple.Tuple("num", Hydra_Toolkit_Node.number), new Data_Tuple.Tuple("time", Hydra_Toolkit_Node.time), new Data_Tuple.Tuple("mouse", Hydra_Toolkit_Node.mouse), new Data_Tuple.Tuple("pi", Hydra_Toolkit_Node.pi), new Data_Tuple.Tuple("seq", Hydra_Toolkit_Node.seq), new Data_Tuple.Tuple("palette", Hydra_Toolkit_Node.palette), new Data_Tuple.Tuple("solid-pal", Hydra_Toolkit_Node.solidPalette) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(Hydra_Toolkit_Generate.generate)(Hydra_Toolkit_Generate.all))([ new Data_Tuple.Tuple("out", Hydra_Toolkit_Node.out), new Data_Tuple.Tuple("render", Hydra_Toolkit_Node.render), new Data_Tuple.Tuple("from-buffer", Hydra_Toolkit_Node.fromBuffer), new Data_Tuple.Tuple("to-buffer", Hydra_Toolkit_Node.toBuffer), new Data_Tuple.Tuple("math", Hydra_Toolkit_Node.math), new Data_Tuple.Tuple("fast", Hydra_Toolkit_Node.fast), new Data_Tuple.Tuple("sqrt", Hydra_Toolkit_Node.sqrt) ])));
module.exports = {
    toolkit: toolkit
};
