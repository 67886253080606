// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Eq = require("../Data.Eq/index.js");
var Signal = require("../Signal/index.js");
var since = $foreign.sinceP(Signal.constant);
var second = 1000.0;
var millisecond = 1.0;
var every = $foreign.everyP(Signal.constant);
var delay = $foreign.delayP(Signal.constant);
var debounce = function (t) {
    return function (s) {
        var whenEqual = function (value) {
            return function (input) {
                return Signal.filter(Data_Eq.eq(Data_Eq.eqBoolean)(value))(value)(input);
            };
        };
        var whenChangeTo = function (value) {
            return function (input) {
                return whenEqual(value)(Signal.dropRepeats(Data_Eq.eqBoolean)(input));
            };
        };
        var leading = whenChangeTo(false)(since(t)(s));
        return Signal.sampleOn(leading)(s);
    };
};
module.exports = {
    every: every,
    delay: delay,
    since: since,
    debounce: debounce,
    millisecond: millisecond,
    second: second,
    now: $foreign.now
};
