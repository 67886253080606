// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Show = require("../Data.Show/index.js");
var Web_HTML_Common = require("../Web.HTML.Common/index.js");
var slotIdLabel = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "slot-id-label" ]);
var slotFocusAreaDebug = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "slot-focus", "debug" ]);
var slotFocusArea = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "slot-focus" ]);
var removeButton = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "remove-button" ]);
var patchesTabs = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "patches-tabs" ]);
var patchTab = function (name) {
    return Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "patch-tab", "patch-tab-" + name, "no-select" ]);
};
var outlet = function (name) {
    return Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "outlet", "outlet-" + name ]);
};
var nodesTabs = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "nodes-tabs" ]);
var nodes = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "nodes" ]);
var nodeTitleFocusDebug = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "node-title-focus", "debug" ]);
var nodeTitleFocus = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "node-title-focus" ]);
var nodeTitle = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "node-title" ]);
var nodeOutlets = function (dir) {
    return Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "node-outlets", "dir-" + Data_Show.show(App_Style.showSlotDirection)(dir) ]);
};
var nodeInlets = function (dir) {
    return Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "node-inlets", "dir-" + Data_Show.show(App_Style.showSlotDirection)(dir) ]);
};
var nodeButton = function (name) {
    return Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "node-button", "node-tab-" + name, "no-select" ]);
};
var node = function (flow) {
    return function (name) {
        var flowStr = function (v) {
            if (v instanceof App_Style.Vertical) {
                return "vert";
            };
            if (v instanceof App_Style.Horizontal) {
                return "horz";
            };
            throw new Error("Failed pattern match at App.Style.ClassNames (line 47, column 5 - line 47, column 30): " + [ v.constructor.name ]);
        };
        return Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "node", "node-" + flowStr(flow), "node-" + name, "no-select" ]);
    };
};
var inlet = function (name) {
    return Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)([ "inlet", "inlet-" + name ]);
};
module.exports = {
    patchesTabs: patchesTabs,
    patchTab: patchTab,
    nodesTabs: nodesTabs,
    nodeTitle: nodeTitle,
    nodeTitleFocus: nodeTitleFocus,
    nodeTitleFocusDebug: nodeTitleFocusDebug,
    nodeButton: nodeButton,
    removeButton: removeButton,
    node: node,
    nodeInlets: nodeInlets,
    inlet: inlet,
    nodeOutlets: nodeOutlets,
    outlet: outlet,
    nodes: nodes,
    slotIdLabel: slotIdLabel,
    slotFocusArea: slotFocusArea,
    slotFocusAreaDebug: slotFocusAreaDebug
};
