// Generated by purs version 0.14.3
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Monad_Cont_Class = require("../Control.Monad.Cont.Class/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Control_Monad_Writer_Class = require("../Control.Monad.Writer.Class/index.js");
var Control_MonadPlus = require("../Control.MonadPlus/index.js");
var Control_MonadZero = require("../Control.MonadZero/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var MaybeT = function (x) {
    return x;
};
var runMaybeT = function (v) {
    return v;
};
var newtypeMaybeT = new Data_Newtype.Newtype(function () {
    return undefined;
});
var monadTransMaybeT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
    var $72 = Control_Monad.liftM1(dictMonad)(Data_Maybe.Just.create);
    return function ($73) {
        return MaybeT($72($73));
    };
});
var mapMaybeT = function (f) {
    return function (v) {
        return f(v);
    };
};
var functorMaybeT = function (dictFunctor) {
    return new Data_Functor.Functor(function (f) {
        return function (v) {
            return Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Maybe.functorMaybe)(f))(v);
        };
    });
};
var monadMaybeT = function (dictMonad) {
    return new Control_Monad.Monad(function () {
        return applicativeMaybeT(dictMonad);
    }, function () {
        return bindMaybeT(dictMonad);
    });
};
var bindMaybeT = function (dictMonad) {
    return new Control_Bind.Bind(function () {
        return applyMaybeT(dictMonad);
    }, function (v) {
        return function (f) {
            return Control_Bind.bind(dictMonad.Bind1())(v)(function (v1) {
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(dictMonad.Applicative0())(Data_Maybe.Nothing.value);
                };
                if (v1 instanceof Data_Maybe.Just) {
                    var v2 = f(v1.value0);
                    return v2;
                };
                throw new Error("Failed pattern match at Control.Monad.Maybe.Trans (line 55, column 11 - line 57, column 42): " + [ v1.constructor.name ]);
            });
        };
    });
};
var applyMaybeT = function (dictMonad) {
    return new Control_Apply.Apply(function () {
        return functorMaybeT(((dictMonad.Bind1()).Apply0()).Functor0());
    }, Control_Monad.ap(monadMaybeT(dictMonad)));
};
var applicativeMaybeT = function (dictMonad) {
    return new Control_Applicative.Applicative(function () {
        return applyMaybeT(dictMonad);
    }, (function () {
        var $74 = Control_Applicative.pure(dictMonad.Applicative0());
        return function ($75) {
            return MaybeT($74(Data_Maybe.Just.create($75)));
        };
    })());
};
var semigroupMaybeT = function (dictMonad) {
    return function (dictSemigroup) {
        return new Data_Semigroup.Semigroup(Control_Apply.lift2(applyMaybeT(dictMonad))(Data_Semigroup.append(dictSemigroup)));
    };
};
var monadAskMaybeT = function (dictMonadAsk) {
    return new Control_Monad_Reader_Class.MonadAsk(function () {
        return monadMaybeT(dictMonadAsk.Monad0());
    }, Control_Monad_Trans_Class.lift(monadTransMaybeT)(dictMonadAsk.Monad0())(Control_Monad_Reader_Class.ask(dictMonadAsk)));
};
var monadReaderMaybeT = function (dictMonadReader) {
    return new Control_Monad_Reader_Class.MonadReader(function () {
        return monadAskMaybeT(dictMonadReader.MonadAsk0());
    }, function (f) {
        return mapMaybeT(Control_Monad_Reader_Class.local(dictMonadReader)(f));
    });
};
var monadContMaybeT = function (dictMonadCont) {
    return new Control_Monad_Cont_Class.MonadCont(function () {
        return monadMaybeT(dictMonadCont.Monad0());
    }, function (f) {
        return MaybeT(Control_Monad_Cont_Class.callCC(dictMonadCont)(function (c) {
            var v = f(function (a) {
                return MaybeT(c(new Data_Maybe.Just(a)));
            });
            return v;
        }));
    });
};
var monadEffectMaybe = function (dictMonadEffect) {
    return new Effect_Class.MonadEffect(function () {
        return monadMaybeT(dictMonadEffect.Monad0());
    }, (function () {
        var $76 = Control_Monad_Trans_Class.lift(monadTransMaybeT)(dictMonadEffect.Monad0());
        var $77 = Effect_Class.liftEffect(dictMonadEffect);
        return function ($78) {
            return $76($77($78));
        };
    })());
};
var monadRecMaybeT = function (dictMonadRec) {
    return new Control_Monad_Rec_Class.MonadRec(function () {
        return monadMaybeT(dictMonadRec.Monad0());
    }, function (f) {
        var $79 = Control_Monad_Rec_Class.tailRecM(dictMonadRec)(function (a) {
            var v = f(a);
            return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(v)(function (m$prime) {
                return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())((function () {
                    if (m$prime instanceof Data_Maybe.Nothing) {
                        return new Control_Monad_Rec_Class.Done(Data_Maybe.Nothing.value);
                    };
                    if (m$prime instanceof Data_Maybe.Just && m$prime.value0 instanceof Control_Monad_Rec_Class.Loop) {
                        return new Control_Monad_Rec_Class.Loop(m$prime.value0.value0);
                    };
                    if (m$prime instanceof Data_Maybe.Just && m$prime.value0 instanceof Control_Monad_Rec_Class.Done) {
                        return new Control_Monad_Rec_Class.Done(new Data_Maybe.Just(m$prime.value0.value0));
                    };
                    throw new Error("Failed pattern match at Control.Monad.Maybe.Trans (line 85, column 16 - line 88, column 43): " + [ m$prime.constructor.name ]);
                })());
            });
        });
        return function ($80) {
            return MaybeT($79($80));
        };
    });
};
var monadStateMaybeT = function (dictMonadState) {
    return new Control_Monad_State_Class.MonadState(function () {
        return monadMaybeT(dictMonadState.Monad0());
    }, function (f) {
        return Control_Monad_Trans_Class.lift(monadTransMaybeT)(dictMonadState.Monad0())(Control_Monad_State_Class.state(dictMonadState)(f));
    });
};
var monadTellMaybeT = function (dictMonadTell) {
    return new Control_Monad_Writer_Class.MonadTell(function () {
        return monadMaybeT(dictMonadTell.Monad1());
    }, dictMonadTell.Semigroup0, (function () {
        var $81 = Control_Monad_Trans_Class.lift(monadTransMaybeT)(dictMonadTell.Monad1());
        var $82 = Control_Monad_Writer_Class.tell(dictMonadTell);
        return function ($83) {
            return $81($82($83));
        };
    })());
};
var monadWriterMaybeT = function (dictMonadWriter) {
    return new Control_Monad_Writer_Class.MonadWriter(function () {
        return monadTellMaybeT(dictMonadWriter.MonadTell1());
    }, dictMonadWriter.Monoid0, mapMaybeT(function (m) {
        return Control_Bind.bind(((dictMonadWriter.MonadTell1()).Monad1()).Bind1())(Control_Monad_Writer_Class.listen(dictMonadWriter)(m))(function (v) {
            return Control_Applicative.pure(((dictMonadWriter.MonadTell1()).Monad1()).Applicative0())(Data_Functor.map(Data_Maybe.functorMaybe)(function (r) {
                return new Data_Tuple.Tuple(r, v.value1);
            })(v.value0));
        });
    }), mapMaybeT(function (m) {
        return Control_Monad_Writer_Class.pass(dictMonadWriter)(Control_Bind.bind(((dictMonadWriter.MonadTell1()).Monad1()).Bind1())(m)(function (a) {
            return Control_Applicative.pure(((dictMonadWriter.MonadTell1()).Monad1()).Applicative0())((function () {
                if (a instanceof Data_Maybe.Nothing) {
                    return new Data_Tuple.Tuple(Data_Maybe.Nothing.value, Control_Category.identity(Control_Category.categoryFn));
                };
                if (a instanceof Data_Maybe.Just) {
                    return new Data_Tuple.Tuple(new Data_Maybe.Just(a.value0.value0), a.value0.value1);
                };
                throw new Error("Failed pattern match at Control.Monad.Maybe.Trans (line 122, column 10 - line 124, column 43): " + [ a.constructor.name ]);
            })());
        }));
    }));
};
var monadThrowMaybeT = function (dictMonadThrow) {
    return new Control_Monad_Error_Class.MonadThrow(function () {
        return monadMaybeT(dictMonadThrow.Monad0());
    }, function (e) {
        return Control_Monad_Trans_Class.lift(monadTransMaybeT)(dictMonadThrow.Monad0())(Control_Monad_Error_Class.throwError(dictMonadThrow)(e));
    });
};
var monadErrorMaybeT = function (dictMonadError) {
    return new Control_Monad_Error_Class.MonadError(function () {
        return monadThrowMaybeT(dictMonadError.MonadThrow0());
    }, function (v) {
        return function (h) {
            return MaybeT(Control_Monad_Error_Class.catchError(dictMonadError)(v)(function (a) {
                var v1 = h(a);
                return v1;
            }));
        };
    });
};
var monoidMaybeT = function (dictMonad) {
    return function (dictMonoid) {
        return new Data_Monoid.Monoid(function () {
            return semigroupMaybeT(dictMonad)(dictMonoid.Semigroup0());
        }, Control_Applicative.pure(applicativeMaybeT(dictMonad))(Data_Monoid.mempty(dictMonoid)));
    };
};
var altMaybeT = function (dictMonad) {
    return new Control_Alt.Alt(function () {
        return functorMaybeT(((dictMonad.Bind1()).Apply0()).Functor0());
    }, function (v) {
        return function (v1) {
            return Control_Bind.bind(dictMonad.Bind1())(v)(function (m) {
                if (m instanceof Data_Maybe.Nothing) {
                    return v1;
                };
                return Control_Applicative.pure(dictMonad.Applicative0())(m);
            });
        };
    });
};
var plusMaybeT = function (dictMonad) {
    return new Control_Plus.Plus(function () {
        return altMaybeT(dictMonad);
    }, Control_Applicative.pure(dictMonad.Applicative0())(Data_Maybe.Nothing.value));
};
var alternativeMaybeT = function (dictMonad) {
    return new Control_Alternative.Alternative(function () {
        return applicativeMaybeT(dictMonad);
    }, function () {
        return plusMaybeT(dictMonad);
    });
};
var monadPlusMaybeT = function (dictMonad) {
    return new Control_MonadPlus.MonadPlus(function () {
        return alternativeMaybeT(dictMonad);
    }, function () {
        return monadMaybeT(dictMonad);
    });
};
var monadZeroMaybeT = function (dictMonad) {
    return new Control_MonadZero.MonadZero(function () {
        return alternativeMaybeT(dictMonad);
    }, function () {
        return monadMaybeT(dictMonad);
    }, function () {
        return undefined;
    });
};
module.exports = {
    MaybeT: MaybeT,
    runMaybeT: runMaybeT,
    mapMaybeT: mapMaybeT,
    newtypeMaybeT: newtypeMaybeT,
    functorMaybeT: functorMaybeT,
    applyMaybeT: applyMaybeT,
    applicativeMaybeT: applicativeMaybeT,
    bindMaybeT: bindMaybeT,
    monadMaybeT: monadMaybeT,
    monadTransMaybeT: monadTransMaybeT,
    altMaybeT: altMaybeT,
    plusMaybeT: plusMaybeT,
    alternativeMaybeT: alternativeMaybeT,
    monadPlusMaybeT: monadPlusMaybeT,
    monadZeroMaybeT: monadZeroMaybeT,
    monadRecMaybeT: monadRecMaybeT,
    monadEffectMaybe: monadEffectMaybe,
    monadContMaybeT: monadContMaybeT,
    monadThrowMaybeT: monadThrowMaybeT,
    monadErrorMaybeT: monadErrorMaybeT,
    monadAskMaybeT: monadAskMaybeT,
    monadReaderMaybeT: monadReaderMaybeT,
    monadStateMaybeT: monadStateMaybeT,
    monadTellMaybeT: monadTellMaybeT,
    monadWriterMaybeT: monadWriterMaybeT,
    semigroupMaybeT: semigroupMaybeT,
    monoidMaybeT: monoidMaybeT,
    MonadTrans: Control_Monad_Trans_Class.MonadTrans,
    lift: Control_Monad_Trans_Class.lift
};
