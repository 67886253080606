// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Meet = (function () {
    function Meet() {

    };
    Meet.value = new Meet();
    return Meet;
})();
var Slice = (function () {
    function Slice() {

    };
    Slice.value = new Slice();
    return Slice;
})();
var showMeetOrSlice = new Data_Show.Show(function (v) {
    if (v instanceof Meet) {
        return "Meet";
    };
    if (v instanceof Slice) {
        return "Slice";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.MeetOrSlice (line 15, column 10 - line 17, column 21): " + [ v.constructor.name ]);
});
var printMeetOrSlice = function (v) {
    if (v instanceof Meet) {
        return "meet";
    };
    if (v instanceof Slice) {
        return "slice";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.MeetOrSlice (line 20, column 20 - line 22, column 19): " + [ v.constructor.name ]);
};
var eqMeetOrSlice = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Meet && y instanceof Meet) {
            return true;
        };
        if (x instanceof Slice && y instanceof Slice) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Meet: Meet,
    Slice: Slice,
    printMeetOrSlice: printMeetOrSlice,
    eqMeetOrSlice: eqMeetOrSlice,
    showMeetOrSlice: showMeetOrSlice
};
