// Generated by purs version 0.14.3
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Hydra_Fn = require("../Hydra.Fn/index.js");
var Addition = (function () {
    function Addition() {

    };
    Addition.value = new Addition();
    return Addition;
})();
var Division = (function () {
    function Division() {

    };
    Division.value = new Division();
    return Division;
})();
var Multiplication = (function () {
    function Multiplication() {

    };
    Multiplication.value = new Multiplication();
    return Multiplication;
})();
var Subtraction = (function () {
    function Subtraction() {

    };
    Subtraction.value = new Subtraction();
    return Subtraction;
})();
var Num = (function () {
    function Num(value0) {
        this.value0 = value0;
    };
    Num.create = function (value0) {
        return new Num(value0);
    };
    return Num;
})();
var MouseX = (function () {
    function MouseX() {

    };
    MouseX.value = new MouseX();
    return MouseX;
})();
var MouseY = (function () {
    function MouseY() {

    };
    MouseY.value = new MouseY();
    return MouseY;
})();
var Time = (function () {
    function Time() {

    };
    Time.value = new Time();
    return Time;
})();
var Seq = (function () {
    function Seq(value0) {
        this.value0 = value0;
    };
    Seq.create = function (value0) {
        return new Seq(value0);
    };
    return Seq;
})();
var CanvasWidth = (function () {
    function CanvasWidth() {

    };
    CanvasWidth.value = new CanvasWidth();
    return CanvasWidth;
})();
var CanvasHeight = (function () {
    function CanvasHeight() {

    };
    CanvasHeight.value = new CanvasHeight();
    return CanvasHeight;
})();
var WindowWidth = (function () {
    function WindowWidth() {

    };
    WindowWidth.value = new WindowWidth();
    return WindowWidth;
})();
var WindowHeight = (function () {
    function WindowHeight() {

    };
    WindowHeight.value = new WindowHeight();
    return WindowHeight;
})();
var Pi = (function () {
    function Pi() {

    };
    Pi.value = new Pi();
    return Pi;
})();
var Expr = (function () {
    function Expr(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Expr.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Expr(value0, value1, value2);
            };
        };
    };
    return Expr;
})();
var Dynamic = (function () {
    function Dynamic(value0) {
        this.value0 = value0;
    };
    Dynamic.create = function (value0) {
        return new Dynamic(value0);
    };
    return Dynamic;
})();
var OfTime = (function () {
    function OfTime(value0) {
        this.value0 = value0;
    };
    OfTime.create = function (value0) {
        return new OfTime(value0);
    };
    return OfTime;
})();
var Harmonic = (function () {
    function Harmonic(value0) {
        this.value0 = value0;
    };
    Harmonic.create = function (value0) {
        return new Harmonic(value0);
    };
    return Harmonic;
})();
var Fast = (function () {
    function Fast(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fast.create = function (value0) {
        return function (value1) {
            return new Fast(value0, value1);
        };
    };
    return Fast;
})();
var Sqrt = (function () {
    function Sqrt(value0) {
        this.value0 = value0;
    };
    Sqrt.create = function (value0) {
        return new Sqrt(value0);
    };
    return Sqrt;
})();
var Rotate = (function () {
    function Rotate(value0) {
        this.value0 = value0;
    };
    Rotate.create = function (value0) {
        return new Rotate(value0);
    };
    return Rotate;
})();
var Scale = (function () {
    function Scale(value0) {
        this.value0 = value0;
    };
    Scale.create = function (value0) {
        return new Scale(value0);
    };
    return Scale;
})();
var Pixelate = (function () {
    function Pixelate(value0) {
        this.value0 = value0;
    };
    Pixelate.create = function (value0) {
        return new Pixelate(value0);
    };
    return Pixelate;
})();
var Repeat = (function () {
    function Repeat(value0) {
        this.value0 = value0;
    };
    Repeat.create = function (value0) {
        return new Repeat(value0);
    };
    return Repeat;
})();
var RepeatX = (function () {
    function RepeatX(value0) {
        this.value0 = value0;
    };
    RepeatX.create = function (value0) {
        return new RepeatX(value0);
    };
    return RepeatX;
})();
var RepeatY = (function () {
    function RepeatY(value0) {
        this.value0 = value0;
    };
    RepeatY.create = function (value0) {
        return new RepeatY(value0);
    };
    return RepeatY;
})();
var Kaleid = (function () {
    function Kaleid(value0) {
        this.value0 = value0;
    };
    Kaleid.create = function (value0) {
        return new Kaleid(value0);
    };
    return Kaleid;
})();
var ScrollX = (function () {
    function ScrollX(value0) {
        this.value0 = value0;
    };
    ScrollX.create = function (value0) {
        return new ScrollX(value0);
    };
    return ScrollX;
})();
var ScrollY = (function () {
    function ScrollY(value0) {
        this.value0 = value0;
    };
    ScrollY.create = function (value0) {
        return new ScrollY(value0);
    };
    return ScrollY;
})();
var Posterize = (function () {
    function Posterize(value0) {
        this.value0 = value0;
    };
    Posterize.create = function (value0) {
        return new Posterize(value0);
    };
    return Posterize;
})();
var Shift = (function () {
    function Shift(value0) {
        this.value0 = value0;
    };
    Shift.create = function (value0) {
        return new Shift(value0);
    };
    return Shift;
})();
var Invert = (function () {
    function Invert(value0) {
        this.value0 = value0;
    };
    Invert.create = function (value0) {
        return new Invert(value0);
    };
    return Invert;
})();
var Contrast = (function () {
    function Contrast(value0) {
        this.value0 = value0;
    };
    Contrast.create = function (value0) {
        return new Contrast(value0);
    };
    return Contrast;
})();
var Brightness = (function () {
    function Brightness(value0) {
        this.value0 = value0;
    };
    Brightness.create = function (value0) {
        return new Brightness(value0);
    };
    return Brightness;
})();
var Luma = (function () {
    function Luma(value0) {
        this.value0 = value0;
    };
    Luma.create = function (value0) {
        return new Luma(value0);
    };
    return Luma;
})();
var Tresh = (function () {
    function Tresh(value0) {
        this.value0 = value0;
    };
    Tresh.create = function (value0) {
        return new Tresh(value0);
    };
    return Tresh;
})();
var Color = (function () {
    function Color(value0) {
        this.value0 = value0;
    };
    Color.create = function (value0) {
        return new Color(value0);
    };
    return Color;
})();
var Saturate = (function () {
    function Saturate(value0) {
        this.value0 = value0;
    };
    Saturate.create = function (value0) {
        return new Saturate(value0);
    };
    return Saturate;
})();
var Hue = (function () {
    function Hue(value0) {
        this.value0 = value0;
    };
    Hue.create = function (value0) {
        return new Hue(value0);
    };
    return Hue;
})();
var Colorama = (function () {
    function Colorama(value0) {
        this.value0 = value0;
    };
    Colorama.create = function (value0) {
        return new Colorama(value0);
    };
    return Colorama;
})();
var Default = (function () {
    function Default() {

    };
    Default.value = new Default();
    return Default;
})();
var O0 = (function () {
    function O0() {

    };
    O0.value = new O0();
    return O0;
})();
var O1 = (function () {
    function O1() {

    };
    O1.value = new O1();
    return O1;
})();
var O2 = (function () {
    function O2() {

    };
    O2.value = new O2();
    return O2;
})();
var O3 = (function () {
    function O3() {

    };
    O3.value = new O3();
    return O3;
})();
var S0 = (function () {
    function S0() {

    };
    S0.value = new S0();
    return S0;
})();
var S1 = (function () {
    function S1() {

    };
    S1.value = new S1();
    return S1;
})();
var S2 = (function () {
    function S2() {

    };
    S2.value = new S2();
    return S2;
})();
var S3 = (function () {
    function S3() {

    };
    S3.value = new S3();
    return S3;
})();
var Noise = (function () {
    function Noise(value0) {
        this.value0 = value0;
    };
    Noise.create = function (value0) {
        return new Noise(value0);
    };
    return Noise;
})();
var Voronoi = (function () {
    function Voronoi(value0) {
        this.value0 = value0;
    };
    Voronoi.create = function (value0) {
        return new Voronoi(value0);
    };
    return Voronoi;
})();
var Osc = (function () {
    function Osc(value0) {
        this.value0 = value0;
    };
    Osc.create = function (value0) {
        return new Osc(value0);
    };
    return Osc;
})();
var Shape = (function () {
    function Shape(value0) {
        this.value0 = value0;
    };
    Shape.create = function (value0) {
        return new Shape(value0);
    };
    return Shape;
})();
var Gradient = (function () {
    function Gradient(value0) {
        this.value0 = value0;
    };
    Gradient.create = function (value0) {
        return new Gradient(value0);
    };
    return Gradient;
})();
var Solid = (function () {
    function Solid(value0) {
        this.value0 = value0;
    };
    Solid.create = function (value0) {
        return new Solid(value0);
    };
    return Solid;
})();
var Source = (function () {
    function Source(value0) {
        this.value0 = value0;
    };
    Source.create = function (value0) {
        return new Source(value0);
    };
    return Source;
})();
var Add = (function () {
    function Add(value0) {
        this.value0 = value0;
    };
    Add.create = function (value0) {
        return new Add(value0);
    };
    return Add;
})();
var Layer = (function () {
    function Layer(value0) {
        this.value0 = value0;
    };
    Layer.create = function (value0) {
        return new Layer(value0);
    };
    return Layer;
})();
var Blend = (function () {
    function Blend(value0) {
        this.value0 = value0;
    };
    Blend.create = function (value0) {
        return new Blend(value0);
    };
    return Blend;
})();
var Mult = (function () {
    function Mult(value0) {
        this.value0 = value0;
    };
    Mult.create = function (value0) {
        return new Mult(value0);
    };
    return Mult;
})();
var Diff = (function () {
    function Diff(value0) {
        this.value0 = value0;
    };
    Diff.create = function (value0) {
        return new Diff(value0);
    };
    return Diff;
})();
var Mask = (function () {
    function Mask(value0) {
        this.value0 = value0;
    };
    Mask.create = function (value0) {
        return new Mask(value0);
    };
    return Mask;
})();
var Texture = (function () {
    function Texture(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Texture.create = function (value0) {
        return function (value1) {
            return new Texture(value0, value1);
        };
    };
    return Texture;
})();
var G = (function () {
    function G(value0) {
        this.value0 = value0;
    };
    G.create = function (value0) {
        return new G(value0);
    };
    return G;
})();
var C = (function () {
    function C(value0) {
        this.value0 = value0;
    };
    C.create = function (value0) {
        return new C(value0);
    };
    return C;
})();
var B = (function () {
    function B(value0) {
        this.value0 = value0;
    };
    B.create = function (value0) {
        return new B(value0);
    };
    return B;
})();
var M = (function () {
    function M(value0) {
        this.value0 = value0;
    };
    M.create = function (value0) {
        return new M(value0);
    };
    return M;
})();
var ModulateRepeat = (function () {
    function ModulateRepeat(value0) {
        this.value0 = value0;
    };
    ModulateRepeat.create = function (value0) {
        return new ModulateRepeat(value0);
    };
    return ModulateRepeat;
})();
var ModulateRepeatX = (function () {
    function ModulateRepeatX(value0) {
        this.value0 = value0;
    };
    ModulateRepeatX.create = function (value0) {
        return new ModulateRepeatX(value0);
    };
    return ModulateRepeatX;
})();
var ModulateRepeatY = (function () {
    function ModulateRepeatY(value0) {
        this.value0 = value0;
    };
    ModulateRepeatY.create = function (value0) {
        return new ModulateRepeatY(value0);
    };
    return ModulateRepeatY;
})();
var ModulateKaleid = (function () {
    function ModulateKaleid(value0) {
        this.value0 = value0;
    };
    ModulateKaleid.create = function (value0) {
        return new ModulateKaleid(value0);
    };
    return ModulateKaleid;
})();
var ModulateScrollX = (function () {
    function ModulateScrollX(value0) {
        this.value0 = value0;
    };
    ModulateScrollX.create = function (value0) {
        return new ModulateScrollX(value0);
    };
    return ModulateScrollX;
})();
var ModulateScrollY = (function () {
    function ModulateScrollY(value0) {
        this.value0 = value0;
    };
    ModulateScrollY.create = function (value0) {
        return new ModulateScrollY(value0);
    };
    return ModulateScrollY;
})();
var Modulate = (function () {
    function Modulate(value0) {
        this.value0 = value0;
    };
    Modulate.create = function (value0) {
        return new Modulate(value0);
    };
    return Modulate;
})();
var ModulateScale = (function () {
    function ModulateScale(value0) {
        this.value0 = value0;
    };
    ModulateScale.create = function (value0) {
        return new ModulateScale(value0);
    };
    return ModulateScale;
})();
var ModulatePixelate = (function () {
    function ModulatePixelate(value0) {
        this.value0 = value0;
    };
    ModulatePixelate.create = function (value0) {
        return new ModulatePixelate(value0);
    };
    return ModulatePixelate;
})();
var ModulateRotate = (function () {
    function ModulateRotate(value0) {
        this.value0 = value0;
    };
    ModulateRotate.create = function (value0) {
        return new ModulateRotate(value0);
    };
    return ModulateRotate;
})();
var ModulateHue = (function () {
    function ModulateHue(value0) {
        this.value0 = value0;
    };
    ModulateHue.create = function (value0) {
        return new ModulateHue(value0);
    };
    return ModulateHue;
})();
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Op = (function () {
    function Op(value0) {
        this.value0 = value0;
    };
    Op.create = function (value0) {
        return new Op(value0);
    };
    return Op;
})();
var Val = (function () {
    function Val(value0) {
        this.value0 = value0;
    };
    Val.create = function (value0) {
        return new Val(value0);
    };
    return Val;
})();
var Mod = (function () {
    function Mod(value0) {
        this.value0 = value0;
    };
    Mod.create = function (value0) {
        return new Mod(value0);
    };
    return Mod;
})();
var Tex = (function () {
    function Tex(value0) {
        this.value0 = value0;
    };
    Tex.create = function (value0) {
        return new Tex(value0);
    };
    return Tex;
})();
var Buf = (function () {
    function Buf(value0) {
        this.value0 = value0;
    };
    Buf.create = function (value0) {
        return new Buf(value0);
    };
    return Buf;
})();
var T = (function () {
    function T(value0) {
        this.value0 = value0;
    };
    T.create = function (value0) {
        return new T(value0);
    };
    return T;
})();
var V = (function () {
    function V(value0) {
        this.value0 = value0;
    };
    V.create = function (value0) {
        return new V(value0);
    };
    return V;
})();
var toFnSourceValue = new Hydra_Fn.ToFn(function (v) {
    if (v instanceof Noise) {
        return Hydra_Fn.fn("noise")([ new Data_Tuple.Tuple("scale", v.value0.scale), new Data_Tuple.Tuple("offset", v.value0.offset) ]);
    };
    if (v instanceof Voronoi) {
        return Hydra_Fn.fn("voronoi")([ new Data_Tuple.Tuple("scale", v.value0.scale), new Data_Tuple.Tuple("speed", v.value0.speed), new Data_Tuple.Tuple("blending", v.value0.blending) ]);
    };
    if (v instanceof Osc) {
        return Hydra_Fn.fn("osc")([ new Data_Tuple.Tuple("freq", v.value0.freq), new Data_Tuple.Tuple("sync", v.value0.sync), new Data_Tuple.Tuple("offset", v.value0.offset) ]);
    };
    if (v instanceof Shape) {
        return Hydra_Fn.fn("shape")([ new Data_Tuple.Tuple("sides", v.value0.sides), new Data_Tuple.Tuple("radius", v.value0.radius), new Data_Tuple.Tuple("smoothing", v.value0.smoothing) ]);
    };
    if (v instanceof Gradient) {
        return Hydra_Fn.fn("gradient")([ new Data_Tuple.Tuple("speed", v.value0.speed) ]);
    };
    if (v instanceof Solid) {
        return Hydra_Fn.fn("solid")([ new Data_Tuple.Tuple("r", v.value0.r), new Data_Tuple.Tuple("g", v.value0.g), new Data_Tuple.Tuple("b", v.value0.b), new Data_Tuple.Tuple("a", v.value0.a) ]);
    };
    if (v instanceof Source) {
        return Hydra_Fn.fn("src")([  ]);
    };
    throw new Error("Failed pattern match at Hydra (line 379, column 1 - line 386, column 37): " + [ v.constructor.name ]);
});
var toFnGeometryValue = new Hydra_Fn.ToFn(function (v) {
    if (v instanceof Rotate) {
        return Hydra_Fn.fn("rotate")([ new Data_Tuple.Tuple("angle", v.value0.angle), new Data_Tuple.Tuple("speed", v.value0.speed) ]);
    };
    if (v instanceof Scale) {
        return Hydra_Fn.fn("scale")([ new Data_Tuple.Tuple("amount", v.value0.amount), new Data_Tuple.Tuple("xMult", v.value0.xMult), new Data_Tuple.Tuple("yMult", v.value0.yMult), new Data_Tuple.Tuple("offsetX", v.value0.offsetX), new Data_Tuple.Tuple("offsetY", v.value0.offsetY) ]);
    };
    if (v instanceof Pixelate) {
        return Hydra_Fn.fn("pixelate")([ new Data_Tuple.Tuple("pixelX", v.value0.pixelX), new Data_Tuple.Tuple("pixelY", v.value0.pixelY) ]);
    };
    if (v instanceof Repeat) {
        return Hydra_Fn.fn("repeat")([ new Data_Tuple.Tuple("repeatX", v.value0.repeatX), new Data_Tuple.Tuple("repeatY", v.value0.repeatY), new Data_Tuple.Tuple("offsetX", v.value0.offsetX), new Data_Tuple.Tuple("offsetY", v.value0.offsetY) ]);
    };
    if (v instanceof RepeatX) {
        return Hydra_Fn.fn("repeatX")([ new Data_Tuple.Tuple("reps", v.value0.reps), new Data_Tuple.Tuple("offset", v.value0.offset) ]);
    };
    if (v instanceof RepeatY) {
        return Hydra_Fn.fn("repeatY")([ new Data_Tuple.Tuple("reps", v.value0.reps), new Data_Tuple.Tuple("offset", v.value0.offset) ]);
    };
    if (v instanceof Kaleid) {
        return Hydra_Fn.fn("kaleid")([ new Data_Tuple.Tuple("nSides", v.value0.nSides) ]);
    };
    if (v instanceof ScrollX) {
        return Hydra_Fn.fn("scrollX")([ new Data_Tuple.Tuple("scrollX", v.value0.scrollX), new Data_Tuple.Tuple("speed", v.value0.speed) ]);
    };
    if (v instanceof ScrollY) {
        return Hydra_Fn.fn("scrollY")([ new Data_Tuple.Tuple("scrollY", v.value0.scrollY), new Data_Tuple.Tuple("speed", v.value0.speed) ]);
    };
    throw new Error("Failed pattern match at Hydra (line 389, column 1 - line 405, column 86): " + [ v.constructor.name ]);
});
var toFnColorValue = new Hydra_Fn.ToFn(function (v) {
    if (v instanceof Posterize) {
        return Hydra_Fn.fn("posterize")([ new Data_Tuple.Tuple("bins", v.value0.bins), new Data_Tuple.Tuple("gamma", v.value0.gamma) ]);
    };
    if (v instanceof Shift) {
        return Hydra_Fn.fn("shift")([ new Data_Tuple.Tuple("r", v.value0.r), new Data_Tuple.Tuple("g", v.value0.g), new Data_Tuple.Tuple("b", v.value0.b), new Data_Tuple.Tuple("a", v.value0.a) ]);
    };
    if (v instanceof Invert) {
        return Hydra_Fn.fn("invert")([ new Data_Tuple.Tuple("amount", v.value0.amount) ]);
    };
    if (v instanceof Contrast) {
        return Hydra_Fn.fn("contrast")([ new Data_Tuple.Tuple("amount", v.value0.amount) ]);
    };
    if (v instanceof Brightness) {
        return Hydra_Fn.fn("brightness")([ new Data_Tuple.Tuple("amount", v.value0.amount) ]);
    };
    if (v instanceof Luma) {
        return Hydra_Fn.fn("luma")([ new Data_Tuple.Tuple("threshold", v.value0.threshold), new Data_Tuple.Tuple("tolerance", v.value0.tolerance) ]);
    };
    if (v instanceof Tresh) {
        return Hydra_Fn.fn("thresh")([ new Data_Tuple.Tuple("threshold", v.value0.threshold), new Data_Tuple.Tuple("tolerance", v.value0.tolerance) ]);
    };
    if (v instanceof Color) {
        return Hydra_Fn.fn("color")([ new Data_Tuple.Tuple("r", v.value0.r), new Data_Tuple.Tuple("g", v.value0.g), new Data_Tuple.Tuple("b", v.value0.b), new Data_Tuple.Tuple("a", v.value0.a) ]);
    };
    if (v instanceof Saturate) {
        return Hydra_Fn.fn("saturate")([ new Data_Tuple.Tuple("amount", v.value0.amount) ]);
    };
    if (v instanceof Hue) {
        return Hydra_Fn.fn("hue")([ new Data_Tuple.Tuple("amount", v.value0.amount) ]);
    };
    if (v instanceof Colorama) {
        return Hydra_Fn.fn("colorama")([ new Data_Tuple.Tuple("amount", v.value0.amount) ]);
    };
    throw new Error("Failed pattern match at Hydra (line 408, column 1 - line 419, column 67): " + [ v.constructor.name ]);
});
var showOperation = new Data_Show.Show(function (v) {
    if (v instanceof Addition) {
        return "+";
    };
    if (v instanceof Subtraction) {
        return "-";
    };
    if (v instanceof Multiplication) {
        return "*";
    };
    if (v instanceof Division) {
        return "/";
    };
    throw new Error("Failed pattern match at Hydra (line 464, column 1 - line 468, column 24): " + [ v.constructor.name ]);
});
var showValue = new Data_Show.Show(function (v) {
    if (v instanceof Num) {
        return Data_Show.show(Data_Show.showNumber)(v.value0);
    };
    if (v instanceof MouseX) {
        return "{mouse.x}";
    };
    if (v instanceof MouseY) {
        return "{mouse.y}";
    };
    if (v instanceof Time) {
        return "{time}";
    };
    if (v instanceof CanvasWidth) {
        return "{width}";
    };
    if (v instanceof CanvasHeight) {
        return "{height}";
    };
    if (v instanceof WindowWidth) {
        return "{win.width}";
    };
    if (v instanceof WindowHeight) {
        return "{win.height}";
    };
    if (v instanceof Seq) {
        return Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(showValue))(v.value0));
    };
    if (v instanceof Pi) {
        return "{pi}";
    };
    if (v instanceof Expr) {
        return "{" + (Data_Show.show(showValue)(v.value0) + (Data_Show.show(showOperation)(v.value1) + (Data_Show.show(showValue)(v.value2) + "}")));
    };
    if (v instanceof OfTime) {
        return "{time -> " + (Data_Show.show(showValue)(v.value0) + "}");
    };
    if (v instanceof Dynamic) {
        return "{* -> " + (Data_Show.show(showValue)(v.value0) + "}");
    };
    if (v instanceof Harmonic) {
        return "{fft:" + (Data_Show.show(Data_Show.showInt)(v.value0) + "}");
    };
    if (v instanceof Fast) {
        return "{fast " + (Data_Show.show(Data_Show.showNumber)(v.value0) + (" " + (Data_Show.show(showValue)(v.value1) + "}")));
    };
    if (v instanceof Sqrt) {
        return "{sqrt " + (Data_Show.show(showValue)(v.value0) + "}");
    };
    throw new Error("Failed pattern match at Hydra (line 471, column 1 - line 487, column 46): " + [ v.constructor.name ]);
});
var showBuffer = new Data_Show.Show(function (v) {
    if (v instanceof Default) {
        return "def";
    };
    if (v instanceof O0) {
        return "o0";
    };
    if (v instanceof O1) {
        return "o1";
    };
    if (v instanceof O2) {
        return "o2";
    };
    if (v instanceof O3) {
        return "o3";
    };
    if (v instanceof S0) {
        return "s0";
    };
    if (v instanceof S1) {
        return "s1";
    };
    if (v instanceof S2) {
        return "s2";
    };
    if (v instanceof S3) {
        return "s3";
    };
    throw new Error("Failed pattern match at Hydra (line 490, column 1 - line 499, column 19): " + [ v.constructor.name ]);
});
var width = new Val(CanvasWidth.value);
var v$prime = V.create;
var toValue = function (v) {
    if (v instanceof Val) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var toTexture = function (v) {
    if (v instanceof Tex) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var toModifier = function (v) {
    if (v instanceof Mod) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var toBuffer = function (v) {
    if (v instanceof Buf) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var time = new Val(Time.value);
var textureOrValue = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof T) {
                return v(v2.value0);
            };
            if (v2 instanceof V) {
                return v1(v2.value0);
            };
            throw new Error("Failed pattern match at Hydra (line 374, column 1 - line 374, column 82): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var textureOf = function (src) {
    return new Texture(src, [  ]);
};
var t$prime = T.create;
var toFnBlendTextureOrValue = new Hydra_Fn.ToFn(function (v) {
    if (v instanceof Add) {
        return Hydra_Fn.fn("add")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("amount", v$prime(v.value0.amount)) ]);
    };
    if (v instanceof Layer) {
        return Hydra_Fn.fn("layer")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)) ]);
    };
    if (v instanceof Blend) {
        return Hydra_Fn.fn("blend")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("amount", v$prime(v.value0.amount)) ]);
    };
    if (v instanceof Mult) {
        return Hydra_Fn.fn("mult")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("amount", v$prime(v.value0.amount)) ]);
    };
    if (v instanceof Diff) {
        return Hydra_Fn.fn("diff")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)) ]);
    };
    if (v instanceof Mask) {
        return Hydra_Fn.fn("mask")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("reps", v$prime(v.value0.reps)), new Data_Tuple.Tuple("offset", v$prime(v.value0.offset)) ]);
    };
    throw new Error("Failed pattern match at Hydra (line 422, column 1 - line 428, column 105): " + [ v.constructor.name ]);
});
var toFnModulateTextureOrValu = new Hydra_Fn.ToFn(function (v) {
    if (v instanceof ModulateRepeat) {
        return Hydra_Fn.fn("modulateRepeat")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("repeatX", v$prime(v.value0.repeatX)), new Data_Tuple.Tuple("repeatY", v$prime(v.value0.repeatY)), new Data_Tuple.Tuple("offsetX", v$prime(v.value0.offsetX)), new Data_Tuple.Tuple("offsetY", v$prime(v.value0.offsetY)) ]);
    };
    if (v instanceof ModulateRepeatX) {
        return Hydra_Fn.fn("modulateRepeatX")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("reps", v$prime(v.value0.reps)), new Data_Tuple.Tuple("offset", v$prime(v.value0.offset)) ]);
    };
    if (v instanceof ModulateRepeatY) {
        return Hydra_Fn.fn("modulateRepeatY")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("reps", v$prime(v.value0.reps)), new Data_Tuple.Tuple("offset", v$prime(v.value0.offset)) ]);
    };
    if (v instanceof ModulateKaleid) {
        return Hydra_Fn.fn("modulateKaleid")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("nSides", v$prime(v.value0.nSides)) ]);
    };
    if (v instanceof ModulateScrollX) {
        return Hydra_Fn.fn("modulateScrollX")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("scrollX", v$prime(v.value0.scrollX)), new Data_Tuple.Tuple("speed", v$prime(v.value0.speed)) ]);
    };
    if (v instanceof ModulateScrollY) {
        return Hydra_Fn.fn("modulateScrollY")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("scrollY", v$prime(v.value0.scrollY)), new Data_Tuple.Tuple("speed", v$prime(v.value0.speed)) ]);
    };
    if (v instanceof Modulate) {
        return Hydra_Fn.fn("modulate")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("amount", v$prime(v.value0.amount)) ]);
    };
    if (v instanceof ModulateScale) {
        return Hydra_Fn.fn("modulateScale")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("multiple", v$prime(v.value0.multiple)), new Data_Tuple.Tuple("offset", v$prime(v.value0.offset)) ]);
    };
    if (v instanceof ModulatePixelate) {
        return Hydra_Fn.fn("modulatePixelate")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("multiple", v$prime(v.value0.multiple)), new Data_Tuple.Tuple("offset", v$prime(v.value0.offset)) ]);
    };
    if (v instanceof ModulateRotate) {
        return Hydra_Fn.fn("modulateRotate")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("multiple", v$prime(v.value0.multiple)), new Data_Tuple.Tuple("offset", v$prime(v.value0.offset)) ]);
    };
    if (v instanceof ModulateHue) {
        return Hydra_Fn.fn("modulateHue")([ new Data_Tuple.Tuple("what", t$prime(v.value0.what)), new Data_Tuple.Tuple("amount", v$prime(v.value0.amount)) ]);
    };
    throw new Error("Failed pattern match at Hydra (line 431, column 1 - line 454, column 101): " + [ v.constructor.name ]);
});
var toFnModifierTextureOrValu = new Hydra_Fn.ToFn(function (v) {
    if (v instanceof G) {
        return Data_Functor.map(Hydra_Fn.fnFunctor)(v$prime)(Hydra_Fn.toFn(toFnGeometryValue)(v.value0));
    };
    if (v instanceof C) {
        return Data_Functor.map(Hydra_Fn.fnFunctor)(v$prime)(Hydra_Fn.toFn(toFnColorValue)(v.value0));
    };
    if (v instanceof B) {
        return Hydra_Fn.toFn(toFnBlendTextureOrValue)(v.value0);
    };
    if (v instanceof M) {
        return Hydra_Fn.toFn(toFnModulateTextureOrValu)(v.value0);
    };
    throw new Error("Failed pattern match at Hydra (line 457, column 1 - line 461, column 38): " + [ v.constructor.name ]);
});
var showTextureOrValue = new Data_Show.Show(function (v) {
    if (v instanceof T) {
        return Data_Show.show(showTexture)(v.value0);
    };
    if (v instanceof V) {
        return Data_Show.show(showValue)(v.value0);
    };
    throw new Error("Failed pattern match at Hydra (line 502, column 1 - line 504, column 32): " + [ v.constructor.name ]);
});
var showTexture = new Data_Show.Show(function (v) {
    return Data_Show.show(Hydra_Fn.fnShow(showValue))(Hydra_Fn.toFn(toFnSourceValue)(v.value0)) + ("\x0a    " + Data_String_Common.joinWith("\x0a    ")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(showModifier))(v.value1)));
});
var showModifier = new Data_Show.Show(function (modifier) {
    return Data_Show.show(Hydra_Fn.fnShow(showTextureOrValue))(Hydra_Fn.toFn(toFnModifierTextureOrValu)(modifier));
});
var showHydra = new Data_Show.Show(function (v) {
    if (v instanceof None) {
        return "None";
    };
    if (v instanceof Op) {
        return Data_Show.show(showOperation)(v.value0);
    };
    if (v instanceof Val) {
        return Data_Show.show(showValue)(v.value0);
    };
    if (v instanceof Mod) {
        return Data_Show.show(showModifier)(v.value0);
    };
    if (v instanceof Tex) {
        return Data_Show.show(showTexture)(v.value0);
    };
    if (v instanceof Buf) {
        return Data_Show.show(showBuffer)(v.value0);
    };
    throw new Error("Failed pattern match at Hydra (line 518, column 1 - line 524, column 30): " + [ v.constructor.name ]);
});
var sqrt = function ($156) {
    return Val.create(Sqrt.create($156));
};
var seq = function ($157) {
    return Val.create(Seq.create($157));
};
var pi = new Val(Pi.value);
var num = function ($158) {
    return Val.create(Num.create($158));
};
var mouseY = new Val(MouseY.value);
var mouseX = new Val(MouseX.value);
var modulate = M.create;
var justModifier = Mod.create;
var isValue = function (v) {
    if (v instanceof Val) {
        return true;
    };
    return false;
};
var isTexture = function (v) {
    if (v instanceof Tex) {
        return true;
    };
    return false;
};
var isOp = function (v) {
    if (v instanceof Op) {
        return true;
    };
    return false;
};
var isModifier = function (v) {
    if (v instanceof Mod) {
        return true;
    };
    return false;
};
var isBuffer = function (v) {
    if (v instanceof Buf) {
        return true;
    };
    return false;
};
var hydraOf = Tex.create;
var height = new Val(CanvasHeight.value);
var geometry = G.create;
var fromOp = Op.create;
var eqOperation = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Addition && y instanceof Addition) {
            return true;
        };
        if (x instanceof Division && y instanceof Division) {
            return true;
        };
        if (x instanceof Multiplication && y instanceof Multiplication) {
            return true;
        };
        if (x instanceof Subtraction && y instanceof Subtraction) {
            return true;
        };
        return false;
    };
});
var eqBuffer = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Default && y instanceof Default) {
            return true;
        };
        if (x instanceof O0 && y instanceof O0) {
            return true;
        };
        if (x instanceof O1 && y instanceof O1) {
            return true;
        };
        if (x instanceof O2 && y instanceof O2) {
            return true;
        };
        if (x instanceof O3 && y instanceof O3) {
            return true;
        };
        if (x instanceof S0 && y instanceof S0) {
            return true;
        };
        if (x instanceof S1 && y instanceof S1) {
            return true;
        };
        if (x instanceof S2 && y instanceof S2) {
            return true;
        };
        if (x instanceof S3 && y instanceof S3) {
            return true;
        };
        return false;
    };
});
var ordBuffer = new Data_Ord.Ord(function () {
    return eqBuffer;
}, function (x) {
    return function (y) {
        if (x instanceof Default && y instanceof Default) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Default) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Default) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof O0 && y instanceof O0) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof O0) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof O0) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof O1 && y instanceof O1) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof O1) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof O1) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof O2 && y instanceof O2) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof O2) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof O2) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof O3 && y instanceof O3) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof O3) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof O3) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof S0 && y instanceof S0) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof S0) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof S0) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof S1 && y instanceof S1) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof S1) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof S1) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof S2 && y instanceof S2) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof S2) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof S2) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof S3 && y instanceof S3) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Hydra (line 29, column 1 - line 29, column 40): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var defaultSource = new Osc({
    freq: new Num(60.0),
    sync: new Num(0.1),
    offset: new Num(0.0)
});
var defaultTexture = textureOf(defaultSource);
var $$default = None.value;
var color = C.create;
var buffer = Buf.create;
var blend = B.create;
var addModifier = function (v) {
    var $159 = Texture.create(v.value0);
    var $160 = Data_Array.snoc(v.value1);
    return function ($161) {
        return $159($160($161));
    };
};
var withModifiers = Data_Foldable.foldl(Data_Foldable.foldableArray)(addModifier);
module.exports = {
    Default: Default,
    O0: O0,
    O1: O1,
    O2: O2,
    O3: O3,
    S0: S0,
    S1: S1,
    S2: S2,
    S3: S3,
    Addition: Addition,
    Division: Division,
    Multiplication: Multiplication,
    Subtraction: Subtraction,
    Num: Num,
    MouseX: MouseX,
    MouseY: MouseY,
    Time: Time,
    Seq: Seq,
    CanvasWidth: CanvasWidth,
    CanvasHeight: CanvasHeight,
    WindowWidth: WindowWidth,
    WindowHeight: WindowHeight,
    Pi: Pi,
    Expr: Expr,
    Dynamic: Dynamic,
    OfTime: OfTime,
    Harmonic: Harmonic,
    Fast: Fast,
    Sqrt: Sqrt,
    Noise: Noise,
    Voronoi: Voronoi,
    Osc: Osc,
    Shape: Shape,
    Gradient: Gradient,
    Solid: Solid,
    Source: Source,
    Rotate: Rotate,
    Scale: Scale,
    Pixelate: Pixelate,
    Repeat: Repeat,
    RepeatX: RepeatX,
    RepeatY: RepeatY,
    Kaleid: Kaleid,
    ScrollX: ScrollX,
    ScrollY: ScrollY,
    Posterize: Posterize,
    Shift: Shift,
    Invert: Invert,
    Contrast: Contrast,
    Brightness: Brightness,
    Luma: Luma,
    Tresh: Tresh,
    Color: Color,
    Saturate: Saturate,
    Hue: Hue,
    Colorama: Colorama,
    Add: Add,
    Layer: Layer,
    Blend: Blend,
    Mult: Mult,
    Diff: Diff,
    Mask: Mask,
    ModulateRepeat: ModulateRepeat,
    ModulateRepeatX: ModulateRepeatX,
    ModulateRepeatY: ModulateRepeatY,
    ModulateKaleid: ModulateKaleid,
    ModulateScrollX: ModulateScrollX,
    ModulateScrollY: ModulateScrollY,
    Modulate: Modulate,
    ModulateScale: ModulateScale,
    ModulatePixelate: ModulatePixelate,
    ModulateRotate: ModulateRotate,
    ModulateHue: ModulateHue,
    G: G,
    C: C,
    B: B,
    M: M,
    Texture: Texture,
    None: None,
    Op: Op,
    Val: Val,
    Mod: Mod,
    Tex: Tex,
    Buf: Buf,
    "default": $$default,
    textureOf: textureOf,
    geometry: geometry,
    color: color,
    blend: blend,
    modulate: modulate,
    addModifier: addModifier,
    withModifiers: withModifiers,
    hydraOf: hydraOf,
    justModifier: justModifier,
    buffer: buffer,
    num: num,
    pi: pi,
    time: time,
    mouseX: mouseX,
    mouseY: mouseY,
    width: width,
    height: height,
    seq: seq,
    sqrt: sqrt,
    defaultSource: defaultSource,
    defaultTexture: defaultTexture,
    fromOp: fromOp,
    isValue: isValue,
    isModifier: isModifier,
    isBuffer: isBuffer,
    isTexture: isTexture,
    isOp: isOp,
    toValue: toValue,
    toBuffer: toBuffer,
    toTexture: toTexture,
    toModifier: toModifier,
    T: T,
    V: V,
    "t'": t$prime,
    "v'": v$prime,
    textureOrValue: textureOrValue,
    eqBuffer: eqBuffer,
    ordBuffer: ordBuffer,
    eqOperation: eqOperation,
    toFnSourceValue: toFnSourceValue,
    toFnGeometryValue: toFnGeometryValue,
    toFnColorValue: toFnColorValue,
    toFnBlendTextureOrValue: toFnBlendTextureOrValue,
    toFnModulateTextureOrValu: toFnModulateTextureOrValu,
    toFnModifierTextureOrValu: toFnModifierTextureOrValu,
    showOperation: showOperation,
    showValue: showValue,
    showBuffer: showBuffer,
    showTextureOrValue: showTextureOrValue,
    showTexture: showTexture,
    showModifier: showModifier,
    showHydra: showHydra
};
