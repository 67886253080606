// Generated by purs version 0.14.3
"use strict";
var App_Mouse = require("../App.Mouse/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Node = (function () {
    function Node(value0) {
        this.value0 = value0;
    };
    Node.create = function (value0) {
        return new Node(value0);
    };
    return Node;
})();
var Link = (function () {
    function Link(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Link.create = function (value0) {
        return function (value1) {
            return new Link(value0, value1);
        };
    };
    return Link;
})();
var Header = (function () {
    function Header(value0) {
        this.value0 = value0;
    };
    Header.create = function (value0) {
        return new Header(value0);
    };
    return Header;
})();
var Inlet = (function () {
    function Inlet(value0) {
        this.value0 = value0;
    };
    Inlet.create = function (value0) {
        return new Inlet(value0);
    };
    return Inlet;
})();
var Outlet = (function () {
    function Outlet(value0) {
        this.value0 = value0;
    };
    Outlet.create = function (value0) {
        return new Outlet(value0);
    };
    return Outlet;
})();
var showDraggable = new Data_Show.Show(function (v) {
    if (v instanceof Node) {
        return "node " + v.value0;
    };
    if (v instanceof Link) {
        return "link " + (Data_Show.show(Data_Tuple.showTuple(Data_Show.showString)(Data_Show.showString))(v.value0) + (" - " + Data_Show.show(Data_Maybe.showMaybe(Data_Tuple.showTuple(Data_Show.showString)(Data_Show.showString)))(v.value1)));
    };
    throw new Error("Failed pattern match at App.Component.Patch.Mouse (line 73, column 1 - line 75, column 87): " + [ v.constructor.name ]);
});
var showClickable = new Data_Show.Show(function (v) {
    if (v instanceof Header) {
        return "header " + v.value0;
    };
    if (v instanceof Inlet) {
        return "inlet " + Data_Show.show(Data_Tuple.showTuple(Data_Show.showString)(Data_Show.showString))(v.value0);
    };
    if (v instanceof Outlet) {
        return "outlet " + Data_Show.show(Data_Tuple.showTuple(Data_Show.showString)(Data_Show.showString))(v.value0);
    };
    throw new Error("Failed pattern match at App.Component.Patch.Mouse (line 67, column 1 - line 70, column 48): " + [ v.constructor.name ]);
});
var init = App_Mouse.init;
var apply = function (pToF) {
    return function (cToD) {
        return function (dToF) {
            return App_Mouse["apply'"](pToF)(Data_Function["const"](Data_Maybe.Just.create))(function (nextPos) {
                return function (v) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create(v.value0))(cToD(nextPos)(v.value1));
                };
            })(Data_Function["const"](Data_Maybe.Just.create))(function (nextPos) {
                return function (v) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create(v.value0))(dToF(nextPos)(v.value1));
                };
            });
        };
    };
};
module.exports = {
    Header: Header,
    Inlet: Inlet,
    Outlet: Outlet,
    Node: Node,
    Link: Link,
    init: init,
    apply: apply,
    showClickable: showClickable,
    showDraggable: showDraggable
};
