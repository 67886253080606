// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Effect = require("../Effect/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");
var Web_DOM_NodeType = require("../Web.DOM.NodeType/index.js");
var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var previousSibling = (function () {
    var $1 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($2) {
        return $1($foreign["_previousSibling"]($2));
    };
})();
var parentNode = (function () {
    var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($4) {
        return $3($foreign["_parentNode"]($4));
    };
})();
var parentElement = (function () {
    var $5 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($6) {
        return $5($foreign["_parentElement"]($6));
    };
})();
var ownerDocument = (function () {
    var $7 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($8) {
        return $7($foreign["_ownerDocument"]($8));
    };
})();
var nodeValue = (function () {
    var $9 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($10) {
        return $9($foreign["_nodeValue"]($10));
    };
})();
var nodeType = function (dictPartial) {
    var $11 = Data_Maybe.fromJust();
    var $12 = Data_Enum.toEnum(Web_DOM_NodeType.boundedEnumNodeType);
    return function ($13) {
        return $11($12($foreign.nodeTypeIndex($13)));
    };
};
var nextSibling = (function () {
    var $14 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($15) {
        return $14($foreign["_nextSibling"]($15));
    };
})();
var lookupPrefix = function (p) {
    var $16 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    var $17 = $foreign["_lookupPrefix"](p);
    return function ($18) {
        return $16($17($18));
    };
};
var lookupNamespaceURI = function (ns) {
    var $19 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    var $20 = $foreign["_lookupNamespaceURI"](ns);
    return function ($21) {
        return $19($20($21));
    };
};
var lastChild = (function () {
    var $22 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($23) {
        return $22($foreign["_lastChild"]($23));
    };
})();
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("Node");
var firstChild = (function () {
    var $24 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
    return function ($25) {
        return $24($foreign["_firstChild"]($25));
    };
})();
module.exports = {
    fromEventTarget: fromEventTarget,
    toEventTarget: toEventTarget,
    nodeType: nodeType,
    ownerDocument: ownerDocument,
    parentNode: parentNode,
    parentElement: parentElement,
    firstChild: firstChild,
    lastChild: lastChild,
    previousSibling: previousSibling,
    nextSibling: nextSibling,
    nodeValue: nodeValue,
    lookupPrefix: lookupPrefix,
    lookupNamespaceURI: lookupNamespaceURI,
    nodeTypeIndex: $foreign.nodeTypeIndex,
    nodeName: $foreign.nodeName,
    baseURI: $foreign.baseURI,
    hasChildNodes: $foreign.hasChildNodes,
    childNodes: $foreign.childNodes,
    setNodeValue: $foreign.setNodeValue,
    textContent: $foreign.textContent,
    setTextContent: $foreign.setTextContent,
    normalize: $foreign.normalize,
    clone: $foreign.clone,
    deepClone: $foreign.deepClone,
    isEqualNode: $foreign.isEqualNode,
    compareDocumentPositionBits: $foreign.compareDocumentPositionBits,
    contains: $foreign.contains,
    isDefaultNamespace: $foreign.isDefaultNamespace,
    insertBefore: $foreign.insertBefore,
    appendChild: $foreign.appendChild,
    replaceChild: $foreign.replaceChild,
    removeChild: $foreign.removeChild
};
