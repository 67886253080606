// Generated by purs version 0.14.3
"use strict";
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Empty = (function () {
    function Empty() {

    };
    Empty.value = new Empty();
    return Empty;
})();
var Idle = (function () {
    function Idle() {

    };
    Idle.value = new Idle();
    return Idle;
})();
var Loading = (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var NoSource = (function () {
    function NoSource() {

    };
    NoSource.value = new NoSource();
    return NoSource;
})();
var toEnumNetworkState = function (v) {
    if (v === 0) {
        return new Data_Maybe.Just(Empty.value);
    };
    if (v === 1) {
        return new Data_Maybe.Just(Idle.value);
    };
    if (v === 2) {
        return new Data_Maybe.Just(Loading.value);
    };
    if (v === 3) {
        return new Data_Maybe.Just(NoSource.value);
    };
    return Data_Maybe.Nothing.value;
};
var showNetworkState = new Data_Show.Show(function (v) {
    if (v instanceof Empty) {
        return "Empty";
    };
    if (v instanceof Idle) {
        return "Idle";
    };
    if (v instanceof Loading) {
        return "Loading";
    };
    if (v instanceof NoSource) {
        return "NoSource";
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLMediaElement.NetworkState (line 29, column 1 - line 33, column 29): " + [ v.constructor.name ]);
});
var fromEnumNetworkState = function (v) {
    if (v instanceof Empty) {
        return 0;
    };
    if (v instanceof Idle) {
        return 1;
    };
    if (v instanceof Loading) {
        return 2;
    };
    if (v instanceof NoSource) {
        return 3;
    };
    throw new Error("Failed pattern match at Web.HTML.HTMLMediaElement.NetworkState (line 46, column 3 - line 50, column 18): " + [ v.constructor.name ]);
};
var eqNetworkState = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Empty && y instanceof Empty) {
            return true;
        };
        if (x instanceof Idle && y instanceof Idle) {
            return true;
        };
        if (x instanceof Loading && y instanceof Loading) {
            return true;
        };
        if (x instanceof NoSource && y instanceof NoSource) {
            return true;
        };
        return false;
    };
});
var ordNetworkState = new Data_Ord.Ord(function () {
    return eqNetworkState;
}, function (x) {
    return function (y) {
        if (x instanceof Empty && y instanceof Empty) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Empty) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Empty) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Idle && y instanceof Idle) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Idle) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Idle) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Loading && y instanceof Loading) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Loading) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Loading) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof NoSource && y instanceof NoSource) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Web.HTML.HTMLMediaElement.NetworkState (line 14, column 1 - line 14, column 52): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var enumNetworkState = new Data_Enum.Enum(function () {
    return ordNetworkState;
}, Data_Enum.defaultPred(toEnumNetworkState)(fromEnumNetworkState), Data_Enum.defaultSucc(toEnumNetworkState)(fromEnumNetworkState));
var boundedNetworkState = new Data_Bounded.Bounded(function () {
    return ordNetworkState;
}, Empty.value, NoSource.value);
var boundedEnumNetworkState = new Data_Enum.BoundedEnum(function () {
    return boundedNetworkState;
}, function () {
    return enumNetworkState;
}, 4, fromEnumNetworkState, toEnumNetworkState);
module.exports = {
    Empty: Empty,
    Idle: Idle,
    Loading: Loading,
    NoSource: NoSource,
    eqNetworkState: eqNetworkState,
    ordNetworkState: ordNetworkState,
    boundedNetworkState: boundedNetworkState,
    enumNetworkState: enumNetworkState,
    boundedEnumNetworkState: boundedEnumNetworkState,
    showNetworkState: showNetworkState
};
