// Generated by purs version 0.14.3
"use strict";
var CSS_Property = require("../CSS.Property/index.js");
var CSS_Selector = require("../CSS.Selector/index.js");
var CSS_String = require("../CSS.String/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_These = require("../Data.These/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Sheet = function (x) {
    return x;
};
var Inline = function (x) {
    return x;
};
var sepWith = function (s) {
    return function (a) {
        return function (b) {
            return a + (s + b);
        };
    };
};
var semigroupInline = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return v + v1;
    };
});
var semigroupFile = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return v + v1;
    };
});
var properties = function (xs) {
    var sheetRules = Data_Either.either(function (v) {
        return Data_Monoid.mempty(Data_Monoid.monoidString);
    })(function (v) {
        return Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ v.value0, ": ", v.value1 ]);
    });
    return Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("; ")(Data_Functor.map(Data_Functor.functorArray)(sheetRules)(xs));
};
var predicate = function (v) {
    if (v instanceof CSS_Selector.Id) {
        return "#" + v.value0;
    };
    if (v instanceof CSS_Selector.Class) {
        return "." + v.value0;
    };
    if (v instanceof CSS_Selector.Attr) {
        return "[" + (v.value0 + "]");
    };
    if (v instanceof CSS_Selector.AttrVal) {
        return "[" + (v.value0 + ("='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrBegins) {
        return "[" + (v.value0 + ("^='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrEnds) {
        return "[" + (v.value0 + ("$='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrContains) {
        return "[" + (v.value0 + ("*='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrSpace) {
        return "[" + (v.value0 + ("~='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrHyph) {
        return "[" + (v.value0 + ("|='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.Pseudo) {
        return ":" + v.value0;
    };
    if (v instanceof CSS_Selector.PseudoFunc) {
        return ":" + (v.value0 + ("(" + (Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(",")(v.value1) + ")")));
    };
    throw new Error("Failed pattern match at CSS.Render (line 175, column 1 - line 175, column 33): " + [ v.constructor.name ]);
};
var selector$prime$prime = function (v) {
    return function (v1) {
        if (v.length === 0 && v1 instanceof CSS_Selector.Star) {
            return [ "*" ];
        };
        if (v1 instanceof CSS_Selector.Star) {
            return [ "" ];
        };
        if (v1 instanceof CSS_Selector.Elem) {
            return [ v1.value0 ];
        };
        if (v1 instanceof CSS_Selector.PathChild) {
            return Control_Apply.apply(Control_Apply.applyArray)(Data_Functor.map(Data_Functor.functorArray)(sepWith(" > "))(selector$prime(v1.value0)))(selector$prime(v1.value1));
        };
        if (v1 instanceof CSS_Selector.Deep) {
            return Control_Apply.apply(Control_Apply.applyArray)(Data_Functor.map(Data_Functor.functorArray)(sepWith(" "))(selector$prime(v1.value0)))(selector$prime(v1.value1));
        };
        if (v1 instanceof CSS_Selector.Adjacent) {
            return Control_Apply.apply(Control_Apply.applyArray)(Data_Functor.map(Data_Functor.functorArray)(sepWith(" + "))(selector$prime(v1.value0)))(selector$prime(v1.value1));
        };
        if (v1 instanceof CSS_Selector.Combined) {
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(selector$prime(v1.value0))(selector$prime(v1.value1));
        };
        throw new Error("Failed pattern match at CSS.Render (line 141, column 1 - line 141, column 63): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var selector$prime = function (v) {
    return Data_Functor.map(Data_Functor.functorArray)(function (v1) {
        return v1 + Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(predicate)(Data_Array.sort(CSS_Selector.ordPredicate)(v.value0));
    })(selector$prime$prime(v.value0)(v.value1));
};
var selector = (function () {
    var $167 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(", ");
    return function ($168) {
        return $167(selector$prime($168));
    };
})();
var nel = function (v) {
    if (v.length === 0) {
        return Data_Maybe.Nothing.value;
    };
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
        return new Data_NonEmpty.NonEmpty(v1.head, v1.tail);
    })(Data_Array.uncons(v));
};
var monoidInline = new Data_Monoid.Monoid(function () {
    return semigroupInline;
}, Data_Monoid.mempty(Data_Monoid.monoidString));
var monoidFile = new Data_Monoid.Monoid(function () {
    return semigroupFile;
}, Data_Monoid.mempty(Data_Monoid.monoidString));
var merger = function (v) {
    if (v.value0 instanceof CSS_Stylesheet.Child) {
        return Data_Maybe.maybe(v.value0.value0)(function (xs$prime) {
            return CSS_Selector.child(merger(xs$prime))(v.value0.value0);
        })(nel(v.value1));
    };
    if (v.value0 instanceof CSS_Stylesheet.Sub) {
        return Data_Maybe.maybe(v.value0.value0)(function (xs$prime) {
            return CSS_Selector.deep(merger(xs$prime))(v.value0.value0);
        })(nel(v.value1));
    };
    if (v.value0 instanceof CSS_Stylesheet.Root) {
        return Data_Maybe.maybe(v.value0.value0)(function (xs$prime) {
            return CSS_Selector.deep(v.value0.value0)(merger(xs$prime));
        })(nel(v.value1));
    };
    if (v.value0 instanceof CSS_Stylesheet.Pop) {
        return Data_Maybe.maybe(CSS_Selector.element("TODO"))(merger)(nel(Data_Array.drop(v.value0.value0)(Data_Array.cons(v.value0)(v.value1))));
    };
    if (v.value0 instanceof CSS_Stylesheet.Self) {
        return Data_Maybe.maybe(CSS_Selector["with"](CSS_Selector.star)(v.value0.value0))(function (xs$prime) {
            return CSS_Selector["with"](merger(xs$prime))(v.value0.value0);
        })(nel(v.value1));
    };
    throw new Error("Failed pattern match at CSS.Render (line 168, column 3 - line 173, column 105): " + [ v.value0.constructor.name ]);
};
var mediaType = function (v) {
    return CSS_Property.plain(v);
};
var imp = function (t) {
    return Data_Maybe.Just.create(Data_These.That.create(Sheet(CSS_String.fromString(CSS_String.isStringString)("@import url(" + (t + ");\x0a")))));
};
var getSheet = function (v) {
    return v;
};
var renderedSheet = function (v) {
    return Control_Bind.bind(Data_Maybe.bindMaybe)(v)((function () {
        var $169 = Data_Functor.map(Data_Maybe.functorMaybe)(getSheet);
        return function ($170) {
            return $169(Data_These.theseRight($170));
        };
    })());
};
var getInline = function (v) {
    return v;
};
var renderedInline = function (v) {
    return Control_Bind.bind(Data_Maybe.bindMaybe)(v)((function () {
        var $171 = Data_Functor.map(Data_Maybe.functorMaybe)(getInline);
        return function ($172) {
            return $171(Data_These.theseLeft($172));
        };
    })());
};
var feature = function (v) {
    return Data_Maybe.maybe(v.value0)(function (v1) {
        return "(" + (v.value0 + (": " + (CSS_Property.plain(v1) + ")")));
    })(v.value1);
};
var mediaQuery = function (v) {
    return "@media " + (mediaType(v.value1) + Data_Semigroup_Foldable.foldl1(Data_NonEmpty.foldable1NonEmpty(Data_Foldable.foldableArray))(Data_Semigroup.append(Data_Semigroup.semigroupString))(Data_Functor.map(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(function ($173) {
        return (function (v1) {
            return " and " + v1;
        })(feature($173));
    })(v.value2)));
};
var eqSheet = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordSheet = new Data_Ord.Ord(function () {
    return eqSheet;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordString)(x)(y);
    };
});
var eqInline = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordInline = new Data_Ord.Ord(function () {
    return eqInline;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordString)(x)(y);
    };
});
var collect$prime = function (v) {
    return function (v1) {
        if (v instanceof CSS_Property.Plain && v1 instanceof CSS_Property.Plain) {
            return [ new Data_Either.Right(new Data_Tuple.Tuple(v.value0, v1.value0)) ];
        };
        if (v instanceof CSS_Property.Prefixed && v1 instanceof CSS_Property.Plain) {
            return Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                return Data_Either.Right.create(new Data_Tuple.Tuple(v3.value0 + v3.value1, v1.value0));
            })(v.value0);
        };
        if (v instanceof CSS_Property.Plain && v1 instanceof CSS_Property.Prefixed) {
            return Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                return Data_Either.Right.create(new Data_Tuple.Tuple(v.value0, v2.value0 + v2.value1));
            })(v1.value0);
        };
        if (v instanceof CSS_Property.Prefixed && v1 instanceof CSS_Property.Prefixed) {
            return Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                return Data_Maybe.maybe(new Data_Either.Left(v2.value0 + v2.value1))((function () {
                    var $174 = Data_Tuple.Tuple.create(v2.value0 + v2.value1);
                    return function ($175) {
                        return Data_Either.Right.create($174((function (v3) {
                            return v2.value0 + v3;
                        })($175)));
                    };
                })())(Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString)(v2.value0)(v1.value0));
            })(v.value0);
        };
        throw new Error("Failed pattern match at CSS.Render (line 156, column 1 - line 156, column 80): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var collect = function (v) {
    return collect$prime(v.value0)(v.value1);
};
var rule$prime = function (sel) {
    return function (props) {
        var p = Control_Bind.bind(Control_Bind.bindArray)(props)(collect);
        var q = Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $176 = Data_NonEmpty.oneOf(Control_Alternative.alternativeArray);
            return function ($177) {
                return Data_These.This.create(Inline(properties($176($177))));
            };
        })())(nel(p));
        var o = function (sel$prime) {
            return Data_Maybe.Just.create(Data_These.That.create(Sheet(Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")([ selector(merger(sel$prime)), "{", properties(p), "}\x0a" ]))));
        };
        return Data_Maybe.maybe(q)(o)(nel(sel));
    };
};
var rules = function (sel) {
    return function (rs) {
        var queries = function (v) {
            if (v instanceof CSS_Stylesheet.Query) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
            };
            return Data_Maybe.Nothing.value;
        };
        var queryRules = Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Maybe.monoidMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(Data_Tuple.uncurry(Data_Function.flip(query$prime)(sel)))(Data_Array.mapMaybe(queries)(rs));
        var property = function (v) {
            if (v instanceof CSS_Stylesheet.Property) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
            };
            return Data_Maybe.Nothing.value;
        };
        var topRules = (function () {
            var rs$prime = Data_Array.mapMaybe(property)(rs);
            var $154 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(rs$prime);
            if ($154) {
                return rule$prime(sel)(rs$prime);
            };
            return Data_Maybe.Nothing.value;
        })();
        var nestedRules = function (a) {
            return rules(Data_Array.cons(a)(sel));
        };
        var nested = function (v) {
            if (v instanceof CSS_Stylesheet.Nested) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
            };
            return Data_Maybe.Nothing.value;
        };
        var nestedSheets = Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Maybe.monoidMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(nestedRules))(Data_Array.mapMaybe(nested)(rs)));
        var kframes = function (v) {
            if (v instanceof CSS_Stylesheet.Keyframe) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        };
        var keyframeRules = Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Maybe.monoidMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(kframe)(Data_Array.mapMaybe(kframes)(rs));
        var imports = function (v) {
            if (v instanceof CSS_Stylesheet.Import) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        };
        var importRules = Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Maybe.monoidMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(imp)(Data_Array.mapMaybe(imports)(rs));
        var faces = function (v) {
            if (v instanceof CSS_Stylesheet.Face) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        };
        var faceRules = Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Maybe.monoidMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(face)(Data_Array.mapMaybe(faces)(rs));
        return Data_Semigroup.append(Data_Maybe.semigroupMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(topRules)(Data_Semigroup.append(Data_Maybe.semigroupMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(importRules)(Data_Semigroup.append(Data_Maybe.semigroupMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(keyframeRules)(Data_Semigroup.append(Data_Maybe.semigroupMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(faceRules)(Data_Semigroup.append(Data_Maybe.semigroupMaybe(Data_These.semigroupThese(semigroupInline)(semigroupFile)))(nestedSheets)(queryRules)))));
    };
};
var query$prime = function (q) {
    return function (sel) {
        return function (rs) {
            return Data_Maybe.Just.create(Data_These.That.create(Sheet(mediaQuery(q) + (" { " + (Data_Maybe.fromMaybe("")(renderedSheet(rules(sel)(rs))) + " }\x0a")))));
        };
    };
};
var kframe = function (v) {
    var renderContent = " " + (v.value0 + (" { " + (Data_Foldable.intercalate(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(Data_Monoid.monoidString)(" ")(Data_Functor.map(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(Data_Tuple.uncurry(frame))(v.value1)) + " }\x0a")));
    var keywords = [ "@keyframes", "@-webkit-keyframes", "@-moz-keyframes", "@-o-keyframes" ];
    var allKeywordsWithContent = Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
        return v1 + renderContent;
    })(keywords));
    return Data_Maybe.Just.create(Data_These.That.create(Sheet(allKeywordsWithContent)));
};
var frame = function (p) {
    return function (rs) {
        var x = Data_Maybe.fromMaybe("")(renderedInline(rules([  ])(rs)));
        return Data_Show.show(Data_Show.showNumber)(p) + ("% " + ("{ " + (x + " }")));
    };
};
var face = function (rs) {
    return Data_Maybe.Just.create(Data_These.That.create(Sheet("@font-face { " + (Data_Maybe.fromMaybe("")(renderedInline(rules([  ])(rs))) + " }\x0a"))));
};
var render = (function () {
    var $178 = rules([  ]);
    return function ($179) {
        return $178(CSS_Stylesheet.runS($179));
    };
})();
var putInline = function (s) {
    return Effect_Console.log(Data_Maybe.fromMaybe("")(renderedInline(render(s))));
};
var putStyleSheet = function (s) {
    return Effect_Console.log(Data_Maybe.fromMaybe("")(renderedSheet(render(s))));
};
module.exports = {
    Inline: Inline,
    getInline: getInline,
    Sheet: Sheet,
    getSheet: getSheet,
    renderedInline: renderedInline,
    renderedSheet: renderedSheet,
    render: render,
    putInline: putInline,
    putStyleSheet: putStyleSheet,
    kframe: kframe,
    frame: frame,
    "query'": query$prime,
    mediaQuery: mediaQuery,
    mediaType: mediaType,
    feature: feature,
    face: face,
    rules: rules,
    imp: imp,
    "rule'": rule$prime,
    selector: selector,
    "selector'": selector$prime,
    "selector''": selector$prime$prime,
    sepWith: sepWith,
    collect: collect,
    "collect'": collect$prime,
    properties: properties,
    merger: merger,
    predicate: predicate,
    nel: nel,
    eqInline: eqInline,
    ordInline: ordInline,
    semigroupInline: semigroupInline,
    monoidInline: monoidInline,
    eqSheet: eqSheet,
    ordSheet: ordSheet,
    semigroupFile: semigroupFile,
    monoidFile: monoidFile
};
