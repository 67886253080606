// Generated by purs version 0.14.3
"use strict";
var CSS_String = require("../CSS.String/index.js");
var Color = require("../Color/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Prefixed = (function () {
    function Prefixed(value0) {
        this.value0 = value0;
    };
    Prefixed.create = function (value0) {
        return new Prefixed(value0);
    };
    return Prefixed;
})();
var Plain = (function () {
    function Plain(value0) {
        this.value0 = value0;
    };
    Plain.create = function (value0) {
        return new Plain(value0);
    };
    return Plain;
})();
var Value = function (x) {
    return x;
};
var Val = function (value) {
    this.value = value;
};
var Literal = function (x) {
    return x;
};
var Key = function (x) {
    return x;
};
var value = function (dict) {
    return dict.value;
};
var valValue = new Val(Control_Category.identity(Control_Category.categoryFn));
var semigroupPrefixed = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        if (v instanceof Plain && v1 instanceof Plain) {
            return Plain.create(v.value0 + v1.value0);
        };
        if (v instanceof Plain && v1 instanceof Prefixed) {
            return Prefixed.create(Data_Functor.map(Data_Functor.functorArray)(Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn)(function (v2) {
                return v.value0 + v2;
            }))(v1.value0));
        };
        if (v instanceof Prefixed && v1 instanceof Plain) {
            return Prefixed.create(Data_Functor.map(Data_Functor.functorArray)(Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn)(function (v2) {
                return v1.value0 + v2;
            }))(v.value0));
        };
        if (v instanceof Prefixed && v1 instanceof Prefixed) {
            return Prefixed.create(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0)(v1.value0));
        };
        throw new Error("Failed pattern match at CSS.Property (line 23, column 1 - line 27, column 59): " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var semigroupValue = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return Value(Data_Semigroup.append(semigroupPrefixed)(v)(v1));
    };
});
var quote = function (s) {
    return "\"" + (s + "\"");
};
var plain = function (v) {
    if (v instanceof Prefixed) {
        return Data_Maybe.fromMaybe("")(Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString)("")(v.value0));
    };
    if (v instanceof Plain) {
        return v.value0;
    };
    throw new Error("Failed pattern match at CSS.Property (line 32, column 1 - line 32, column 28): " + [ v.constructor.name ]);
};
var monoidPrefixed = new Data_Monoid.Monoid(function () {
    return semigroupPrefixed;
}, new Plain(Data_Monoid.mempty(Data_Monoid.monoidString)));
var monoidValue = new Data_Monoid.Monoid(function () {
    return semigroupValue;
}, Data_Monoid.mempty(monoidPrefixed));
var isStringPrefixed = new CSS_String.IsString(Plain.create);
var isStringValue = new CSS_String.IsString((function () {
    var $102 = CSS_String.fromString(isStringPrefixed);
    return function ($103) {
        return Value($102($103));
    };
})());
var noCommas = function (dictVal) {
    var $104 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(monoidValue)(CSS_String.fromString(isStringValue)(" "));
    return function ($105) {
        return $104((function (v) {
            return Data_Functor.map(Data_Functor.functorArray)(value(dictVal))(v);
        })($105));
    };
};
var valColor = new Val((function () {
    var $106 = CSS_String.fromString(isStringValue);
    return function ($107) {
        return $106(Color.cssStringHSLA($107));
    };
})());
var valList = function (dictVal) {
    return new Val((function () {
        var $108 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(monoidValue)(CSS_String.fromString(isStringValue)(", "));
        return function ($109) {
            return $108((function (v) {
                return Data_Functor.map(Data_Functor.functorArray)(value(dictVal))(v);
            })($109));
        };
    })());
};
var valNonEmpty = function (dictVal) {
    return new Val((function () {
        var $110 = value(valList(dictVal));
        var $111 = Data_NonEmpty.oneOf(Control_Alternative.alternativeArray);
        return function ($112) {
            return $110($111($112));
        };
    })());
};
var valLiteral = new Val(function (v) {
    return CSS_String.fromString(isStringValue)(quote(v));
});
var valNumber = new Val((function () {
    var $113 = CSS_String.fromString(isStringValue);
    var $114 = Data_Show.show(Data_Show.showNumber);
    return function ($115) {
        return $113($114($115));
    };
})());
var valString = new Val(CSS_String.fromString(isStringValue));
var valTuple = function (dictVal) {
    return function (dictVal1) {
        return new Val(function (v) {
            return Data_Semigroup.append(semigroupValue)(value(dictVal)(v.value0))(Data_Semigroup.append(semigroupValue)(CSS_String.fromString(isStringValue)(" "))(value(dictVal1)(v.value1)));
        });
    };
};
var valUnit = new Val(function (v) {
    return CSS_String.fromString(isStringValue)("");
});
var isStringKey = new CSS_String.IsString((function () {
    var $116 = CSS_String.fromString(isStringPrefixed);
    return function ($117) {
        return Key($116($117));
    };
})());
var eqPrefixed = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Prefixed && y instanceof Prefixed) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString)))(x.value0)(y.value0);
        };
        if (x instanceof Plain && y instanceof Plain) {
            return x.value0 === y.value0;
        };
        return false;
    };
});
var eqValue = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(eqPrefixed)(x)(y);
    };
});
var ordPrefixed = new Data_Ord.Ord(function () {
    return eqPrefixed;
}, function (x) {
    return function (y) {
        if (x instanceof Prefixed && y instanceof Prefixed) {
            return Data_Ord.compare(Data_Ord.ordArray(Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString)))(x.value0)(y.value0);
        };
        if (x instanceof Prefixed) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Prefixed) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Plain && y instanceof Plain) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        throw new Error("Failed pattern match at CSS.Property (line 18, column 1 - line 18, column 44): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var ordValue = new Data_Ord.Ord(function () {
    return eqValue;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(ordPrefixed)(x)(y);
    };
});
var eqLiteral = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordLiteral = new Data_Ord.Ord(function () {
    return eqLiteral;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordString)(x)(y);
    };
});
var eqKey = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            return Data_Eq.eq(eqPrefixed)(x)(y);
        };
    });
};
var ordKey = function (dictOrd) {
    return new Data_Ord.Ord(function () {
        return eqKey(dictOrd.Eq0());
    }, function (x) {
        return function (y) {
            return Data_Ord.compare(ordPrefixed)(x)(y);
        };
    });
};
var cast = function (v) {
    return v;
};
module.exports = {
    value: value,
    Prefixed: Prefixed,
    Plain: Plain,
    plain: plain,
    quote: quote,
    Key: Key,
    cast: cast,
    Value: Value,
    Val: Val,
    Literal: Literal,
    noCommas: noCommas,
    eqPrefixed: eqPrefixed,
    ordPrefixed: ordPrefixed,
    isStringPrefixed: isStringPrefixed,
    semigroupPrefixed: semigroupPrefixed,
    monoidPrefixed: monoidPrefixed,
    eqKey: eqKey,
    ordKey: ordKey,
    isStringKey: isStringKey,
    eqValue: eqValue,
    ordValue: ordValue,
    isStringValue: isStringValue,
    semigroupValue: semigroupValue,
    monoidValue: monoidValue,
    eqLiteral: eqLiteral,
    ordLiteral: ordLiteral,
    valLiteral: valLiteral,
    valValue: valValue,
    valString: valString,
    valUnit: valUnit,
    valTuple: valTuple,
    valNumber: valNumber,
    valList: valList,
    valNonEmpty: valNonEmpty,
    valColor: valColor
};
