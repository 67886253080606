// Generated by purs version 0.14.3
"use strict";
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Component_Input = require("../Hydra.Component.Input/index.js");
var Hydra_Extract = require("../Hydra.Extract/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var NoOp = (function () {
    function NoOp() {

    };
    NoOp.value = new NoOp();
    return NoOp;
})();
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Change = (function () {
    function Change(value0) {
        this.value0 = value0;
    };
    Change.create = function (value0) {
        return new Change(value0);
    };
    return Change;
})();
var render = function (dictMonadEffect) {
    return function (v) {
        return Halogen_Svg_Elements.g([  ])([ Hydra_Component_Input.number(v.value0)({
            min: 0.0,
            max: 255.0,
            step: 1.0e-2
        })(NoOp.value)(Change.create) ]);
    };
};
var initialState = function (v) {
    return new Data_Tuple.Tuple(0.0, v.node);
};
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v1) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Node["get'"](v1.value1)("num")))(function (n) {
                    return Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(new Data_Tuple.Tuple(Hydra_Extract.numOr(0.0)(n), v1.value1));
                });
            });
        };
        if (v instanceof Change) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return new Data_Tuple.Tuple(v.value0, v1.value1);
            }))(function () {
                return Halogen_Query_HalogenM.raise(App_Toolkit_UI.SendToOutlet.create("num")(Hydra.num(v.value0)));
            });
        };
        if (v instanceof NoOp) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        throw new Error("Failed pattern match at Hydra.Component.Node.Num (line 56, column 16 - line 65, column 18): " + [ v.constructor.name ]);
    };
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render(dictMonadEffect),
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    NoOp: NoOp,
    Initialize: Initialize,
    Change: Change,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
