// Generated by purs version 0.14.3
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map = require("../Data.Map/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Effect = require("../Effect/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var Toolkit = (function () {
    function Toolkit(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Toolkit.create = function (value0) {
        return function (value1) {
            return new Toolkit(value0, value1);
        };
    };
    return Toolkit;
})();
var spawn = function (family) {
    return function (v) {
        return Data_Traversable.sequence(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Effect.functorEffect)(Noodle_Node.markFamily(family)))(Data_Functor.map(Data_Maybe.functorMaybe)(Noodle_Node.make(v.value0))(Data_Map_Internal.lookup(Data_Ord.ordString)(family)(v.value1))));
    };
};
var nodeFamilies = function (v) {
    return Data_Map.keys(v.value1);
};
var make = function (def) {
    var $9 = Toolkit.create(def);
    var $10 = Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray);
    return function ($11) {
        return $9($10($11));
    };
};
module.exports = {
    Toolkit: Toolkit,
    make: make,
    spawn: spawn,
    nodeFamilies: nodeFamilies
};
