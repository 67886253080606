// Generated by purs version 0.14.3
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Strip = (function () {
    function Strip(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Strip.create = function (value0) {
        return function (value1) {
            return new Strip(value0, value1);
        };
    };
    return Strip;
})();
var unfold = function (v) {
    return v.value1;
};
var size = function (v) {
    return v.value0;
};
var make = function (v) {
    return function (maxWidth) {
        return function (items) {
            var maxItemsX = Data_Int.floor(maxWidth);
            var itemsCount = Data_Set.size(items);
            var fullItemWidth = Data_Vec2.w(v.value0) + Data_Vec2.x(v.value1);
            var fullItemHeight = Data_Vec2.h(v.value0) + Data_Vec2.y(v.value1);
            var fitsInWidthX = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(maxItemsX)(Data_Int.floor(fullItemWidth)) * Data_Int.floor(fullItemWidth) | 0;
            var buttonPos = function (idx) {
                var linearX = Data_Int.toNumber(idx) * fullItemWidth;
                var modX = Data_Int.toNumber(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(Data_Int.floor(linearX))(fitsInWidthX));
                var divX = Data_Int.toNumber(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(Data_Int.floor(linearX))(fitsInWidthX));
                return Data_Vec2.make(Data_Vec2.x(v.value1) + modX)(divX * fullItemHeight);
            };
            var bottomItemsY = Data_Vec2.y(buttonPos(itemsCount - 1 | 0)) + fullItemHeight;
            var addPos = function (v1) {
                return new Data_Tuple.Tuple(buttonPos(v1.value0), v1.value1);
            };
            return Strip.create(Data_Vec2.make(maxWidth)(bottomItemsY))(Data_Functor.map(Data_Functor.functorArray)(addPos)(Data_Array.mapWithIndex(Data_Tuple.Tuple.create)(Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(items))));
        };
    };
};
var reflow = function (dictOrd) {
    return function (sizes) {
        return function (newWidth) {
            var $19 = make(sizes)(newWidth);
            var $20 = Data_Set.fromFoldable(Data_Foldable.foldableArray)(dictOrd);
            var $21 = Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd);
            return function ($22) {
                return $19($20($21(unfold($22))));
            };
        };
    };
};
module.exports = {
    Strip: Strip,
    size: size,
    make: make,
    unfold: unfold,
    reflow: reflow
};
