// Generated by purs version 0.14.3
"use strict";
var App_Layout = require("../App.Layout/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Pin = function (x) {
    return x;
};
var PinBoard = function (x) {
    return x;
};
var newtypePinBoard_ = new Data_Newtype.Newtype(function () {
    return undefined;
});
var functorPin = new Data_Functor.Functor(function (f) {
    return function (v) {
        return Pin(new Data_Tuple.Tuple(f(v.value0), new Data_Tuple.Tuple(v.value1.value0, v.value1.value1)));
    };
});
var functorPinBoard = new Data_Functor.Functor(function (f) {
    return function (m) {
        return Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(functorPin)(f))(m);
    };
});
var toArray = (function () {
    var $62 = Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v;
    });
    var $63 = Data_Newtype.unwrap();
    return function ($64) {
        return $62($63($64));
    };
})();
var search = function (pos) {
    var matches = function (v) {
        if (Data_Vec2.inside(Data_Semiring.semiringNumber)(Data_Ord.ordNumber)(pos)(new Data_Tuple.Tuple(v.value1.value0, v.value1.value1))) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(pos)(v.value1.value0)));
        };
        if (Data_Boolean.otherwise) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at App.Layout.PinBoard (line 112, column 9 - line 112, column 106): " + [ v.constructor.name ]);
    };
    var $65 = Data_Array.findMap(matches);
    var $66 = Data_Newtype.unwrap();
    return function ($67) {
        return $65($66($67));
    };
};
var search$prime = function (pos) {
    return function (pb) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.fst)(search(pos)(pb));
    };
};
var pinNowhere = function (i) {
    return Pin(new Data_Tuple.Tuple(i, new Data_Tuple.Tuple(Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2)), Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2)))));
};
var pin = function (pos) {
    return function (size1) {
        return function (i) {
            var $68 = Data_Array.cons(Pin(new Data_Tuple.Tuple(i, new Data_Tuple.Tuple(pos, size1))));
            var $69 = Data_Newtype.unwrap();
            return function ($70) {
                return PinBoard($68($69($70)));
            };
        };
    };
};
var isPosInside = function (pos$prime) {
    return function (v) {
        var $38 = Data_Vec2.inside(Data_Semiring.semiringNumber)(Data_Ord.ordNumber)(pos$prime)(new Data_Tuple.Tuple(v.value1.value0, v.value1.value1));
        if ($38) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, new Data_Tuple.Tuple(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(pos$prime)(v.value1.value0), v.value1.value1)));
        };
        return Data_Maybe.Nothing.value;
    };
};
var sample = function (pos) {
    var $71 = Data_Array.findMap(isPosInside(pos));
    var $72 = Data_Newtype.unwrap();
    return function ($73) {
        return $71($72($73));
    };
};
var getNumbers = function (v) {
    return new Data_Tuple.Tuple(v.value1.value0, v.value1.value1);
};
var get = function (v) {
    return Data_Tuple.fst(v);
};
var fold = function (f) {
    return function (k) {
        var $74 = Data_Array.foldr(f)(k);
        return function ($75) {
            return $74(toArray($75));
        };
    };
};
var size = (function () {
    var toSize = function (v) {
        return Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(v.value1)(v.value0);
    };
    var addSize = function (v) {
        return function (v1) {
            return new Data_Tuple.Tuple((function () {
                var $54 = Data_Ord.lessThan(Data_Vec.ordVec(Data_Ord.ordNumber))(v.value1.value0)(v1.value0);
                if ($54) {
                    return v.value1.value0;
                };
                return v1.value0;
            })(), (function () {
                var $55 = Data_Ord.greaterThan(Data_Vec.ordVec(Data_Ord.ordNumber))(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(v.value1.value0)(v.value1.value1))(v1.value1);
                if ($55) {
                    return Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(v.value1.value0)(v.value1.value1);
                };
                return v1.value1;
            })());
        };
    };
    var $76 = fold(addSize)(new Data_Tuple.Tuple(Data_Vec2.zero, Data_Vec2.zero));
    return function ($77) {
        return toSize($76($77));
    };
})();
var empty = [  ];
var compareByItem = function (dictEq) {
    return function (a) {
        return function (b) {
            return Data_Eq.eq(dictEq)(get(a))(get(b));
        };
    };
};
var find = function (dictEq) {
    return function (needle) {
        return function (pb) {
            return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Data_Array.find(compareByItem(dictEq)(pinNowhere(needle)))(Data_Newtype.unwrap()(pb)))(getNumbers);
        };
    };
};
var isLayoutPinBoard = new App_Layout.IsLayout(function (v) {
    return empty;
}, function (dictEq) {
    return find(dictEq);
}, fold, sample, size);
var unpin = function (dictEq) {
    return function (a) {
        var $78 = Data_Array.deleteBy(compareByItem(dictEq))(pinNowhere(a));
        var $79 = Data_Newtype.unwrap();
        return function ($80) {
            return PinBoard($78($79($80)));
        };
    };
};
var isPinningLayoutPinBoard = new App_Layout.IsPinningLayout(function () {
    return isLayoutPinBoard;
}, function (v) {
    return function (p) {
        return function (s) {
            return pin(p)(s)(v);
        };
    };
}, function (dictEq) {
    return unpin(dictEq);
});
module.exports = {
    Pin: Pin,
    PinBoard: PinBoard,
    compareByItem: compareByItem,
    isPosInside: isPosInside,
    size: size,
    get: get,
    getNumbers: getNumbers,
    empty: empty,
    pinNowhere: pinNowhere,
    toArray: toArray,
    sample: sample,
    find: find,
    fold: fold,
    search: search,
    "search'": search$prime,
    pin: pin,
    unpin: unpin,
    functorPin: functorPin,
    functorPinBoard: functorPinBoard,
    newtypePinBoard_: newtypePinBoard_,
    isLayoutPinBoard: isLayoutPinBoard,
    isPinningLayoutPinBoard: isPinningLayoutPinBoard
};
