// Generated by purs version 0.14.3
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_Fn = require("../Hydra.Fn/index.js");
var Hydra_Queue = require("../Hydra.Queue/index.js");
var friendly = {
    argNames: true,
    newLines: true
};
var compileOp = function (v) {
    if (v instanceof Hydra.Addition) {
        return "+";
    };
    if (v instanceof Hydra.Subtraction) {
        return "-";
    };
    if (v instanceof Hydra.Division) {
        return "/";
    };
    if (v instanceof Hydra.Multiplication) {
        return "*";
    };
    throw new Error("Failed pattern match at Hydra.Compile (line 37, column 1 - line 37, column 33): " + [ v.constructor.name ]);
};
var compileValue = function (v) {
    return function (v1) {
        if (v1 instanceof Hydra.Num) {
            return Data_Show.show(Data_Show.showNumber)(v1.value0);
        };
        if (v1 instanceof Hydra.MouseX) {
            return "() => mouse.x";
        };
        if (v1 instanceof Hydra.MouseY) {
            return "() => mouse.y";
        };
        if (v1 instanceof Hydra.Time) {
            return "() => time";
        };
        if (v1 instanceof Hydra.CanvasWidth) {
            return "width";
        };
        if (v1 instanceof Hydra.CanvasHeight) {
            return "height";
        };
        if (v1 instanceof Hydra.WindowWidth) {
            return "window.innerWidth";
        };
        if (v1 instanceof Hydra.WindowHeight) {
            return "window.innerHeight";
        };
        if (v1 instanceof Hydra.Seq) {
            return "[" + (Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Hydra.showValue))(v1.value0)) + "]");
        };
        if (v1 instanceof Hydra.Pi) {
            return "Math.PI";
        };
        if (v1 instanceof Hydra.Harmonic) {
            return "a.fft[" + (Data_Show.show(Data_Show.showInt)(v1.value0) + "]");
        };
        if (v1 instanceof Hydra.Sqrt) {
            return "sqrt(" + (Data_Show.show(Hydra.showValue)(v1.value0) + ")");
        };
        if (v1 instanceof Hydra.Fast) {
            return compileValue(v)(v1.value1) + (".fast(" + (Data_Show.show(Data_Show.showNumber)(v1.value0) + ")"));
        };
        if (v1 instanceof Hydra.Expr) {
            return "(" + (compileValue(v)(v1.value0) + (compileOp(v1.value1) + (compileValue(v)(v1.value2) + ")")));
        };
        if (v1 instanceof Hydra.Dynamic) {
            return "() => " + compileValue(v)(v1.value0);
        };
        if (v1 instanceof Hydra.OfTime) {
            return "(time) => " + compileValue(v)(v1.value0);
        };
        throw new Error("Failed pattern match at Hydra.Compile (line 44, column 1 - line 44, column 44): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var compileFnBy = function (compiler) {
    return function (toString) {
        return function (v) {
            var compileArg = function (v1) {
                return (function () {
                    if (compiler.argNames) {
                        return "/*" + (v1.value0 + "*/ ");
                    };
                    return "";
                })() + toString(v1.value1);
            };
            return v.name + ("(" + (Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(compileArg)(Hydra_Fn.argsToArray(v.args))) + ")"));
        };
    };
};
var compileFn = function (dictToFn) {
    return function (compiler) {
        var $57 = compileFnBy(compiler)(compileValue(compiler));
        var $58 = Hydra_Fn.toFn(dictToFn);
        return function ($59) {
            return $57($58($59));
        };
    };
};
var compileBuffer = function (v) {
    if (v instanceof Hydra.Default) {
        return "";
    };
    if (v instanceof Hydra.O0) {
        return "o0";
    };
    if (v instanceof Hydra.O1) {
        return "o1";
    };
    if (v instanceof Hydra.O2) {
        return "o2";
    };
    if (v instanceof Hydra.O3) {
        return "o3";
    };
    if (v instanceof Hydra.S0) {
        return "s0";
    };
    if (v instanceof Hydra.S1) {
        return "s1";
    };
    if (v instanceof Hydra.S2) {
        return "s2";
    };
    if (v instanceof Hydra.S3) {
        return "s3";
    };
    throw new Error("Failed pattern match at Hydra.Compile (line 99, column 17 - line 108, column 15): " + [ v.constructor.name ]);
};
var compileTexture = function (compiler) {
    return function (v) {
        if (v.value0 instanceof Hydra.Source) {
            return "src(" + (compileBuffer(v.value0.value0) + (")" + (function () {
                var $36 = Data_Array.length(v.value1) > 0;
                if ($36) {
                    return (function () {
                        if (compiler.newLines) {
                            return "\x0a   .";
                        };
                        return ".";
                    })() + compileModifiers(compiler)(v.value1);
                };
                if (compiler.newLines) {
                    return "\x0a   ";
                };
                return "";
            })()));
        };
        return compileFn(Hydra.toFnSourceValue)(compiler)(v.value0) + (function () {
            var $42 = Data_Array.length(v.value1) > 0;
            if ($42) {
                return (function () {
                    if (compiler.newLines) {
                        return "\x0a   .";
                    };
                    return ".";
                })() + compileModifiers(compiler)(v.value1);
            };
            if (compiler.newLines) {
                return "\x0a   ";
            };
            return "";
        })();
    };
};
var compileModifiers = function (compiler) {
    return function (modifiers) {
        return Data_String_Common.joinWith((function () {
            if (compiler.newLines) {
                return "\x0a    .";
            };
            return ".";
        })())(Data_Functor.map(Data_Functor.functorArray)(compileModifier(compiler))(modifiers));
    };
};
var compileModifier = function (compiler) {
    return function (mod) {
        return compileFn$prime(Hydra.toFnModifierTextureOrValu)(compiler)(mod);
    };
};
var compileFn$prime = function (dictToFn) {
    return function (compiler) {
        var $60 = compileFnBy(compiler)(Hydra.textureOrValue(compileTexture(compiler))(compileValue(compiler)));
        var $61 = Hydra_Fn.toFn(dictToFn);
        return function ($62) {
            return $60($61($62));
        };
    };
};
var compile = function (compiler) {
    return function (queue) {
        var ouputCode = function (v) {
            if (v.value0 instanceof Hydra.Default) {
                return compileTexture(compiler)(v.value1) + ((function () {
                    if (compiler.newLines) {
                        return "\x0a   ";
                    };
                    return "";
                })() + ".out();");
            };
            return compileTexture(compiler)(v.value1) + ((function () {
                if (compiler.newLines) {
                    return "\x0a   ";
                };
                return "";
            })() + (".out(" + (compileBuffer(v.value0) + ");")));
        };
        return Data_String_Common.joinWith((function () {
            if (compiler.newLines) {
                return "\x0a\x0a";
            };
            return "\x0a";
        })())(Data_Functor.map(Data_Functor.functorArray)(ouputCode)(Hydra_Queue.toUnfoldable(Data_Unfoldable.unfoldableArray)(queue)));
    };
};
var compileWithRender = function (compiler) {
    return function (queue) {
        return compile(compiler)(queue) + (function () {
            if (compiler.newLines) {
                return "\x0a\x0a    render();";
            };
            return "\x0arender();";
        })();
    };
};
var compact = {
    argNames: false,
    newLines: false
};
module.exports = {
    compile: compile,
    compileWithRender: compileWithRender,
    friendly: friendly,
    compact: compact
};
