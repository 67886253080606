// Generated by purs version 0.14.3
"use strict";
var Data_Vec2 = require("../Data.Vec2/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Attributes_Transform = require("../Halogen.Svg.Attributes.Transform/index.js");
var translateTo = function (vec) {
    return new Halogen_Svg_Attributes_Transform.Translate(Data_Vec2.x(vec), Data_Vec2.y(vec));
};
var translateTo$prime = function (vec) {
    return Halogen_Svg_Attributes.transform([ translateTo(vec) ]);
};
module.exports = {
    translateTo: translateTo,
    "translateTo'": translateTo$prime
};
