// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Hydra_Fn = require("../Hydra.Fn/index.js");
var Hydra_Toolkit_Shape = require("../Hydra.Toolkit.Shape/index.js");
var Hydra_Try = require("../Hydra.Try/index.js");
var Noodle_Node_Define = require("../Noodle.Node.Define/index.js");
var Noodle_Node_Shape = require("../Noodle.Node.Shape/index.js");
var T = (function () {
    function T() {

    };
    T.value = new T();
    return T;
})();
var V = (function () {
    function V() {

    };
    V.value = new V();
    return V;
})();
var Source = (function () {
    function Source() {

    };
    Source.value = new Source();
    return Source;
})();
var Geom = (function () {
    function Geom() {

    };
    Geom.value = new Geom();
    return Geom;
})();
var Color = (function () {
    function Color() {

    };
    Color.value = new Color();
    return Color;
})();
var Blend = (function () {
    function Blend() {

    };
    Blend.value = new Blend();
    return Blend;
})();
var Mod = (function () {
    function Mod() {

    };
    Mod.value = new Mod();
    return Mod;
})();
var GenId = function (x) {
    return x;
};
var v = Data_Function.flip(Data_Tuple.Tuple.create)(V.value);
var v1 = function (v1$prime) {
    return [ v(v1$prime) ];
};
var v2 = function (v1$prime) {
    return function (v2$prime) {
        return Data_Functor.map(Data_Functor.functorArray)(v)([ v1$prime, v2$prime ]);
    };
};
var v3 = function (v1$prime) {
    return function (v2$prime) {
        return function (v3$prime) {
            return Data_Functor.map(Data_Functor.functorArray)(v)([ v1$prime, v2$prime, v3$prime ]);
        };
    };
};
var v4 = function (v1$prime) {
    return function (v2$prime) {
        return function (v3$prime) {
            return function (v4$prime) {
                return Data_Functor.map(Data_Functor.functorArray)(v)([ v1$prime, v2$prime, v3$prime, v4$prime ]);
            };
        };
    };
};
var v5 = function (v1$prime) {
    return function (v2$prime) {
        return function (v3$prime) {
            return function (v4$prime) {
                return function (v5$prime) {
                    return Data_Functor.map(Data_Functor.functorArray)(v)([ v1$prime, v2$prime, v3$prime, v4$prime, v5$prime ]);
                };
            };
        };
    };
};
var tovToShape = function (v6) {
    if (v6 instanceof T) {
        return Hydra_Toolkit_Shape.texture;
    };
    if (v6 instanceof V) {
        return Hydra_Toolkit_Shape.value;
    };
    throw new Error("Failed pattern match at Hydra.Toolkit.Generate (line 194, column 1 - line 194, column 48): " + [ v6.constructor.name ]);
};
var t = Data_Function.flip(Data_Tuple.Tuple.create)(T.value);
var vt0 = function (t0) {
    return [ t(t0) ];
};
var vt1 = function (t0$prime) {
    return function (v1$prime) {
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vt0(t0$prime))(v1(v1$prime));
    };
};
var vt2 = function (t0$prime) {
    return function (v1$prime) {
        return function (v2$prime) {
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vt0(t0$prime))(v2(v1$prime)(v2$prime));
        };
    };
};
var vt3 = function (t0$prime) {
    return function (v1$prime) {
        return function (v2$prime) {
            return function (v3$prime) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vt0(t0$prime))(v3(v1$prime)(v2$prime)(v3$prime));
            };
        };
    };
};
var vt4 = function (t0$prime) {
    return function (v1$prime) {
        return function (v2$prime) {
            return function (v3$prime) {
                return function (v4$prime) {
                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vt0(t0$prime))(v4(v1$prime)(v2$prime)(v3$prime)(v4$prime));
                };
            };
        };
    };
};
var vt5 = function (t0$prime) {
    return function (v1$prime) {
        return function (v2$prime) {
            return function (v3$prime) {
                return function (v4$prime) {
                    return function (v5$prime) {
                        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vt0(t0$prime))(v5(v1$prime)(v2$prime)(v3$prime)(v4$prime)(v5$prime));
                    };
                };
            };
        };
    };
};
var vtt0 = function (t0) {
    return function (t1) {
        return [ t(t0), t(t1) ];
    };
};
var vtt1 = function (t0$prime) {
    return function (t1$prime) {
        return function (v1$prime) {
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vtt0(t0$prime)(t1$prime))(v1(v1$prime));
        };
    };
};
var vtt2 = function (t0$prime) {
    return function (t1$prime) {
        return function (v1$prime) {
            return function (v2$prime) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vtt0(t0$prime)(t1$prime))(v2(v1$prime)(v2$prime));
            };
        };
    };
};
var vtt3 = function (t0$prime) {
    return function (t1$prime) {
        return function (v1$prime) {
            return function (v2$prime) {
                return function (v3$prime) {
                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vtt0(t0$prime)(t1$prime))(v3(v1$prime)(v2$prime)(v3$prime));
                };
            };
        };
    };
};
var vtt4 = function (t0$prime) {
    return function (t1$prime) {
        return function (v1$prime) {
            return function (v2$prime) {
                return function (v3$prime) {
                    return function (v4$prime) {
                        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(vtt0(t0$prime)(t1$prime))(v4(v1$prime)(v2$prime)(v3$prime)(v4$prime));
                    };
                };
            };
        };
    };
};
var toFnStringTextureOrValue = new Hydra_Fn.ToFn(function (v6) {
    if (v6 === "noise") {
        return Hydra_Fn.fn("noise")(v2("scale")("offset"));
    };
    if (v6 === "voronoi") {
        return Hydra_Fn.fn("voronoi")(v3("scale")("speed")("blending"));
    };
    if (v6 === "osc") {
        return Hydra_Fn.fn("osc")(v3("freq")("sync")("offset"));
    };
    if (v6 === "shape") {
        return Hydra_Fn.fn("shape")(v3("sides")("radius")("smoothing"));
    };
    if (v6 === "gradient") {
        return Hydra_Fn.fn("gradient")(v1("speed"));
    };
    if (v6 === "solid") {
        return Hydra_Fn.fn("solid")(v4("r")("g")("b")("a"));
    };
    if (v6 === "rotate") {
        return Hydra_Fn.fn("rotate")(vt2("texture")("angle")("speed"));
    };
    if (v6 === "scale") {
        return Hydra_Fn.fn("scale")(vt5("texture")("amount")("x-mult")("y-mult")("offset-x")("offset-y"));
    };
    if (v6 === "pixelate") {
        return Hydra_Fn.fn("pixelate")(vt2("texture")("pixel-x")("pixel-y"));
    };
    if (v6 === "repeat") {
        return Hydra_Fn.fn("repeat")(vt4("texture")("repeat-x")("repeat-y")("offset-x")("offset-y"));
    };
    if (v6 === "repeat-x") {
        return Hydra_Fn.fn("repeat-x")(vt2("texture")("reps")("offset"));
    };
    if (v6 === "repeat-y") {
        return Hydra_Fn.fn("repeat-y")(vt2("texture")("reps")("offset"));
    };
    if (v6 === "kaleid") {
        return Hydra_Fn.fn("kaleid")(vt1("texture")("n-sides"));
    };
    if (v6 === "scroll-x") {
        return Hydra_Fn.fn("scroll-x")(vt2("texture")("amount")("speed"));
    };
    if (v6 === "scroll-y") {
        return Hydra_Fn.fn("scroll-y")(vt2("texture")("amount")("speed"));
    };
    if (v6 === "posterize") {
        return Hydra_Fn.fn("posterize")(vt2("texture")("bins")("gamma"));
    };
    if (v6 === "shift") {
        return Hydra_Fn.fn("shift")(vt4("texture")("r")("g")("b")("a"));
    };
    if (v6 === "invert") {
        return Hydra_Fn.fn("invert")(vt1("texture")("amount"));
    };
    if (v6 === "contrast") {
        return Hydra_Fn.fn("contrast")(vt1("texture")("amount"));
    };
    if (v6 === "brightness") {
        return Hydra_Fn.fn("brightness")(vt1("texture")("amount"));
    };
    if (v6 === "luma") {
        return Hydra_Fn.fn("luma")(vt2("texture")("threshhold")("tolerance"));
    };
    if (v6 === "thresh") {
        return Hydra_Fn.fn("thresh")(vt2("texture")("threshhold")("tolerance"));
    };
    if (v6 === "color") {
        return Hydra_Fn.fn("color")(vt4("texture")("r")("g")("b")("a"));
    };
    if (v6 === "saturate") {
        return Hydra_Fn.fn("saturate")(vt1("texture")("amount"));
    };
    if (v6 === "hue") {
        return Hydra_Fn.fn("hue")(vt1("texture")("amount"));
    };
    if (v6 === "colorama") {
        return Hydra_Fn.fn("colorama")(vt1("texture")("amount"));
    };
    if (v6 === "add") {
        return Hydra_Fn.fn("add")(vtt1("texture")("what")("amount"));
    };
    if (v6 === "layer") {
        return Hydra_Fn.fn("layer")(vtt0("texture")("what"));
    };
    if (v6 === "blend") {
        return Hydra_Fn.fn("blend")(vtt1("texture")("what")("amount"));
    };
    if (v6 === "mult") {
        return Hydra_Fn.fn("mult")(vtt1("texture")("what")("amount"));
    };
    if (v6 === "diff") {
        return Hydra_Fn.fn("diff")(vtt0("texture")("what"));
    };
    if (v6 === "mask") {
        return Hydra_Fn.fn("mask")(vtt2("texture")("what")("reps")("offset"));
    };
    if (v6 === "mod-repeat") {
        return Hydra_Fn.fn("mod-repeat")(vtt4("texture")("what")("repeat-x")("repeat-y")("offset-x")("offset-y"));
    };
    if (v6 === "mod-repeat-x") {
        return Hydra_Fn.fn("mod-repeat-x")(vtt2("texture")("what")("reps")("offset"));
    };
    if (v6 === "mod-repeat-y") {
        return Hydra_Fn.fn("mod-repeat-y")(vtt2("texture")("what")("reps")("offset"));
    };
    if (v6 === "mod-kaleid") {
        return Hydra_Fn.fn("mod-kaleid")(vtt1("texture")("what")("n-sides"));
    };
    if (v6 === "mod-scroll-x") {
        return Hydra_Fn.fn("mod-scroll-x")(vtt2("texture")("what")("amount")("speed"));
    };
    if (v6 === "mod-scroll-y") {
        return Hydra_Fn.fn("mod-scroll-y")(vtt2("texture")("what")("amount")("speed"));
    };
    if (v6 === "modulate") {
        return Hydra_Fn.fn("modulate")(vtt1("texture")("what")("amount"));
    };
    if (v6 === "mod-scale") {
        return Hydra_Fn.fn("mod-scale")(vtt2("texture")("what")("multiple")("offset"));
    };
    if (v6 === "mod-pixelate") {
        return Hydra_Fn.fn("mod-pixelate")(vtt2("texture")("what")("multiple")("offset"));
    };
    if (v6 === "mod-rotate") {
        return Hydra_Fn.fn("mod-rotate")(vtt2("texture")("what")("multiple")("offset"));
    };
    if (v6 === "mod-hue") {
        return Hydra_Fn.fn("mod-hue")(vtt1("texture")("what")("amount"));
    };
    return Hydra_Fn.fn("")([  ]);
});
var toFnGenIdTextureOrValue = new Hydra_Fn.ToFn(function (v6) {
    return Hydra_Fn.toFn(toFnStringTextureOrValue)(v6);
});
var outletsFromFn = function (fn) {
    return Noodle_Node_Shape.formOutlets([ new Data_Tuple.Tuple(Hydra_Fn.getName(fn), Hydra_Toolkit_Shape.texture) ]);
};
var loadFromInlet = Data_Function.flip(Noodle_Node_Define.receive);
var makeReceiver = function (x) {
    return function (fn) {
        return function (f) {
            return function (inlets) {
                return Noodle_Node_Define["pass'"]([ new Data_Tuple.Tuple(Hydra_Fn.getName(fn), Control_Bind.join(Data_Maybe.bindMaybe)(x(f)(Data_Functor.map(Hydra_Fn.fnFunctor)(Data_Functor.map(Data_Functor.functorFn)(loadFromInlet(inlets))(Data_Tuple.fst))(Hydra_Fn.addNames(fn))))) ]);
            };
        };
    };
};
var inletsFromFn = function (fn) {
    return Noodle_Node_Shape.formInlets(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(tovToShape))(Hydra_Fn.getArgs(fn)));
};
var fromFn = function (fn) {
    return Noodle_Node_Define.define(inletsFromFn(fn))(outletsFromFn(fn));
};
var fromFn1 = function (fn) {
    return function (f) {
        return fromFn(fn)(makeReceiver(Hydra_Fn.applyFn1)(fn)(f));
    };
};
var fromFn2 = function (fn) {
    return function (f) {
        return fromFn(fn)(makeReceiver(Hydra_Fn.applyFn2)(fn)(f));
    };
};
var fromFn3 = function (fn) {
    return function (f) {
        return fromFn(fn)(makeReceiver(Hydra_Fn.applyFn3)(fn)(f));
    };
};
var fromFn4 = function (fn) {
    return function (f) {
        return fromFn(fn)(makeReceiver(Hydra_Fn.applyFn4)(fn)(f));
    };
};
var fromFn5 = function (fn) {
    return function (f) {
        return fromFn(fn)(makeReceiver(Hydra_Fn.applyFn5)(fn)(f));
    };
};
var fromFn6 = function (fn) {
    return function (f) {
        return fromFn(fn)(makeReceiver(Hydra_Fn.applyFn6)(fn)(f));
    };
};
var generate$prime = function (v6) {
    if (v6 === "noise") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("noise"))(Hydra_Try.noise);
    };
    if (v6 === "voronoi") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("voronoi"))(Hydra_Try.voronoi);
    };
    if (v6 === "osc") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("osc"))(Hydra_Try.osc);
    };
    if (v6 === "shape") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("shape"))(Hydra_Try.shape);
    };
    if (v6 === "gradient") {
        return fromFn1(Hydra_Fn.toFn(toFnStringTextureOrValue)("gradient"))(Hydra_Try.gradient);
    };
    if (v6 === "solid") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("solid"))(Hydra_Try.solid);
    };
    if (v6 === "rotate") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("rotate"))(Hydra_Try.rotate);
    };
    if (v6 === "scale") {
        return fromFn6(Hydra_Fn.toFn(toFnStringTextureOrValue)("scale"))(Hydra_Try.scale);
    };
    if (v6 === "pixelate") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("pixelate"))(Hydra_Try.pixelate);
    };
    if (v6 === "repeat") {
        return fromFn5(Hydra_Fn.toFn(toFnStringTextureOrValue)("repeat"))(Hydra_Try.repeat);
    };
    if (v6 === "repeat-x") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("repeat-x"))(Hydra_Try.repeatX);
    };
    if (v6 === "repeat-y") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("repeat-y"))(Hydra_Try.repeatY);
    };
    if (v6 === "kaleid") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("kaleid"))(Hydra_Try.kaleid);
    };
    if (v6 === "scroll-x") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("scroll-x"))(Hydra_Try.scrollX);
    };
    if (v6 === "scroll-y") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("scroll-y"))(Hydra_Try.scrollY);
    };
    if (v6 === "posterize") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("posterize"))(Hydra_Try.posterize);
    };
    if (v6 === "shift") {
        return fromFn5(Hydra_Fn.toFn(toFnStringTextureOrValue)("shift"))(Hydra_Try.shift);
    };
    if (v6 === "invert") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("invert"))(Hydra_Try.invert);
    };
    if (v6 === "contrast") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("contrast"))(Hydra_Try.contrast);
    };
    if (v6 === "brightness") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("brightness"))(Hydra_Try.brightness);
    };
    if (v6 === "luma") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("luma"))(Hydra_Try.luma);
    };
    if (v6 === "thresh") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("thresh"))(Hydra_Try.thresh);
    };
    if (v6 === "color") {
        return fromFn5(Hydra_Fn.toFn(toFnStringTextureOrValue)("color"))(Hydra_Try.color);
    };
    if (v6 === "saturate") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("saturate"))(Hydra_Try.saturate);
    };
    if (v6 === "hue") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("hue"))(Hydra_Try.hue);
    };
    if (v6 === "colorama") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("colorama"))(Hydra_Try.colorama);
    };
    if (v6 === "add") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("add"))(Hydra_Try.add);
    };
    if (v6 === "layer") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("layer"))(Hydra_Try.layer);
    };
    if (v6 === "blend") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("blend"))(Hydra_Try.blend);
    };
    if (v6 === "mult") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("mult"))(Hydra_Try.mult);
    };
    if (v6 === "diff") {
        return fromFn2(Hydra_Fn.toFn(toFnStringTextureOrValue)("diff"))(Hydra_Try.diff);
    };
    if (v6 === "mask") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mask"))(Hydra_Try.mask);
    };
    if (v6 === "mod-repeat") {
        return fromFn6(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-repeat"))(Hydra_Try.modRepeat);
    };
    if (v6 === "mod-repeat-x") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-repeat-x"))(Hydra_Try.modRepeatX);
    };
    if (v6 === "mod-repeat-y") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-repeat-y"))(Hydra_Try.modRepeatY);
    };
    if (v6 === "mod-kaleid") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-kaleid"))(Hydra_Try.modKaleid);
    };
    if (v6 === "mod-scroll-x") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-scroll-x"))(Hydra_Try.modScrollX);
    };
    if (v6 === "mod-scroll-y") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-scroll-y"))(Hydra_Try.modScrollY);
    };
    if (v6 === "modulate") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("modulate"))(Hydra_Try.modulate);
    };
    if (v6 === "mod-scale") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-scale"))(Hydra_Try.modScale);
    };
    if (v6 === "mod-pixelate") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-pixelate"))(Hydra_Try.modPixelate);
    };
    if (v6 === "mod-rotate") {
        return fromFn4(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-rotate"))(Hydra_Try.modRotate);
    };
    if (v6 === "mod-hue") {
        return fromFn3(Hydra_Fn.toFn(toFnStringTextureOrValue)("mod-hue"))(Hydra_Try.modHue);
    };
    return Noodle_Node_Define.empty;
};
var generate = function (v6) {
    return new Data_Tuple.Tuple(v6, generate$prime(v6));
};
var byKind = function (v6) {
    if (v6 instanceof Source) {
        return [ "noise", "voronoi", "osc", "shape", "gradient", "solid" ];
    };
    if (v6 instanceof Geom) {
        return [ "rotate", "scale", "pixelate", "repeat", "repeat-x", "repeat-y", "kaleid", "scroll-x", "scroll-y" ];
    };
    if (v6 instanceof Color) {
        return [ "posterize", "shift", "invert", "contrast", "brightness", "luma", "thresh", "color", "saturate", "hue", "colorama" ];
    };
    if (v6 instanceof Blend) {
        return [ "add", "layer", "blend", "mult", "diff", "mask" ];
    };
    if (v6 instanceof Mod) {
        return [ "mod-repeat", "mod-repeat-x", "mod-repeat-y", "mod-kaleid", "mod-scroll-x", "mod-scroll-y", "modulate", "mod-scale", "mod-pixelate", "mod-rotate", "mod-hue" ];
    };
    throw new Error("Failed pattern match at Hydra.Toolkit.Generate (line 43, column 1 - line 43, column 36): " + [ v6.constructor.name ]);
};
var all$prime = Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.Tuple.create(Source.value))(byKind(Source.value)))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.Tuple.create(Geom.value))(byKind(Geom.value)))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.Tuple.create(Color.value))(byKind(Color.value)))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.Tuple.create(Blend.value))(byKind(Blend.value)))(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.Tuple.create(Mod.value))(byKind(Mod.value))))));
var ofKind = function (family) {
    return Data_Array.findMap(function (v6) {
        var $16 = v6.value1 === family;
        if ($16) {
            return new Data_Maybe.Just(v6.value0);
        };
        return Data_Maybe.Nothing.value;
    })(all$prime);
};
var all = Data_Functor.map(Data_Functor.functorArray)(GenId)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(byKind(Source.value))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(byKind(Geom.value))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(byKind(Color.value))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(byKind(Blend.value))(byKind(Mod.value))))));
module.exports = {
    all: all,
    generate: generate,
    Source: Source,
    Geom: Geom,
    Color: Color,
    Blend: Blend,
    Mod: Mod,
    byKind: byKind,
    ofKind: ofKind
};
