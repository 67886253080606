// Generated by purs version 0.14.3
"use strict";
var App_Component_Patch = require("../App.Component.Patch/index.js");
var App_Emitters = require("../App.Emitters/index.js");
var App_Style_ClassNames = require("../App.Style.ClassNames/index.js");
var App_Svg_Extra = require("../App.Svg.Extra/index.js");
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var CSS_Font = require("../CSS.Font/index.js");
var CSS_Size = require("../CSS.Size/index.js");
var CSS_Stylesheet = require("../CSS.Stylesheet/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_CSS = require("../Halogen.HTML.CSS/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_Query = require("../Halogen.Query/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Subscription = require("../Halogen.Subscription/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Halogen_Svg_Elements_None = require("../Halogen.Svg.Elements.None/index.js");
var Noodle_Network = require("../Noodle.Network/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var SelectPatch = (function () {
    function SelectPatch(value0) {
        this.value0 = value0;
    };
    SelectPatch.create = function (value0) {
        return new SelectPatch(value0);
    };
    return SelectPatch;
})();
var AnimationFrame = (function () {
    function AnimationFrame(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AnimationFrame.create = function (value0) {
        return function (value1) {
            return new AnimationFrame(value0, value1);
        };
    };
    return AnimationFrame;
})();
var WindowResize = (function () {
    function WindowResize(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WindowResize.create = function (value0) {
        return function (value1) {
            return new WindowResize(value0, value1);
        };
    };
    return WindowResize;
})();
var ToPatch = (function () {
    function ToPatch(value0) {
        this.value0 = value0;
    };
    ToPatch.create = function (value0) {
        return new ToPatch(value0);
    };
    return ToPatch;
})();
var FromPatch = (function () {
    function FromPatch(value0) {
        this.value0 = value0;
    };
    FromPatch.create = function (value0) {
        return new FromPatch(value0);
    };
    return FromPatch;
})();
var initialState = function (v) {
    return {
        network: v.network,
        toolkit: v.toolkit,
        style: v.style,
        flow: v.flow,
        components: v.components,
        markings: v.markings,
        getFlags: v.getFlags,
        patchState: v.patchState,
        currentPatch: v.currentPatch,
        windowSize: Data_Vec2.make(1000.0)(1000.0),
        currentFrame: 0.0
    };
};
var _tkPatch = Type_Proxy["Proxy"].value;
var handleAction = function (dictMonadAff) {
    return function (dictMonadEffect) {
        return function (v) {
            if (v instanceof Initialize) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.innerWidth)(Web_HTML.window)))(function (innerWidth) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.innerHeight)(Web_HTML.window)))(function (innerHeight) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                            var $26 = {};
                            for (var $27 in v1) {
                                if ({}.hasOwnProperty.call(v1, $27)) {
                                    $26[$27] = v1[$27];
                                };
                            };
                            $26.windowSize = Data_Vec2.make(Data_Int.toNumber(innerWidth))(Data_Int.toNumber(innerHeight));
                            return $26;
                        }))(function () {
                            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(App_Emitters.windowDimensions(Effect_Class.monadEffectEffect)))(function (windowResize) {
                                return Halogen_Query_HalogenM["subscribe'"](function (sid) {
                                    return Data_Functor.map(Halogen_Subscription.functorEmitter)(WindowResize.create(sid))(windowResize);
                                });
                            });
                        });
                    });
                });
            };
            if (v instanceof SelectPatch) {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                    return state;
                });
            };
            if (v instanceof AnimationFrame) {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                    var $30 = {};
                    for (var $31 in state) {
                        if ({}.hasOwnProperty.call(state, $31)) {
                            $30[$31] = state[$31];
                        };
                    };
                    $30.currentFrame = v.value1;
                    return $30;
                });
            };
            if (v instanceof WindowResize) {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                    var $35 = {};
                    for (var $36 in state) {
                        if ({}.hasOwnProperty.call(state, $36)) {
                            $35[$36] = state[$36];
                        };
                    };
                    $35.windowSize = Data_Vec2.make(Data_Int.toNumber(v.value1.w))(Data_Int.toNumber(v.value1.h));
                    return $35;
                });
            };
            if (v instanceof ToPatch) {
                return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                    return "tkPatch";
                }))(Data_Ord.ordUnit)(_tkPatch)(Data_Unit.unit)(App_Toolkit_UI.TellPatch.create(v.value0));
            };
            if (v instanceof FromPatch) {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                    var $43 = {};
                    for (var $44 in state) {
                        if ({}.hasOwnProperty.call(state, $44)) {
                            $43[$44] = state[$44];
                        };
                    };
                    $43.patchState = v.value0.value0;
                    return $43;
                });
            };
            throw new Error("Failed pattern match at App.Component.App (line 195, column 16 - line 219, column 40): " + [ v.constructor.name ]);
        };
    };
};
var _patch = Type_Proxy["Proxy"].value;
var render = function (dictMonadEffect) {
    return function (v) {
        var patchTab = function (label) {
            return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.patchTab(label)) ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(60.0), Halogen_Svg_Attributes.height(20.0), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.patchTab.background))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.patchTab.stroke))), Halogen_Svg_Attributes.strokeWidth(1.0) ]), Halogen_Svg_Elements.text([ App_Svg_Extra["translateTo'"](Data_Vec2.make(3.0)(3.0)) ])([ Halogen_HTML_Core.text(label) ]) ]);
        };
        var patchesTabs = Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.patchesTabs) ])(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(patchTab)(Data_Tuple.fst))(Noodle_Network.patches(v.network)));
        var patchOffset = Data_Vec2.make(0.0)(20.0 + 4.0);
        var maybeCurrent = function (v1) {
            if (v1 instanceof Data_Maybe.Just) {
                return Halogen_Svg_Elements.g([ App_Svg_Extra["translateTo'"](Data_Vec2.make(0.0)(20.0 + 4.0)) ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                    return "patch";
                }))(Data_Ord.ordUnit)(_patch)(Data_Unit.unit)(App_Component_Patch.component(dictMonadEffect))({
                    patch: v1.value0,
                    toolkit: v.toolkit,
                    style: v.style,
                    flow: v.flow,
                    offset: patchOffset,
                    markings: v.markings,
                    getFlags: v.getFlags,
                    customNodeBody: v.components.node,
                    area: Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(v.windowSize)(patchOffset),
                    patchState: v.patchState
                })(ToPatch.create) ]);
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Halogen_Svg_Elements.text([ App_Svg_Extra["translateTo'"](Data_Vec2.make(0.0)(20.0 + 4.0)) ])([ Halogen_HTML_Core.text("No patch selected") ]);
            };
            throw new Error("Failed pattern match at App.Component.App (line 170, column 9 - line 183, column 18): " + [ v1.constructor.name ]);
        };
        var currentPatch = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Function.flip(Noodle_Network.patch)(v.network))(v.currentPatch);
        var curFrame = Halogen_Svg_Elements.text([ App_Svg_Extra["translateTo'"](Data_Vec2.make(200.0)(0.0)), Halogen_Svg_Attributes.class_("debug") ])([ Halogen_HTML_Core.text(Data_Show.show(Data_Show.showNumber)(v.currentFrame)) ]);
        var background = Halogen_Svg_Elements.g([  ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(Data_Vec2.w(v.windowSize)), Halogen_Svg_Attributes.height(Data_Vec2.h(v.windowSize)), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(v.style.bg.fill))) ]), (function () {
            var v1 = Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create)(currentPatch))(v.components.patch);
            if (v1 instanceof Data_Maybe.Nothing) {
                return Halogen_Svg_Elements_None.none;
            };
            if (v1 instanceof Data_Maybe.Just) {
                return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                    return "tkPatch";
                }))(Data_Ord.ordUnit)(_tkPatch)(Data_Unit.unit)(v1.value0.value1)({
                    patch: v1.value0.value0,
                    size: v.windowSize,
                    patchState: v.patchState
                })(FromPatch.create);
            };
            throw new Error("Failed pattern match at App.Component.App (line 146, column 19 - line 155, column 38): " + [ v1.constructor.name ]);
        })() ]);
        return Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(CSS_Font.fontFamily(v.style.font.family)(Data_NonEmpty.singleton(Control_Plus.plusArray)(CSS_Font.sansSerif)))(function () {
            return CSS_Font.fontSize(CSS_Size.pt(v.style.font.size));
        })) ])([ Halogen_Svg_Elements.svg([ Halogen_Svg_Attributes.width(Data_Vec2.w(v.windowSize)), Halogen_Svg_Attributes.height(Data_Vec2.h(v.windowSize)), Halogen_Svg_Attributes.id("noodle") ])([ background, curFrame, patchesTabs, maybeCurrent(currentPatch) ]) ]);
    };
};
var component = function (dictMonadAff) {
    return function (dictMonadEffect) {
        return Halogen_Component.mkComponent({
            initialState: initialState,
            render: render(dictMonadAff.MonadEffect0()),
            "eval": Halogen_Component.mkEval({
                handleAction: handleAction(dictMonadAff)(dictMonadAff.MonadEffect0()),
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                initialize: new Data_Maybe.Just(Initialize.value),
                finalize: Halogen_Component.defaultEval.finalize
            })
        });
    };
};
module.exports = {
    "_patch": _patch,
    "_tkPatch": _tkPatch,
    Initialize: Initialize,
    SelectPatch: SelectPatch,
    AnimationFrame: AnimationFrame,
    WindowResize: WindowResize,
    ToPatch: ToPatch,
    FromPatch: FromPatch,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
