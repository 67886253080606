// Generated by purs version 0.14.3
"use strict";
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Shape = (function () {
    function Shape(value0) {
        this.value0 = value0;
    };
    Shape.create = function (value0) {
        return new Shape(value0);
    };
    return Shape;
})();
var IsShape = function (accept) {
    this.accept = accept;
};
var visible = function (v) {
    return new Shape({
        id: v.value0.id,
        "default": v["value0"]["default"],
        accept: v.value0.accept,
        isHot: v.value0.isHot,
        hidden: false
    });
};
var transform = function (v) {
    return v.value0.accept;
};
var shapeIsShape = new IsShape(function (v) {
    return v.value0.accept;
});
var shape = function (id1) {
    return function (v) {
        return function (accept1) {
            return new Shape({
                id: id1,
                "default": v,
                accept: accept1,
                isHot: true,
                hidden: false
            });
        };
    };
};
var shapeBy = function (id1) {
    return function (v) {
        return function (test) {
            return shape(id1)(v)(function (d) {
                var $27 = test(d);
                if ($27) {
                    return new Data_Maybe.Just(d);
                };
                return Data_Maybe.Nothing.value;
            });
        };
    };
};
var through = function (id1) {
    return Data_Function.flip(shape(id1))(Data_Maybe.Just.create);
};
var lcmap = function (f) {
    return function (v) {
        return new Shape({
            id: v.value0.id,
            "default": v["value0"]["default"],
            accept: function ($66) {
                return v.value0.accept(f($66));
            },
            isHot: v.value0.isHot,
            hidden: v.value0.hidden
        });
    };
};
var isHot = function (v) {
    return v.value0.isHot;
};
var isHidden = function (v) {
    return v.value0.hidden;
};
var id = function (v) {
    return v.value0.id;
};
var hot = function (v) {
    return new Shape({
        id: v.value0.id,
        "default": v["value0"]["default"],
        accept: v.value0.accept,
        isHot: true,
        hidden: v.value0.hidden
    });
};
var hidden = function (v) {
    return new Shape({
        id: v.value0.id,
        "default": v["value0"]["default"],
        accept: v.value0.accept,
        isHot: v.value0.isHot,
        hidden: true
    });
};
var getDefault = function (v) {
    return v["value0"]["default"];
};
var functorShape = new Data_Functor.Functor(function (f) {
    return function (v) {
        return new Shape({
            id: v.value0.id,
            "default": f(v["value0"]["default"]),
            isHot: v.value0.isHot,
            hidden: v.value0.hidden,
            accept: Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(f))(v.value0.accept)
        });
    };
});
var move = function (f) {
    return function (g) {
        return function (shape_) {
            return lcmap(g)(Data_Functor.map(functorShape)(f)(shape_));
        };
    };
};
var profunctorShape = new Data_Profunctor.Profunctor(function (f) {
    return function (g) {
        return function (s) {
            return lcmap(f)(Data_Functor.map(functorShape)(g)(s));
        };
    };
});
var move$prime = Data_Profunctor.dimap(profunctorShape);
var rmap = Data_Functor.map(functorShape);
var $$default = function (default$prime) {
    return function (v) {
        return new Shape({
            id: v.value0.id,
            "default": default$prime,
            accept: v.value0.accept,
            isHot: v.value0.isHot,
            hidden: v.value0.hidden
        });
    };
};
var cold = function (v) {
    return new Shape({
        id: v.value0.id,
        "default": v["value0"]["default"],
        accept: v.value0.accept,
        isHot: false,
        hidden: v.value0.hidden
    });
};
var acceptWith = function (f) {
    return function (v) {
        return new Shape({
            id: v.value0.id,
            "default": v["value0"]["default"],
            accept: f,
            isHot: v.value0.isHot,
            hidden: v.value0.hidden
        });
    };
};
var accept = function (dict) {
    return dict.accept;
};
module.exports = {
    accept: accept,
    Shape: Shape,
    IsShape: IsShape,
    id: id,
    "default": $$default,
    getDefault: getDefault,
    transform: transform,
    isHot: isHot,
    cold: cold,
    hot: hot,
    isHidden: isHidden,
    hidden: hidden,
    visible: visible,
    shape: shape,
    shapeBy: shapeBy,
    through: through,
    acceptWith: acceptWith,
    lcmap: lcmap,
    rmap: rmap,
    move: move,
    "move'": move$prime,
    functorShape: functorShape,
    profunctorShape: profunctorShape,
    shapeIsShape: shapeIsShape
};
