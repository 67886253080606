// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var UserSpaceOnUse_ = (function () {
    function UserSpaceOnUse_() {

    };
    UserSpaceOnUse_.value = new UserSpaceOnUse_();
    return UserSpaceOnUse_;
})();
var ObjectBoundingBox = (function () {
    function ObjectBoundingBox() {

    };
    ObjectBoundingBox.value = new ObjectBoundingBox();
    return ObjectBoundingBox;
})();
var showMaskUnit = new Data_Show.Show(function (v) {
    if (v instanceof UserSpaceOnUse_) {
        return "userSpaceOnUse";
    };
    if (v instanceof ObjectBoundingBox) {
        return "objectBoundingBox";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.MaskUnit (line 15, column 10 - line 17, column 45): " + [ v.constructor.name ]);
});
var printMaskUnit = function (v) {
    if (v instanceof UserSpaceOnUse_) {
        return "userSpaceOnUse";
    };
    if (v instanceof ObjectBoundingBox) {
        return "objectBoundingBox";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.MaskUnit (line 22, column 17 - line 24, column 43): " + [ v.constructor.name ]);
};
var eqMaskUnit = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof UserSpaceOnUse_ && y instanceof UserSpaceOnUse_) {
            return true;
        };
        if (x instanceof ObjectBoundingBox && y instanceof ObjectBoundingBox) {
            return true;
        };
        return false;
    };
});
module.exports = {
    UserSpaceOnUse_: UserSpaceOnUse_,
    ObjectBoundingBox: ObjectBoundingBox,
    printMaskUnit: printMaskUnit,
    eqMaskUnit: eqMaskUnit,
    showMaskUnit: showMaskUnit
};
