// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var Color = require("../Color/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var body = {
    shadow: new App_Style.Solid({
        offset: Data_Vec2.make(5.0)(5.0)
    }),
    size: 110.0,
    margin: Data_Vec2.make(50.0)(10.0),
    fill: Color.rgba(0)(0)(0)(0.9),
    stroke: Color.rgb(0)(0)(0),
    strokeWidth: 1.0,
    cornerRadius: 5.0
};
module.exports = {
    body: body
};
