// Generated by purs version 0.14.3
"use strict";
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var OSet = function (x) {
    return x;
};
var zipWithA = function (dictEq) {
    return function (dictApplicative) {
        return function (f) {
            return function (v) {
                return function (v1) {
                    return Data_Functor.map((dictApplicative.Apply0()).Functor0())((function () {
                        var $267 = Data_Array.nubEq(dictEq);
                        return function ($268) {
                            return OSet($267($268));
                        };
                    })())(Data_Array.zipWithA(dictApplicative)(f)(v)(v1));
                };
            };
        };
    };
};
var zipWith = function (dictEq) {
    return function (f) {
        return function (v) {
            return function (v1) {
                return OSet(Data_Array.nubEq(dictEq)(Data_Array.zipWith(f)(v)(v1)));
            };
        };
    };
};
var zip = function (v) {
    return function (v1) {
        return OSet(Data_Array.zip(v)(v1));
    };
};
var updateAtIndices = function (dictEq) {
    return function (dictFoldable) {
        return function (us) {
            return function (v) {
                return OSet(Data_Array.nubEq(dictEq)(Data_Array.updateAtIndices(dictFoldable)(us)(v)));
            };
        };
    };
};
var updateAt = function (dictEq) {
    return function (p) {
        return function (x) {
            return function (v) {
                var f = function (xs$prime) {
                    var $133 = Data_Array.elem(dictEq)(x)(v);
                    if ($133) {
                        return v;
                    };
                    return xs$prime;
                };
                return Data_Functor.map(Data_Maybe.functorMaybe)(f)(Data_Array.updateAt(p)(x)(v));
            };
        };
    };
};
var unzip = function (dictEq) {
    return function (dictEq1) {
        return function (v) {
            var f = function (v1) {
                return new Data_Tuple.Tuple(OSet(Data_Array.nubEq(dictEq)(v1.value0)), OSet(Data_Array.nubEq(dictEq1)(v1.value1)));
            };
            return f(Data_Array.unzip(v));
        };
    };
};
var unsnoc = function (v) {
    var f = function (v1) {
        return {
            init: v1.init,
            last: v1.last
        };
    };
    return Data_Functor.map(Data_Maybe.functorMaybe)(f)(Data_Array.unsnoc(v));
};
var unsafeIndex = function (dictPartial) {
    return function (v) {
        return function (p) {
            return v[p];
        };
    };
};
var unionBy = function (dictEq) {
    return function (f) {
        return function (v) {
            return function (v1) {
                return OSet(Data_Array.nubEq(dictEq)(Data_Array.unionBy(f)(v)(v1)));
            };
        };
    };
};
var union = function (dictEq) {
    return function (v) {
        return function (v1) {
            return OSet(Data_Array.nubEq(dictEq)(Data_Array.union(dictEq)(v)(v1)));
        };
    };
};
var uncons = function (v) {
    var f = function (v1) {
        return {
            head: v1.head,
            tail: v1.tail
        };
    };
    return Data_Functor.map(Data_Maybe.functorMaybe)(f)(Data_Array.uncons(v));
};
var toUnfoldable = function (dictUnfoldable) {
    return function (v) {
        return Data_Array.toUnfoldable(dictUnfoldable)(v);
    };
};
var takeWhile = function (f) {
    return function (v) {
        return OSet(Data_Array.takeWhile(f)(v));
    };
};
var takeEnd = function (p) {
    return function (v) {
        return OSet(Data_Array.takeEnd(p)(v));
    };
};
var take = function (p) {
    return function (v) {
        return OSet(Data_Array.take(p)(v));
    };
};
var tail = function (v) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(OSet)(Data_Array.tail(v));
};
var span = function (f) {
    return function (v) {
        var g = function (v1) {
            return {
                init: v1.init,
                rest: v1.rest
            };
        };
        return g(Data_Array.span(f)(v));
    };
};
var sortWith = function (dictOrd) {
    return function (f) {
        return function (v) {
            return OSet(Data_Array.sortWith(dictOrd)(f)(v));
        };
    };
};
var sortBy = function (f) {
    return function (v) {
        return OSet(Data_Array.sortBy(f)(v));
    };
};
var sort = function (dictOrd) {
    return function (v) {
        return OSet(Data_Array.sort(dictOrd)(v));
    };
};
var snoc = function (dictEq) {
    return function (v) {
        return function (x) {
            var $173 = Data_Array.elem(dictEq)(x)(v);
            if ($173) {
                return v;
            };
            return OSet(Data_Array.snoc(v)(x));
        };
    };
};
var slice = function (p) {
    return function (q) {
        return function (v) {
            return OSet(Data_Array.slice(p)(q)(v));
        };
    };
};
var singleton = function (a) {
    return [ a ];
};
var semigroupOSet = function (dictEq) {
    return new Data_Semigroup.Semigroup(function (v) {
        return function (v1) {
            return OSet(Data_Array.nubEq(dictEq)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v)(v1)));
        };
    });
};
var reverse = function (v) {
    return OSet(Data_Array.reverse(v));
};
var range = function (a) {
    return function (b) {
        return OSet(Data_Array.range(a)(b));
    };
};
var partition = function (f) {
    return function (v) {
        var g = function (v1) {
            return {
                no: v1.no,
                yes: v1.yes
            };
        };
        return g(Data_Array.partition(f)(v));
    };
};
var $$null = function (v) {
    return Data_Array["null"](v);
};
var modifyAt = function (dictEq) {
    return function (p) {
        return function (f) {
            return function (v) {
                return Data_Functor.map(Data_Maybe.functorMaybe)((function () {
                    var $269 = Data_Array.nubEq(dictEq);
                    return function ($270) {
                        return OSet($269($270));
                    };
                })())(Data_Array.modifyAt(p)(f)(v));
            };
        };
    };
};
var mapWithIndex = function (f) {
    return function (v) {
        return OSet(Data_Array.mapWithIndex(f)(v));
    };
};
var mapMaybe = function (f) {
    return function (v) {
        return OSet(Data_Array.mapMaybe(f)(v));
    };
};
var length = function (v) {
    return Data_Array.length(v);
};
var last = function (v) {
    return Data_Array.last(v);
};
var isEmpty = function (dictEq) {
    return function (v) {
        return Data_Eq.eq(Data_Eq.eqArray(dictEq))(v)([  ]);
    };
};
var intersectBy = function (f) {
    return function (v) {
        return function (v1) {
            return OSet(Data_Array.intersectBy(f)(v)(v1));
        };
    };
};
var intersect = function (dictEq) {
    return function (v) {
        return function (v1) {
            return OSet(Data_Array.intersect(dictEq)(v)(v1));
        };
    };
};
var insertBy = function (dictEq) {
    return function (cmp) {
        return function (x) {
            return function (v) {
                var $204 = Data_Array.elem(dictEq)(x)(v);
                if ($204) {
                    return v;
                };
                return OSet(Data_Array.insertBy(cmp)(x)(v));
            };
        };
    };
};
var insertAt = function (dictEq) {
    return function (p) {
        return function (x) {
            return function (v) {
                var f = function (xs$prime) {
                    var $208 = Data_Array.elem(dictEq)(x)(v);
                    if ($208) {
                        return v;
                    };
                    return xs$prime;
                };
                return Data_Functor.map(Data_Maybe.functorMaybe)(f)(Data_Array.insertAt(p)(x)(v));
            };
        };
    };
};
var insert = function (dictOrd) {
    return function (x) {
        return function (v) {
            var $211 = Data_Array.elem(dictOrd.Eq0())(x)(v);
            if ($211) {
                return v;
            };
            return OSet(Data_Array.insert(dictOrd)(x)(v));
        };
    };
};
var init = function (v) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(OSet)(Data_Array.init(v));
};
var index = function (v) {
    return function (b) {
        return Data_Array.index(v)(b);
    };
};
var head = function (v) {
    return Data_Array.head(v);
};
var fromFoldable = function (dictFoldable) {
    var $271 = Data_Array.fromFoldable(dictFoldable);
    return function ($272) {
        return OSet($271($272));
    };
};
var foldableOSet = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (f) {
        return function (v) {
            return Data_Foldable.foldMap(Data_Foldable.foldableArray)(dictMonoid)(f)(v);
        };
    };
}, function (f) {
    return function (x) {
        return function (v) {
            return Data_Foldable.foldl(Data_Foldable.foldableArray)(f)(x)(v);
        };
    };
}, function (f) {
    return function (x) {
        return function (v) {
            return Data_Foldable.foldr(Data_Foldable.foldableArray)(f)(x)(v);
        };
    };
});
var foldRecM = function (dictMonadRec) {
    return function (f) {
        return function (x) {
            return function (v) {
                return Data_Array.foldRecM(dictMonadRec)(f)(x)(v);
            };
        };
    };
};
var foldM = function (dictMonad) {
    return function (f) {
        return function (x) {
            return function (v) {
                return Data_Array.foldM(dictMonad)(f)(x)(v);
            };
        };
    };
};
var findLastIndex = function (cmp) {
    return function (v) {
        return Data_Array.findLastIndex(cmp)(v);
    };
};
var findIndex = function (cmp) {
    return function (v) {
        return Data_Array.findIndex(cmp)(v);
    };
};
var filterA = function (dictApplicative) {
    return function (f) {
        return function (v) {
            return Data_Functor.map((dictApplicative.Apply0()).Functor0())(OSet)(Data_Array.filterA(dictApplicative)(f)(v));
        };
    };
};
var filter = function (f) {
    return function (v) {
        return OSet(Data_Array.filter(f)(v));
    };
};
var eqSet = function (dictEq) {
    return new Data_Eq.Eq(function (v) {
        return function (v1) {
            return Data_Eq.eq(Data_Eq.eqArray(dictEq))(v)(v1);
        };
    });
};
var encodeJsonOSet = function (dictEncodeJson) {
    return Data_Argonaut_Encode_Class.encodeJsonArray(dictEncodeJson);
};
var empty = [  ];
var map = function (dictOrd) {
    return function (f) {
        return Data_Foldable.foldl(foldableOSet)(function (m) {
            return function (a) {
                return insert(dictOrd)(f(a))(m);
            };
        })(empty);
    };
};
var elemLastIndex = function (dictEq) {
    return function (a) {
        return function (v) {
            return Data_Array.elemLastIndex(dictEq)(a)(v);
        };
    };
};
var elemIndex = function (dictEq) {
    return function (a) {
        return function (v) {
            return Data_Array.elemIndex(dictEq)(a)(v);
        };
    };
};
var dropWhile = function (f) {
    return function (v) {
        return OSet(Data_Array.dropWhile(f)(v));
    };
};
var dropEnd = function (p) {
    return function (v) {
        return OSet(Data_Array.dropEnd(p)(v));
    };
};
var drop = function (p) {
    return function (v) {
        return OSet(Data_Array.drop(p)(v));
    };
};
var difference = function (dictEq) {
    return function (v) {
        return function (v1) {
            return OSet(Data_Array.difference(dictEq)(v)(v1));
        };
    };
};
var subset = function (dictEq) {
    return function (s1) {
        return function (s2) {
            return isEmpty(dictEq)(difference(dictEq)(s1)(s2));
        };
    };
};
var properSubset = function (dictEq) {
    return function (s1) {
        return function (s2) {
            return subset(dictEq)(s1)(s2) && Data_Eq.notEq(eqSet(dictEq))(s1)(s2);
        };
    };
};
var deleteWith = function (f) {
    return function (v) {
        if (v.length === 0) {
            return v;
        };
        return Data_Maybe.maybe(v)(function (i) {
            return OSet(Data_Maybe.fromJust()(Data_Array.deleteAt(i)(v)));
        })(Data_Array.findIndex(f)(v));
    };
};
var deleteAt = function (p) {
    return function (v) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(OSet)(Data_Array.deleteAt(p)(v));
    };
};
var $$delete = function (dictEq) {
    return function (x) {
        return function (v) {
            return OSet(Data_Array["delete"](dictEq)(x)(v));
        };
    };
};
var decodeJsonOSet = function (dictDecodeJson) {
    return Data_Argonaut_Decode_Class.decodeArray(dictDecodeJson);
};
var cons = function (dictEq) {
    return function (x) {
        return function (v) {
            var $260 = Data_Array.elem(dictEq)(x)(v);
            if ($260) {
                return v;
            };
            return OSet(Data_Array.cons(x)(v));
        };
    };
};
var concat = function (dictEq) {
    return function (v) {
        return OSet(Data_Array.nubEq(dictEq)(Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1;
        })(v))));
    };
};
var catMaybes = function (v) {
    return OSet(Data_Array.catMaybes(v));
};
var alterAt = function (dictEq) {
    return function (p) {
        return function (f) {
            return function (v) {
                return Data_Functor.map(Data_Maybe.functorMaybe)((function () {
                    var $273 = Data_Array.nubEq(dictEq);
                    return function ($274) {
                        return OSet($273($274));
                    };
                })())(Data_Array.alterAt(p)(f)(v));
            };
        };
    };
};
module.exports = {
    fromFoldable: fromFoldable,
    toUnfoldable: toUnfoldable,
    empty: empty,
    isEmpty: isEmpty,
    singleton: singleton,
    subset: subset,
    properSubset: properSubset,
    map: map,
    range: range,
    "null": $$null,
    length: length,
    cons: cons,
    snoc: snoc,
    insert: insert,
    insertBy: insertBy,
    head: head,
    last: last,
    tail: tail,
    init: init,
    uncons: uncons,
    unsnoc: unsnoc,
    index: index,
    elemIndex: elemIndex,
    elemLastIndex: elemLastIndex,
    findIndex: findIndex,
    findLastIndex: findLastIndex,
    insertAt: insertAt,
    deleteAt: deleteAt,
    updateAt: updateAt,
    updateAtIndices: updateAtIndices,
    modifyAt: modifyAt,
    alterAt: alterAt,
    reverse: reverse,
    concat: concat,
    filter: filter,
    partition: partition,
    filterA: filterA,
    mapMaybe: mapMaybe,
    catMaybes: catMaybes,
    mapWithIndex: mapWithIndex,
    sort: sort,
    sortBy: sortBy,
    sortWith: sortWith,
    slice: slice,
    take: take,
    takeWhile: takeWhile,
    drop: drop,
    dropWhile: dropWhile,
    span: span,
    union: union,
    unionBy: unionBy,
    "delete": $$delete,
    deleteWith: deleteWith,
    difference: difference,
    intersect: intersect,
    intersectBy: intersectBy,
    zipWith: zipWith,
    zipWithA: zipWithA,
    zip: zip,
    unzip: unzip,
    foldM: foldM,
    foldRecM: foldRecM,
    unsafeIndex: unsafeIndex,
    eqSet: eqSet,
    semigroupOSet: semigroupOSet,
    foldableOSet: foldableOSet,
    encodeJsonOSet: encodeJsonOSet,
    decodeJsonOSet: decodeJsonOSet,
    all: Data_Foldable.all,
    any: Data_Foldable.any,
    elem: Data_Foldable.elem,
    find: Data_Foldable.find,
    findMap: Data_Foldable.findMap,
    fold: Data_Foldable.fold,
    foldMap: Data_Foldable.foldMap,
    foldl: Data_Foldable.foldl,
    foldr: Data_Foldable.foldr,
    intercalate: Data_Foldable.intercalate,
    notElem: Data_Foldable.notElem,
    scanl: Data_Traversable.scanl,
    scanr: Data_Traversable.scanr
};
