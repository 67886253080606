// Generated by purs version 0.14.3
"use strict";
var Hydra = require("../Hydra/index.js");
var Noodle_Channel_Shape = require("../Noodle.Channel.Shape/index.js");
var value = Noodle_Channel_Shape.shapeBy("value")(Hydra.None.value)(Hydra.isValue);
var texture = Noodle_Channel_Shape.shapeBy("texture")(Hydra.None.value)(Hydra.isTexture);
var operation = Noodle_Channel_Shape.shapeBy("op")(Hydra.None.value)(Hydra.isOp);
var modifier = Noodle_Channel_Shape.shapeBy("modifier")(Hydra.None.value)(Hydra.isModifier);
var buffer = Noodle_Channel_Shape.shapeBy("buffer")(Hydra.None.value)(Hydra.isBuffer);
module.exports = {
    value: value,
    texture: texture,
    modifier: modifier,
    buffer: buffer,
    operation: operation
};
