// Generated by purs version 0.14.3
"use strict";
var DOM_HTML_Indexed_InputType = require("../DOM.HTML.Indexed.InputType/index.js");
var DOM_HTML_Indexed_StepValue = require("../DOM.HTML.Indexed.StepValue/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Show = require("../Data.Show/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var number = function (val) {
    return function (v) {
        return function (def) {
            return function (handler) {
                return Halogen_Svg_Elements.foreignObject([ Halogen_Svg_Attributes.x(0.0), Halogen_Svg_Attributes.y(0.0), Halogen_Svg_Attributes.width(52.0), Halogen_Svg_Attributes.height(22.0) ])([ Halogen_HTML_Elements.input([ Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputNumber.value), Halogen_HTML_Properties.width(40), Halogen_HTML_Properties.height(9), Halogen_HTML_Properties.min(v.min), Halogen_HTML_Properties.max(v.max), Halogen_HTML_Properties.step(new DOM_HTML_Indexed_StepValue.Step(v.step)), Halogen_HTML_Properties.value(Data_Show.show(Data_Show.showNumber)(val)), Halogen_HTML_Events.onValueInput((function () {
                    var $8 = Data_Maybe.maybe(def)(handler);
                    return function ($9) {
                        return $8(Data_Number.fromString($9));
                    };
                })()) ]) ]);
            };
        };
    };
};
var button = function (handler) {
    return Halogen_Svg_Elements.foreignObject([ Halogen_Svg_Attributes.x(0.0), Halogen_Svg_Attributes.y(0.0), Halogen_Svg_Attributes.width(52.0), Halogen_Svg_Attributes.height(22.0) ])([ Halogen_HTML_Elements.input([ Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputButton.value), Halogen_HTML_Properties.width(40), Halogen_HTML_Properties.height(9), Halogen_HTML_Events.onClick(Data_Function["const"](handler)) ]) ]);
};
module.exports = {
    number: number,
    button: button
};
