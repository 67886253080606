// Generated by purs version 0.14.3
"use strict";
var Color = require("../Color/index.js");
var Halogen_Svg_Attributes_Color = require("../Halogen.Svg.Attributes.Color/index.js");
var transparent = Color.rgba(0)(0)(0)(0.0);
var toSvg = function (color) {
    var v = Color.toRGBA(color);
    return new Halogen_Svg_Attributes_Color.RGBA(v.r, v.g, v.b, v.a);
};
var dim = function (amount) {
    return function (color) {
        var v = Color.toRGBA(color);
        return Color.rgba(v.r)(v.g)(v.b)(v.a - amount);
    };
};
module.exports = {
    toSvg: toSvg,
    transparent: transparent,
    dim: dim
};
