// Generated by purs version 0.14.3
"use strict";
var CSS_Common = require("../CSS.Common/index.js");
var CSS_Property = require("../CSS.Property/index.js");
var CSS_String = require("../CSS.String/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Size = function (x) {
    return x;
};
var Angle = function (x) {
    return x;
};
var vw = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("vw"));
};
var vmin = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("vmin"));
};
var vmax = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("vmax"));
};
var vh = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("vh"));
};
var valSize = new CSS_Property.Val(function (v) {
    return v;
});
var valAngle = new CSS_Property.Val(function (v) {
    return v;
});
var unitless = (function () {
    var $32 = CSS_Property.value(CSS_Property.valNumber);
    return function ($33) {
        return Size($32($33));
    };
})();
var sym = function (f) {
    return function (a) {
        return f(a)(a)(a)(a);
    };
};
var rem = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("rem"));
};
var rad = function (i) {
    return Angle(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("rad")));
};
var px = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("px"));
};
var pt = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("pt"));
};
var pct = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("%"));
};
var nil = Size(CSS_String.fromString(CSS_Property.isStringValue)("0"));
var isStringSize = new CSS_String.IsString((function () {
    var $34 = CSS_String.fromString(CSS_Property.isStringValue);
    return function ($35) {
        return Size($34($35));
    };
})());
var ex = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("ex"));
};
var eqSize = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
        };
    });
};
var ordSize = function (dictOrd) {
    return new Data_Ord.Ord(function () {
        return eqSize(dictOrd.Eq0());
    }, function (x) {
        return function (y) {
            return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
        };
    });
};
var eqAngle = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
        };
    });
};
var ordAngle = function (dictOrd) {
    return new Data_Ord.Ord(function () {
        return eqAngle(dictOrd.Eq0());
    }, function (x) {
        return function (y) {
            return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
        };
    });
};
var em = function (i) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("em"));
};
var deg = function (i) {
    return Angle(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("deg")));
};
var autoSize = new CSS_Common.Auto(CSS_String.fromString(isStringSize)("auto"));
module.exports = {
    Size: Size,
    nil: nil,
    unitless: unitless,
    px: px,
    pt: pt,
    em: em,
    ex: ex,
    pct: pct,
    rem: rem,
    vw: vw,
    vh: vh,
    vmin: vmin,
    vmax: vmax,
    sym: sym,
    Angle: Angle,
    deg: deg,
    rad: rad,
    eqSize: eqSize,
    ordSize: ordSize,
    isStringSize: isStringSize,
    valSize: valSize,
    autoSize: autoSize,
    eqAngle: eqAngle,
    ordAngle: ordAngle,
    valAngle: valAngle
};
