// Generated by purs version 0.14.3
"use strict";
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Attributes_CSSLength = require("../Halogen.Svg.Attributes.CSSLength/index.js");
var Halogen_Svg_Attributes_FontSize = require("../Halogen.Svg.Attributes.FontSize/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra = require("../Hydra/index.js");
var Web_HTML_Common = require("../Web.HTML.Common/index.js");
var Select = (function () {
    function Select(value0) {
        this.value0 = value0;
    };
    Select.create = function (value0) {
        return new Select(value0);
    };
    return Select;
})();
var operations = [ Hydra.Addition.value, Hydra.Division.value, Hydra.Multiplication.value, Hydra.Subtraction.value ];
var initialState = function (v) {
    return Hydra.Addition.value;
};
var handleAction = function (v) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM)(v.value0))(function () {
        return Halogen_Query_HalogenM.raise(App_Toolkit_UI.SendToInlet.create("op")(Hydra.fromOp(v.value0)));
    });
};
var bodyWidth = 110.0;
var bodyHeight = 80.0;
var render = function (currentOp) {
    var operatorsCount = Data_Int.toNumber(Data_Array.length(operations));
    var xFor = function (idx) {
        return (bodyWidth / 2.0 - (20.0 * operatorsCount) / 2.0) + Data_Int.toNumber(idx) * 20.0;
    };
    var operatorButton = function (idx) {
        return function (op) {
            return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.class_(Web_HTML_Common.ClassName("operator-button")) ])([ Halogen_Svg_Elements.text([ Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg((function () {
                var $5 = Data_Eq.eq(Hydra.eqOperation)(op)(currentOp);
                if ($5) {
                    return Color.rgb(255)(255)(255);
                };
                return Color.rgb(100)(100)(100);
            })()))), Halogen_Svg_Attributes.x(xFor(idx)), Halogen_Svg_Attributes.y(bodyHeight / 2.0), Halogen_Svg_Attributes.font_size(Halogen_Svg_Attributes_FontSize.FontSizeLength.create(new Halogen_Svg_Attributes_CSSLength.Px(25.0))) ])([ Halogen_HTML_Core.text(Data_Show.show(Hydra.showOperation)(op)) ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.x(xFor(idx)), Halogen_Svg_Attributes.y(bodyHeight / 2.0 - 20.0 / 2.0), Halogen_Svg_Attributes.width(20.0), Halogen_Svg_Attributes.height(20.0), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.rgba(0)(0)(0)(0.0)))), Halogen_HTML_Events.onClick(Data_Function["const"](new Select(op))) ]) ]);
        };
    };
    return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.class_("math-node") ])([ Halogen_Svg_Elements.g([  ])(Data_Array.mapWithIndex(operatorButton)(operations)) ]);
};
var component = Halogen_Component.mkComponent({
    initialState: initialState,
    render: render,
    "eval": Halogen_Component.mkEval({
        handleAction: handleAction,
        handleQuery: Halogen_Component.defaultEval.handleQuery,
        receive: Halogen_Component.defaultEval.receive,
        initialize: Halogen_Component.defaultEval.initialize,
        finalize: Halogen_Component.defaultEval.finalize
    })
});
module.exports = {
    Select: Select,
    operations: operations,
    bodyWidth: bodyWidth,
    bodyHeight: bodyHeight,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    component: component
};
