// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var UserSpaceOnUse = (function () {
    function UserSpaceOnUse() {

    };
    UserSpaceOnUse.value = new UserSpaceOnUse();
    return UserSpaceOnUse;
})();
var StrokeWidth = (function () {
    function StrokeWidth() {

    };
    StrokeWidth.value = new StrokeWidth();
    return StrokeWidth;
})();
var showMarkerUnit = new Data_Show.Show(function (v) {
    if (v instanceof UserSpaceOnUse) {
        return "userSpaceOnUse";
    };
    if (v instanceof StrokeWidth) {
        return "strokeWidth";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.MarkerUnit (line 15, column 10 - line 17, column 36): " + [ v.constructor.name ]);
});
var printMarkerUnit = function (v) {
    if (v instanceof UserSpaceOnUse) {
        return "userSpaceOnUse";
    };
    if (v instanceof StrokeWidth) {
        return "strokeWidth";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.MarkerUnit (line 22, column 19 - line 24, column 34): " + [ v.constructor.name ]);
};
var eqMarkerUnit = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof UserSpaceOnUse && y instanceof UserSpaceOnUse) {
            return true;
        };
        if (x instanceof StrokeWidth && y instanceof StrokeWidth) {
            return true;
        };
        return false;
    };
});
module.exports = {
    UserSpaceOnUse: UserSpaceOnUse,
    StrokeWidth: StrokeWidth,
    printMarkerUnit: printMarkerUnit,
    eqMarkerUnit: eqMarkerUnit,
    showMarkerUnit: showMarkerUnit
};
