// Generated by purs version 0.14.3
"use strict";
var App_Style_Hydra_Background = require("../App.Style.Hydra.Background/index.js");
var App_Toolkit_UI = require("../App.Toolkit.UI/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML_Elements = require("../Halogen.HTML.Elements/index.js");
var Halogen_HTML_Properties = require("../Halogen.HTML.Properties/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Attributes_Color = require("../Halogen.Svg.Attributes.Color/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Hydra_Engine = require("../Hydra.Engine/index.js");
var Hydra_Queue = require("../Hydra.Queue/index.js");
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var initialState = function (v) {
    return {
        hydraReady: false,
        size: Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2)),
        queue: v.patchState
    };
};
var handleQuery = function (v) {
    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
        var $14 = {};
        for (var $15 in state) {
            if ({}.hasOwnProperty.call(state, $15)) {
                $14[$15] = state[$15];
            };
        };
        $14.queue = Hydra_Queue.toBuffer(v.value0.value0)(v.value0.value1)(state.queue);
        return $14;
    }))(function (v1) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM.raise(new App_Toolkit_UI.Next(v1.queue)))(function () {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(v.value1));
        });
    });
};
var canvasId = "hydra-canvas";
var handleAction = function (dictMonadEffect) {
    return function (v) {
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Hydra_Engine.init(canvasId)))(function () {
                return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                    var $24 = {};
                    for (var $25 in v1) {
                        if ({}.hasOwnProperty.call(v1, $25)) {
                            $24[$25] = v1[$25];
                        };
                    };
                    $24.hydraReady = true;
                    return $24;
                });
            });
        };
        if (v instanceof Receive) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                var $27 = {};
                for (var $28 in v1) {
                    if ({}.hasOwnProperty.call(v1, $28)) {
                        $27[$28] = v1[$28];
                    };
                };
                $27.size = v.value0.size;
                $27.queue = v.value0.patchState;
                return $27;
            });
        };
        throw new Error("Failed pattern match at Hydra.Component.Patch (line 68, column 1 - line 68, column 101): " + [ v.constructor.name ]);
    };
};
var render = function (v) {
    return Halogen_Svg_Elements.foreignObject([ Halogen_Svg_Attributes.x(0.0), Halogen_Svg_Attributes.y(0.0), Halogen_Svg_Attributes.width(Data_Vec2.w(v.size)), Halogen_Svg_Attributes.height(Data_Vec2.h(v.size)), Halogen_Svg_Attributes.id("hydra-holder") ])([ Halogen_HTML_Elements.canvas([ Halogen_HTML_Properties.id(canvasId), Halogen_HTML_Properties.style("width: 100%; height: 100%;background-color: " + (Halogen_Svg_Attributes_Color.printColor(Data_Maybe.Just.create(Color_Extra.toSvg(App_Style_Hydra_Background.bg.fill))) + ";")) ]) ]);
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: handleQuery,
            receive: function ($35) {
                return Data_Maybe.Just.create(Receive.create($35));
            },
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
module.exports = {
    Initialize: Initialize,
    Receive: Receive,
    canvasId: canvasId,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    handleQuery: handleQuery,
    component: component
};
