// Generated by purs version 0.14.3
"use strict";
var Color = require("../Color/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Hydra = require("../Hydra/index.js");
var Hydra_API = require("../Hydra.API/index.js");
var seq = function (v) {
    if (v instanceof Hydra.Val && v.value0 instanceof Hydra.Seq) {
        return v.value0.value0;
    };
    if (v instanceof Hydra.Val) {
        return [ v.value0 ];
    };
    return [  ];
};
var performMaybe = function (v) {
    return function (v1) {
        return function (v2) {
            if (v instanceof Data_Maybe.Just && (v.value0 instanceof Hydra.Val && (v1 instanceof Data_Maybe.Just && (v1.value0 instanceof Hydra.Op && (v2 instanceof Data_Maybe.Just && v2.value0 instanceof Hydra.Val))))) {
                return Data_Maybe.Just.create(Hydra.Val.create(Hydra_API.expr(v1.value0.value0)(v.value0.value0)(v2.value0.value0)));
            };
            return Data_Maybe.Nothing.value;
        };
    };
};
var numV = function (v) {
    if (v instanceof Hydra.Num) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var numsV = function (v) {
    if (v instanceof Hydra.Num) {
        return [ v.value0 ];
    };
    if (v instanceof Hydra.Seq) {
        return Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(numV)(v.value0));
    };
    return [  ];
};
var seq$prime = function (v) {
    if (v instanceof Hydra.Val) {
        return numsV(v.value0);
    };
    return [  ];
};
var numOr = function (def) {
    return function (v) {
        if (v instanceof Hydra.Val) {
            return Data_Maybe.fromMaybe(def)(numV(v.value0));
        };
        return def;
    };
};
var modifier = Hydra.toModifier;
var fastVal = function (v) {
    return function (v1) {
        if (v instanceof Data_Maybe.Just && (v.value0 instanceof Hydra.Val && (v.value0.value0 instanceof Hydra.Num && (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Hydra.Val)))) {
            return Data_Maybe.Just.create(Hydra.Val.create(new Hydra.Fast(v.value0.value0.value0, v1.value0.value0)));
        };
        return Data_Maybe.Nothing.value;
    };
};
var entity = Hydra.toTexture;
var colorMod = (function () {
    var joinToColor = function (r) {
        return function (g) {
            return function (b) {
                return function (a) {
                    return Color.rgba(Data_Int.floor(r * 255.0))(Data_Int.floor(g * 255.0))(Data_Int.floor(b * 255.0))(a);
                };
            };
        };
    };
    var extractColors = function (v) {
        if (v instanceof Hydra.C && v.value0 instanceof Hydra.Color) {
            return Data_Maybe.Just.create(Control_Apply.apply(Control_Apply.applyArray)(Control_Apply.apply(Control_Apply.applyArray)(Control_Apply.apply(Control_Apply.applyArray)(Data_Functor.map(Data_Functor.functorArray)(joinToColor)(numsV(v.value0.value0.r)))(numsV(v.value0.value0.g)))(numsV(v.value0.value0.b)))(numsV(v.value0.value0.a)));
        };
        return Data_Maybe.Nothing.value;
    };
    var fromModifiers = function (modifiers) {
        return Data_Array.concat(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(extractColors)(modifiers)));
    };
    return function (v) {
        if (v instanceof Hydra.Tex) {
            return fromModifiers(v.value0.value1);
        };
        return [  ];
    };
})();
var buildSeq5 = function (h1) {
    return function (h2) {
        return function (h3) {
            return function (h4) {
                return function (h5) {
                    var seqOf = function (maybeH) {
                        return Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(seq)(maybeH));
                    };
                    return Hydra.Val.create(Hydra.Seq.create(Data_Semigroup.append(Data_Semigroup.semigroupArray)(seqOf(h1))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(seqOf(h2))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(seqOf(h3))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(seqOf(h4))(seqOf(h5)))))));
                };
            };
        };
    };
};
module.exports = {
    numV: numV,
    numsV: numsV,
    numOr: numOr,
    seq: seq,
    "seq'": seq$prime,
    entity: entity,
    modifier: modifier,
    colorMod: colorMod,
    buildSeq5: buildSeq5,
    performMaybe: performMaybe,
    fastVal: fastVal
};
