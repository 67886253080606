// Generated by purs version 0.14.3
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var IsLayout = function (container, find, fold, sample, size) {
    this.container = container;
    this.find = find;
    this.fold = fold;
    this.sample = sample;
    this.size = size;
};
var IsPinningLayout = function (IsLayout0, pin, unpin) {
    this.IsLayout0 = IsLayout0;
    this.pin = pin;
    this.unpin = unpin;
};
var IsAutoLayout = function (IsLayout0, pack) {
    this.IsLayout0 = IsLayout0;
    this.pack = pack;
};
var unpin = function (dict) {
    return dict.unpin;
};
var size = function (dict) {
    return dict.size;
};
var sample = function (dict) {
    return dict.sample;
};
var pin = function (dict) {
    return dict.pin;
};
var pinMany = function (dictIsPinningLayout) {
    return function (dictFoldable) {
        return function (source) {
            return function (layout) {
                return Data_Foldable.foldr(dictFoldable)(function (v) {
                    return pin(dictIsPinningLayout)(v.value0)(v.value1.value0)(v.value1.value1);
                })(layout)(source);
            };
        };
    };
};
var pack = function (dict) {
    return dict.pack;
};
var packMany = function (dictIsAutoLayout) {
    return function (dictFoldable) {
        return function (source) {
            return function (layout) {
                return Data_Foldable.foldr(dictFoldable)(function (v) {
                    return function (dst) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(dst)(pack(dictIsAutoLayout)(v.value0)(v.value1));
                    };
                })(new Data_Maybe.Just(layout))(source);
            };
        };
    };
};
var packOrDrop = function (dictIsAutoLayout) {
    return function (a) {
        return function (size1) {
            return function (dst) {
                return Data_Maybe.fromMaybe(dst)(pack(dictIsAutoLayout)(a)(size1)(dst));
            };
        };
    };
};
var packOrDropMany = function (dictIsAutoLayout) {
    return function (dictFoldable) {
        return function (source) {
            return function (layout) {
                return Data_Foldable.foldr(dictFoldable)(function (v) {
                    return function (dst) {
                        return packOrDrop(dictIsAutoLayout)(v.value0)(v.value1)(dst);
                    };
                })(layout)(source);
            };
        };
    };
};
var fold = function (dict) {
    return dict.fold;
};
var toArray = function (dictIsLayout) {
    return fold(dictIsLayout)(Data_Array.cons)([  ]);
};
var toUnfoldable = function (dictUnfoldable) {
    return function (dictIsLayout) {
        var $56 = Data_Array.toUnfoldable(dictUnfoldable);
        var $57 = toArray(dictIsLayout);
        return function ($58) {
            return $56($57($58));
        };
    };
};
var toList = function (dictIsLayout) {
    return fold(dictIsLayout)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
};
var find = function (dict) {
    return dict.find;
};
var count = function (dictIsLayout) {
    var $59 = toArray(dictIsLayout);
    return function ($60) {
        return Data_Array.length($59($60));
    };
};
var container = function (dict) {
    return dict.container;
};
var reflow = function (dictIsAutoLayout) {
    return function (size1) {
        var $61 = Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
            return function (dst) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(dst)(pack(dictIsAutoLayout)(v.value0)(v.value1.value1));
            };
        })(Data_Maybe.Just.create(container(dictIsAutoLayout.IsLayout0())(size1)));
        var $62 = toArray(dictIsAutoLayout.IsLayout0());
        return function ($63) {
            return $61($62($63));
        };
    };
};
var reflowOrDrop = function (dictIsAutoLayout) {
    return function (size1) {
        var $64 = Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
            return function (dst) {
                return packOrDrop(dictIsAutoLayout)(v.value0)(v.value1.value1)(dst);
            };
        })(container(dictIsAutoLayout.IsLayout0())(size1));
        var $65 = toArray(dictIsAutoLayout.IsLayout0());
        return function ($66) {
            return $64($65($66));
        };
    };
};
var sqContainer = function (dictIsLayout) {
    return function (n) {
        return container(dictIsLayout)(Data_Vec2.make(n)(n));
    };
};
var atPos$prime$prime = function (dictIsLayout) {
    return function (pos) {
        return function (layout) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return new Data_Tuple.Tuple(v.value0, v.value1.value1);
            })(sample(dictIsLayout)(pos)(layout));
        };
    };
};
var atPos$prime = function (dictIsLayout) {
    return function (pos) {
        return function (layout) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return new Data_Tuple.Tuple(v.value0, v.value1.value0);
            })(sample(dictIsLayout)(pos)(layout));
        };
    };
};
var atPos = function (dictIsLayout) {
    return function (pos) {
        return function (layout) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.fst)(sample(dictIsLayout)(pos)(layout));
        };
    };
};
module.exports = {
    container: container,
    find: find,
    fold: fold,
    pack: pack,
    pin: pin,
    sample: sample,
    size: size,
    unpin: unpin,
    IsLayout: IsLayout,
    IsAutoLayout: IsAutoLayout,
    IsPinningLayout: IsPinningLayout,
    sqContainer: sqContainer,
    count: count,
    toList: toList,
    toArray: toArray,
    toUnfoldable: toUnfoldable,
    atPos: atPos,
    "atPos'": atPos$prime,
    "atPos''": atPos$prime$prime,
    packOrDrop: packOrDrop,
    reflow: reflow,
    reflowOrDrop: reflowOrDrop,
    packMany: packMany,
    packOrDropMany: packOrDropMany,
    pinMany: pinMany
};
