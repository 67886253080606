// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var BoundedRecord = function (OrdRecord0, bottomRecord, topRecord) {
    this.OrdRecord0 = OrdRecord0;
    this.bottomRecord = bottomRecord;
    this.topRecord = topRecord;
};
var Bounded = function (Ord0, bottom, top) {
    this.Ord0 = Ord0;
    this.bottom = bottom;
    this.top = top;
};
var topRecord = function (dict) {
    return dict.topRecord;
};
var top = function (dict) {
    return dict.top;
};
var boundedUnit = new Bounded(function () {
    return Data_Ord.ordUnit;
}, Data_Unit.unit, Data_Unit.unit);
var boundedRecordNil = new BoundedRecord(function () {
    return Data_Ord.ordRecordNil;
}, function (v) {
    return function (v1) {
        return {};
    };
}, function (v) {
    return function (v1) {
        return {};
    };
});
var boundedProxy3 = new Bounded(function () {
    return Data_Ord.ordProxy3;
}, Type_Proxy.Proxy3.value, Type_Proxy.Proxy3.value);
var boundedProxy2 = new Bounded(function () {
    return Data_Ord.ordProxy2;
}, Type_Proxy.Proxy2.value, Type_Proxy.Proxy2.value);
var boundedProxy = new Bounded(function () {
    return Data_Ord.ordProxy;
}, Type_Proxy["Proxy"].value, Type_Proxy["Proxy"].value);
var boundedOrdering = new Bounded(function () {
    return Data_Ord.ordOrdering;
}, Data_Ordering.LT.value, Data_Ordering.GT.value);
var boundedNumber = new Bounded(function () {
    return Data_Ord.ordNumber;
}, $foreign.bottomNumber, $foreign.topNumber);
var boundedInt = new Bounded(function () {
    return Data_Ord.ordInt;
}, $foreign.bottomInt, $foreign.topInt);
var boundedChar = new Bounded(function () {
    return Data_Ord.ordChar;
}, $foreign.bottomChar, $foreign.topChar);
var boundedBoolean = new Bounded(function () {
    return Data_Ord.ordBoolean;
}, false, true);
var bottomRecord = function (dict) {
    return dict.bottomRecord;
};
var boundedRecord = function (dictRowToList) {
    return function (dictBoundedRecord) {
        return new Bounded(function () {
            return Data_Ord.ordRecord()(dictBoundedRecord.OrdRecord0());
        }, bottomRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value), topRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value));
    };
};
var bottom = function (dict) {
    return dict.bottom;
};
var boundedRecordCons = function (dictIsSymbol) {
    return function (dictBounded) {
        return function (dictCons) {
            return function (dictCons1) {
                return function (dictBoundedRecord) {
                    return new BoundedRecord(function () {
                        return Data_Ord.ordRecordCons(dictBoundedRecord.OrdRecord0())()(dictIsSymbol)(dictBounded.Ord0());
                    }, function (v) {
                        return function (rowProxy) {
                            var tail = bottomRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(rowProxy);
                            var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                            var insert = Record_Unsafe.unsafeSet(key);
                            return insert(bottom(dictBounded))(tail);
                        };
                    }, function (v) {
                        return function (rowProxy) {
                            var tail = topRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(rowProxy);
                            var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                            var insert = Record_Unsafe.unsafeSet(key);
                            return insert(top(dictBounded))(tail);
                        };
                    });
                };
            };
        };
    };
};
module.exports = {
    Bounded: Bounded,
    bottom: bottom,
    top: top,
    BoundedRecord: BoundedRecord,
    bottomRecord: bottomRecord,
    topRecord: topRecord,
    boundedBoolean: boundedBoolean,
    boundedInt: boundedInt,
    boundedChar: boundedChar,
    boundedOrdering: boundedOrdering,
    boundedUnit: boundedUnit,
    boundedNumber: boundedNumber,
    boundedProxy: boundedProxy,
    boundedProxy2: boundedProxy2,
    boundedProxy3: boundedProxy3,
    boundedRecordNil: boundedRecordNil,
    boundedRecordCons: boundedRecordCons,
    boundedRecord: boundedRecord,
    EQ: Data_Ord.EQ,
    GT: Data_Ord.GT,
    LT: Data_Ord.LT,
    Ord: Data_Ord.Ord,
    OrdRecord: Data_Ord.OrdRecord,
    compare: Data_Ord.compare
};
