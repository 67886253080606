// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Noodle_Node = require("../Noodle.Node/index.js");
var TellPatch = (function () {
    function TellPatch(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TellPatch.create = function (value0) {
        return function (value1) {
            return new TellPatch(value0, value1);
        };
    };
    return TellPatch;
})();
var NodeCarry = (function () {
    function NodeCarry(value0) {
        this.value0 = value0;
    };
    NodeCarry.create = function (value0) {
        return new NodeCarry(value0);
    };
    return NodeCarry;
})();
var Next = (function () {
    function Next(value0) {
        this.value0 = value0;
    };
    Next.create = function (value0) {
        return new Next(value0);
    };
    return Next;
})();
var SendToInlet = (function () {
    function SendToInlet(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SendToInlet.create = function (value0) {
        return function (value1) {
            return new SendToInlet(value0, value1);
        };
    };
    return SendToInlet;
})();
var SendToOutlet = (function () {
    function SendToOutlet(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SendToOutlet.create = function (value0) {
        return function (value1) {
            return new SendToOutlet(value0, value1);
        };
    };
    return SendToOutlet;
})();
var Replace = (function () {
    function Replace(value0) {
        this.value0 = value0;
    };
    Replace.create = function (value0) {
        return new Replace(value0);
    };
    return Replace;
})();
var ToPatch = (function () {
    function ToPatch(value0) {
        this.value0 = value0;
    };
    ToPatch.create = function (value0) {
        return new ToPatch(value0);
    };
    return ToPatch;
})();
var flagsFor = function (getFlags) {
    return function (node) {
        return Data_Maybe.fromMaybe(App_Style.defaultFlags)(Data_Functor.map(Data_Maybe.functorMaybe)(getFlags)(Noodle_Node.family(node)));
    };
};
module.exports = {
    NodeCarry: NodeCarry,
    SendToInlet: SendToInlet,
    SendToOutlet: SendToOutlet,
    Replace: Replace,
    ToPatch: ToPatch,
    Next: Next,
    TellPatch: TellPatch,
    flagsFor: flagsFor
};
