// Generated by purs version 0.14.3
"use strict";
var $foreign = require("./foreign.js");
var Signal = require("../Signal/index.js");
var Signal_Time = require("../Signal.Time/index.js");
var MouseLeftButton = (function () {
    function MouseLeftButton() {

    };
    MouseLeftButton.value = new MouseLeftButton();
    return MouseLeftButton;
})();
var MouseMiddleButton = (function () {
    function MouseMiddleButton() {

    };
    MouseMiddleButton.value = new MouseMiddleButton();
    return MouseMiddleButton;
})();
var MouseIE8MiddleButton = (function () {
    function MouseIE8MiddleButton() {

    };
    MouseIE8MiddleButton.value = new MouseIE8MiddleButton();
    return MouseIE8MiddleButton;
})();
var MouseRightButton = (function () {
    function MouseRightButton() {

    };
    MouseRightButton.value = new MouseRightButton();
    return MouseRightButton;
})();
var windowDimensions = $foreign.windowDimensionsP(Signal.constant);
var touch = $foreign.touchP(Signal.constant);
var tap = function __do() {
    var touches = touch();
    return Signal.flippedMap(Signal.functorSignal)(touches)(function (t) {
        if (t.length === 0) {
            return false;
        };
        return true;
    });
};
var mousePos = $foreign.mousePosP(Signal.constant);
var mouseButton = $foreign.mouseButtonP(Signal.constant);
var mouseButtonPressed = function (btn) {
    var buttonNumber = (function () {
        if (btn instanceof MouseLeftButton) {
            return 0;
        };
        if (btn instanceof MouseRightButton) {
            return 2;
        };
        if (btn instanceof MouseMiddleButton) {
            return 1;
        };
        if (btn instanceof MouseIE8MiddleButton) {
            return 4;
        };
        throw new Error("Failed pattern match at Signal.DOM (line 48, column 20 - line 52, column 32): " + [ btn.constructor.name ]);
    })();
    return mouseButton(buttonNumber);
};
var keyPressed = $foreign.keyPressedP(Signal.constant);
var animationFrame = $foreign.animationFrameP(Signal.constant)(Signal_Time.now);
module.exports = {
    animationFrame: animationFrame,
    keyPressed: keyPressed,
    mouseButton: mouseButton,
    mouseButtonPressed: mouseButtonPressed,
    touch: touch,
    tap: tap,
    mousePos: mousePos,
    windowDimensions: windowDimensions,
    MouseLeftButton: MouseLeftButton,
    MouseMiddleButton: MouseMiddleButton,
    MouseIE8MiddleButton: MouseIE8MiddleButton,
    MouseRightButton: MouseRightButton
};
