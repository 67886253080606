// Generated by purs version 0.14.3
"use strict";
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Ops = require("../Data.Typelevel.Num.Ops/index.js");
var Data_Typelevel_Num_Reps = require("../Data.Typelevel.Num.Reps/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var y = Data_Function.flip(Data_Vec.index(Data_Typelevel_Num_Sets.natD1)())(Data_Typelevel_Num_Reps.d1);
var x = Data_Function.flip(Data_Vec.index(Data_Typelevel_Num_Sets.natD0)())(Data_Typelevel_Num_Reps.d0);
var w = x;
var toTuple = function (p) {
    return new Data_Tuple.Tuple(x(p), y(p));
};
var r = y;
var make = function (x1) {
    return function (y1) {
        return Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD2)()(Data_Typelevel_Num_Ops.divMod10D1D0)()(Data_Typelevel_Num_Ops.divMod10D2D0))(x1)(Data_Vec.cons(Data_Typelevel_Num_Ops.typelevelSucc(Data_Typelevel_Num_Sets.posD1)()(Data_Typelevel_Num_Ops.divMod10D0D0)()(Data_Typelevel_Num_Ops.divMod10D1D0))(y1)(Data_Vec.empty));
    };
};
var vv = function (n) {
    return make(n)(n);
};
var square = vv;
var xy = vv;
var x$prime = function (dictSemiring) {
    return function (x1) {
        return make(x1)(Data_Semiring.zero(dictSemiring));
    };
};
var w$prime = function (dictSemiring) {
    return x$prime(dictSemiring);
};
var xz = function (dictSemiring) {
    return function (v) {
        return make(x(v))(Data_Semiring.zero(dictSemiring));
    };
};
var wz = function (dictSemiring) {
    return xz(dictSemiring);
};
var y$prime = function (dictSemiring) {
    return function (y1) {
        return make(Data_Semiring.zero(dictSemiring))(y1);
    };
};
var r$prime = function (dictSemiring) {
    return y$prime(dictSemiring);
};
var zero = make(0.0)(0.0);
var zy = function (dictSemiring) {
    return function (v) {
        return make(Data_Semiring.zero(dictSemiring))(y(v));
    };
};
var zh = function (dictSemiring) {
    return zy(dictSemiring);
};
var zr = function (dictSemiring) {
    return zy(dictSemiring);
};
var lz = function (dictSemiring) {
    return xz(dictSemiring);
};
var l$prime = function (dictSemiring) {
    return x$prime(dictSemiring);
};
var l = x;
var h$prime = function (dictSemiring) {
    return y$prime(dictSemiring);
};
var h = y;
var inside = function (dictSemiring) {
    return function (dictOrd) {
        return function (pos) {
            return function (v) {
                var py = y(pos);
                var px = x(pos);
                var by = y(v.value0);
                var bx = x(v.value0);
                var bw = w(v.value1);
                var bh = h(v.value1);
                return Data_Ord.greaterThanOrEq(dictOrd)(px)(bx) && (Data_Ord.lessThanOrEq(dictOrd)(px)(Data_Semiring.add(dictSemiring)(bx)(bw)) && (Data_Ord.greaterThanOrEq(dictOrd)(py)(by) && Data_Ord.lessThanOrEq(dictOrd)(py)(Data_Semiring.add(dictSemiring)(by)(bh))));
            };
        };
    };
};
var inside$prime = function (dictSemiring) {
    return function (dictOrd) {
        return function (pos) {
            return function (size) {
                return inside(dictSemiring)(dictOrd)(pos)(new Data_Tuple.Tuple(Data_Semiring.zero(Data_Vec.semiringVec(dictSemiring)(Data_Typelevel_Num_Sets.natD2)), size));
            };
        };
    };
};
var fromTuple = Data_Tuple.uncurry(make);
var div = function (dictEuclideanRing) {
    return function (v1) {
        return function (v2) {
            return make(Data_EuclideanRing.div(dictEuclideanRing)(x(v1))(x(v2)))(Data_EuclideanRing.div(dictEuclideanRing)(y(v1))(y(v2)));
        };
    };
};
var area = function (dictSemiring) {
    return function (v) {
        return Data_Semiring.mul(dictSemiring)(w(v))(h(v));
    };
};
module.exports = {
    zero: zero,
    make: make,
    vv: vv,
    xy: xy,
    square: square,
    toTuple: toTuple,
    fromTuple: fromTuple,
    x: x,
    "x'": x$prime,
    y: y,
    "y'": y$prime,
    l: l,
    "l'": l$prime,
    r: r,
    "r'": r$prime,
    w: w,
    "w'": w$prime,
    h: h,
    "h'": h$prime,
    xz: xz,
    zy: zy,
    wz: wz,
    zh: zh,
    lz: lz,
    zr: zr,
    area: area,
    inside: inside,
    "inside'": inside$prime,
    div: div
};
