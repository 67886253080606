// Generated by purs version 0.14.3
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Noodle_Channel_Shape = require("../Noodle.Channel.Shape/index.js");
var Shape = function (x) {
    return x;
};
var newtypeShape_ = new Data_Newtype.Newtype(function () {
    return undefined;
});
var withOutlets = [  ];
var withInlets = [  ];
var outlets = (function () {
    var $26 = Data_Newtype.unwrap();
    return function ($27) {
        return Data_Tuple.snd($26($27));
    };
})();
var outletsBy = function (pred) {
    var $28 = Data_Array.filter(function ($30) {
        return pred(Data_Tuple.snd($30));
    });
    return function ($29) {
        return $28(outlets($29));
    };
};
var outlet = function (name) {
    return function (v) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.snd)(Data_Array.find((function () {
            var $31 = Data_Eq.eq(Data_Eq.eqString)(name);
            return function ($32) {
                return $31(Data_Tuple.fst($32));
            };
        })())(v.value1));
    };
};
var noOutlets = [  ];
var noInlets = [  ];
var move = function (f) {
    return function (g) {
        return function (v) {
            return Shape(new Data_Tuple.Tuple(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(Noodle_Channel_Shape.move(f)(g)))(v.value0), Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(Noodle_Channel_Shape.move(f)(g)))(v.value1)));
        };
    };
};
var make = Data_Tuple.curry(Shape);
var invariantShape = new Data_Functor_Invariant.Invariant(move);
var inlets = (function () {
    var $33 = Data_Newtype.unwrap();
    return function ($34) {
        return Data_Tuple.fst($33($34));
    };
})();
var inletsBy = function (pred) {
    var $35 = Data_Array.filter(function ($37) {
        return pred(Data_Tuple.snd($37));
    });
    return function ($36) {
        return $35(inlets($36));
    };
};
var inlet = function (name) {
    return function (v) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.snd)(Data_Array.find((function () {
            var $38 = Data_Eq.eq(Data_Eq.eqString)(name);
            return function ($39) {
                return $38(Data_Tuple.fst($39));
            };
        })())(v.value0));
    };
};
var formOutlets = Control_Category.identity(Control_Category.categoryFn);
var formInlets = Control_Category.identity(Control_Category.categoryFn);
var empty = Shape(new Data_Tuple.Tuple(noInlets, noOutlets));
var dimensionsBy = function (pred) {
    return function (v) {
        return new Data_Tuple.Tuple(Data_Array.length(Data_Array.filter(function ($40) {
            return pred(Data_Tuple.snd($40));
        })(v.value0)), Data_Array.length(Data_Array.filter(function ($41) {
            return pred(Data_Tuple.snd($41));
        })(v.value1)));
    };
};
var dimensions = function (v) {
    return new Data_Tuple.Tuple(Data_Array.length(v.value0), Data_Array.length(v.value1));
};
var andOutlet = Data_Array.snoc;
var andInlet = Data_Array.snoc;
module.exports = {
    Shape: Shape,
    empty: empty,
    make: make,
    noInlets: noInlets,
    noOutlets: noOutlets,
    formInlets: formInlets,
    formOutlets: formOutlets,
    withInlets: withInlets,
    withOutlets: withOutlets,
    andInlet: andInlet,
    andOutlet: andOutlet,
    inlet: inlet,
    outlet: outlet,
    inlets: inlets,
    inletsBy: inletsBy,
    outlets: outlets,
    outletsBy: outletsBy,
    dimensions: dimensions,
    dimensionsBy: dimensionsBy,
    move: move,
    newtypeShape_: newtypeShape_,
    invariantShape: invariantShape
};
