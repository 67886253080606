// Generated by purs version 0.14.3
"use strict";
var App_Component_ButtonStrip = require("../App.Component.ButtonStrip/index.js");
var App_Component_Link = require("../App.Component.Link/index.js");
var App_Component_Node = require("../App.Component.Node/index.js");
var App_Component_Patch_Mouse = require("../App.Component.Patch.Mouse/index.js");
var App_Emitters = require("../App.Emitters/index.js");
var App_Layout = require("../App.Layout/index.js");
var App_Layout_BinPack_R2 = require("../App.Layout.BinPack.R2/index.js");
var App_Layout_Optional = require("../App.Layout.Optional/index.js");
var App_Layout_PinBoard = require("../App.Layout.PinBoard/index.js");
var App_Layout_Strip = require("../App.Layout.Strip/index.js");
var App_Mouse = require("../App.Mouse/index.js");
var App_Style = require("../App.Style/index.js");
var App_Style_ClassNames = require("../App.Style.ClassNames/index.js");
var App_Svg_Extra = require("../App.Svg.Extra/index.js");
var Color = require("../Color/index.js");
var Color_Extra = require("../Color.Extra/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Typelevel_Num_Sets = require("../Data.Typelevel.Num.Sets/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Vec = require("../Data.Vec/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Halogen_Component = require("../Halogen.Component/index.js");
var Halogen_HTML = require("../Halogen.HTML/index.js");
var Halogen_HTML_Core = require("../Halogen.HTML.Core/index.js");
var Halogen_HTML_Events = require("../Halogen.HTML.Events/index.js");
var Halogen_Query_HalogenM = require("../Halogen.Query.HalogenM/index.js");
var Halogen_Svg_Attributes = require("../Halogen.Svg.Attributes/index.js");
var Halogen_Svg_Attributes_Transform = require("../Halogen.Svg.Attributes.Transform/index.js");
var Halogen_Svg_Elements = require("../Halogen.Svg.Elements/index.js");
var Halogen_Svg_Elements_None = require("../Halogen.Svg.Elements.None/index.js");
var Noodle_Patch = require("../Noodle.Patch/index.js");
var Noodle_Toolkit = require("../Noodle.Toolkit/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Initialize = (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Receive = (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var FromNode = (function () {
    function FromNode(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FromNode.create = function (value0) {
        return function (value1) {
            return new FromNode(value0, value1);
        };
    };
    return FromNode;
})();
var AddNode = (function () {
    function AddNode(value0) {
        this.value0 = value0;
    };
    AddNode.create = function (value0) {
        return new AddNode(value0);
    };
    return AddNode;
})();
var DetachNode = (function () {
    function DetachNode(value0) {
        this.value0 = value0;
    };
    DetachNode.create = function (value0) {
        return new DetachNode(value0);
    };
    return DetachNode;
})();
var PinNode = (function () {
    function PinNode(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PinNode.create = function (value0) {
        return function (value1) {
            return new PinNode(value0, value1);
        };
    };
    return PinNode;
})();
var Connect = (function () {
    function Connect(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Connect.create = function (value0) {
        return function (value1) {
            return new Connect(value0, value1);
        };
    };
    return Connect;
})();
var Disconnect = (function () {
    function Disconnect(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Disconnect.create = function (value0) {
        return function (value1) {
            return new Disconnect(value0, value1);
        };
    };
    return Disconnect;
})();
var HandleMouse = (function () {
    function HandleMouse(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    HandleMouse.create = function (value0) {
        return function (value1) {
            return new HandleMouse(value0, value1);
        };
    };
    return HandleMouse;
})();
var extract = function (v) {
    return {
        area: v.area,
        patchState: v.patchState
    };
};
var areaOf = function (getFlags) {
    return function (style) {
        return function (flow) {
            return function (patch) {
                return function (nodeId) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(App_Component_Node.areaOf(getFlags)(style)(flow))(Noodle_Patch.findNode(nodeId)(patch));
                };
            };
        };
    };
};
var handleAction = function (dictMonadEffect) {
    var whereInsideNode = function (state) {
        return App_Component_Node.whereInside(state.getFlags)(state.style)(state.flow);
    };
    var topLinkAt = function (inletPath) {
        return function (patch) {
            return Data_Array.head(Data_Functor.mapFlipped(Data_Functor.functorArray)(Noodle_Patch.linksLeadingTo(inletPath)(patch))(Data_Tuple.fst));
        };
    };
    var liftSubject = function (nodeId) {
        return function (v) {
            if (v instanceof App_Component_Node.Header) {
                return new App_Component_Patch_Mouse.Header(nodeId);
            };
            if (v instanceof App_Component_Node.Inlet) {
                return App_Component_Patch_Mouse.Inlet.create(new Data_Tuple.Tuple(nodeId, v.value0));
            };
            if (v instanceof App_Component_Node.Outlet) {
                return App_Component_Patch_Mouse.Outlet.create(new Data_Tuple.Tuple(nodeId, v.value0));
            };
            throw new Error("Failed pattern match at App.Component.Patch (line 444, column 9 - line 444, column 71): " + [ nodeId.constructor.name, v.constructor.name ]);
        };
    };
    var findNodeInPinned = function (state) {
        return Data_Function.flip(App_Layout["atPos'"](App_Layout_PinBoard.isLayoutPinBoard))(state.pinned);
    };
    var findNodeInLayout = function (state) {
        return Data_Function.flip(App_Layout_Optional["atPos'"](App_Layout_BinPack_R2.bin2IsLayout))(state.layout);
    };
    var findSubjectUnderPos = function (state) {
        return function (pos) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(findNodeInLayout(state)(pos))(findNodeInPinned(state)(pos)))(function (v) {
                return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Noodle_Patch.findNode(v.value0)(state.patch))(Data_Function.flip(whereInsideNode(state))(v.value1)))(liftSubject(v.value0)))(Data_Tuple.Tuple.create(v.value1));
            });
        };
    };
    var draggableToClickable = function (v) {
        return function (v1) {
            return function (v2) {
                return Data_Maybe.Nothing.value;
            };
        };
    };
    var clickableToDraggable = function (patch) {
        return function (v) {
            return function (v1) {
                if (v1 instanceof App_Component_Patch_Mouse.Header) {
                    return Data_Maybe.Just.create(new App_Component_Patch_Mouse.Node(v1.value0));
                };
                if (v1 instanceof App_Component_Patch_Mouse.Inlet) {
                    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(topLinkAt(v1.value0)(patch))(function (outletPath) {
                        return App_Component_Patch_Mouse.Link.create(outletPath)(new Data_Maybe.Just(v1.value0));
                    });
                };
                if (v1 instanceof App_Component_Patch_Mouse.Outlet) {
                    return Data_Maybe.Just.create(new App_Component_Patch_Mouse.Link(v1.value0, Data_Maybe.Nothing.value));
                };
                throw new Error("Failed pattern match at App.Component.Patch (line 433, column 9 - line 433, column 98): " + [ patch.constructor.name, v.constructor.name, v1.constructor.name ]);
            };
        };
    };
    return function (v) {
        if (v instanceof Initialize) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window)))(function (document) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM["subscribe'"]((function () {
                    var $235 = App_Emitters.mouseDown(document);
                    return function ($236) {
                        return $235(HandleMouse.create($236));
                    };
                })()))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM["subscribe'"]((function () {
                        var $237 = App_Emitters.mouseMove(document);
                        return function ($238) {
                            return $237(HandleMouse.create($238));
                        };
                    })()))(function () {
                        return Halogen_Query_HalogenM["subscribe'"]((function () {
                            var $239 = App_Emitters.mouseUp(document);
                            return function ($240) {
                                return $239(HandleMouse.create($240));
                            };
                        })());
                    });
                });
            });
        };
        if (v instanceof Receive) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                var $63 = {};
                for (var $64 in state) {
                    if ({}.hasOwnProperty.call(state, $64)) {
                        $63[$64] = state[$64];
                    };
                };
                $63.area = v.value0.area;
                $63.layout = App_Layout.reflowOrDrop(App_Layout_BinPack_R2.bin2IsAutoLayout)(v.value0.area)(state.layout);
                $63.buttonStrip = App_Component_ButtonStrip.reflow(Data_Ord.ordString)(Data_Vec2.w(v.value0.area))(state.buttonStrip);
                $63.patchState = v.value0.patchState;
                return $63;
            });
        };
        if (v instanceof AddNode) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                return v1.toolkit;
            }))(function (toolkit) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Toolkit.spawn(v.value0)(toolkit)))(function (maybeNode) {
                    if (maybeNode instanceof Data_Maybe.Just) {
                        return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                            var nodeName = Noodle_Patch.addUniqueNodeId(state.patch)(v.value0);
                            var nodeArea = App_Component_Node.areaOf(state.getFlags)(state.style)(state.flow)(maybeNode.value0);
                            var $68 = {};
                            for (var $69 in state) {
                                if ({}.hasOwnProperty.call(state, $69)) {
                                    $68[$69] = state[$69];
                                };
                            };
                            $68.patch = Noodle_Patch.addNode(nodeName)(maybeNode.value0)(state.patch);
                            $68.layout = Data_Maybe.fromMaybe(state.layout)(App_Layout_Optional.pack(App_Layout_BinPack_R2.bin2IsAutoLayout)(nodeName)(nodeArea)(state.layout));
                            return $68;
                        });
                    };
                    if (maybeNode instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at App.Component.Patch (line 342, column 9 - line 355, column 33): " + [ maybeNode.constructor.name ]);
                });
            });
        };
        if (v instanceof DetachNode) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                var $73 = {};
                for (var $74 in state) {
                    if ({}.hasOwnProperty.call(state, $74)) {
                        $73[$74] = state[$74];
                    };
                };
                $73.layout = App_Layout_Optional.abandon(App_Layout_BinPack_R2.functorBin2)(Data_Eq.eqString)(v.value0)(state.layout);
                $73.pinned = App_Layout.unpin(App_Layout_PinBoard.isPinningLayoutPinBoard)(Data_Eq.eqString)(v.value0)(state.pinned);
                return $73;
            });
        };
        if (v instanceof PinNode) {
            return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                var nodeArea = Data_Maybe.fromMaybe(Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2)))(areaOf(state.getFlags)(state.style)(state.flow)(state.patch)(v.value0));
                var $77 = {};
                for (var $78 in state) {
                    if ({}.hasOwnProperty.call(state, $78)) {
                        $77[$78] = state[$78];
                    };
                };
                $77.pinned = App_Layout.pin(App_Layout_PinBoard.isPinningLayoutPinBoard)(v.value0)(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(v.value1)(state.offset))(nodeArea)(state.pinned);
                return $77;
            });
        };
        if (v instanceof Connect) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Patch.disconnect(v.value0)(v.value1)(state.patch)))(function (nextPatch) {
                    return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Patch.connect(v.value0)(v.value1)(nextPatch)))(function (nextPatch$prime) {
                        return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                            var $82 = {};
                            for (var $83 in v1) {
                                if ({}.hasOwnProperty.call(v1, $83)) {
                                    $82[$83] = v1[$83];
                                };
                            };
                            $82.patch = nextPatch$prime;
                            return $82;
                        });
                    });
                });
            });
        };
        if (v instanceof Disconnect) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Patch.disconnect(v.value0)(v.value1)(state.patch)))(function (nextPatch) {
                    return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        var $87 = {};
                        for (var $88 in v1) {
                            if ({}.hasOwnProperty.call(v1, $88)) {
                                $87[$88] = v1[$88];
                            };
                        };
                        $87.patch = nextPatch;
                        return $87;
                    });
                });
            });
        };
        if (v instanceof FromNode && v.value1 instanceof App_Component_Node.Replace) {
            return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
        };
        if (v instanceof FromNode && v.value1 instanceof App_Component_Node.Remove) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Noodle_Patch.removeNode(v.value0)(state.patch)))(function (nextPatch) {
                    return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                        var $95 = {};
                        for (var $96 in v1) {
                            if ({}.hasOwnProperty.call(v1, $96)) {
                                $95[$96] = v1[$96];
                            };
                        };
                        $95.patch = nextPatch;
                        return $95;
                    });
                });
            });
        };
        if (v instanceof FromNode && v.value1 instanceof App_Component_Node.ToPatch) {
            return Halogen_Query_HalogenM.raise(v.value1.value0);
        };
        if (v instanceof HandleMouse) {
            return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                var bsOffset = Data_Vec2.zh(Data_Semiring.semiringNumber)(App_Layout_Strip.size(state.buttonStrip));
                var mouseOffset = Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(state.offset)(bsOffset);
                var nextMouse = App_Component_Patch_Mouse.apply((function () {
                    var $241 = findSubjectUnderPos(state);
                    var $242 = Data_Function.flip(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2)))(mouseOffset);
                    return function ($243) {
                        return $241($242($243));
                    };
                })())(clickableToDraggable(state.patch))(draggableToClickable(state.patch))(v.value1)(state.mouse);
                return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                    var $103 = {};
                    for (var $104 in v1) {
                        if ({}.hasOwnProperty.call(v1, $104)) {
                            $103[$104] = v1[$104];
                        };
                    };
                    $103.mouse = nextMouse;
                    return $103;
                }))(function () {
                    if (nextMouse instanceof App_Mouse.StartDrag && nextMouse.value1.value1 instanceof App_Component_Patch_Mouse.Node) {
                        return handleAction(dictMonadEffect)(new DetachNode(nextMouse.value1.value1.value0));
                    };
                    if (nextMouse instanceof App_Mouse.StartDrag && (nextMouse.value1.value1 instanceof App_Component_Patch_Mouse.Link && nextMouse.value1.value1.value1 instanceof Data_Maybe.Just)) {
                        return handleAction(dictMonadEffect)(new Disconnect(nextMouse.value1.value1.value0, nextMouse.value1.value1.value1.value0));
                    };
                    if (nextMouse instanceof App_Mouse.DropAt && nextMouse.value1.value1 instanceof App_Component_Patch_Mouse.Node) {
                        return handleAction(dictMonadEffect)(PinNode.create(nextMouse.value1.value1.value0)(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(nextMouse.value0)(bsOffset))(nextMouse.value1.value0)));
                    };
                    if (nextMouse instanceof App_Mouse.DropAt && (nextMouse.value1.value1 instanceof App_Component_Patch_Mouse.Link && nextMouse.value1.value1.value1 instanceof Data_Maybe.Nothing)) {
                        var v1 = findSubjectUnderPos(state)(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(nextMouse.value0)(mouseOffset));
                        if (v1 instanceof Data_Maybe.Just && v1.value0.value1 instanceof App_Component_Patch_Mouse.Inlet) {
                            return handleAction(dictMonadEffect)(new Connect(nextMouse.value1.value1.value0, v1.value0.value1.value0));
                        };
                        return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                    };
                    return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                });
            });
        };
        throw new Error("Failed pattern match at App.Component.Patch (line 320, column 16 - line 429, column 26): " + [ v.constructor.name ]);
    };
};
var addNodesFrom = function (getFlags) {
    return function (style) {
        return function (flow) {
            return function (patch) {
                return function (layout) {
                    return Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
                        return function (layout$prime) {
                            return Data_Maybe.fromMaybe(layout$prime)(App_Layout_Optional.pack(App_Layout_BinPack_R2.bin2IsAutoLayout)(v.value0)(App_Component_Node.areaOf(getFlags)(style)(flow)(v.value1))(layout$prime));
                        };
                    })(layout)(Noodle_Patch.nodes(patch));
                };
            };
        };
    };
};
var initialState = function (v) {
    return {
        patch: v.patch,
        toolkit: v.toolkit,
        style: v.style,
        flow: v.flow,
        area: v.area,
        patchState: v.patchState,
        offset: v.offset,
        getFlags: v.getFlags,
        markings: v.markings,
        customNodeBody: v.customNodeBody,
        layout: addNodesFrom(v.getFlags)(v.style)(v.flow)(v.patch)(App_Layout.container(App_Layout_BinPack_R2.bin2IsLayout)(v.area)),
        buttonStrip: App_Component_ButtonStrip.make(Data_Vec2.w(v.area))(Noodle_Toolkit.nodeFamilies(v.toolkit)),
        pinned: App_Layout.container(App_Layout_PinBoard.isLayoutPinBoard)(v.area),
        mouse: App_Component_Patch_Mouse.init
    };
};
var _node = Type_Proxy["Proxy"].value;
var render = function (dictMonadEffect) {
    return function (state) {
        var nodeButton = function (v) {
            return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.nodeButton(v.value1)), App_Svg_Extra["translateTo'"](v.value0), Halogen_HTML_Events.onClick(function (v1) {
                return new AddNode(v.value1);
            }) ])([ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(Data_Vec2.w(App_Component_ButtonStrip.buttonSize)), Halogen_Svg_Attributes.height(Data_Vec2.h(App_Component_ButtonStrip.buttonSize)), Halogen_Svg_Attributes.fill(Data_Functor.map(Data_Maybe.functorMaybe)(Color_Extra.toSvg)(Control_Alt.alt(Data_Maybe.altMaybe)(state.markings.node(v.value1))(new Data_Maybe.Just(state.style.nodeTab.background)))), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(state.style.nodeTab.stroke))), Halogen_Svg_Attributes.strokeWidth(1.0) ]), Halogen_Svg_Elements.text([  ])([ Halogen_HTML_Core.text("+ " + v.value1) ]) ]);
        };
        var nodeButtons = Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.nodesTabs) ])(Data_Functor.map(Data_Functor.functorArray)(nodeButton)(App_Layout_Strip.unfold(state.buttonStrip)));
        var mouseState = Halogen_Svg_Elements.text([ App_Svg_Extra["translateTo'"](Data_Vec2.make(500.0)(-20.0)), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color.white))), Halogen_Svg_Attributes.class_("debug") ])([ Halogen_HTML_Core.text(Data_Show.show(App_Mouse.showMouse(Data_Tuple.showTuple(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(App_Component_Patch_Mouse.showClickable))(Data_Tuple.showTuple(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(App_Component_Patch_Mouse.showClickable))(Data_Tuple.showTuple(Data_Vec.showVec(Data_Typelevel_Num_Sets.natD2)(Data_Show.showNumber))(App_Component_Patch_Mouse.showDraggable)))(state.mouse)) ]);
        var findNodePosition = function (nodeName) {
            return Control_Alt.alt(Data_Maybe.altMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(App_Layout_Optional.find(App_Layout_BinPack_R2.bin2IsLayout)(Data_Eq.eqString)(nodeName)(state.layout))(Data_Tuple.fst))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(App_Layout.find(App_Layout_PinBoard.isLayoutPinBoard)(Data_Eq.eqString)(nodeName)(state.pinned))(Data_Tuple.fst));
        };
        var linkEndsPositions = function (v) {
            return function (v1) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (outletConnectorPos) {
                    return function (srcNodePos) {
                        return function (inletConnectorPos) {
                            return function (dstNodePos) {
                                return new Data_Tuple.Tuple(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(srcNodePos)(outletConnectorPos), Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(dstNodePos)(inletConnectorPos));
                            };
                        };
                    };
                })(Control_Bind.bind(Data_Maybe.bindMaybe)(Noodle_Patch.findNode(v.value0)(state.patch))(App_Component_Node.outletConnectorPos(state.getFlags)(state.style)(state.flow)(v.value1))))(findNodePosition(v.value0)))(Control_Bind.bind(Data_Maybe.bindMaybe)(Noodle_Patch.findNode(v1.value0)(state.patch))(App_Component_Node.inletConnectorPos(state.getFlags)(state.style)(state.flow)(v1.value1))))(findNodePosition(v1.value0));
            };
        };
        var drawLink = function (v) {
            return function (v1) {
                return function (v2) {
                    if (v instanceof App_Style.Straight) {
                        return Halogen_Svg_Elements.line([ Halogen_Svg_Attributes.x1(v1.value0), Halogen_Svg_Attributes.x2(v2.value0), Halogen_Svg_Attributes.y1(v1.value1), Halogen_Svg_Attributes.y2(v2.value1), Halogen_Svg_Attributes.strokeWidth(3.0), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.white))) ]);
                    };
                    if (v instanceof App_Style.Curve) {
                        return Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d((function () {
                            if (state.flow instanceof App_Style.Vertical) {
                                return App_Component_Link.bezierByH({
                                    x0: v1.value0,
                                    y0: v1.value1,
                                    x1: v2.value0,
                                    y1: v2.value1
                                });
                            };
                            if (state.flow instanceof App_Style.Horizontal) {
                                return App_Component_Link.bezierByV({
                                    x0: v1.value0,
                                    y0: v1.value1,
                                    x1: v2.value0,
                                    y1: v2.value1
                                });
                            };
                            throw new Error("Failed pattern match at App.Component.Patch (line 255, column 27 - line 257, column 77): " + [ state.flow.constructor.name ]);
                        })()), Halogen_Svg_Attributes.strokeWidth(1.5), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.white))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color_Extra.transparent))) ]);
                    };
                    if (v instanceof App_Style.Pipe) {
                        return Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d(App_Component_Link.pipeByH({
                            x0: v1.value0,
                            y0: v1.value1,
                            x1: v2.value0,
                            y1: v2.value1
                        })), Halogen_Svg_Attributes.strokeWidth(1.5), Halogen_Svg_Attributes.stroke(Data_Maybe.Just.create(Color_Extra.toSvg(Color.white))), Halogen_Svg_Attributes.fill(Data_Maybe.Just.create(Color_Extra.toSvg(Color_Extra.transparent))) ]);
                    };
                    throw new Error("Failed pattern match at App.Component.Patch (line 247, column 9 - line 252, column 18): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
        var bsBottomY = Data_Vec2.h(App_Layout_Strip.size(state.buttonStrip));
        var bsOffset = Data_Vec2["h'"](Data_Semiring.semiringNumber)(bsBottomY);
        var closedLink = function (v) {
            var v1 = linkEndsPositions(v.value0)(v.value1);
            if (v1 instanceof Data_Maybe.Just) {
                var v2 = Data_Vec2.toTuple(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(bsOffset)(v1.value0.value0));
                var v3 = Data_Vec2.toTuple(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(bsOffset)(v1.value0.value1));
                return drawLink(state.style.link.type)(new Data_Tuple.Tuple(v2.value0, v2.value1))(new Data_Tuple.Tuple(v3.value0, v3.value1));
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Halogen_Svg_Elements_None.none;
            };
            throw new Error("Failed pattern match at App.Component.Patch (line 279, column 13 - line 285, column 35): " + [ v1.constructor.name ]);
        };
        var existingLinks = Halogen_Svg_Elements.g([  ])(Data_Functor.map(Data_Functor.functorArray)(closedLink)(Data_Array.fromFoldable(Data_Set.foldableSet)(Noodle_Patch.links(state.patch))));
        var openLink = function (pos) {
            return function (v) {
                var v1 = Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create)(Control_Bind.bind(Data_Maybe.bindMaybe)(Noodle_Patch.findNode(v.value0)(state.patch))(App_Component_Node.outletConnectorPos(state.getFlags)(state.style)(state.flow)(v.value1))))(findNodePosition(v.value0));
                if (v1 instanceof Data_Maybe.Just) {
                    var v2 = Data_Vec2.toTuple(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Semiring.add(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2))(bsOffset)(v1.value0.value1))(v1.value0.value0));
                    var v3 = Data_Vec2.toTuple(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(pos)(state.offset));
                    return drawLink(state.style.link.type)(new Data_Tuple.Tuple(v2.value0, v2.value1))(new Data_Tuple.Tuple(v3.value0, v3.value1));
                };
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Halogen_Svg_Elements_None.none;
                };
                throw new Error("Failed pattern match at App.Component.Patch (line 290, column 13 - line 300, column 35): " + [ v1.constructor.name ]);
            };
        };
        var node$prime = function (v) {
            return Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.transform([ Halogen_Svg_Attributes_Transform.Translate.create(v.x)(bsBottomY + v.y) ]), Halogen_Svg_Attributes.classes(App_Style_ClassNames.node(state.flow)(v.name)) ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                return "node";
            }))(Data_Ord.ordString)(_node)(v.name)(App_Component_Node.component(dictMonadEffect))({
                node: v.node,
                name: v.name,
                style: state.style,
                flow: state.flow,
                getFlags: state.getFlags,
                markings: state.markings,
                controlArea: state.customNodeBody,
                linksCount: Noodle_Patch.linksCountAtNode(v.name)(state.patch),
                patchState: state.patchState
            })(FromNode.create(v.name)) ]);
        };
        var assocNode = function (v) {
            return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Noodle_Patch.findNode(v.value0)(state.patch))(function (v1) {
                return {
                    name: v.value0,
                    node: v1,
                    x: Data_Vec2.x(v.value1.value0),
                    y: Data_Vec2.y(v.value1.value0),
                    w: Data_Vec2.w(v.value1.value1),
                    h: Data_Vec2.h(v.value1.value1)
                };
            });
        };
        var floatingNode = function (pos) {
            return function (v) {
                var nodeArea = Data_Maybe.fromMaybe(Data_Semiring.zero(Data_Vec.semiringVec(Data_Semiring.semiringNumber)(Data_Typelevel_Num_Sets.natD2)))(areaOf(state.getFlags)(state.style)(state.flow)(state.patch)(v.value0));
                var v1 = assocNode(new Data_Tuple.Tuple(v.value0, new Data_Tuple.Tuple(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(Data_Ring.sub(Data_Vec.ringVec(Data_Ring.ringNumber)(Data_Typelevel_Num_Sets.natD2))(pos)(v.value1))(bsOffset))(state.offset), nodeArea)));
                if (v1 instanceof Data_Maybe.Just) {
                    return node$prime(v1.value0);
                };
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Halogen_Svg_Elements_None.none;
                };
                throw new Error("Failed pattern match at App.Component.Patch (line 242, column 16 - line 244, column 35): " + [ v1.constructor.name ]);
            };
        };
        var whatIsBeingDragged = function (v) {
            if (v instanceof App_Mouse.StartDrag && v.value1.value1 instanceof App_Component_Patch_Mouse.Node) {
                return Halogen_Svg_Elements_None.none;
            };
            if (v instanceof App_Mouse.Dragging && v.value2.value1 instanceof App_Component_Patch_Mouse.Node) {
                return floatingNode(v.value1)(new Data_Tuple.Tuple(v.value2.value1.value0, v.value2.value0));
            };
            if (v instanceof App_Mouse.Dragging && v.value2.value1 instanceof App_Component_Patch_Mouse.Link) {
                return openLink(v.value1)(v.value2.value1.value0);
            };
            return Halogen_Svg_Elements_None.none;
        };
        var packedNodes$prime = Data_List.catMaybes(Data_Functor.map(Data_List_Types.functorList)(assocNode)(App_Layout_Optional.toList(App_Layout_BinPack_R2.bin2IsLayout)(state.layout)));
        var nodesLayout = Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.nodes) ])(Data_Functor.map(Data_Functor.functorArray)(node$prime)(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(packedNodes$prime)));
        var pinnedNodes$prime = Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(assocNode)(App_Layout.toArray(App_Layout_PinBoard.isLayoutPinBoard)(state.pinned)));
        var pinnedNodes = Halogen_Svg_Elements.g([ Halogen_Svg_Attributes.classes(App_Style_ClassNames.nodes) ])(Data_Functor.map(Data_Functor.functorArray)(node$prime)(pinnedNodes$prime));
        return Halogen_Svg_Elements.g([  ])([ mouseState, nodeButtons, existingLinks, nodesLayout, pinnedNodes, whatIsBeingDragged(state.mouse) ]);
    };
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render(dictMonadEffect),
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($244) {
                return Data_Maybe.Just.create(Receive.create(extract($244)));
            },
            initialize: new Data_Maybe.Just(Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
var _link = Type_Proxy["Proxy"].value;
module.exports = {
    "_node": _node,
    "_link": _link,
    Initialize: Initialize,
    Receive: Receive,
    FromNode: FromNode,
    AddNode: AddNode,
    DetachNode: DetachNode,
    PinNode: PinNode,
    Connect: Connect,
    Disconnect: Disconnect,
    HandleMouse: HandleMouse,
    initialState: initialState,
    render: render,
    handleAction: handleAction,
    extract: extract,
    component: component,
    addNodesFrom: addNodesFrom,
    areaOf: areaOf
};
