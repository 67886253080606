// Generated by purs version 0.14.3
"use strict";
var App_Style = require("../App.Style/index.js");
var Color = require("../Color/index.js");
var Data_Vec2 = require("../Data.Vec2/index.js");
var slot = {
    connector: new App_Style.DoubleCircle(3.0, 5.0),
    offset: Data_Vec2.make(10.0)(0.0),
    direction: App_Style.Inside.value,
    info: App_Style.Always.value,
    strokeWidth: 1.0,
    stroke: Color.rgb(0)(0)(0),
    fill: Color.rgb(255)(255)(255),
    dimWhenNoLinks: true,
    label: {
        color: Color.rgb(255)(255)(255),
        maxWidth: 30.0
    },
    value: {
        color: Color.rgb(255)(255)(255),
        maxWidth: 10.0
    }
};
module.exports = {
    slot: slot
};
