// Generated by purs version 0.14.3
"use strict";
var Data_Show = require("../Data.Show/index.js");
var OutsideBody = (function () {
    function OutsideBody() {

    };
    OutsideBody.value = new OutsideBody();
    return OutsideBody;
})();
var InsideBody = (function () {
    function InsideBody() {

    };
    InsideBody.value = new InsideBody();
    return InsideBody;
})();
var Always = (function () {
    function Always() {

    };
    Always.value = new Always();
    return Always;
})();
var OnHover = (function () {
    function OnHover() {

    };
    OnHover.value = new OnHover();
    return OnHover;
})();
var Never = (function () {
    function Never() {

    };
    Never.value = new Never();
    return Never;
})();
var Inside = (function () {
    function Inside() {

    };
    Inside.value = new Inside();
    return Inside;
})();
var Between = (function () {
    function Between() {

    };
    Between.value = new Between();
    return Between;
})();
var Outside = (function () {
    function Outside() {

    };
    Outside.value = new Outside();
    return Outside;
})();
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Solid = (function () {
    function Solid(value0) {
        this.value0 = value0;
    };
    Solid.create = function (value0) {
        return new Solid(value0);
    };
    return Solid;
})();
var Blurred = (function () {
    function Blurred(value0) {
        this.value0 = value0;
    };
    Blurred.create = function (value0) {
        return new Blurred(value0);
    };
    return Blurred;
})();
var Title = (function () {
    function Title() {

    };
    Title.value = new Title();
    return Title;
})();
var Ribbon = (function () {
    function Ribbon() {

    };
    Ribbon.value = new Ribbon();
    return Ribbon;
})();
var OnlyInlets = (function () {
    function OnlyInlets() {

    };
    OnlyInlets.value = new OnlyInlets();
    return OnlyInlets;
})();
var OnlyOutlets = (function () {
    function OnlyOutlets() {

    };
    OnlyOutlets.value = new OnlyOutlets();
    return OnlyOutlets;
})();
var UserBody = (function () {
    function UserBody(value0) {
        this.value0 = value0;
    };
    UserBody.create = function (value0) {
        return new UserBody(value0);
    };
    return UserBody;
})();
var InletsAndOutlets = (function () {
    function InletsAndOutlets() {

    };
    InletsAndOutlets.value = new InletsAndOutlets();
    return InletsAndOutlets;
})();
var UserBodyBetweenSlots = (function () {
    function UserBodyBetweenSlots() {

    };
    UserBodyBetweenSlots.value = new UserBodyBetweenSlots();
    return UserBodyBetweenSlots;
})();
var UserBodyBetweenSlotsMin = (function () {
    function UserBodyBetweenSlotsMin(value0) {
        this.value0 = value0;
    };
    UserBodyBetweenSlotsMin.create = function (value0) {
        return new UserBodyBetweenSlotsMin(value0);
    };
    return UserBodyBetweenSlotsMin;
})();
var Vertical = (function () {
    function Vertical() {

    };
    Vertical.value = new Vertical();
    return Vertical;
})();
var Horizontal = (function () {
    function Horizontal() {

    };
    Horizontal.value = new Horizontal();
    return Horizontal;
})();
var Straight = (function () {
    function Straight() {

    };
    Straight.value = new Straight();
    return Straight;
})();
var Curve = (function () {
    function Curve() {

    };
    Curve.value = new Curve();
    return Curve;
})();
var Pipe = (function () {
    function Pipe() {

    };
    Pipe.value = new Pipe();
    return Pipe;
})();
var Square = (function () {
    function Square(value0) {
        this.value0 = value0;
    };
    Square.create = function (value0) {
        return new Square(value0);
    };
    return Square;
})();
var Rect = (function () {
    function Rect(value0) {
        this.value0 = value0;
    };
    Rect.create = function (value0) {
        return new Rect(value0);
    };
    return Rect;
})();
var Circle = (function () {
    function Circle(value0) {
        this.value0 = value0;
    };
    Circle.create = function (value0) {
        return new Circle(value0);
    };
    return Circle;
})();
var DoubleCircle = (function () {
    function DoubleCircle(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DoubleCircle.create = function (value0) {
        return function (value1) {
            return new DoubleCircle(value0, value1);
        };
    };
    return DoubleCircle;
})();
var showSlotDirection = new Data_Show.Show(function (v) {
    if (v instanceof Inside) {
        return "inside";
    };
    if (v instanceof Outside) {
        return "outside";
    };
    if (v instanceof Between) {
        return "between";
    };
    throw new Error("Failed pattern match at App.Style (line 219, column 1 - line 222, column 29): " + [ v.constructor.name ]);
});
var defaultFlags = {
    hasTitle: true,
    hasRibbon: true,
    controlArea: false,
    hasRemoveButton: true
};
module.exports = {
    Square: Square,
    Rect: Rect,
    Circle: Circle,
    DoubleCircle: DoubleCircle,
    Vertical: Vertical,
    Horizontal: Horizontal,
    Inside: Inside,
    Between: Between,
    Outside: Outside,
    Always: Always,
    OnHover: OnHover,
    Never: Never,
    Straight: Straight,
    Curve: Curve,
    Pipe: Pipe,
    Title: Title,
    Ribbon: Ribbon,
    OnlyInlets: OnlyInlets,
    OnlyOutlets: OnlyOutlets,
    UserBody: UserBody,
    InletsAndOutlets: InletsAndOutlets,
    UserBodyBetweenSlots: UserBodyBetweenSlots,
    UserBodyBetweenSlotsMin: UserBodyBetweenSlotsMin,
    OutsideBody: OutsideBody,
    InsideBody: InsideBody,
    None: None,
    Solid: Solid,
    Blurred: Blurred,
    defaultFlags: defaultFlags,
    showSlotDirection: showSlotDirection
};
