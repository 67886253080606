// Generated by purs version 0.14.3
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var AutoOrient = (function () {
    function AutoOrient() {

    };
    AutoOrient.value = new AutoOrient();
    return AutoOrient;
})();
var AutoStartReverse = (function () {
    function AutoStartReverse() {

    };
    AutoStartReverse.value = new AutoStartReverse();
    return AutoStartReverse;
})();
var showOrient = new Data_Show.Show(function (v) {
    if (v instanceof AutoOrient) {
        return "auto";
    };
    if (v instanceof AutoStartReverse) {
        return "auto-start-reverse";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Orient (line 15, column 10 - line 17, column 45): " + [ v.constructor.name ]);
});
var printOrient = function (v) {
    if (v instanceof AutoOrient) {
        return "auto";
    };
    if (v instanceof AutoStartReverse) {
        return "auto-start-reverse";
    };
    throw new Error("Failed pattern match at Halogen.Svg.Attributes.Orient (line 22, column 15 - line 24, column 43): " + [ v.constructor.name ]);
};
var eqOrient = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof AutoOrient && y instanceof AutoOrient) {
            return true;
        };
        if (x instanceof AutoStartReverse && y instanceof AutoStartReverse) {
            return true;
        };
        return false;
    };
});
module.exports = {
    AutoOrient: AutoOrient,
    AutoStartReverse: AutoStartReverse,
    printOrient: printOrient,
    eqOrient: eqOrient,
    showOrient: showOrient
};
