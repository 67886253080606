// Generated by purs version 0.14.3
"use strict";
var Color = require("../Color/index.js");
var patchTab = {
    background: Color.rgb(170)(170)(170),
    stroke: Color.rgba(0)(0)(0)(0.7)
};
module.exports = {
    patchTab: patchTab
};
